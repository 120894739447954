import {config} from './../../enviroment';

const ChatWidget = () => {
    const store = JSON.parse(localStorage.getItem('bearer'));
    (function(w, d, s, u) {
        w.RocketChat = function(c) { w.RocketChat._.push(c) };
        w.RocketChat._ = [];
        w.RocketChat.url = u;
        var h = d.getElementsByTagName(s)[0],
              j = d.createElement(s);
        j.async = true;
        j.src = `${config.urls.ROCKET_CHAT_WIDGET}rocketchat-livechat.min.js`;
        h.parentNode.insertBefore(j, h);
        w.RocketChat(function() {
            this.setCustomField('personId', store ? store.personId : '')
        });
        })(window, document, 'script', config.urls.ROCKET_CHAT_WIDGET);


}

export default ChatWidget;