import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AdditionalAttach from "./_additional-attachment.js";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) =>
  createStyles({
    addMoreFile: {
      textTransform: "capitalize",
      color: theme.palette.tertiary.main,
    },
  })
);
let cnt = 0;
export default function Additional({dropdownData,FileContentAll,setFileContentAll}) {
  let classes = useStyles();
  let countItems = localStorage.getItem("countItems");
  let labels = useSelector((store)=>store?.labels?.data?.getLabels?.ICSA_ADDITIONAL_ATTACHMENT);
  if (countItems === null) {
    countItems = [0];
  } else {
    countItems = JSON.parse(countItems);
  }
  const [addCount, setCount] = useState(countItems);
  const countPlus = () => {
    let temp = cnt++;
    setCount([...addCount, temp]);
    countItems = [...addCount, temp];
    localStorage.setItem("countItems", JSON.stringify(countItems));
  };

  return (
    <>
      <div className="horizontalLine">
        <Typography color="primary" variant="h6">
          <b>{labels?.ADDITIONAL_DOCUMENTS}</b>
        </Typography>
      </div>
      <Grid container>
        {dropdownData.length > 0 &&
          addCount.map((x, key) => {
            return <AdditionalAttach count={key} dropdownData={dropdownData} FileContentAll={FileContentAll} setFileContentAll={setFileContentAll}/>;
          })}
      </Grid>
      <Button className={classes.addMoreFile} onClick={countPlus}>
        <b>&#43; {labels?.ADD_MORE_FILES}</b>
      </Button>
    </>
  );
}