export const languageConstants = {
  LABELS_REQUEST: "LABELS_REQUEST",
  LABELS_SUCCESS: "LABELS_SUCCESS",
  LABELS_FAILURE: "LABELS_FAILURE",

  BEARER_REQUEST:"BEARER_REQUEST",
  BEARER_SUCCESS: "BEARER_SUCCESS",
  BEARER_FAILURE: "BEARER_FAILURE",

};

