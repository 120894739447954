import React, { Component, useEffect } from "react";
import { Typography, Grid, Hidden, makeStyles } from "@material-ui/core";
import { useStyles } from "../../../app/base-style";
import "../../../auth/auth.css";
import "../../../app/base-style.css";
import { authStyles } from "../../../auth/auth-style";
import { Card, CardContent } from "@material-ui/core";
import "../../../quick-pay/quick-pay.css";
import { useMediaQuery } from "react-responsive";
import { adminService } from "../../../../services/index";
import { Skeleton } from "@material-ui/lab";
import { store } from "../../../../helpers/store"
import { bearerTokenService } from "../../../../services/bearer.service";
import { useState } from "react";
import NSACommonHeader from "./_new-service-header";
import { useSelector } from "react-redux";
import { paymentService } from '../service/payment-service';
import { TrendingUpRounded } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {saveNewServiceAgreementData} from "../model/actions"
import { useLocation } from "react-router-dom";
import { routePaths } from "../../../config/route-paths";
import { history } from "../../../../helpers/history";
import { config } from "../../../../enviroment";

const useStyle = makeStyles(theme => ({
    progressBar:{
      [theme.breakpoints.down("xs")]: {
        width: "60vh"
      },
    },
    paddingTop15: {
      paddingTop:15
    },
    minHeigh80: {
      minHeight: '80vh'
    }
}));

export function ThankyouMain(props) {
  const classes = useStyles();
  const classesLocal = useStyle();
  const [thankyou_subtitle, setthankyou_subtitle] = React.useState([]);
  const [thankyou_header, setThankyou_header] = React.useState([]);
  const [inquiryParagraph, setinquiryParagraph] = React.useState([]);
  const Value = JSON.parse(localStorage.getItem("Value")); 
  const paymentTypeMigs = useSelector((state)=>state?.parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[1]);
  const paymentTypeQPAY = useSelector((state)=>state?.parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[0]);
  const paymentTypeCyberSource = useSelector((state)=>state?.parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[2]);
  const paymentTypeSkipCash = useSelector((state)=>state?.parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[3]);
  const dispatch = useDispatch();
  const labels_Upfront_Payment = useSelector((state)=>state?.labels?.data?.getLabels?.UPFRONT_PAYMENT);

  const log = (logObj) => {
    adminService.log(logObj).then((res) => {
    });
  }

  let resultIndicator = new URLSearchParams(window.location.search).get(
      "resultIndicator"
    );
    let statusId = new URLSearchParams(window.location.search).get("statusId");
    let decision = new URLSearchParams(window.location.search).get("decision");
    let Response_Amount = new URLSearchParams(window.location.search).get(
      "Response.Amount"
    );
    let Response_CurrencyCode = new URLSearchParams(window.location.search).get(
      "Response.CurrencyCode"
    );
    let Response_PUN = new URLSearchParams(window.location.search).get(
      "Response.PUN"
    );
    let Response_EZConnectResponseDate = new URLSearchParams(
      window.location.search
    ).get("Response.EZConnectResponseDate");
    let Response_ConfirmationID = new URLSearchParams(window.location.search).get(
      "Response.ConfirmationID"
    );
    let Response_MerchantModuleSessionID = new URLSearchParams(
      window.location.search
    ).get("Response.MerchantModuleSessionID");
    let Response_Status = new URLSearchParams(window.location.search).get(
      "Response.Status"
    );
    let Response_StatusMessage = new URLSearchParams(window.location.search).get(
      "Response.StatusMessage"
      )?.replaceAll(" ", "+");
    let Response_MerchantID = new URLSearchParams(window.location.search).get(
      "Response.MerchantID"
    );
    let Response_BankID = new URLSearchParams(window.location.search).get(
      "Response.BankID"
    );
    let Response_Lang = new URLSearchParams(window.location.search).get(
      "Response.Lang"
    );
    let Response_AcquirerID =
      new URLSearchParams(window.location.search).get("Response.AcquirerID") || "";
    let Response_CardNumber = new URLSearchParams(window.location.search).get(
      "Response.CardNumber"
    );
    
    let Response_CardExpiryDate = new URLSearchParams(window.location.search).get(
      "Response.CardExpiryDate"
    );
    let Response_CardHolderName =
      new URLSearchParams(window.location.search).get("Response.CardHolderName") ||
      " ";
    let Response_SecureHash = new URLSearchParams(window.location.search).get(
      "Response.SecureHash"
    );
    let Response_AgentID = new URLSearchParams(window.location.search).get(
      "Response.AgentID"
    )?.replace(null, "");

    let depositAmount = localStorage.getItem("depositeAmount");
    depositAmount = Number(depositAmount);
  let language_Code = useSelector((store) => store?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE);
  let payment_Type;
  if (resultIndicator && paymentTypeMigs) {
      payment_Type = paymentTypeMigs;
  }else if (decision && paymentTypeCyberSource) {
      payment_Type = paymentTypeCyberSource;
  }else if (Response_Status && paymentTypeQPAY) {
      payment_Type = paymentTypeQPAY;
  }else if (statusId && paymentTypeSkipCash) {
    payment_Type = paymentTypeSkipCash;
  }

  function emptyLocalStorage(){
    localStorage.removeItem("mobile");
    localStorage.removeItem("uniqueId");
    localStorage.removeItem("userName");
    localStorage.removeItem("email");
    localStorage.removeItem("orderId");
    localStorage.removeItem("Value");
  }

  function deleteFiles(){
  localStorage.removeItem("fileName");
  localStorage.removeItem("fileContent");
  localStorage.removeItem("fileNamep");
  localStorage.removeItem("fileContentp");
  localStorage.removeItem("fileNameq");
  localStorage.removeItem("fileContentq");
  localStorage.removeItem("fileType");
  localStorage.removeItem("fileTypep");
  localStorage.removeItem("fileTypeq");
  localStorage.removeItem("signature");
  localStorage.removeItem("signaturetime");
  localStorage.removeItem("cpqid");
  localStorage.removeItem("AllFilesName")
  localStorage.removeItem("AllFilesContent")
  localStorage.removeItem("AllFilesType")
  localStorage.removeItem("dropdownItems")
  localStorage.removeItem("fileDropdownq")
  localStorage.removeItem("fileDropdownp")
  localStorage.removeItem("fileDropdown")
  }

  const [paymentDone, setPaymentDone] = useState(false)
  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);
  useEffect(() => {
    if(!paymentDone){
      log({ key: "NSA_PAYMENT", message: "Initiating Post payment...", stackOrLog: "STACK" })
      if (resultIndicator){
        log({ key: "NSA_PAYMENT", message: "ResultIndicator Found", stackOrLog: "STACK" })
        setLoading(true)
        log({ key: "NSA_PAYMENT", message: "Postpayment(credit card) API calling...", stackOrLog: "STACK" })
        log({ key: "NSA_PAYMENT", message: `resultIndicator (${payment_Type}) : ${resultIndicator} lgCd: ${language_Code}`, stackOrLog: "STACK" })
        paymentService
        .postPaymentProcessingCreditPay(payment_Type,resultIndicator,language_Code)
        .then((res) => {
          log({ key: "NSA_PAYMENT", message: "Postpayment response received", stackOrLog: "STACK" })
          let response = res.data.data.postPaymentProcessing;
          if(response?.genericErrorInformation?.isInError == "true" || response?.errorInformation?.isInError == "true"){
            log({ key: "NSA_PAYMENT", message: "Error", stackOrLog: "STACK" })
            if(response.genericErrorInformation.status == "RETRY_ALLOWED"){
              log({ key: "NSA_PAYMENT", message: "RETRY_ALLOWED", stackOrLog: "LOG" })
              if(Value){
                dispatch(saveNewServiceAgreementData(Value));
                emptyLocalStorage();
                history.push({ 
                  pathname: routePaths.NSA_PAY,
                  state: {paymentOpen:true}
                 });
              }
            } else{
              log({ key: "NSA_PAYMENT", message: "Error", addOnLog: response, stackOrLog: "LOG" })
                setError(labels_Upfront_Payment?.SOMETHING_WRONG)
                setLoading(false);
                emptyLocalStorage();
                deleteFiles()
            }
          }
          else if(response?.errorInformation?.isInError == "false"){
            log({ key: "NSA_PAYMENT", message: "isInError: false", stackOrLog: "STACK" })
            log({ key: "NSA_PAYMENT", message: `Ticket No: ${response?.genCase}`, stackOrLog: "LOG" })
            localStorage.setItem("ticketno",response?.genCase);
            emptyLocalStorage();
            setPaymentDone(true)
            setLoading(false);
            deleteFiles()
          }
        })
        .catch((e) => {
          log({ key: "NSA_PAYMENT", message: "Error Data", addOnLog: e, stackOrLog: "LOG" })
          setError(labels_Upfront_Payment?.SOMETHING_WRONG)
          setLoading(false);
          emptyLocalStorage();
          deleteFiles()
        });
      } 
      else if(statusId){
        const queryString = window.location.search
        .substring(1)
        .replace(/\+/g, "%2B");
      const urlParams = new URLSearchParams(queryString);
      const RequestInput = {};
      urlParams.forEach((value, key) => {
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value);
        RequestInput[decodedKey] = decodedValue;
      });
      log({ key: "NSA_PAYMENT", message: "Status Id  Found", stackOrLog: "STACK" })
      setLoading(true)
      log({ key: "NSA_PAYMENT", message: "Postpayment(credit card SkipCash) API calling...", stackOrLog: "STACK" })
      log({ key: "NSA_PAYMENT", message: `statusid (${payment_Type}) : ${statusId} lgCd: ${language_Code}`, stackOrLog: "STACK" })
      paymentService
      .postPaymentProcessing(payment_Type,RequestInput)
      .then((res) => {
        log({ key: "NSA_PAYMENT", message: "Postpayment response received", stackOrLog: "STACK" })
        let response = res.data.data.postPaymentProcessing;
        if(response?.genericErrorInformation?.isInError == "true" || response?.errorInformation?.isInError == "true"){
          log({ key: "NSA_PAYMENT", message: "Error", stackOrLog: "STACK" })
          if(response.genericErrorInformation.status == "RETRY_ALLOWED"){
            log({ key: "NSA_PAYMENT", message: "RETRY_ALLOWED", stackOrLog: "LOG" })
            if(Value){
              dispatch(saveNewServiceAgreementData(Value));
              emptyLocalStorage();
              history.push({ 
                pathname: routePaths.NSA_PAY,
                state: {paymentOpen:true}
               });
            }
          } else{
            log({ key: "NSA_PAYMENT", message: "Error", addOnLog: response, stackOrLog: "LOG" })
              setError(labels_Upfront_Payment?.SOMETHING_WRONG)
              setLoading(false);
              emptyLocalStorage();
              deleteFiles()
          }
        }
        else if(response?.errorInformation?.isInError == "false"){
          log({ key: "NSA_PAYMENT", message: "isInError: false", stackOrLog: "STACK" })
          log({ key: "NSA_PAYMENT", message: `Ticket No: ${response?.genCase}`, stackOrLog: "LOG" })
          localStorage.setItem("ticketno",response?.genCase);
          emptyLocalStorage();
          setPaymentDone(true)
          setLoading(false);
          deleteFiles()
        }
      })
      .catch((e) => {
        log({ key: "NSA_PAYMENT", message: "Error Data", addOnLog: e, stackOrLog: "LOG" })
        setError(labels_Upfront_Payment?.SOMETHING_WRONG)
        setLoading(false);
        emptyLocalStorage();
        deleteFiles()
      });
      }
      else if(decision){
        const queryString = window.location.search.substring(1).replace(/\+/g, "%2B");
      const urlParams = new URLSearchParams(queryString);
      const RequestInput = {};
      urlParams.forEach((value, key) => {
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value);
        RequestInput[decodedKey] = decodedValue;
      });
        log({ key: "NSA_PAYMENT", message: "decision Found", stackOrLog: "STACK" })
        setLoading(true)
        log({ key: "NSA_PAYMENT", message: "Postpayment(credit card CyberSource) API calling...", stackOrLog: "STACK" })
        log({ key: "NSA_PAYMENT", message: `decision (${payment_Type}) : ${decision} lgCd: ${language_Code}`, stackOrLog: "STACK" })
        paymentService
        .postPaymentProcessing(payment_Type,RequestInput)
        .then((res) => {
          log({ key: "NSA_PAYMENT", message: "Postpayment response received", stackOrLog: "STACK" })
          let response = res.data.data.postPaymentProcessing;
          if(response?.genericErrorInformation?.isInError == "true" || response?.errorInformation?.isInError == "true"){
            log({ key: "NSA_PAYMENT", message: "Error", stackOrLog: "STACK" })
            if(response.genericErrorInformation.status == "RETRY_ALLOWED"){
              log({ key: "NSA_PAYMENT", message: "RETRY_ALLOWED", stackOrLog: "LOG" })
              if(Value){
                dispatch(saveNewServiceAgreementData(Value));
                emptyLocalStorage();
                history.push({ 
                  pathname: routePaths.NSA_PAY,
                  state: {paymentOpen:true}
                 });
              }
            } else{
              log({ key: "NSA_PAYMENT", message: "Error", addOnLog: response, stackOrLog: "LOG" })
                setError(labels_Upfront_Payment?.SOMETHING_WRONG)
                setLoading(false);
                emptyLocalStorage();
                deleteFiles()
            }
          }
          else if(response?.errorInformation?.isInError == "false"){
            log({ key: "NSA_PAYMENT", message: "isInError: false", stackOrLog: "STACK" })
            log({ key: "NSA_PAYMENT", message: `Ticket No: ${response?.genCase}`, stackOrLog: "LOG" })
            localStorage.setItem("ticketno",response?.genCase);
            emptyLocalStorage();
            setPaymentDone(true)
            setLoading(false);
            deleteFiles()
          }
        })
        .catch((e) => {
          log({ key: "NSA_PAYMENT", message: "Error Data", addOnLog: e, stackOrLog: "LOG" })
          setError(labels_Upfront_Payment?.SOMETHING_WRONG)
          setLoading(false);
          emptyLocalStorage();
          deleteFiles()
        });
      }
      else{
        if (Response_CardNumber && Response_CardExpiryDate && Response_StatusMessage != config.payment.PAYMENT_FIALED) {
          setLoading(true)
          paymentService
           .postPaymentProcessingDebitPay(
              payment_Type,
              Response_Amount,
              Response_BankID,
              Response_CurrencyCode,
              Response_EZConnectResponseDate,
              Response_Lang,
              Response_MerchantID,
              Response_MerchantModuleSessionID,
              Response_PUN,
              Response_SecureHash,
              Response_ConfirmationID,
              Response_Status,
              Response_StatusMessage,
              Response_AcquirerID,
              Response_CardNumber,
              Response_CardExpiryDate,
              Response_CardHolderName,
              Response_AgentID
            )
            .then((res) => {
              let response = res.data.data.postPaymentProcessing;
              if(response?.genericErrorInformation?.isInError == "true" || response?.errorInformation?.isInError == "true"){
                if(response.genericErrorInformation.status == "RETRY_ALLOWED"){
                  if(Value){
                    dispatch(saveNewServiceAgreementData(Value));
                    emptyLocalStorage();
                    history.push({ 
                      pathname: routePaths.NSA_PAY,
                      state: {paymentOpen:true}
                     });
                }
                } else{
                    setError(labels_Upfront_Payment?.SOMETHING_WRONG)
                    setLoading(false);
                    emptyLocalStorage();
                    deleteFiles()
                }
              }
              else if(response?.errorInformation?.isInError == "false"){
                localStorage.setItem("ticketno",response?.genCase);
                emptyLocalStorage();
                setPaymentDone(true)
                setLoading(false);
                deleteFiles()
              }
            })
            .catch((e) => {
              setError(labels_Upfront_Payment?.SOMETHING_WRONG)
              setLoading(false);
              emptyLocalStorage();
              deleteFiles()
            });
        }
        else {
          if(Value){
              dispatch(saveNewServiceAgreementData(Value));
              emptyLocalStorage();
              history.push({ 
                pathname: routePaths.NSA_PAY,
                state: {paymentOpen:true}
               });
          } else if(!localStorage.getItem("depositeAmount")){
              emptyLocalStorage();
              history.push(routePaths.NSA);
          } 
        }
      }
    }
  }, [payment_Type])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      pointer: "cursor",
    });
    if(localStorage.getItem("ticketno") != null ){
      emptyLocalStorage();
      deleteFiles()
      setPaymentDone(true)
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    if (props.data) {
      props.data.forEach((item) => {
        if (item.contentCode === "THANK_YOU_SERVICE_AGREEMENT_SUBTITLE" || item.contentCode === "THANK_YOU_SERVICE_AGREEMENT_SUBTITLE_WITHOUT_PAYMENT") {
          if(depositAmount == 0 && item.contentCode === "THANK_YOU_SERVICE_AGREEMENT_SUBTITLE_WITHOUT_PAYMENT"){
            let data = item.answer.replace(
              "***",
              localStorage.getItem("ticketno")
            );          
            setthankyou_subtitle(data);
            setThankyou_header(item.question);
          } else if(depositAmount > 0 && item.contentCode === "THANK_YOU_SERVICE_AGREEMENT_SUBTITLE"){
            let data = item.answer.replace(
              "***",
              localStorage.getItem("ticketno")
            );          
            data = data.replace(
              "****",
              localStorage.getItem("depositeAmount")
            );
            setthankyou_subtitle(data);
            setThankyou_header(item.question);
          }
          
        }
        if (item.contentCode === "THANK_YOU_SERVICE_AGREEMENT_INQUIRY") {
          setinquiryParagraph(item.answer);
        }
      });
    }
  }, [props.data,loading]);

  return (
    loading ? (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '80vh' }}
        >
          <Grid item xs={3} className={classesLocal.progressBar}>
          <CircularProgress color="primary" size={60} />
          </Grid>   
      </Grid> 
    ):( 
    paymentDone ?( 
    <React.Fragment >
      <NSACommonHeader
            labelSubTitle={props.labelSubTitle}
            baseStyle={props.baseStyle}
            activeStep={4}
          />
      <Grid style={{ minHeight: '80vh' }}>
        <Typography color="primary" variant="h6">
          <b>{thankyou_header && thankyou_header}</b>
        </Typography>

        {thankyou_subtitle && <Typography
        variant="subtitle1"
        className={classesLocal.paddingTop15}
        dangerouslySetInnerHTML={{
          __html: thankyou_subtitle,
        }}
        /> }
        <br />
        <br />
        <div align="center">
          <Typography
            variant="subtitle1"
            className={classes.quaternaryColor}
            dangerouslySetInnerHTML={{
              __html: inquiryParagraph,
            }}
          ></Typography>
        </div>
      </Grid>
    </React.Fragment>):(
      error ? (
          <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className={classesLocal.minHeigh80}
          >
            <Grid item xs={3} className={classesLocal.progressBar}>
            <Typography color="primary" variant="h5">
              {error}
            </Typography>     
          </Grid>   
        </Grid>): null 
    )
    )
  );
}


function NSAMain(props) {
    const[data,setData] = useState();
    const[labelSubTitle,setLabelSubTitle] = useState();
    const[lastNote,setLastNote] = useState();
    const[confirmSubTitle,setConfirmSubTitle] = useState();
    let lang = useSelector(
      (store) =>
        store?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
    );
    
    async function callAdmin(){
        await adminService.getContentData(lang, "NEW_SERVICE_AGREEMENT").then((res) => {
          store.getState().snackbar=false;
          setData(res.data.getContents.NEW_SERVICE_AGREEMENT)
          res.data.getContents.NEW_SERVICE_AGREEMENT.forEach((item) => {
            if (item.contentCode === "NEW_SERVICE_AGREEMENT_SUBTITLE") {
                setLabelSubTitle(item.answer);
            } 
            if (item.contentCode === "CONFIRM_SERVICE_AGREEMENT_SUBTITLE") {
                setConfirmSubTitle(item.answer)
            } 
          });
        }).catch((err) => {
          store.getState().snackbar=false;
        }) 
      }

      useEffect(() => {
        store.getState().snackbar=true;
        if(localStorage.getItem("authbearer")){
        callAdmin();
        }else{
        bearerTokenService
        .getBearerToken()
        .then(async (successBearer) => {          
        callAdmin();                  
        })
        .catch((error) => {
        });
    }
    },[lang])
  return (
    <React.Fragment>
        <CardContent className="cardPadding">
          {data && labelSubTitle ? (
            <ThankyouMain
              baseStyle={props.baseStyle}
              data={data}
              authStyle={props.authStyle}
              labelSubTitle ={labelSubTitle}
              confirmSubTitle={confirmSubTitle}
            />
          ) : (
            <Skeleton variant="text" />
          )}
        </CardContent>
      </React.Fragment>
  )
}

export default function Thankyou() {
  let pids = localStorage.getItem("ticketno");
  const classes = useStyles();
  const authClasses = authStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 460px)" });
  let quinaryBackgroundBlur = `rightSecondaryBack serviceHeight ${classes.quinaryBackground}`;
  let primaryBackgroundBlur = `primaryBack ${classes.primaryBackground} `;
  useEffect(() => {
    const rocketContainer = document.querySelector('.rocketchat-container');
    const rocketWidget = document.querySelector('.rocketchat-widget')
    if(rocketContainer && rocketWidget) {
      rocketContainer.style.display = "none"
      rocketWidget.style.display = "none" 
    }
  })
  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <React.Fragment>
          <Grid container direction="column" >
            <Grid item>
              <div className={primaryBackgroundBlur} />
            </Grid>
            <Grid item>
              <div className={quinaryBackgroundBlur} />
            </Grid>
          </Grid>
          <Card className="stack-top">
            <NSAMain baseStyle={classes} authStyle={authClasses} />
          </Card>
        </React.Fragment>
      ) : (
        <div className="margin-top-60">
          <NSAMain baseStyle={classes} authStyle={authClasses} />
        </div>
      )}
    </React.Fragment>
  );
}