import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { store } from "../../../helpers";

let labels = "";

store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
});

export default function PaymentComponent(props) {
  return (
    <React.Fragment>
      <Typography component="h1" variant="h5" color="primary">
        {(props.res &&
          props.res.genericErrorInformation &&
          props.res.genericErrorInformation.isInError == "true") ||
        props.error ? (
          <b>{labels.QUICK_PAY_PAYMENT_FAILED}</b>
        ) : (
          <b>{labels.QUICK_PAY_PAYMENT_SUCCESS}</b>
        )}
      </Typography>
      <br />
      <Grid container direction="row">
        <Grid item lg={2} sm={4} xs={6}>
          <Typography variant="body1">
            <b>
              {(props.res &&
                props.res.mainData &&
                props.res.mainData[0].head.billId) ||
              props.billId
                ? labels.BILL_PAY_BILL_ID
                : null}
            </b>
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="body1">
            {props.res &&
            props.res.mainData &&
            props.res.mainData[0].head.billId
              ? props.res.mainData[0].head.billId
              : props.billId && props.billId}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" className="margin-top-10">
        <Grid item lg={2} sm={4} xs={6}>
          <Typography variant="body1">
            {props.error ? (
              <b>{labels.QUICK_PAY_ERROR_DETAILS}</b>
            ) : props.res &&
              props.res.genericErrorInformation &&
              props.res.genericErrorInformation.isInError == "true" ? (
              <b>{labels.QUICK_PAY_ERROR_DETAILS}</b>
            ) : (
              <b> {labels.QUICK_PAY_AMOUNT_PAID}</b>
            )}
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="body1">
            {props.error ? (
              props.error
            ) : props.res &&
              props.res.genericErrorInformation &&
              props.res.genericErrorInformation.isInError == "true" ? (
              <b>{props.res.genericErrorInformation.error}</b>
            ) : (
              <React.Fragment>
                {props.res && props.res.mainData
                  ? props.res.mainData[0].paymentInfo.input.currency +
                    "  " +
                    Number(parseFloat(props.res.mainData[0].paymentInfo.input.paymentAmount).toFixed(2)).toLocaleString('en', {
                      minimumFractionDigits: 2
                  })
                  : null}
              </React.Fragment>
            )}
          </Typography>
        </Grid>
      </Grid>
      {props.res &&
        props.res.genericErrorInformation &&
        props.res.genericErrorInformation.isInError == "true" && (
          <Grid container direction="row" className="margin-top-10">
            <Grid item lg={2} sm={4} xs={6}>
              <Typography variant="body1">
                <b>{labels.QUICK_PAY_ERROR_AMOUNT}</b>
              </Typography>
            </Grid>
            <Grid item sm={6} xs={6}>
              <Typography variant="body1">
                {props.res && props.res.mainData
                  ? props.res.mainData[0].paymentInfo.input.currency +
                    "  " +         
                    Number(parseFloat(props.res.mainData[0].paymentInfo.input.paymentAmount).toFixed(2)).toLocaleString('en', {
                      minimumFractionDigits: 2
                  })
                  : null}
              </Typography>
            </Grid>
          </Grid>
        )}
      {props.res &&
      props.res.genericErrorInformation &&
      props.res.genericErrorInformation.isInError ==
        "true" ? null : props.res &&
        props.res.mainData &&
        props.res.mainData[0].paymentInfo &&
        props.res.mainData[0].paymentInfo.output &&
        props.res.mainData[0].paymentInfo.output.customOutput &&
        props.res.mainData[0].paymentInfo.output.customOutput.field1 ? (
        <Grid container direction="row" className="margin-top-10">
          <Grid item lg={2} sm={4} xs={6}>
            <Typography variant="body1">
              <b>{labels.QUICK_PAY_PAYMENT_NUMBER}</b>
            </Typography>
          </Grid>
          <Grid item sm={6} xs={6}>
            <Typography variant="body1">
              {props.res.mainData[0].paymentInfo.inputOutput.paymentEventId }
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      {props.res && props.res.mainData && (
        <Grid container direction="row" className="margin-top-10">
          <Grid item lg={2} sm={4} xs={6}>
            <Typography variant="body1">
              <b>{labels.QUICK_PAY_PAYMENT_TYPE}</b>
            </Typography>
          </Grid>
          <Grid item sm={6} xs={6}>
            <Typography variant="body1">
              {props.res.mainData[0].paymentInfo.input.paymentType}
            </Typography>
          </Grid>
        </Grid>
      )}
      {props.res && props.res.mainData && (
        <Grid container direction="row" className="margin-top-10">
          <Grid item lg={2} sm={4} xs={6}>
            <Typography variant="body1">
              <b>{labels.QUICK_PAY_TRANSACTION_DATE}</b>
            </Typography>
          </Grid>
          <Grid item sm={6} xs={6}>
            <Typography variant="body1">
              {props.res.mainData[0].paymentInfo.input.paymentDateTime}
            </Typography>
          </Grid>
        </Grid>
      )}
      {props.res && props.res.mainData && (
        <Grid container direction="row" className="margin-top-10">
          <Grid item lg={2} sm={4} xs={6}>
            <Typography variant="body1">
              <b>{labels.QUCIK_PAY_CREDIT_CARD_TYPE}</b>
            </Typography>
          </Grid>
          <Grid item sm={6} xs={6}>
            <Typography variant="body1">
              {props.res.mainData[0].paymentInfo.input.creditCardInfo.cardType}
            </Typography>
          </Grid>
        </Grid>
      )}
      {props.res && props.res.mainData && (
        <Grid container direction="row" className="margin-top-10">
          <Grid item lg={2} sm={4} xs={6}>
            <Typography variant="body1">
              <b>{labels.QUICK_PAY_CREDIT_CARD_NUMBER}</b>
            </Typography>
          </Grid>
          <Grid item sm={6} xs={6}>
            <Typography variant="body1">
              {
                props.res.mainData[0].paymentInfo.input.creditCardInfo
                  .cardNumber
              }
            </Typography>
          </Grid>
        </Grid>
      )}
      {/* <Grid container direction="row" className="margin-top-10">
        <Grid item lg={2} sm={4} xs={6}>
          <Typography variant="body1">
            {<b>Expire Month / Year</b>}
            
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="body1">            
            {props.res && props.res.mainData ? props.res.mainData[0].paymentInfo.input.creditCardInfo.expMonth +
            "/"+ props.res.mainData[0].paymentInfo.input.creditCardInfo.expYear :null}
          </Typography>
        </Grid>
      </Grid>       */}
    </React.Fragment>
  );
}
