import React from "react";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { userService } from "../../services/index";
import "../auth/auth.css";
import "../app/base-style.css";
import { history } from "../../helpers/index";
import { routePaths } from "../config/route-paths";
import { store } from "../../helpers";
import { useStyles, ColorTooltip } from "../app/base-style";
import { connect } from "react-redux";
import { pathActions } from "../../actions";
import { useMediaQuery } from "react-responsive";
import { CssTextField, authStyles } from "../auth/auth-style";
import clsx from "clsx";
import ReactCountryFlag from "react-country-flag";
import ReCAPTCHA from "react-google-recaptcha";
import { config } from "../../enviroment";
import Alert from "@material-ui/lab/Alert";
import ImageBackground from "../../assests/qc-bg.jpg";
import { useEffect } from "react";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";

const delay = 500;
const siteKey = config.recaptchaKey.siteKey;
let enableCaptcha = false;
let labels = {};
let labelsError={}
let mobileLength = "";
let qatariLength = "";
let passportLength = "";
let companyNumberLength = "";
let usernameLength = "";
let passwordLength = "";

store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
    labelsError = store.getState().labels.data.getLabels.SERVER_EXCEPTIONS;
  }
  if (store.getState().parametersWidgets.ParameterLookup !== undefined) {
    enableCaptcha = store.getState().parametersWidgets.ParameterLookup
      .ENABLE_CAPTCHA;
  }
  if (store.getState().parametersWidgets.ParameterLookup) {
    mobileLength = store.getState().parametersWidgets.ParameterLookup
      .MOBILE_LENGTH;
    mobileLength = Number(mobileLength);
    qatariLength = store.getState().parametersWidgets.ParameterLookup
      .QATAR_ID_LENGTH;
    qatariLength = Number(qatariLength);
    passportLength = store.getState().parametersWidgets.ParameterLookup
      .PASSPORT_NO_LENGTH;
    passportLength = Number(passportLength);
    companyNumberLength = store.getState().parametersWidgets.ParameterLookup
      .COMPANY_NO_LENGTH;
    companyNumberLength = Number(companyNumberLength);
    usernameLength = store.getState().parametersWidgets.ParameterLookup
      .USERNAME_LENGTH;
    usernameLength = Number(usernameLength);
    passwordLength = store.getState().parametersWidgets.ParameterLookup
      .PASSWORD_MAXLENGTH;
    passwordLength = Number(passwordLength);
  }
});

function Register(props) {
  const classes = useStyles();
  const authClasses = authStyles();
  const display = useMediaQuery({ query: "(min-width : 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
  let rightBlue = `split right ${classes.primaryBackground}`;

  useEffect(() => {
    const rocketContainer = document.querySelector('.rocketchat-container');
    const rocketWidget = document.querySelector('.rocketchat-widget')
    if(rocketContainer && rocketWidget) {
      rocketContainer.style.display = "block"
      rocketWidget.style.display = "block" 
    }
  })

  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <div>
          <div className="bodyimage" />
          <div className={rightBlue}>
            <RegisterMain
              propsMain={props}
              baseStyle={classes}
              display={display}
              authStyle={authClasses}
            />
          </div>
        </div>
      ) : (
        <React.Fragment>
          <Grid container direction="column" spacing={0}>
            <Grid item className={[classes.primaryBackground,classes.paddingTopHomepage]}>
              <RegisterMain
                propsMain={props}
                baseStyle={classes}
                display={display}
                authStyle={authClasses}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
class RegisterMain extends React.Component {
  constructor(props) {
    props.propsMain.getLocationPath(routePaths.REGISTER);
    super(props);
    this.state = {
      user: {
        registeredEmail: "",
        registeredCell: "",
        password: "",
        confirmPassword: "",
        uniqueId: "",
        username: "",
        countryCode:"+974",
        mobileWithoutExt:""
      },
      uniqueValue: "QATAR_ID",
      emailRequire: false,
      emailError: false,
      mobileRequire: false,
      mobileError: false,
      usernameRequire: false,
      usernameValid: false,
      pwdRequire: false,
      pwdError: false,
      confirmRequire: false,
      confirmError: false,
      uniqueIdRequire: false,
      uniqueIdError: false,
      isOTP: false,
      error: false,
      msg: "",
      captcha: {
        callback: "not fired",
        value: "[empty]",
        load: false,
        expired: false,
      },
      isSignUpButtonDisabled: null,
      isSignupLoading: false,
    };
    this.emailBlur = this.emailBlur.bind(this);
    this.mobileBlur = this.mobileBlur.bind(this);
    this.pwdBlur = this.pwdBlur.bind(this);
    this.confirmpwdBlur = this.confirmpwdBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.signupSubmit = this.signupSubmit.bind(this);
    this.cancelSubmit = this.cancelSubmit.bind(this);
    this.keypress = this.keypress.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.uniqueRadioChange = this.uniqueRadioChange.bind(this);
    this.uniqueIdBlur = this.uniqueIdBlur.bind(this);
    this.usernameBlur = this.usernameBlur.bind(this);
    this.handleOnChangePhone=this.handleOnChangePhone.bind(this);
    this._reCaptchaRef = React.createRef();
  }

  componentDidMount() {
    const { captcha } = this.state;
    if (store.getState().parametersWidgets.ParameterLookup !== undefined) {
      enableCaptcha = store.getState().parametersWidgets.ParameterLookup
        .ENABLE_CAPTCHA;
    }

    if (this.state.isSignUpButtonDisabled === null && enableCaptcha) {
      this.setState({
        isSignUpButtonDisabled: true,
      });
    }


    setTimeout(() => {
      this.setState({
        captcha: {
          ...captcha,
          load: true,
        },
      });
    }, delay);
  }
  componentDidUpdate() {
    if(document.getElementById("registerCell_Id")){
    if (this.state.mobileError === true || this.state.mobileRequire === true) {
      document.getElementById("registerCell_Id").style.borderColor = "#e57373";
    } else {
      document.getElementById("registerCell_Id").style.borderColor =
        "white";
    }
  }
  }
  captchaHandleChange = (value) => {
    const { captcha } = this.state;
    this.setState({
      captcha: {
        ...captcha,
        value: value,
      },
    });

    if (value !== null) this.setState({ isSignUpButtonDisabled: false });

    if (value === null)
      this.setState({
        captcha: {
          ...captcha,
          expired: true,
        },
      });
  };

  handleOnChangePhone(value) {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        registeredCell: value,
      },
    });
  }

  captchaAsyncScriptOnLoad = () => {
    const { captcha } = this.state;
    this.setState({
      captcha: {
        ...captcha,
        callback: "called!",
      },
    });
  };

  captchaHandleExpired = (value) => {
    this._reCaptchaRef.reset();
  };

  emailBlur() {
    const { user } = this.state;
    if (store.getState().parametersWidgets.ParameterLookup) {
      if (user.registeredEmail) {
        let email = user.registeredEmail.toLowerCase();
        this.setState({
          emailRequire: false,
        });
        var emailData = store.getState().parametersWidgets.ParameterLookup
          .EMAIL_REGEX;
        var emailRegEx = new RegExp(emailData);
        var test = emailRegEx.test(email);
        if (test) {
          this.setState({ emailError: false });
        } else {
          this.setState({ emailError: true });
        }
      } else {
        this.setState({
          emailRequire: true,
          emailError: false,
        });
      }
    }
  }

  mobileBlur() {
    const { user } = this.state;
    if (user.registeredCell) {
      this.setState({
        mobileRequire: false,
      });
      let phoneNumber = parsePhoneNumber(user.registeredCell);

      if (phoneNumber) {
        this.setState({
          mobileRequire:false
        })
        if(isValidPhoneNumber(user.registeredCell)=== true){
          this.setState({
            mobileError:false,
            user: {
              ...user,
              countryCode: phoneNumber.countryCallingCode,
              mobileWithoutExt:phoneNumber.nationalNumber
            },
          });
        }else{
          this.setState({
            mobileRequire: false,
            mobileError: true,
          });
        }
      }else {
        this.setState({
          mobileRequire: true,
          mobileError: false,
        });
      }
    } else {
      this.setState({
        mobileRequire: true,
        mobileError: false,
      });
    }
  }

  pwdBlur() {
    const { user } = this.state;
    if (user.password) {
      this.setState({
        pwdRequire: false,
      });
      if (store.getState().parametersWidgets.ParameterLookup) {
        var data = store.getState().parametersWidgets.ParameterLookup
          .PASSWORD_REGEX;
        var reg = new RegExp(data);
        var test = reg.test(user.password);

        if (test) {
          this.setState({
            pwdError: false,
          });
        } else {
          this.setState({
            pwdError: true,
            confirmError: false,
          });
        }
      }
    } else {
      this.setState({
        pwdRequire: true,
        pwdError: false,
      });
    }
  }

  confirmpwdBlur() {
    const { user } = this.state;
    if (user.confirmPassword) {
      this.setState({
        confirmRequire: false,
      });
      if (user.confirmPassword && user.password) {
        if (user.password === user.confirmPassword) {
          this.setState({
            confirmError: false,
          });
        } else {
          this.setState({
            confirmError: true,
            pwdRequire: false,
            pwdError: false,
          });
        }
      } else {
        this.setState({
          confirmError: true,
          pwdRequire: false,
          pwdError: false,
        });
      }
    } else {
      this.setState({
        confirmRequire: true,
        confirmError: false,
      });
    }
  }

  keypress(event) {
    if (event.key === "Enter") {
      this.pwdBlur();
      this.confirmpwdBlur();
      if (
        this.state.isSignUpButtonDisabled === false &&
        this.state.isSignupLoading === false
      ) {
        this.signupSubmit(event);
      }
    }
  }

  signupSubmit(event) {
    event.preventDefault();
    this.emailBlur();
    this.mobileBlur();
    this.pwdBlur();
    this.confirmpwdBlur();
    this.uniqueIdBlur();
    this.usernameBlur();
    const { user, uniqueValue } = this.state;
    if (
      user.confirmPassword &&
      user.password &&
      user.registeredCell &&
      user.mobileWithoutExt &&
      user.countryCode &&
      user.registeredEmail &&
      user.uniqueId &&
      user.username
    ) {
      if (
        this.state.emailRequire === false &&
        this.state.emailError === false &&
        this.state.mobileRequire === false &&
        this.state.mobileError === false &&
        this.state.pwdRequire === false &&
        this.state.pwdError === false &&
        this.state.confirmRequire === false &&
        this.state.confirmError === false &&
        this.state.uniqueIdRequire === false &&
        this.state.uniqueIdError === false &&
        this.state.usernameRequire === false &&
        this.state.usernameValid === false
      ) {
        this.setState({
          isSignupLoading: true,
        });
        userService
          .userCheck(user.username)
          .then((res) => {
            userService
              .register(user, uniqueValue)
              .then((response) => {
                let res = {
                  username: user.username,
                };
                localStorage.removeItem("flowType");
                localStorage.setItem("flowType", "SIGNUP");
                localStorage.setItem("username", user.username);
                const state = store.getState();
                let isOTPOn = false;
                this.props.propsMain.getPath(routePaths.REGISTER, res);
                const widgets = state.parametersWidgets.WidgetLookUp;
                if (widgets) {
                  widgets.forEach((widgetItem) => {
                    if (
                      widgetItem.widgetCode === "SIGNUP_OTP_VALIDATION" &&
                      widgetItem.widgetDefaultValue === "true"
                    ) {
                      isOTPOn = true;
                    }
                  });
                }

                if (isOTPOn) {
                  history.push(routePaths.AUTH_OTP);
                } else {
                  history.push(routePaths.LANDING + "?flowCode=signupSuccess");
                }
                this.setState({
                  isSignupLoading: false,
                });
              })
              .catch((error) => {
                if (error.response && error.response.data.statusCode === 400) {
                  history.push(
                    routePaths.LANDING + "?flowCode=userVerificationFailure"
                  );
                } else if (
                  error.response &&
                  error.response.data.statusCode === 500
                ) {
                  this.setState({
                    error: true,
                    msg: labelsError.SERVER_EXCEPTIONS_NOT_RIGHT,
                    isSignupLoading: false,
                  });
                } else {
                  this.setState({
                    error: true,
                    msg: error.response.data.message,
                    isSignupLoading: false,
                  });
                }
              });
          })
          .catch((error) => {
            if (error.response) {
              this.setState({
                error: true,
                msg: error.response.data.message,
                isSignupLoading: false,
              });
            }
          });
      }
    }
  }

  cancelSubmit = (event) => {
    this.setState({
      emailRequire: false,
      emailError: false,
      mobileRequire: false,
      mobileError: false,
      pwdRequire: false,
      pwdError: false,
      confirmRequire: false,
      confirmError: false,
      isOTP: false,
    });
    history.push(routePaths.LOGIN);
  };

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    if (
      (this.state.uniqueValue === "QATAR_ID" && name === "uniqueId") ||
      name === "registeredCell"
    ) {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({
          user: {
            ...user,
            [name]: value,
          },
        });
      }
    } else if (name === "username") {
      const re = /^[a-zA-Z0-9_@.]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({
          user: {
            ...user,
            [name]: value.toLowerCase(),
          },
        });
      }
    } else if (name === "registeredEmail") {
      this.setState({
        user: {
          ...user,
          [name]: value.toLowerCase(),
        },
      });
    } else {
      this.setState({
        user: {
          ...user,
          [name]: value,
        },
      });
    }
  }

  onKeyDown(event) {
    if (event.which === 38 || event.which === 40) {
      event.preventDefault();
    }
  }

  uniqueRadioChange(event) {
    const { user } = this.state;

    this.setState({
      uniqueValue: event.target.value,
      user: {
        ...user,
        uniqueId: "",
      },
      uniqueIdRequire: false,
      uniqueIdError: false,
    });
  }

  uniqueIdBlur() {
    const { user } = this.state;
    if (user.uniqueId) {
      this.setState({
        uniqueIdRequire: false,
      });
      if (store.getState().parametersWidgets.ParameterLookup) {
        if (this.state.uniqueValue === "PASSPORT_NO") {
          if (user.uniqueId.length <= passportLength) {
            var passportValidation = store.getState().parametersWidgets
              .ParameterLookup.PASSPORT_VALIDATION;
            var passportRegEx = new RegExp(passportValidation);
            var test = passportRegEx.test(user.uniqueId);
            if (test) {
              this.setState({ uniqueIdError: false });
            } else {
              this.setState({ uniqueIdError: true });
            }
          } else {
            this.setState({ uniqueIdError: true });
          }
        } else if (this.state.uniqueValue === "QATAR_ID") {
          var QATAR_ID_LENGTH = store.getState().parametersWidgets
            .ParameterLookup.QATAR_ID_LENGTH;
          if (user.uniqueId.length === Number(QATAR_ID_LENGTH)) {
            this.setState({ uniqueIdError: false });
          } else {
            this.setState({ uniqueIdError: true });
          }
        } else if (this.state.uniqueValue === "COMPANY_NO") {
          var CompanyRegNoValidation = store.getState().parametersWidgets
            .ParameterLookup.COMPANY_REG_NO;
          var CompanyRegNoRegEx = new RegExp(CompanyRegNoValidation);
          var testCompany = CompanyRegNoRegEx.test(user.uniqueId);
          if (testCompany) {
            this.setState({ uniqueIdError: false });
          } else {
            this.setState({ uniqueIdError: true });
          }
        }
      }
    } else {
      this.setState({
        uniqueIdRequire: true,
        uniqueIdError: false,
      });
    }
  }

  usernameBlur() {
    const { user } = this.state;
    if (user.username) {
      this.setState({
        usernameRequire: false,
      });
      if (store.getState().parametersWidgets.ParameterLookup) {
        var usernameValidation = store.getState().parametersWidgets
          .ParameterLookup.USERNAME;
        var usernameRegEx = new RegExp(usernameValidation);
        var test = usernameRegEx.test(user.username);
        if (test) {
          this.setState({ usernameValid: false });
        } else {
          this.setState({ usernameValid: true });
        }
      }
    } else {
      this.setState({
        usernameRequire: true,
      });
    }
  }

  render() {
    const { captcha } = this.state || {};
    const { user } = this.state;
    let CancelButtonStyle = `btnBorder ${this.props.baseStyle.whiteColor} ${this.props.baseStyle.whiteBorder}`;
    let submitButtonStyle = `btnBorder ${this.props.baseStyle.whiteColor}`;
    let disableStyle = `btnBorder ${this.props.authStyle.disableButton} ${this.props.baseStyle.whiteColor}`;
    let loadingStyle = `btnBorder ${this.props.authStyle.loadingCursor} ${this.props.baseStyle.whiteColor}`;

    if (store.getState().parametersWidgets.ParameterLookup) {
      var country_Calling_Code = store.getState().parametersWidgets
        .ParameterLookup.COUNTRY_DIALING_CODE;
      var country_Code = store.getState().parametersWidgets.ParameterLookup
        .COUNTRY_CODE;
    }

    return (   
      <React.Fragment>
        {store.getState().labels.data && (
          <div className="containermargin ">
            <Typography
              variant="h4"
              className={this.props.baseStyle.whiteColor}
              align="center"
            >
              <b>{labels.REGISTER_SIGNUP}</b>
            </Typography>
            <div className="form">
              <br />
            </div>

            <Grid container spacing={3} direction="column">
              <Grid item>
                <Typography
                  display="inline"
                  variant="h6"
                  align="left"
                  className={
                    this.state.emailRequire || this.state.emailError
                      ? this.props.baseStyle.errorColor
                      : this.props.baseStyle.whiteColor
                  }
                >
                  {labels.SIGNUP_REGISTERED_EMAIL}
                </Typography>

                <CssTextField
                  type="text"
                  fullWidth
                  margin="normal"
                  placeholder={labels.SIGNUP_EMAIL_PACEHOLDER}
                  id="registeredEmail_Id"
                  name="registeredEmail"
                  value={user.registeredEmail.toLocaleLowerCase()}
                  onChange={this.handleChange}
                  onBlur={this.emailBlur}
                  error={this.state.emailRequire || this.state.emailError}
                  autoComplete="off"
                  helperText={
                    this.state.emailRequire === true
                      ? labels.ERROR_EMAIL_REQUIRED
                      : this.state.emailError === true
                      ? labels.ERROR_EMAIL_VALIDATION
                      : null
                  }
                />
                <br />
              </Grid>
              {mobileLength && (
                <Grid item>
                  <Typography
                    display="inline"
                    variant="h6"
                    align="left"
                    className={
                      this.state.mobileRequire || this.state.mobileError
                        ? this.props.baseStyle.errorColor
                        : this.props.baseStyle.whiteColor
                    }
                  >
                    {labels.SIGNUP_REGISTERED_CELLNUMBER}
                  </Typography>
                  <PhoneInput
                    fullWidth
                    flags={flags}
                    id="registerCell_Id"
                    name="registeredCell"
                    value={user.registeredCell}
                    autoComplete="off"
                    placeholder={labels.SIGNUP_CELLNUMBER_PLACEHOLDER}
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="QA"
                    onChange={this.handleOnChangePhone}
                    limitMaxLength={true}
                    className="phoneClass"
                    onBlur={this.mobileBlur}
                  />
                  <Typography
                    variant="body2"
                    className={this.props.baseStyle.errorColor}
                  >
                    {this.state.mobileRequire === true
                      ? labels.SIGNUP_CELLNUMBER_REQUIRED
                      : this.state.mobileError === true
                      ? labels.SIGNUP_CELLNUMBER_NOT_VALID
                      : null}
                  </Typography>
                  <br />
                </Grid>
              )}
              <Grid item>
                {qatariLength && (
                  <Grid container>
                    <FormControl className={this.props.baseStyle.whiteColor}>
                      <Typography
                        display="inline"
                        variant="h6"
                        align="left"
                        className={
                          this.state.uniqueIdRequire || this.state.uniqueIdError
                            ? this.props.baseStyle.errorColor
                            : this.props.baseStyle.whiteColor
                        }
                      >
                        {labels.SIGNUP_UNIQUE_ID_LABEL}
                      </Typography>
                      <RadioGroup
                        aria-label="gender"
                        name="uniqueValue"
                        value={this.state.uniqueValue}
                        onChange={this.uniqueRadioChange}
                        row
                        className="noWrapRadio"
                      >
                        <FormControlLabel
                          value="QATAR_ID"
                          control={
                            <Radio
                              className={this.props.baseStyle.whiteColor}
                            />
                          }
                          label={labels.SIGNUP_UNIQUE_ID_QATARID_LABEL}
                        />
                        <FormControlLabel
                          value="PASSPORT_NO"
                          control={
                            <Radio
                              className={this.props.baseStyle.whiteColor}
                            />
                          }
                          label={labels.SIGNUP_UNIQUE_ID_PASSPORTNO_LABEL}
                        />
                        <FormControlLabel
                          value="COMPANY_NO"
                          control={
                            <Radio
                              className={this.props.baseStyle.whiteColor}
                            />
                          }
                          label={labels.SIGNUP_UNIQUE_ID_COMPANY_REG_NO_LABEL}
                        />
                      </RadioGroup>
                    </FormControl>
                    <CssTextField
                      fullWidth
                      type="text"
                      inputProps={{
                        maxLength:
                          this.state.uniqueValue === "QATAR_ID"
                            ? qatariLength
                            : this.state.uniqueValue === "PASSPORT_NO"
                            ? passportLength
                            : companyNumberLength,
                      }}
                      placeholder={
                        this.state.uniqueValue === "QATAR_ID"
                          ? labels.SIGNUP_UNIQUE_ID_QATARID_PLACEHOLDER
                          : this.state.uniqueValue === "PASSPORT_NO"
                          ? labels.SIGNUP_UNIQUE_ID_PASSPORTNO_PLACEHOLDER
                          : this.state.uniqueValue === "COMPANY_NO"
                          ? labels.SIGNUP_UNIQUE_ID_COMPANY_REG_NO_PLACEHOLDER
                          : null
                      }
                      margin="normal"
                      id="uniqueId_Id"
                      name="uniqueId"
                      value={user.uniqueId}
                      onChange={this.handleChange}
                      onBlur={this.uniqueIdBlur}
                      autoComplete="off"
                      onKeyDown={this.onKeyDown}
                      error={
                        this.state.uniqueIdRequire || this.state.uniqueIdError
                      }
                      helperText={
                        this.state.uniqueIdRequire === true
                          ? this.state.uniqueValue === "QATAR_ID"
                            ? labels.SIGNUP_QATARI_ID_REQUIRED
                            : this.state.uniqueValue === "PASSPORT_NO"
                            ? labels.SIGNUP_PASSPORT_NO_REQUIRED
                            : this.state.uniqueValue === "COMPANY_NO"
                            ? labels.SIGNUP_COMPANY_NO_REQUIRED
                            : null
                          : this.state.uniqueIdError === true
                          ? this.state.uniqueValue === "QATAR_ID"
                            ? labels.SIGNUP_QATARI_ID_NOT_VALID
                            : this.state.uniqueValue === "PASSPORT_NO"
                            ? labels.SIGNUP_PASSPORT_NO_NOT_VALID
                            : this.state.uniqueValue === "COMPANY_NO"
                            ? labels.SIGNUP_COMPANY_NO_NOT_VALID
                            : null
                          : null
                      }
                    />
                    <br />
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <ColorTooltip
                  arrow
                  title={labels.SIGNUP_USERNAME_HELPTEXT}
                  placement={clsx("", this.props.display && "bottom")}
                >
                  <Typography
                    display="inline"
                    variant="h6"
                    align="left"
                    className={
                      this.state.usernameRequire || this.state.usernameValid
                        ? this.props.baseStyle.errorColor
                        : this.props.baseStyle.whiteColor
                    }
                  >
                    {labels.SIGNUP_PAGE_USERNAME_FIELD}
                  </Typography>
                </ColorTooltip>

                <CssTextField
                  fullWidth
                  placeholder={labels.SIGNUP_USERNAME_PLACEHOLDER}
                  type="text"
                  margin="normal"
                  id="username_Id"
                  name="username"
                  value={user.username}
                  onChange={this.handleChange}
                  onBlur={this.usernameBlur}
                  inputProps={{ maxLength: usernameLength }}
                  error={this.state.usernameRequire || this.state.usernameValid}
                  autoComplete="off"
                  helperText={
                    this.state.usernameRequire === true
                      ? labels.SIGNUP_FIELD_USERNAME_REQUIRED
                      : this.state.usernameValid === true
                      ? labels.SIGNUP_USERNAME_HELPTEXT
                      : null
                  }
                />
                <br />
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <ColorTooltip
                      arrow
                      title={labels.ERROR_HELPER}
                      placement={clsx("", this.props.display && "bottom")}
                    >
                      <Typography
                        display="inline"
                        variant="h6"
                        align="left"
                        className={
                          this.state.pwdRequire ||
                          this.state.pwdError ||
                          this.state.confirmError
                            ? this.props.baseStyle.errorColor
                            : this.props.baseStyle.whiteColor
                        }
                      >
                        {labels.LOGIN_PASSWORD}
                      </Typography>
                    </ColorTooltip>
                    <CssTextField
                      fullWidth
                      placeholder={labels.SIGNUP_PASSWORD_PLACEHOLDER}
                      margin="normal"
                      type="password"
                      id="password_Id"
                      inputProps={{ maxLength: passwordLength }}
                      name="password"
                      value={user.password}
                      onChange={this.handleChange}
                      onBlur={this.pwdBlur}
                      autoComplete="off"
                      error={
                        this.state.pwdRequire ||
                        this.state.pwdError ||
                        this.state.confirmError
                      }
                      helperText={
                        this.state.pwdRequire === true
                          ? labels.ERROR_PASSWORD_REQUIRED
                          : this.state.pwdError === true
                          ? labels.LOGIN_INVALID_PASSWORD
                          : null
                      }
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <ColorTooltip
                      arrow
                      title={labels.SIGNUP_CONFIRM_PASSWORD_HELPTEXT}
                      placement={clsx("", this.props.display && "bottom")}
                    >
                      <Typography
                        display="inline"
                        variant="h6"
                        align="left"
                        className={
                          this.state.confirmRequire || this.state.confirmError
                            ? this.props.baseStyle.errorColor
                            : this.props.baseStyle.whiteColor
                        }
                      >
                        {labels.SIGNUP_CONFIRM_PASSWORD}
                      </Typography>
                    </ColorTooltip>
                    <CssTextField
                      fullWidth
                      placeholder={labels.SIGNUP_CONFIRM_PASSWORD_PLACEHOLDER}
                      margin="normal"
                      type="password"
                      id="confirmPassword_Id"
                      name="confirmPassword"
                      value={user.confirmPassword}
                      onChange={this.handleChange}
                      onKeyPress={this.keypress}
                      autoComplete="off"
                      onBlur={this.confirmpwdBlur}
                      error={
                        this.state.confirmRequire || this.state.confirmError
                      }
                      helperText={
                        this.state.confirmRequire === true
                          ? labels.SIGNUP_CONFIRM_PASSWORD_REQUIRED
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      {this.state.confirmError === true && (
                        <Typography
                          className={this.props.baseStyle.errorColor}
                          variant="subtitle1"
                          align="center"
                        >
                          {labels.ERROR_ERROR}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justify="center" alignItems="center">
                  {enableCaptcha && (
                    <Grid item>
                      <Grid container justify="center" alignItems="center">
                        {captcha.load && (
                          <ReCAPTCHA
                            style={{ display: "inline-block" }}
                            theme="light"
                            ref={this._reCaptchaRef}
                            sitekey={siteKey}
                            onChange={this.captchaHandleChange}
                            asyncScriptOnLoad={this.captchaAsyncScriptOnLoad}
                            size="normal"
                            onExpired={this.captchaHandleExpired}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <div className="margin-top-20">
              {this.state.error === true && (
                <React.Fragment>
                  <br />
                  <Alert severity="error">{this.state.msg}</Alert>
                </React.Fragment>
              )}
            </div>
            <Grid
              className="margin-top-20"
              container
              justify="center"
              alignItems="center"
              spacing={2}
              direction="row-reverse"
            >
              <Grid item>
                {this.state.isSignUpButtonDisabled === false ? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={
                      this.state.isSignupLoading
                        ? loadingStyle
                        : submitButtonStyle
                    }
                    onClick={
                      this.state.isSignupLoading === false
                        ? this.signupSubmit
                        : null
                    }
                  >
                    {labels.REGISTER_SIGNUP}{" "}
                    {this.state.isSignupLoading && (
                      <CircularProgress size={30} />
                    )}
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    type="submit"
                    variant="contained"
                    className={disableStyle}
                  >
                    {labels.REGISTER_SIGNUP}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  type="reset"
                  variant="outlined"
                  className={CancelButtonStyle}
                  onClick={this.cancelSubmit}
                >
                  {labels.BUTTON_CANCEL}
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const actionCreators = {
  getPath: pathActions.getPath,
  getLocationPath: pathActions.getLocationPath,
};

const connectedRegister = connect(null, actionCreators)(Register);
export { connectedRegister as Register };
