import React, { Component,useState,useEffect } from "react";
import "../../../auth/auth.css";
import "../../../app/base-style.css";
import Button from "@material-ui/core/Button";
import {
  Typography,
  Grid,
  MenuItem,
  InputLabel, 
  makeStyles,
  Box,
  CardContent,
} from "@material-ui/core";
import { store } from "../../../../helpers";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import "./_new-service-agreement.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import { adminService } from "../../../../services/admin.service";
import { OutlinedTextFiled ,TitleSelect} from "../../../auth/auth-style";
import { useSelector } from "react-redux";
import { Hidden } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { grey300, grey600, grey700, white } from "material-ui/styles/colors";
import {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from "@material-ui/lab/Alert";

const useStyle = makeStyles((theme) => ({
    popupTitle : {
      backgroundColor: theme.palette.primary.main,
      color: white,
    }
  }));

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: white,
    },
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabStyles: {
    minWidth: "20%",
    minHeight: "5vh",
    fontWeight: "light",
    transition: "0.5s ease-in-out",
    borderRadius: " 4px 4px 0 0",
    marginRight: 15
},
selectedTab: {
    background: theme.palette.primary.main + "!important",
    opacity : 1,
    color: white
},
unSelctedTab: {
    background: grey300,
    color: grey600,
    opacity : 1
},
baseTabStyles: {
    marginTop: "1rem",
    minHeight: "30px"
},
tabs: {
  "& .MuiTabs-indicator": {
    display: "none"
  }
},
cardBorder:{
  border: '2px solid '+theme.palette.primary.main
},
addJointOwnerLink : {
  color: theme.palette.primary.main + "!important",
  opacity : 1,
  display: "block",
},
mobilePadding: {
  paddingBottom: 12,
  paddingTop: 12
},
minFourJointOwner:{
  minHeight:"auto",
  paddingLeft:0,
  minWidth:"fit-content"
}
}));
const CustomTab = withStyles({
  root: {
    textTransform: "none"
  }
})(Tab);

function JointOwnerDetails(props) {
  const classes = useStyles();
  const labels = useSelector((state)=>state?.labels?.data?.getLabels?.APPLICANT_DETAILS);
  const label_User_management = useSelector((state)=>state?.labels?.data?.getLabels?.USER_MANAGEMENT);
  const labels_Property = useSelector((state)=>state?.labels?.data?.getLabels?.PROPERTY_DETAILS);

  let companyNumberLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.COMPANY_NO_LENGTH);
  companyNumberLength = Number(companyNumberLength);
  let mobileLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.MOBILE_LENGTH);
  mobileLength = Number(mobileLength);
  let qatariLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.QATAR_ID_LENGTH);
  qatariLength = Number(qatariLength);
  let passportLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.PASSPORT_NO_LENGTH);
  passportLength = Number(passportLength);
  const country_Calling_Code = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.COUNTRY_DIALING_CODE);
  const country_Code = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.COUNTRY_CODE);
  let userNameLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.USERNAME_MAX_LENGTH);
  userNameLength = Number(userNameLength);
  let saveAlertTimeLimit = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.JOINT_OWNER_SUCCESS_TIME);
  saveAlertTimeLimit = Number(saveAlertTimeLimit);
  
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    if(newValue < props.jointOwnerDetails.length){
      setSelectedTab(newValue);
    }
  }
  const labels_Upfront_Payment = useSelector((state)=>state?.labels?.data?.getLabels?.UPFRONT_PAYMENT);

  const[user,setUser]=useState(
    {
      landline: "",
      extension_landline: ""
    })
    const [uniqueValue, setuniqueValue] = useState("QATAR_ID")

    const [landlineRequire, setLandlineRequire] = useState(false);
    const [landlineError, setLandlineError] = useState(false)
   const landlineBlur = () => {
      if (user.landline) {
        setLandlineRequire(false);
        if (store.getState().parametersWidgets.ParameterLookup) {
          var MOBILE_LENGTH = store.getState().parametersWidgets.ParameterLookup
            .MOBILE_LENGTH;
          if (user.landline.length === Number(MOBILE_LENGTH)) {
            setLandlineError(false);
          } else {
            setLandlineError(true);
          }
        }
      } else {
        setLandlineRequire(true);
        setLandlineError(false);
      }
    }

    let MOBILE_REGEX = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.MOBILE_REGEX);
      MOBILE_REGEX=MOBILE_REGEX.replace(
        "\\\\",
        "\\"
      );
    const handleChangelocal = (event) => {
      setSaveButtonVisibility(false);
      const { name, value } = event.target;
      if (name === "landline" ||  name === "unique") {
        const re=RegExp(MOBILE_REGEX)
        if (event.target.value === "" || re.test(event.target.value)) {
           if (name === "landline") {
            setUser(
              {
                ...user,
                [name]: value,
              }
            )
          } 
        }
      } else {
        setUser(
          {
            ...user,
            [name]: value,
          }
        )
      }
    }

    const uniqueRadioChange = (event) => {
      setSaveButtonVisibility(false);
      setuniqueID("");
      setuniqueValue(event.target.value)
      setUser({
          ...user,
          uniqueId: "",
      })
    }

    const [title, setTitle] = useState("MR");
    const [fusername, setfUser] = useState("");
    const [fusernameRequire, setfUsernameRequire] = useState(false);
    const [fusernameValid, setfUsernameValid] = useState(false);
    const [username, setUserName] = useState("");

  const [lusername, setlUser] = useState("");
  const [lusernameRequire, setlUsernameRequire] = useState(false);
  const [lusernameValid, setlUsernameValid] = useState(false);

  const [emailRequire, setemailRequire] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [email, setEmail] = useState("");

  const [mobile, setMobile] = useState("");
  const [withoutExtmobile, setwithoutExtmobile] = useState("");

  const [mobileRequire, setmobileRequire] = useState(false);
  const [mobileError, setmobileError] = useState(false);

  const [countryCode, setCountryCode] = useState(country_Calling_Code);
  const [countryName, setcountryName] = useState(country_Code);

  const [uniqueID, setuniqueID] = useState("");
  const [uniqueIdRequire, setuniqueIdRequire] = useState(false);

  const [uniqueIdError, setuniqueIdError] = useState(false);
  const [puniqueIdError, setpuniqueIdError] = useState(false);

  const [saveButtonVisibility, setSaveButtonVisibility] = useState(true);

  useEffect(() => {
    setLandlineRequire(false);
    setLandlineError(false);
    setfUsernameRequire(false);
    setfUsernameValid(false);
    setlUsernameRequire(false);
    setlUsernameValid(false);
    setemailRequire(false);
    setemailError(false);
    setmobileRequire(false);
    setmobileError(false);
    setuniqueIdRequire(false);
    setuniqueIdError(false);
    setpuniqueIdError(false);
    setSaveButtonVisibility(true);

    if(Object.keys(props.jointOwnerDetails[selectedTab]).length === 0 || props.jointOwnerDetails[selectedTab]=={}){
      setTitle("MR");
      setfUser("");
      setUserName("");
      setlUser("");
      setEmail("");
      setMobile("");
      setcountryName("QA");
      setuniqueID("");
      setuniqueValue("QATAR_ID");
      setCountryCode("");
      setwithoutExtmobile("");
      setUser(
        {
          landline: "",
          extension_landline: ""
        }
      )
    }else{
      setTitle(props.jointOwnerDetails[selectedTab].title);
      setfUser(props.jointOwnerDetails[selectedTab].fusername);
      setUserName(props.jointOwnerDetails[selectedTab].username);
      setlUser(props.jointOwnerDetails[selectedTab].lusername);
      setEmail(props.jointOwnerDetails[selectedTab].email);
      setMobile(props.jointOwnerDetails[selectedTab].mobile);
      setcountryName(props.jointOwnerDetails[selectedTab].countryName);
      setuniqueID(props.jointOwnerDetails[selectedTab].uniqueID);
      setuniqueValue(props.jointOwnerDetails[selectedTab].uniqueValue);
      setCountryCode(props.jointOwnerDetails[selectedTab].countryCode);
      setwithoutExtmobile(props.jointOwnerDetails[selectedTab].withoutExtmobile);
      setUser(
        {
          landline: props.jointOwnerDetails[selectedTab].landline,
          extension_landline: props.jointOwnerDetails[selectedTab].extension_landline 
        }
      )
    }
  }, [selectedTab])

  let handleOnChangePhone = (e) => {
    setSaveButtonVisibility(false);
    if (e) {
      setMobile(e);
      setmobileRequire(false);
      let phoneNumber = parsePhoneNumber(e);
      if (phoneNumber) {
        setmobileRequire(false)
        if (isValidPhoneNumber(e) === true) {
          setmobileError(false);
          setCountryCode(phoneNumber.countryCallingCode);
          setcountryName(phoneNumber.country);
          setMobile(phoneNumber.number);
          setwithoutExtmobile(phoneNumber.nationalNumber);
        } else {
          setmobileError(true);
        }
      }else{
        setmobileRequire(true)
      }
    } else {
      setmobileRequire(true);
    }
  };

  let handleTitleChange = (event) => {
    setSaveButtonVisibility(false);
    let { name, value } = event.target;
    setTitle(value);
  };

  const emailBlur = () => {
    if (store.getState().parametersWidgets.ParameterLookup) {
      if (email) {
        let emails = email.toLowerCase();

        setemailRequire(false);
        var emailData = store.getState().parametersWidgets.ParameterLookup
          .EMAIL_REGEX;
        var emailRegEx = new RegExp(emailData);
        var test = emailRegEx.test(emails);
        if (test) {
          setemailError(false);
        } else {
          setemailError(true);
        }
      } else {
        setemailRequire(true);
        setemailError(false);
      }
    }
  };

  const handleChange = (event) => {
    setSaveButtonVisibility(false);
    const { name, value } = event.target;
    if (
      name === "registeredCell" ||
      name === "landline" ||
      name === "rregisteredCell"
    ) {
      const re=RegExp(MOBILE_REGEX)
      if (event.target.value === "" || re.test(event.target.value)) {
        if (name === "registeredCell") {
          setMobile(value);
        } 
      }
    } else if (name === "fusername") {
      setfUser(value);
    } else if (name === "lusername") {
      setlUser(value);
    } else if (name === "musername") {
      setUserName(value);
    } else if (name === "registeredEmail") {
      setEmail(value);
    } else if (uniqueValue === "QATAR_ID" && name === "uniqueId") {
      setuniqueID(value);
    } else if (uniqueValue === "PASSPORT_NO" && name === "uniqueId") {
      setuniqueID(value);
    } 
  };

  const fusernameBlur = () => {
    if (fusername) {
      setfUsernameRequire(false);
      let nameMinLength = store.getState().parametersWidgets.ParameterLookup
        .NAME_MIN_LENGTH;
      if (fusername.length >= Number(nameMinLength)) {
        setfUsernameValid(false);
      } else {
        setfUsernameValid(true);
      }
    } else {
      setfUsernameRequire(true);
    }
  };

  const lusernameBlur = () => {
    if (lusername) {
      setlUsernameRequire(false);
      let nameMinLength = store.getState().parametersWidgets.ParameterLookup
        .NAME_MIN_LENGTH;
      if (lusername.length >= Number(nameMinLength)) {
        setlUsernameValid(false);
      } else {
        setlUsernameValid(true);
      }
    } else {
      setlUsernameRequire(true);
    }
  };

  let mobileBlur = (e) => {};

  let passportMinimumLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.PASSPORT_MINIMUM_LENGTH);
  let uniqueIdBlur = () => {
    if (uniqueID) {
      setuniqueIdRequire(false);
      if (store.getState().parametersWidgets.ParameterLookup) {
        if (uniqueValue === "PASSPORT_NO") {
          if (uniqueID.length > 0) {
            if (uniqueID.length < Number(passportMinimumLength)) {
              setpuniqueIdError(true);
            } else {
              setpuniqueIdError(false);
            }
          } else {
            setuniqueIdRequire(true);
          }
        } else if (uniqueValue === "QATAR_ID") {
          var QATAR_ID_LENGTH = store.getState().parametersWidgets
            .ParameterLookup.QATAR_ID_LENGTH;
          if (uniqueID.length !== Number(QATAR_ID_LENGTH) || isNaN(uniqueID)) {
            setuniqueIdError(true);
          } else {
            setuniqueIdError(false);
          }
        }
      }
    } else {
      setuniqueIdRequire(true);
    }
  };

  const [openSaveSnackbar, setopenSaveSnackbar] = useState(false);
  const handleCloseSaveSnackbar = () => {
    setopenSaveSnackbar(false);
  }

  const handleSave = () => {
    let idError = false;
    if (uniqueValue === "QATAR_ID") {
        idError = uniqueIdError;
    } else {
        idError = puniqueIdError;
      }
    if (
      ((fusername == "" ||
        fusernameValid ||
        lusername == "" ||
        lusernameValid)) ||
      email == "" ||
      uniqueID == "" ||
      idError ||
      emailError ||
      mobile == "" ||
      mobileError || mobileRequire
    ) {
      if (fusername === "") {
        setfUsernameRequire(true);
      }
      if (lusername === "") {
        setlUsernameRequire(true);
      }
      if (email === "") {
        setemailRequire(true);
      }
      if (mobile === "") {
        setmobileRequire(true);
      }
      if (uniqueID === "") {
        setuniqueIdRequire(true);
      }
      return false;
    }
    else{
      setSaveButtonVisibility(true);
      setopenSaveSnackbar(true)
      let finalObject = "";
      finalObject = {
      title : title,
      fusername : fusername,
      username : username,
      lusername : lusername,
      email: email,
      mobile: mobile,
      countryCode: countryCode,
      withoutExtmobile: withoutExtmobile,
      countryName : countryName,
      uniqueID: uniqueID,
      uniqueValue : uniqueValue,
      landline : user.landline,
      extension_landline : user.extension_landline,
      }; 
      props.saveJointOwnerData(selectedTab,finalObject)
    }
  }

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleDelete = () => {
    setOpenDeleteDialog(true);
  } 
  
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  }
  const handleYesDeleteDialog = () => {
    props.deleteJointOwnerData(selectedTab)
    setSelectedTab(0);
    setOpenDeleteDialog(false);
  }

  return (
    <div >
          <SimpleTabs selectedTab={selectedTab} handleTabChange={handleTabChange} jointOwnerDetails={props.jointOwnerDetails} handleAddJointOwnerClick={props.handleAddJointOwnerClick}/>
          <CardContent className={classes.cardBorder}>
          <Grid container direction="row" spacing={2} className="margin-top-10">
            <Grid item xs={3} sm={2} md={1} lg={1}>
              <Typography align="left">
                  {labels?.TITLE}
              </Typography>
              {props.titleDropdown && (
                <TitleSelect
                  fullWidth
                  variant="outlined"
                  labelId="label"
                  id="select"
                  value={title}
                  onChange={handleTitleChange}
                >
                  {props.titleDropdown.map((item, k) => {
                    return (
                      <MenuItem value={item.dropDownCode}>
                        {item.dropDownValue}
                      </MenuItem>
                    );
                  })}
                </TitleSelect>
              )}
            </Grid>

            <Grid item xs={9} sm={10} md={3} lg={3}>
              <InputLabel align="left" required>
                  {labels?.FIRST_NAME}
              </InputLabel>
              <OutlinedTextFiled
                variant="outlined"
                id="username_Id"
                name="fusername"
                fullWidth
                inputProps={{
                  maxLength: userNameLength,
                }}
                value={fusername}
                onChange={handleChange}
                onBlur={fusernameBlur}
                error={fusernameRequire || fusernameValid}
                autoComplete="off"
                helperText={
                  fusernameRequire === true ? (
                    labels?.FIRST_NAME_REQUIRED
                  ) : fusernameValid === true ? (
                    labels?.NAME_NOT_VALID
                  ) : (
                    <div>&nbsp;</div>
                  )
                }
                placeholder={labels?.FIRST_NAME_PLACEHOLDER
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <InputLabel
                align="left"
              >
                   {labels?.MIDDLE_NAME}
              </InputLabel>
              <OutlinedTextFiled
                variant="outlined"
                id="username_Id"
                name="musername"
                fullWidth
                inputProps={{
                  maxLength: userNameLength,
                }}
                value={username}
                onChange={handleChange}
                autoComplete="off"
                placeholder={
                     labels?.MIDDLE_NAME_PLACEHOLDER
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <InputLabel align="left" required>
                  {labels?.LAST_NAME}
              </InputLabel>
              <OutlinedTextFiled
                variant="outlined"
                id="username_Id"
                name="lusername"
                fullWidth
                inputProps={{
                  maxLength: userNameLength,
                }}
                value={lusername}
                onChange={handleChange}
                onBlur={lusernameBlur}
                error={lusernameRequire || lusernameValid}
                autoComplete="off"
                helperText={
                  lusernameRequire === true ? (
                    labels?.LAST_NAME_REQUIRED
                  ) : lusernameValid === true ? (
                    labels?.NAME_NOT_VALID
                  ) : (
                    <div>&nbsp;</div>
                  )
                }
                placeholder={
                     labels?.LAST_NAME_PLACEHOLDER
                }
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} className="margin-top-10">
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <InputLabel align="left" required>
                {label_User_management?.SIGNUP_REGISTERED_EMAIL}
              </InputLabel>
              <OutlinedTextFiled
                fullWidth
                variant="outlined"
                maxLength="100"
                id="registeredEmail_Id"
                name="registeredEmail"
                value={email}
                disabled={false}
                onChange={handleChange}
                onBlur={emailBlur}
                autoComplete="off"
                placeholder={label_User_management?.SIGNUP_EMAIL_PACEHOLDER}
                error={emailRequire || emailError}
                helperText={
                  emailRequire === true ? (
                    label_User_management?.ERROR_EMAIL_REQUIRED
                  ) : emailError === true ? (
                    label_User_management?.ERROR_EMAIL_VALIDATION
                  ) : (
                    <div>&nbsp;</div>
                  )
                }
              />
            </Grid>
            <Grid item xs={8} sm={3} md={4} lg={4}>
            <InputLabel align="left">{labels?.LANDLINE_NUMBER}</InputLabel>
            <OutlinedTextFiled
              variant="outlined"
              id="landline_Id"
              inputProps={{
                maxLength: mobileLength,
              }}
              fullWidth
              name="landline"
              disabled={false}
              value={user.landline}
              onChange={handleChangelocal}
              onBlur={landlineBlur}
              autoComplete="off"
              placeholder={labels?.LANDLINE_PLACEHOLDER}
              error={landlineError}
              helperText={
                landlineError ? (
                  labels?.LANDLINE_NOT_VALID
                ) : (
                  <div>&nbsp;</div>
                )
              }
            />
          </Grid>
          <Grid item xs={4} sm={3} md={4} lg={4}>
            <InputLabel align="left">Extension</InputLabel>
            <OutlinedTextFiled
              variant="outlined"
              id="landline_Id"
              inputProps={{
                maxLength: mobileLength,
              }}
              fullWidth
              name="extension_landline"
              disabled={false}
              value={user.extension_landline}
              onChange={handleChangelocal}
              autoComplete="off"
              placeholder="ext."
            />
          </Grid>
          </Grid>
        
        <Grid container direction="row" spacing={3}>
            {/* <> */}
            <Grid item xs={12} sm={6} md={5} lg={4} style={{paddingBottom:0}}>
              <Grid>
                <InputLabel color="primary" align="left" required>
                  <b>{labels?.CHOOSE_IDENTIFICATION}</b>
                </InputLabel>
              </Grid>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="uniqueValue"
                  value={uniqueValue}
                  onChange={uniqueRadioChange}
                  row
                >
                  <FormControlLabel
                    value={"QATAR_ID"}
                    control={
                      <Radio className={props.classesBase.linkColor} />
                    }
                    label={labels_Upfront_Payment?.JOINT_OWNER_QATAR_ID}
                  />
                  <FormControlLabel
                    value={"PASSPORT_NO"}
                    control={
                      <Radio className={props.classesBase.linkColor} />
                    }
                    label={
                      label_User_management?.SIGNUP_UNIQUE_ID_PASSPORTNO_LABEL
                    }
                  />
                </RadioGroup>
              </FormControl>
              <Grid item>
                <OutlinedTextFiled
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    maxLength:
                      uniqueValue=== "QATAR_ID"
                        ? qatariLength
                        : uniqueValue === "PASSPORT_NO"
                        ? passportLength
                        : null,
                  }}
                  placeholder={
                    uniqueValue === "QATAR_ID" ||
                    uniqueValue == null
                      ? label_User_management?.SIGNUP_UNIQUE_ID_QATARID_PLACEHOLDER
                      : uniqueValue === "PASSPORT_NO"
                      ? label_User_management?.SIGNUP_UNIQUE_ID_PASSPORTNO_PLACEHOLDER
                      : null
                  }
                  id="uniqueId_Id"
                  name="uniqueId"
                  value={uniqueID}
                  onChange={handleChange}
                  onBlur={uniqueIdBlur}
                  autoComplete="off"
                  error={
                    uniqueValue === "QATAR_ID" ||
                    uniqueValue === null
                      ? uniqueIdRequire || uniqueIdError
                      : uniqueValue === "PASSPORT_NO"
                      ? uniqueIdRequire || puniqueIdError
                      : null
                  }
                  helperText={
                    uniqueValue === "QATAR_ID" ||
                    uniqueValue === null ? (
                      uniqueIdRequire === true ? (
                        label_User_management?.SIGNUP_QATARI_ID_REQUIRED
                      ) : uniqueIdError === true ? (
                        label_User_management?.SIGNUP_QATARI_ID_NOT_VALID
                      ) : (
                        <div>&nbsp;</div>
                      )
                    ) : uniqueValue === "PASSPORT_NO" ? (
                      uniqueIdRequire === true ? (
                        label_User_management?.SIGNUP_PASSPORT_NO_REQUIRED
                      ) : puniqueIdError === true ? (
                        label_User_management?.SIGNUP_PASSPORT_NO_NOT_VALID
                      ) : (
                        <div>&nbsp;</div>
                      )
                    ) : (
                      <div>&nbsp;</div>
                    )
                  }
                />
                
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4}>
            <Hidden xsDown> 
            <InputLabel color="primary" align="left">
              &nbsp;
            </InputLabel>
            </Hidden>
            
            <InputLabel align="left" className={classes.mobilePadding} required>
              {label_User_management?.SIGNUP_REGISTERED_CELLNUMBER}
            </InputLabel>
            <PhoneInput
              variant="outlined"
              fullWidth
              flags={flags}
              id="registeredCell_Id"
              name="registeredCell"
              value={mobile}
              autoComplete="off"
              placeholder={label_User_management?.SIGNUP_CELLNUMBER_PLACEHOLDER}
              international
              onBlur={mobileBlur}
              countryCallingCodeEditable={false}
              defaultCountry={countryName}
              onChange={handleOnChangePhone}
              limitMaxLength={true}
            />

            <Typography
              variant="caption"
              color="error"
              className="marginleftError"
            >
              {mobileRequire === true
                ? label_User_management?.SIGNUP_CELLNUMBER_REQUIRED
                : mobileError === true
                ? label_User_management?.SIGNUP_CELLNUMBER_NOT_VALID
                : null}
            </Typography>
            </Grid>
        </Grid>

        <hr/>
        <div style={{display:"flex", justifyContent:"flex-end"}}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleDelete}
          >
            {labels_Upfront_Payment?.DELETE}
        </Button>
        <Button
          variant="contained"
          disabled={saveButtonVisibility}
          color="primary"
          onClick={handleSave}
          style={{marginLeft: 20}}
          >
            {labels_Upfront_Payment?.SAVE}
        </Button>
        </div>
          <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSaveSnackbar}
          onClose={handleCloseSaveSnackbar}
          autoHideDuration={saveAlertTimeLimit}
        >
          <Alert onClose={handleCloseSaveSnackbar} severity="success" variant="filled">
          {labels_Upfront_Payment?.JOINT_OWNER_ADDED_SUCCESS}.
          </Alert>
        </Snackbar>
        <Dialog onClose={handleCloseDeleteDialog} aria-labelledby="customized-dialog-title" open={openDeleteDialog}>
        <DialogContent dividers style={{textAlign:"center"}}>
          <Typography gutterBottom>
            {labels_Upfront_Payment?.DELETE_JOINT_OWNER_DIALOG}
          </Typography>
        </DialogContent>
        <DialogActions>
        <Button
              variant="outlined"
              color="primary"
              onClick={handleCloseDeleteDialog}
              size="large"
              >
                {labels_Upfront_Payment?.NO}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleYesDeleteDialog}
              size="large"
              >
                {labels_Upfront_Payment?.YES}
            </Button>
        </DialogActions>
        </Dialog>
        </CardContent>
        {(props.jointOwnDetailsErrorArray.length > 0) && (
                  <Typography color="error" variant="subtitle2">
                    {labels_Upfront_Payment?.JOINT_OWNER_DETAILS_ERROR}
                    {props.jointOwnDetailsErrorArray.map((item,index) => {
                      let tempVar = (index+1)==props.jointOwnDetailsErrorArray.length?".":", ";
                      return (`${labels_Upfront_Payment?.JOINT_OWNER_DETAILS} ${item + 1}${tempVar}` )
                    })
                    }
                  </Typography>
          )}
  </div>
  )
}

export function SimpleTabs(props) {
  const classes = useStyles();
  const labels_Upfront_Payment = useSelector((state)=>state?.labels?.data?.getLabels?.UPFRONT_PAYMENT);
  let maxJointOwner = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.MAX_JOINT_OWNER);
  maxJointOwner=Number(maxJointOwner);
  return (
    <div >
        <Tabs 
        variant="scrollable"
        scrollButtons="auto"
        value={props.selectedTab} 
        onChange={props.handleTabChange} 
        aria-label="simple tabs example"
        className={[classes.baseTabStyles,classes.tabs]}
        >
        {props.jointOwnerDetails.map((tab,index) => {
                return (
                    <Tab 
                      key={index}
                      className={ [(props.selectedTab == index) ? classes.selectedTab : classes.unSelctedTab , classes.tabStyles]}
                      label={<b>{labels_Upfront_Payment?.JOINT_OWNER_DETAILS}{" "}{index+1}</b>}
                    />
                )
          })}
          {(props.jointOwnerDetails.length < maxJointOwner) &&
            <Tab 
                className={ [classes.addJointOwnerLink , classes.tabStyles]}
                label={<b>{labels_Upfront_Payment?.ADD_JOINT_OWNER_PLUS}</b>}
                onClick={props.handleAddJointOwnerClick}
              />
          }
          <CustomTab 
              className={classes.minFourJointOwner}
              label={<b>{labels_Upfront_Payment?.MAX_JOINT_OWNER_MESSAGE}</b>}
              disabled="true"
           />
        </Tabs>
    </div>
  );
}

export default JointOwnerDetails;