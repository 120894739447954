import { appConstants } from "../constants/app.constants";
import { appService, commonService } from "../services/index";
import { store } from "../helpers/store";

export const appActions = {
  getParametersWidgets,
  getConnectionDetails,
  getAppLogo,
  getAboutImage
};

function getParametersWidgets() {
  return dispatch => {
    appService.getParametersWidgets().then(res => {
      if (res) {
        dispatch(success(res));
      } else {
        dispatch(failure());
      }
    });
  };

  function success(params) {
    return { type: appConstants.PARAMETER_WIDGET_SUCCESS, params };
  }
  function failure() {
    return { type: appConstants.PARAMETER_WIDGET_FAILURE };
  }
}

function getConnectionDetails(connectionId,mobileNo,emailId){
  return dispatch => {
    dispatch(success(connectionId,mobileNo,emailId));
  };

  function success(connection,mobileNo,email) {
    return { type: appConstants.BALANCE_VIEW_SUCCESS, connection,mobileNo,emailId };
  }
}


function getAppLogo(tenant) {
  return dispatch => {
    commonService.getAssestUrl(tenant).then(res => {
      if (res) {
        dispatch(success(res));
      } else {
        dispatch(failure());
      }
    });
  };

  function success(logoResponse) {
    return { type: appConstants.APPLOGO_SUCCESS, logoResponse };
  }
  function failure() {
    return { type: appConstants.APPLOGO_FAILURE };
  }
}

function getAboutImage(tenant) {
  return dispatch => {
    commonService.getAssestUrl(tenant).then(res => {
      if (res) {
        dispatch(success(res));
      } else {
        dispatch(failure());
      }
    });
  };

  function success(imageResponse) {
    return { type: appConstants.ABOUT_IMAGE_SUCCESS, imageResponse };
  }
  function failure() {
    return { type: appConstants.ABOUT_IMAGE_FAILURE };
  }
}
