import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { paymentService } from "../service/payment-service";

export default function SkipCashTransactionRequestNSA(props) {
  const [res, setRes] = useState(null);
  const debounce = (fn, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  const btnCall = () => {
    props.log({
      key: "NSA_PAYMENT",
      message: props.card + "clicked",
      stackOrLog: "STACK",
    });
    props.setError(false);
    localStorage.setItem(
      "depositeAmount",
      (Math.round(props.formData.depositeAmount * 100) / 100).toFixed(2)
    );
    localStorage.setItem("uniqueId", props.formData.uniqueID);
    localStorage.setItem(
      "userName",
      props.formData.fusername + " " + props.formData.lusername
    );
    localStorage.setItem("email", props.formData.email);
    localStorage.setItem("mobile", props.formData.mobile);
    localStorage.removeItem("ticketno");
    let tempInput = JSON.parse(JSON.stringify(props?.dataObject));
    if (tempInput?.csaAttachments?.length) {
      tempInput.csaAttachments.forEach((item) => {
        item.fileContent = "HIDDEN";
      });
    }
    props.log({
      key: "NSA_PAYMENT",
      message: `Data (${props.paymentTypeSkipCash}) :`,
      addOnLog: tempInput,
      stackOrLog: "LOG",
    });
    localStorage.setItem("paymentProcess", "Y");
    paymentService
      .prePaymentProcessing(props.paymentType, props.dataObject)
      .then((res) => {
        setRes(res.data.prePaymentProcessing);
        localStorage.removeItem("paymentProcess");
        let dataValue = Object.assign({}, props.formData);
        dataValue.jointOwnerDetails.map((item, index) => {
          item.fileContent = "";
        });
        dataValue.csaAttachment.map((item, index) => {
          item.fileContent = "";
        });
        dataValue.FileContent = "";
        dataValue.FileContentp = "";
        dataValue.FileContentq = "";
        dataValue.FileContentAll = "";
        const Value = JSON.stringify(dataValue);
        localStorage.setItem("Value", Value);
        localStorage.removeItem("orderId");
        localStorage.setItem("orderId", res.data.prePaymentProcessing?.id);
        const paymentUrl = res.data.prePaymentProcessing?.payUrl;
        window.location.href = paymentUrl;
      })
      .catch((error) => {
        localStorage.removeItem("paymentProcess");
        props.log({
          key: "NSA_PAYMENT",
          message: "Prepayment API error",
          stackOrLog: "STACK",
        });
        props.setError(true);
        if (error?.response?.data?.errors[0]?.message) {
          props.log({
            key: "NSA_PAYMENT",
            message: error?.response?.data?.errors[0]?.message,
            stackOrLog: "STACK",
          });
          props.setErrorMessage(error?.response?.data?.errors[0]?.message);
        }
        props.log({ key: "NSA_PAYMENT", message: "Abort", stackOrLog: "LOG" });
        props.setOpenLoading(false);
      });
  };
  return (
    <div>
      <Button
        variant="contained"
        size="medium"
        color="secondary"
        className={props.className}
        onClick={debounce(btnCall, 2000)}
      >
        {props.labelName}
      </Button>
    </div>
  );
}
