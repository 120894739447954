import { config } from "../../enviroment";
import axios from "axios";

const BUILD_CHECK_COOLDOWN = 30000;
const BUILD_CHECK_COOLDOWN_AFTER_PAYMENT = 120000;
export var gettingAppVersion = null;

export async function checkAppVersion() {
  gettingAppVersion = true;
  const nextBuildVersionCall = localStorage.getItem("nextBuildVersionCall");
  if (
    nextBuildVersionCall &&
    Number(nextBuildVersionCall) &&
    Date.now() - Number(nextBuildVersionCall) < 0
  ) {
    gettingAppVersion = false;
    return;
  }
  const appVersion = await getAppVersion();
  gettingAppVersion = false;
  if (!appVersion || !Number(appVersion)) {
    return;
  }
  let localAuthVersion = localStorage.getItem("localAuthVersion");
  if (!localAuthVersion) {
    localStorage.setItem("localAuthVersion", appVersion);
    return;
  }
  if (Number(appVersion) > Number(localAuthVersion)) {
    reloadPage(appVersion);
  }
}
export async function getAppVersion() {
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.AUTH_UI_BASE_URL + "/auth-build-version.json",
      method: "get",
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    })
      .then(function (response) {
        localStorage.setItem(
          "nextBuildVersionCall",
          Date.now() + BUILD_CHECK_COOLDOWN
        );
        resolve(response?.data?.buildVersion);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export const reloadPage = (appVersion) => {
  let paymentInProcess = localStorage.getItem("paymentProcess");
  if (paymentInProcess === "Y") {
    return;
  } else {
    alert("Portal has been updated with latest changes, Press OK to continue");
    localStorage.removeItem("paymentProcess");
    localStorage.setItem("localAuthVersion", appVersion);
    window.location.reload();
  }
};
