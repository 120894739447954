import React from "react";
import { Router } from "react-router-dom";
import { history } from "../../helpers/index";
import { connect } from "react-redux";
import { createMuiTheme } from "@material-ui/core/styles";
import { Layout } from "../layout/layout-component";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  appActions,
  languageActions,
  bearerActions,
} from "../../actions/index";
import { appStyles } from "./app-style";
import { useStyles } from "./base-style";
import { commonService } from "../../services/common.service";
import "./base-style.css";
import setupAxiosInterceptors from "./axios-setup";
import NeoSansBold from "../../assests/fonts/neo-sans-bold.woff2";
import NeoSansLight from "../../assests/fonts/neo-sans-light.woff2";
import NeoSansRegular from "../../assests/fonts/neo-sans-regular.woff2";
import ChatWidget from "../chat-widget/chat.widget";
import { routePaths } from "../config/route-paths";
import { adminService } from "../../services";
import { bearerTokenService } from "../../services/bearer.service";
import Cookies from "universal-cookie";
import { store } from "../../helpers/store";

const neoSansRegular = {
  fontFamily: "NeoSansRegular",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    local('NeoSansRegular'),    
    url(${NeoSansRegular}) format('woff2')
  `,
};

const neoSansLight = {
  fontFamily: "NeoSansLight",
  fontStyle: "light",
  fontDisplay: "swap",
  src: `
    local('NeoSansLight'),    
    url(${NeoSansLight}) format('woff2')
  `,
};

const neoSansBold = {
  fontFamily: "NeoSansBold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('NeoSansBold'),    
    url(${NeoSansBold}) format('woff2')
  `,
};
class App extends React.Component {
  constructor(props) {
    // props.getBearer();
    ChatWidget();
    super(props);
    setupAxiosInterceptors();
    let isLogout = new URLSearchParams(window.location.search).get("logout");
    if (isLogout) {
      localStorage.clear();
      // code commented as part of ADS-3590
      // window.location.href = window.location.origin;
    }

    if (localStorage.getItem("bearer") !== null) {
      if (
        !window.location.pathname.startsWith(routePaths.BILL_PAY) &&
        !window.location.pathname.startsWith(routePaths.QUICK_PAY) &&
        !window.location.pathname.startsWith(routePaths.VIEW_BALANCE) &&
        !window.location.pathname.startsWith(routePaths.NSA) &&
        !window.location.pathname.startsWith(routePaths.PAYMENT) &&
        !window.location.pathname.startsWith(routePaths.PAYMENT_BILLPAY)&&
        !window.location.pathname.startsWith(routePaths.MIGRATE_USER)
      ) {
        commonService.gotoMainApp();
      }
    }
    const rocketContainer = document.querySelector(".rocketchat-container");
    const rocketWidget = document.querySelector(".rocketchat-widget");
    if (rocketContainer && rocketWidget) {
      rocketContainer.style.display = "none";
      rocketWidget.style.display = "none";
    }
    this.state = {
      user: localStorage.getItem("user"),
      theme: appStyles.outerTheme,
    };
  }
  async componentDidMount() {
    store.getState().snackbar = false;
    const cookies = new Cookies();
    let authbearer = cookies.get("authbearer");
    let authbear = localStorage.getItem("authbearer");
    adminService.log({ key: "APP_BEARER", message: "Calling Generate Bearer...", stackOrLog: "STACK" });
    await bearerTokenService
      .getBearerToken()
      .then((successBearer) => {
        adminService.log({ key: "APP_BEARER", message: "Token Received", stackOrLog: "LOG" });
        this.props.getParametersWidgets();
        this.props.downloadLabels();
      })
      .catch((error) => {
        adminService.log({ key: "APP_BEARER", message: "Error", addOnLog: error, stackOrLog: "STACK" });
      });
  }

  render() {
    this.props.store.subscribe(() => {
      const state = this.props.store.getState();
      this.setState({
        user: state.authentication.user,
      });

      if (state.parametersWidgets.ParameterLookup) {
        const theme = createMuiTheme({
          palette: {
            primary: {
              main: state.parametersWidgets.ParameterLookup.PRIMARY_COLOR,
            },
            secondary: {
              main: state.parametersWidgets.ParameterLookup.SECONDARY_COLOR,
            },
            tertiary: {
              main: state.parametersWidgets.ParameterLookup.TERTIARY_COLOR,
            },
            quaternary: {
              main: state.parametersWidgets.ParameterLookup.QUATERNARY_COLOR,
            },
            quinary: {
              main: state.parametersWidgets.ParameterLookup.QUINARY_COLOR,
            },
            whiteColor: {
              main: "#FFFFFF",
            },
            blackColor: {
              main: "#000000",
            },
            usageNegativeColor: {
              main: "#FF0000",
            },
            usagePositiveColor: {
              main: "#01B61E",
            },
          },
          typography: {
            fontFamily: "NeoSansRegular, NeoSansLight, NeoSansBold",
          },
          overrides: {
            MuiCssBaseline: {
              "@global": {
                "@font-face": [neoSansRegular, neoSansLight, neoSansBold],
              },
            },
            MuiFormLabel: {
              asterisk: {
                color: '#db3131',
                '&$error': {
                  color: '#db3131'
                },
              },
              root:{
                color:"black"
              }
            }
          },
        });
        this.setState({
          theme: theme,
        });
      }
    });
    return localStorage.getItem("bearer") === null ||
      window.location.pathname.startsWith(routePaths.BILL_PAY) ||
      window.location.pathname.startsWith(routePaths.QUICK_PAY) ||
      window.location.pathname.startsWith(routePaths.VIEW_BALANCE) ||
      window.location.pathname.startsWith(routePaths.NSA) ||
      window.location.pathname.startsWith(routePaths.PAYMENT) ||
      window.location.pathname.startsWith(routePaths.PAYMENT_BILLPAY) ? (
      <ThemeProvider theme={this.state.theme}>
        <div dir="ltr" className={useStyles.primaryBackground}>
          <Router history={history}>
            <Layout history={history} />
          </Router>
        </div>
      </ThemeProvider>
    ) : (
      commonService.gotoMainApp()
    );
  }
}

const actionCreators = {
  getBearer: bearerActions.getBearer,
  getParametersWidgets: appActions.getParametersWidgets,
  downloadLabels: languageActions.downloadLabels,
};

const connectedApp = connect(null, actionCreators)(App);
export { connectedApp as App };
