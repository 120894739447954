import React from "react";
import { history } from "../../helpers/index";
import { routePaths } from "../config/route-paths";
import "../auth/auth.css";
import { Button, ButtonGroup } from "@material-ui/core";
import { useStyles } from "../app/base-style";
import { store } from "../../helpers/index";
import { useMediaQuery } from "react-responsive";

let labels = {};
store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
});
function LSButton(props) {
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 550px)" });

  return (
    <LSButtonMain baseStyle={classes} isTabletOrMobile={isTabletOrMobile} />
  );
}
class LSButtonMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
      signup: false,
      path: routePaths.LOGIN,
    };
  }

  signup = () => {
    this.setState({ signup: true, login: false });
    history.push(routePaths.REGISTER);
  };

  login = () => {
    this.setState({ login: true, signup: false });
    history.push(routePaths.LOGIN);
  };

  UNSAFE_componentWillUpdate() {
    store.subscribe(() => {
      this.setState({
        login: store.getState().location.login,
        signup: store.getState().location.signup,
      });
    });
  }

  render() {
    let right = `rightBorder btnWidth ${this.props.baseStyle.secondaryBorder}`;
    let left = `leftBorder btnWidth ${this.props.baseStyle.secondaryBorder}`;
    let leftWithout = `leftBorder btnWidth ${this.props.baseStyle.whiteColor} ${this.props.baseStyle.secondaryBorder}`;
    let rightWithout = `rightBorder btnWidth ${this.props.baseStyle.whiteColor} ${this.props.baseStyle.secondaryBorder}`;
    return (
      <React.Fragment>
        {store.getState().labels.data && (
          <ButtonGroup
            color="secondary"
            fullWidth={!this.props.isTabletOrMobile}
          >
            <Button
              className={this.state.signup === true ? leftWithout : left}
              variant={this.state.signup === true ? "contained" : "outlined"}
              onClick={this.signup}
            >
              {labels.REGISTER_SIGNUP}
            </Button>

            <Button
              className={this.state.login === true ? rightWithout : right}
              variant={this.state.login === true ? "contained" : "outlined"}
              onClick={this.login}
            >
              {labels.LOGIN_TITLE}
            </Button>
          </ButtonGroup>
        )}
      </React.Fragment>
    );
  }
}

export default LSButton;
