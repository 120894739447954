import React, { Component } from "react";
import "../auth/auth.css";
import "../app/base-style.css";
import { useStyles } from "../app/base-style";
import { authStyles } from "../auth/auth-style";
import { Card, CardContent, Grid } from "@material-ui/core";
import "./quick-pay.css";
import { useMediaQuery } from "react-responsive";
import { BalancePage } from "./balance-page";
import { adminService } from "../../services/index";
import { Skeleton } from "@material-ui/lab";
import { QuickPayCommonHeader } from "./quick-pay-header";

export default function ViewBalance() {
  const classes = useStyles();
  const authClasses = authStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 960px)" });
  let quinaryBackgroundBlur = `rightSecondaryBack ${classes.quinaryBackground}`;
  let primaryBackgroundBlur = `primaryBack ${classes.primaryBackground} `;
  //let billpay = new URLSearchParams(window.location.search).get("billpay");
  let billpay = localStorage.getItem("billpay");

  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <React.Fragment>
          <Grid container direction="column">
            <Grid item>
              <div className={primaryBackgroundBlur} />
            </Grid>
            <Grid item>
              <div className={quinaryBackgroundBlur} />
            </Grid>
          </Grid>
          <Card className="stack-top">
            <MainBalancePage
              baseStyle={classes}
              authStyle={authClasses}
              billpay={billpay}
            />
          </Card>
        </React.Fragment>
      ) : (
        <div className="margin-top-60">
          <MainBalancePage
            baseStyle={classes}
            authStyle={authClasses}
            billpay={billpay}
          />
        </div>
      )}
    </React.Fragment>
  );
}
class MainBalancePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      labelSubTitle: "",
      lastNote: "",
    };
  }

  componentDidMount() {
    adminService.getContentData("en", "USER_MANAGEMENT").then((res) => {
      this.setState({
        data: res.data.getContents.USER_MANAGEMENT,
      });

      res.data.getContents.USER_MANAGEMENT.forEach((item) => {
        if (item.contentCode === "QUICK_PAY_SUBTITLE") {
          if (!this.props.billpay || this.props.billpay === "false") {
            this.setState({
              labelSubTitle: item.answer,
            });
          }
        } else if (item.contentCode === "BALANCE_LAST_PARAGRAPH") {
          this.setState({
            lastNote: item.answer,
          });
        } else if (
          item.contentCode === "BILL_PAY_SUBTITLE" &&
          this.props.billpay === "true"
        ) {
          this.setState({
            labelSubTitle: item.answer,
          });
        }
      });
    });
  }

  render() {
    let { labelSubTitle, lastNote, data } = this.state;
    return (
      <React.Fragment>
        <CardContent className="cardPadding">
          {labelSubTitle ? (
            <QuickPayCommonHeader
              labelSubTitle={labelSubTitle}
              baseStyle={this.props.baseStyle}
              billpay={this.props.billpay}
            />
          ) : (
            <Skeleton />
          )}
          {data ? (
            <BalancePage
              baseStyle={this.props.baseStyle}
              lastNote={lastNote}
              data={data}
              authStyle={this.props.authStyle}
              billpay={localStorage.getItem("billpay")}
            />
          ) : (
            <Skeleton variant="text" />
          )}
        </CardContent>
      </React.Fragment>
    );
  }
}
