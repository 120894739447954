import { createMuiTheme } from "@material-ui/core/styles";
import NeoSansBold from "../../assests/fonts/neo-sans-bold.woff2";
import NeoSansLight from "../../assests/fonts/neo-sans-light.woff2";
import NeoSansRegular from "../../assests/fonts/neo-sans-regular.woff2";

const neoSansRegular = {
  fontFamily: "NeoSansRegular",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    local('NeoSansRegular'),    
    url(${NeoSansRegular}) format('woff2')
  `,
};

const neoSansLight = {
  fontFamily: "NeoSansLight",
  fontStyle: "light",
  fontDisplay: "swap",
  src: `
    local('NeoSansLight'),    
    url(${NeoSansLight}) format('woff2')
  `,
};

const neoSansBold = {
  fontFamily: "NeoSansBold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('NeoSansBold'),    
    url(${NeoSansBold}) format('woff2')
  `,
};

export const appStyles = {
  outerTheme: outerTheme(),
};

function outerTheme() {
  return createMuiTheme({
    palette: {
      primary: {
        main: "#004987",
      },
      secondary: {
        main: "#FFA400",
      },
      tertiary: {
        main: "#008996",
      },
      quaternary: {
        main: "#D3D3D3",
      },
      quinary: {
        main: "#DEEDF2",
      },
      whiteColor: {
        main: "#FFFFFF",
      },
      blackColor: {
        main: "#000000",
      },
      usageNegativeColor: {
        main: "#FF0000",
      },
      usagePositiveColor: {
        main: "#01B61E",
      },
    },
    typography: {
      fontFamily: "NeoSansRegular, NeoSansLight, NeoSansBold",
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [neoSansRegular, neoSansLight, neoSansBold],
        },
      },
    },
  });
}
