import React, { useState, useRef } from "react";
import PrintIcon from "@material-ui/icons/Print";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ReactToPrint from "react-to-print";
import { IconButton, Typography } from "@material-ui/core";
import { commonService } from "../../services/index";
import { store } from "../../helpers/store";
import "../quick-pay/quick-pay.css";
import "./terms-condition.css";
import headerLogo from "../../assests/icon-QC.png";
let labels = "";

store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
});
export default function PrintDownload(props) {
  const componentRef = useRef();

  const [appLogo, setAppLogo] = useState(null);

  if (!appLogo && store.getState().parametersWidgets.ParameterLookup) {
    commonService
      .getAssestUrl(
        store.getState().parametersWidgets.ParameterLookup.TENANT_APP_LOGO
      )
      .then((response) => {
        setAppLogo(response);
      });
  }

  return (
    <React.Fragment>
      <ReactToPrint
        trigger={() => (
          <IconButton aria-label="close" className={props.classes.printButton}>
            <PrintIcon />
          </IconButton>
        )}
        content={() => componentRef.current}
        documentTitle={labels.TERMS_CONDITION_TITLE}
        copyStyles
      />

      {/* <IconButton
        aria-label="close"
        className={props.classes.downloadButton}
        id="cmd"
      >
        <CloudDownloadIcon />
      </IconButton> */}
      <div className="displayPrint">
        <div ref={componentRef}>
          <div
            dir={props.index === 1 ? "rtl" : "ltr"}
            className="printClass"
            align={props.index === 1 ? "right" : "left"}
          >
            <img
              className="app-logo-print"
              src={appLogo ? appLogo.assetPath : headerLogo}
              alt={
                appLogo
                  ? labels[appLogo.fallBackLabelKey]
                  : labels.ASSET_LOGO_FALLBACK_TEXT
              }
            />
            <Typography
              color="primary"
              variant="h5"
              component="h2"
              align="center"
            >
              <b>{labels.TERMS_CONDITION_TITLE}</b>
            </Typography>
            <Typography
              dangerouslySetInnerHTML={{
                __html: props.data,
              }}
            />
          </div>
        </div>
      </div>
          </React.Fragment>
  );
}
