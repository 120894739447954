import React, { Component } from "react";
import { history } from "../../helpers/index";
import "../auth/auth.css";
import "../app/base-style.css";
import { Link } from "react-router-dom";
import { routePaths } from "../config/route-paths";
import { Typography, Button, Grid } from "@material-ui/core";
import { userService } from "../../services/index";
import { store } from "../../helpers";
import { useStyles, ColorTooltip } from "../app/base-style";
import { commonService } from "../../services/common.service";
import { connect } from "react-redux";
import { pathActions } from "../../actions";
import { CssTextField, authStyles } from "../auth/auth-style";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import ImageBackground from "../../assests/qc-bg.jpg";
import { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";

let labels = {};
let labelsError={}
let usernameLength = "";
let passwordLength = "";

store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
    labelsError=store.getState().labels.data.getLabels.SERVER_EXCEPTIONS;
  }

  if (store.getState().parametersWidgets.ParameterLookup) {
    usernameLength = store.getState().parametersWidgets.ParameterLookup
      .USERNAME_LENGTH;
    usernameLength = Number(usernameLength);
    passwordLength = store.getState().parametersWidgets.ParameterLookup
      .PASSWORD_MAXLENGTH;
    passwordLength = Number(passwordLength);
  }
});


function Login(props) {
  const classes = useStyles();
  const authClasses = authStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
  let rightBlue = `split right ${classes.primaryBackground}`;

  let username = new URLSearchParams(window.location.search).get("username");
  if (username === null) {
    username = "";
  }

  useEffect(() => {    
    const rocketContainer = document.querySelector('.rocketchat-container');
    const rocketWidget = document.querySelector('.rocketchat-widget')
    if(rocketContainer && rocketWidget) {
      rocketContainer.style.display = "block"
      rocketWidget.style.display = "block" 
    }
  })
  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <div >
          <div className="bodyimage" />
          <div className={rightBlue}>
            <LoginMain
              propsMain={props}
              baseStyle={classes}
              authStyle={authClasses}
              username={username}
            />{" "}
          </div>
        </div>
      ) : (
        <React.Fragment>
          <Grid container direction="column" spacing={0}>
            <Grid item className={[classes.primaryBackground,classes.paddingTopHomepage]}>
              <LoginMain
                propsMain={props}
                baseStyle={classes}
                authStyle={authClasses}
                username={username}
              />{" "}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
class LoginMain extends Component {
  constructor(props) {
    props.propsMain.getLocationPath(routePaths.LOGIN);

    super(props);
    this.state = {
      username: this.props.username,
      password: "",
      submitted: true,
      error: false,
      userError: false,
      userRequire: false,
      pwdRequired: false,
      isSignupLoading: false,
      errorCode:null,
    };
    this.keypress = this.keypress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loginSubmit = this.loginSubmit.bind(this);
    this.pwdBlur = this.pwdBlur.bind(this);
    this.emailBlur = this.emailBlur.bind(this);
    this.submit= this.submit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    if (name === "username") {
      const re = /^[a-zA-Z0-9]+$/;
      // if (e.target.value === "" || re.test(e.target.value)) {
      if (e.target.value === "" || (e.target.value)) {
        this.setState({
          [name]: value.toLowerCase().trim(),
        });
      }
    } else {
      this.setState({ [name]: value });
    }
  }

  emailBlur() {
    const { username } = this.state;

    if (store.getState().parametersWidgets.ParameterLookup) {
      if (username) {
        let email = username.toLowerCase();
        this.setState({
          userRequire: false,
          error: false,
        });
        var usernameData = store.getState().parametersWidgets.ParameterLookup
          .USERNAME;
        var usernameRegEx = new RegExp(usernameData);
        // var test = usernameRegEx.test(email);
        // if (test) {
        //   this.setState({ userError: false });
        // } else {
        //   this.setState({ userError: true });
        // }
      } else {
        this.setState({
          userRequire: true,
          userError: false,
          error: false,
        });
      }
    }
  }
  submit() {
    this.emailBlur();
    const username = this.state.username;
    if (username) {
      if (this.state.userError === false && this.state.userRequire === false) {
        this.setState({
          isSignupLoading: true,
        });
        userService
          .resendEmail(username)
          .then((response) => {
            this.setState({
              isSignupLoading: false,
            });
            history.push(routePaths.LANDING + "?flowCode=signupSuccess");
          })
          .catch((error) => {
            this.setState({
              error: true,
            });
          });
      }
    }
  }
  pwdBlur() {
    const { password } = this.state;
    if (password) {
      this.setState({
        pwdRequired: false,
        error: false,
      });
    } else {
      this.setState({
        pwdRequired: true,
        error: false,
      });
    }
  }

  loginSubmit(e) {
    e.preventDefault();
    this.emailBlur();
    this.pwdBlur();
    const { username, password } = this.state;
    if (username && password) {
      if (
        this.state.userError === false &&
        this.state.userRequire === false &&
        this.state.pwdRequired === false
      ) {
        this.setState({
          isSignupLoading: true,
        });
        userService
          .login(username, password)
          .then((response) => {
            this.props.propsMain.getPath(routePaths.LOGIN, {
              username,
              password,
            });

            if (response.acessToken) {
              localStorage.clear();
              localStorage.setItem(
                "bearer",
                commonService.encodeString(JSON.stringify(response))
              );
              commonService.gotoMainApp();
            } else if (response.lastFourDigitsOfPhoneNumber) {
              if (response.otpVerified !== "true") {
                localStorage.removeItem("flowType");
                localStorage.setItem("flowType", "LOGIN_OTPVERIFIED");
                localStorage.setItem('LastFourDigit',response.lastFourDigitsOfPhoneNumber)
                history.push(routePaths.AUTH_OTP);
              } else {
                localStorage.removeItem("flowType");
                localStorage.setItem("flowType", "LOGIN");
                history.push(routePaths.AUTH_OTP);
              }
            }
            this.setState({
              isSignupLoading: false,
            });
          })
          .catch((error) => {            
            if (error.response && error.response.data.statusCode === 403) {
              let errMessage, errMessageCode, terminationDate;
              errMessage = error.response.data.message;
              if (errMessage.indexOf("|") > 0) {
                errMessageCode = errMessage.split("|")[0];
                terminationDate = errMessage.split("|")[1];
                if (errMessageCode === "ACCOUNT_TERMINATED_INVALID_SA") {
                  history.push(
                    routePaths.LANDING +
                      "?flowCode=userAccountDeactivated&terminationDate=" +
                      terminationDate
                  );
                } else {
                  // verification fail
                  history.push(
                    routePaths.LANDING +
                      "?flowCode=userVerificationFailure"
                      );
                }
              } else {
                history.push(
                  routePaths.LANDING +
                    "?flowCode=userBlockedTemp&min=" +
                    error.response.data.message
                );
              }
            } else if (error.response) {
              if(error.response.data.statusCode === 500 || error.response.data.statusCode === 404){
                this.setState({
                  error: true,
                  msg: labelsError.SERVER_EXCEPTIONS_NOT_RIGHT,
                  errorCode: error.response.data.statusCode,
                  isSignupLoading: false,
                })
              } else {                
                this.setState({
                  error: true,
                  msg: error.response.data.message,
                  errorCode: error.response.data.statusCode,
                  isSignupLoading: false,
                });
              }
              
            }
          });
      }
    }
  }
  keypress(event) {
    if (event.key === "Enter") {
      this.loginSubmit(event);
    }
  }
  render() {
    let isResetOn = false;
    let isEnableMobile = false;
    const state = store.getState();
    const widgets = state.parametersWidgets.WidgetLookUp;
    if (widgets) {
      widgets.forEach((widgetItem) => {
        if (
          widgetItem.widgetCode === "RESET_PASSWORD" &&
          widgetItem.widgetDefaultValue === "true"
        ) {
          isResetOn = true;
        } else if (
          widgetItem.widgetCode === "ENABLE_MOBILE_AUTHENTICATION" &&
          widgetItem.widgetDefaultValue === "true"
        ) {
          isEnableMobile = true;
        }
      });
    }
    const { username, password } = this.state;
    let linkText = `lblForget ${this.props.authStyle.quinaryColor}`;
    let submitButtonStyle = `btnBorder ${this.props.baseStyle.whiteColor}`;
    let loginLink = `btnLink ${this.props.authStyle.quinaryColor}`;
    let loadingStyle = `btnBorder ${this.props.authStyle.loadingCursor} ${this.props.authStyle.quinaryColor}`;
    let CancelButtonStyle = `btnBorder ${this.props.baseStyle.whiteColor} ${this.props.authStyle.tertiaryButton}`;

    return (
      <React.Fragment>
        {store.getState().labels.data && (
          <div className="containermargin">
            <Typography
              variant="h4"
              align="center"
              className={this.props.baseStyle.whiteColor}
            >
              <b>{labels.LOGIN_TITLE}</b>
            </Typography>
            <div className="margin-top-50">
              <br />
            </div>
            <form onSubmit={this.loginSubmit}>

            <Grid container spacing={4} direction="column">
              <Grid item>
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <Typography
                      variant="h6"
                      align="left"
                      className={
                        this.state.userRequire || this.state.userError
                          ? this.props.baseStyle.errorColor
                          : this.props.baseStyle.whiteColor
                      }
                    >
                      {labels.SIGNUP_USERNAME_LABEL}
                    </Typography>
                    <CssTextField
                      fullWidth
                      id="Username_Id"
                      name="username"
                      value={username}
                      onChange={this.handleChange}
                      onBlur={this.emailBlur}
                      inputProps={{ maxLength: usernameLength }}
                      error={this.state.userRequire || this.state.userError}
                      autoComplete="off"
                      helperText={
                        this.state.userRequire === true
                          ? labels.SIGNUP_USERNAME_REQUIRED
                          : this.state.userError === true
                          ? labels.SIGNUP_USERNAME_NOT_VALID
                          : null
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Link
                      to={{
                        pathname: routePaths.RESET_REQUEST,
                        data: {
                          title: labels.FORGOT_YOUR_USERNAME,
                        },
                      }}
                      className={linkText}
                      align="left"
                    >
                      {labels.LOGIN_FORGOT_USERNAME}
                    </Link>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <Typography
                      variant="h6"
                      align="left"
                      className={
                        this.state.pwdRequired
                          ? this.props.baseStyle.errorColor
                          : this.props.baseStyle.whiteColor
                      }
                    >
                      {labels.LOGIN_PASSWORD}
                    </Typography>
                    <CssTextField
                      fullWidth
                      type="password"
                      id="Password_Id"
                      name="password"
                      value={password}
                      inputProps={{ maxLength: passwordLength }}
                      onChange={this.handleChange}
                      onBlur={this.pwdBlur}
                      autoComplete="off"
                      error={this.state.pwdRequired}
                      onKeyPress={this.keypress}
                      helperText={
                        this.state.pwdRequired === true
                          ? labels.ERROR_PASSWORD_REQUIRED
                          : null
                      }
                    />
                  </Grid>
                  <Grid item>
                    {isResetOn === true && (
                      <Link
                        to={{
                          pathname: routePaths.RESET_REQUEST,
                          data: {
                            title: labels.FORGOT_PASSWORD,
                          },
                        }}
                        className={linkText}
                        align="left"
                      >
                        {labels.LOGIN_FORGOTPWD}
                      </Link>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          
            <div className="margin-top-50">
              <br />
            </div>
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={2}
              direction="row"
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={
                    this.state.isSignupLoading
                      ? loadingStyle
                      : submitButtonStyle
                  }
                  onClick={
                    this.state.isSignupLoading === false
                      ? this.loginSubmit
                      : null
                  }
                >
                  {labels.LOGIN_TITLE} {" "} {this.state.isSignupLoading && <CircularProgress size={17} color="inherit"/>}
                  {isEnableMobile}
                </Button>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  align="left"
                  className={this.props.baseStyle.whiteColor}
                >
                  {isEnableMobile === true && labels.LOGIN_OR}
                </Typography>
              </Grid>
              {isEnableMobile === true && (
                <Grid item>
                  <ColorTooltip
                    arrow
                    title={labels.LOGIN_VIA_OTP_HELPTEXT}
                    placement={clsx("top", this.props.display && "right-end")}
                  >
                    <Link
                      to={{
                        pathname: routePaths.LOGIN_VIA_OTP,
                        data: {
                          username: this.state.username,
                        },
                      }}
                      className={loginLink}
                    >
                      <Typography variant="h6">
                        {labels.LOGIN_VIA_OTP} &#10095;
                      </Typography>
                    </Link>
                  </ColorTooltip>
                </Grid>
              )}
            </Grid>
            </form>
            <div className="margin-top-20">
              {this.state.error === true && (
                <React.Fragment>
                  <br />
                  <Alert severity="error">{this.state.msg}</Alert>
                </React.Fragment>
              )}
            </div>
            <br />
            {this.state.errorCode && this.state.errorCode == 400 && this.state.msg == labels.LOGIN_EMAIL_ERROR ? (
                    <Grid item align="center">
                      <Button
                        onClick={this.submit}
                        className={CancelButtonStyle}
                        variant="h6"
                      >
                        {" "}
                        <Typography variant="h6">
                          {labels.LOGIN_RESEND_EMAIL}
                        </Typography>
                      </Button>
                    </Grid>
                  ) : null}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const actionCreators = {
  getPath: pathActions.getPath,
  getLocationPath: pathActions.getLocationPath,
};

const connectedLogin = connect(null, actionCreators)(Login);
export { connectedLogin as Login };
