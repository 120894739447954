import { combineReducers } from "redux";
import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { parametersWidgets, snackbar, appLogo,aboutImage } from "./app.reducer";
import { labels } from "../reducers/labels.reducer";
import { path, location, submit } from "./path.reducer";
import { connectionId } from "./app.reducer";
import {newSeriveAgreementData} from "../components/new-service-agreement-with-upfront-payment/new-service-agreement/model/reducers"

const rootReducer = combineReducers({
  authentication,
  snackbar,
  registration,
  parametersWidgets,
  labels,
  path,
  location,
  connectionId,
  submit,
  appLogo,
  aboutImage,
  newSeriveAgreementData
});

export default rootReducer;
