import { Button, Typography, Grid } from "@material-ui/core";
import React from "react";
import { useStyles } from "../app/base-style";
import "./retry.css";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { commonService } from "../../services/common.service";
import { routePaths } from "../config/route-paths";
import { history } from "../../helpers/history";
import { store } from "../../helpers/store";

export default function Retry(props) {
  const classes = useStyles();
  let labels = {};
  let label = {};
  let lastContent =
    '<span> Let us know at <a href="mailto:web@exapmle.com" class="***">inquiry@qatarcool.com</a> if you are seeing this again.</span>';
  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const anchorRef = React.useRef(null);
  const logout = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    commonService.logoutUser();
  };
  if (
    lastContent ===
    '<span> Let us know at <a href="mailto:web@exapmle.com" class="***">inquiry@qatarcool.com</a> if you are seeing this again.</span>'
  ) {
    lastContent = lastContent.replace("***", "link");
  }
  const reloadPage= () =>{
    let FirstHit=localStorage.getItem('retry_cmp');
    if(FirstHit==null){
      localStorage.setItem("retry_cmp",1);
      window.history.back();
    }
    else{
      setCount(count + 1);
      localStorage.removeItem('retry_cmp');
    }
  }
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.SERVER_EXCEPTIONS;
    label=store.getState().labels.data.getLabels.HEADER;
  }
  return (
    <Grid className="div">
      <Grid className="card-style">
        <Grid>
          <div className={classes.whiteColor} align="center">
            <CloudDownloadOutlinedIcon className="icons" />
            <Typography className={classes.whiteColor} align="center">
              <ClearOutlinedIcon style={{ color: "#F14F3F", fontSize: "3rem" }} />{" "}
              <br />
            </Typography>
          </div>
          <Typography className={classes.whiteColor} style={{fontSize:"2rem"}} align="center">
            <b>
              {" "} 
              {labels.length > 0  ?
                labels.SERVER_EXCEPTIONS_NOT_RIGHT
                : "Something is not right here ..."}{" "}
            </b>
          </Typography>
          <br />
          <div align="center">
            <Button
             onClick={reloadPage}
              variant="contained"
              color="secondary"
              className={classes.whiteColor}
            >
              {labels.length > 0 ? labels.SERVER_EXCEPTIONS_RELOAD : "Reload Page"}
            </Button>
            <br />
            <br />
            {count && count > 0 ? (
              <span className={classes.whiteColor} align="center">
                {props.contentData ? (
                  <Typography
                    className={classes.whiteColor}
                    align="center"
                    dangerouslySetInnerHTML={{
                      __html: props.contentData,
                    }}
                  />
                ) : 
                <Typography>
                  Let us know at {" "}
                  <a href="mailto:we@example.com" className="link">inquiry@qatarcool.com</a>{" "}
                  if you are seeing this again.
                </Typography>
                }
              </span>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
