import React, { useEffect, useState } from "react";
import "../app/base-style.css";
import {
  Typography,
  Button,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Grid,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getConnectionService } from "./quick-pay-connection-finder-service";
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStylesCustom = makeStyles((theme) => ({
  lightGreyColor: {
    color: theme.palette.quaternary.main,
  },
  selectHeight: {
    padding: "0.7rem 2rem 0.7rem 0.7rem !important",
  },
  formControl: {
    width: "100%",
  },
  valueText: {
    fontWeight: "bolder",
  },
  arrow: { 
    color: theme.palette.primary.main,
    marginRight: "0.5rem"
  },
  link: {
    fontWeight: "bolder",
    background: "none",
    boxShadow: "none",
    padding: 0,
    textTransform: "none",
    textDecoration: "underline",
    color: "#932250",
    "&:hover": {
      textDecoration: "underline",
      background: "none",
      boxShadow: "none",
    },
  },
  orHorizontalRule: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "7px",
    "&:before": {
      content: `""`,
      flex: "1 1",
      borderBottom: "1px solid",
      margin: "auto",
      marginRight: "10px",
    },
    "&:after": {
      content: `""`,
      flex: "1 1",
      borderBottom: "1px solid",
      margin: "auto",
      marginLeft: "10px",
    },
  },
}));

function ConnectionFinder(props) {
  const labels = useSelector(
    (state) => state?.labels?.data?.getLabels?.PROPERTY_DETAILS
  );
  const quickPayLabels = useSelector(
    (state) => state?.labels?.data?.getLabels?.USER_MANAGEMENT
  );
  const labelsApplicantLabels = useSelector(
    (state) => state?.labels?.data?.getLabels?.APPLICANT_DETAILS
  );
  const classesLocal = useStylesCustom();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [address4Loading, setAddress4Loading] = useState(false);
  const [address3Loading, setAddress3Loading] = useState(false);
  const [address2Loading, setAddress2Loading] = useState(false);
  const [address1Loading, setAddress1Loading] = useState(false);
  const [stateList, setStateList] = useState();
  const [address4List, setAddress4List] = useState();
  const [address3List, setAddress3List] = useState();
  const [address2List, setAddress2List] = useState();
  const [address1List, setAddress1List] = useState();
  const [state, setState] = useState("");
  const [address4, setAddress4] = useState("");
  const [address3, setAddress3] = useState("");
  const [address2, setAddress2] = useState("");
  const [address1, setAddress1] = useState("");
  const [connectionIdLocal, setConnectionIdLocal] = useState();
  const [propertyIdLocal, setPropertyIdLocal] = useState();

  const clearAddress1 = () => {
    setConnectionIdLocal(null);
    setPropertyIdLocal(null);
    setAddress1List(null);
    setAddress1("");
  };
  const clearAddress2 = () => {
    clearAddress1();
    setAddress2List(null);
    setAddress2("");
  };
  const clearAddress3 = () => {
    clearAddress2();
    setAddress3List(null);
    setAddress3("");
  };
  const clearAddress4 = () => {
    clearAddress3();
    setAddress4List(null);
    setAddress4("");
  };
  const findStateCd = (state) =>
    stateList?.find((stateObj) => stateObj.descr === state)?.stateCD;
  useEffect(() => {
    setStateLoading(true);
    getConnectionService
      .getState()
      .then((res) => {
        if (res?.results?.length) {
          setStateList(res.results);
        } else {
          setStateList([]);
        }
        setStateLoading(false);
      })
      .catch((err) => {
        setStateLoading(false);
        setStateList([]);
      });
  }, []);
  useEffect(() => {
    if (state) {
      setAddress4Loading(true);
      getConnectionService
        .getAddress4({ state: findStateCd(state) })
        .then((res) => {
          if (res?.results?.length) {
            setAddress4List(res.results);
          } else {
            setAddress4List([]);
          }
          setAddress4Loading(false);
        })
        .catch((err) => {
          setAddress4Loading(false);
          setAddress4List([]);
        });
    }
  }, [state]);
  useEffect(() => {
    if (state && address4) {
      setAddress3Loading(true);
      getConnectionService
        .getAddress3({ state: findStateCd(state), address4 })
        .then((res) => {
          if (res?.results?.length) {
            setAddress3List(res.results);
          } else {
            setAddress3List([]);
          }
          setAddress3Loading(false);
        })
        .catch((err) => {
          setAddress3Loading(false);
          setAddress3List([]);
        });
    }
  }, [address4]);
  useEffect(() => {
    if (state && address4 && address3) {
      setAddress2Loading(true);
      getConnectionService
        .getAddress2({ state: findStateCd(state), address4, address3 })
        .then((res) => {
          if (res?.results?.length) {
            setAddress2List(res.results);
          } else {
            setAddress2List([]);
          }
          setAddress2Loading(false);
        })
        .catch((err) => {
          setAddress2Loading(false);
          setAddress2List([]);
        });
    }
  }, [address3]);
  useEffect(() => {
    if (state && address4 && address3 && address2) {
      setAddress1Loading(true);
      getConnectionService
        .getAddress1({
          state: findStateCd(state),
          address4,
          address3,
          address2,
        })
        .then((res) => {
          if (res?.results?.length) {
            setAddress1List(res.results);
          } else {
            setAddress1List([]);
          }
          setAddress1Loading(false);
        })
        .catch((err) => {
          setAddress1Loading(false);
          setAddress1List([]);
        });
    }
  }, [address2]);

  const stateHandleChange = (event) => {
    clearAddress4();
    setState(event.target.value);
  };
  const address4HandleChange = (event) => {
    clearAddress3();
    setAddress4(event.target.value);
  };
  const address3HandleChange = (event) => {
    clearAddress2();
    setAddress3(event.target.value);
  };
  const address2HandleChange = (event) => {
    clearAddress1();
    setAddress2(event.target.value);
  };
  const address1HandleChange = (event) => {
    setAddress1(event.target.value);
    setLocalIds(event.target.value);
  };
  const setLocalIds = (address1) => {
    if (address1) {
      let premise = address1List?.find((item) => item?.address1 == address1);
      setConnectionIdLocal(premise?.connection_Id);
      setPropertyIdLocal(premise?.property_Id);
    }
  };
  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleCloseForced = () => {
    setDialogOpen(false);
  };
  const handleClose = () => {
    setDialogOpen(false);
    localStorage.setItem("connectionId", connectionIdLocal);
    if (connectionIdLocal) {
      props.parentSetState({
        connectionId: connectionIdLocal,
      });
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + 45 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + exdate.toUTCString();
      document.cookie =
        "connectionId=" + connectionIdLocal + ";" + expires + ";path=/";
    }
  };
  return (
    <div>
      {true ? (
        <div>
          <Grid container>
            <Grid item>
              <ArrowForwardSharpIcon className={classesLocal.arrow}/>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickOpen}
                className={classesLocal.link}
              >
                {quickPayLabels?.QUICK_PAY_PROPERTY_FINDER}
              </Button>
            </Grid>
          </Grid>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            onClose={handleCloseForced}
            aria-labelledby="customized-dialog-title"
            open={dialogOpen}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={handleCloseForced}
            >
              <Typography
                color="primary"
                gutterBottom
                variant="subtitle1"
                component="h1"
                style={{ margin: "auto", fontSize: "1.2rem" }}
              >
                <b>{labels?.SELECT_PREMISE}</b>
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              {!stateLoading ? (
                <React.Fragment>
                  <Typography variant="subtitle1" color="primary">
                    {labels?.SELECT_PREMISE_DISTRICT}
                  </Typography>
                  <FormControl className={classesLocal.formControl}>
                    <Select
                      fullWidth
                      displayEmpty
                      variant="outlined"
                      value={state}
                      onChange={stateHandleChange}
                      input={
                        <OutlinedInput
                          classes={{
                            input: classesLocal.selectHeight,
                          }}
                        />
                      }
                    >
                      <MenuItem
                        className={classesLocal.lightGreyColor}
                        key={"address"}
                        value=""
                        disabled
                      >
                        <span className={classesLocal.lightGreyColor}>
                          {labels?.PLEASE_SELECT_DISTRICT}
                        </span>
                      </MenuItem>
                      {stateList?.map((dist, index) => (
                        <MenuItem key={index + 1} value={dist?.descr}>
                          <ListItemText primary={dist?.descr} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </React.Fragment>
              ) : null}
              <br />
              <br />
              {!address4Loading && address4List ? (
                <React.Fragment>
                  <Typography variant="subtitle1" color="primary">
                    {labels?.SELECT_BULIDING}
                  </Typography>
                  <FormControl className={classesLocal.formControl}>
                    <Select
                      displayEmpty
                      fullWidth
                      variant="outlined"
                      value={address4}
                      onChange={address4HandleChange}
                      input={
                        <OutlinedInput
                          classes={{
                            input: classesLocal.selectHeight,
                          }}
                        />
                      }
                    >
                      <MenuItem
                        className={classesLocal.lightGreyColor}
                        key={"buildingNew"}
                        value=""
                        disabled
                      >
                        <span className={classesLocal.lightGreyColor}>
                          {labels?.PLEASE_SELECT_BUILDING}
                        </span>
                      </MenuItem>
                      {address4List.map((item, index) => (
                        <MenuItem key={index + 1} value={item?.address4}>
                          <ListItemText
                            primary={
                              item?.address4
                                ? item.address4
                                : labels?.DROPDOWN_OTHERS
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                </React.Fragment>
              ) : null}
              {!address3Loading && address3List ? (
                <React.Fragment>
                  <Typography variant="subtitle1" color="primary">
                    {labels?.BUILDING_NAME}
                  </Typography>
                  <FormControl className={classesLocal.formControl}>
                    <Select
                      displayEmpty
                      fullWidth
                      variant="outlined"
                      value={address3}
                      onChange={address3HandleChange}
                      input={
                        <OutlinedInput
                          classes={{
                            input: classesLocal.selectHeight,
                          }}
                        />
                      }
                    >
                      <MenuItem
                        className={classesLocal.lightGreyColor}
                        key={"address"}
                        value=""
                        disabled
                      >
                        <span className={classesLocal.lightGreyColor}>
                          {labels?.PLEASE_SELECT_BUILDING}
                          {" " + labelsApplicantLabels?.APPLICANT_NAME}
                        </span>
                      </MenuItem>
                      {address3List.map((item, index) => (
                        <MenuItem key={index + 1} value={item?.address3}>
                          <ListItemText
                            primary={
                              item?.address3
                                ? item.address3
                                : labels?.DROPDOWN_OTHERS
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                </React.Fragment>
              ) : null}
              {!address2Loading && address2List ? (
                <React.Fragment>
                  <Typography variant="subtitle1" color="primary">
                    {labels?.SELECT_UNIT}
                  </Typography>
                  <FormControl className={classesLocal.formControl}>
                    <Select
                      displayEmpty
                      fullWidth
                      variant="outlined"
                      value={address2}
                      onChange={address2HandleChange}
                      input={
                        <OutlinedInput
                          classes={{
                            input: classesLocal.selectHeight,
                          }}
                        />
                      }
                    >
                      <MenuItem
                        className={classesLocal.lightGreyColor}
                        key={"address"}
                        value=""
                        disabled
                      >
                        <span className={classesLocal.lightGreyColor}>
                          {labels?.PLEASE_SELECT_UNIT}
                        </span>
                      </MenuItem>
                      {address2List.map((item, index) => (
                        <MenuItem key={index + 1} value={item?.address2}>
                          <ListItemText
                            primary={
                              item?.address2
                                ? item.address2
                                : labels?.DROPDOWN_OTHERS
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                </React.Fragment>
              ) : null}
              {!address1Loading && address1List ? (
                <React.Fragment>
                  <Typography variant="subtitle1" color="primary">
                    {labels?.SELECT_PREMISE_ADDRESS}
                  </Typography>
                  <FormControl className={classesLocal.formControl}>
                    <Select
                      displayEmpty
                      fullWidth
                      variant="outlined"
                      value={address1}
                      onChange={address1HandleChange}
                      input={
                        <OutlinedInput
                          classes={{
                            input: classesLocal.selectHeight,
                          }}
                        />
                      }
                    >
                      <MenuItem key={"address"} value="" disabled>
                        <span className={classesLocal.lightGreyColor}>
                          {labels?.PLEASE_SELECT_ADDRESS}
                        </span>
                      </MenuItem>
                      {address1List.map((item, index) => (
                        <MenuItem key={index + 1} value={item?.address1}>
                          <ListItemText
                            primary={
                              item?.address1
                                ? item.address1
                                : labels?.DROPDOWN_OTHERS
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br />
                  <br /> <br />
                </React.Fragment>
              ) : null}
              {stateLoading ||
              address4Loading ||
              address3Loading ||
              address2Loading ||
              address1Loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : null}
              {connectionIdLocal && (
                <Grid container direction="row">
                  <Grid xs="auto">
                    <Typography variant="subtitle1" color="primary">
                      {quickPayLabels?.QUICK_PAY_CONNECTION_ID}&nbsp;
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                      {quickPayLabels?.QUICK_PAY_PROPERTY_ID}&nbsp;
                    </Typography>
                  </Grid>
                  <Grid xs="auto">
                    <Typography variant="subtitle1" className={classesLocal.valueText}>
                      :&nbsp;{connectionIdLocal}
                    </Typography>
                    <Typography variant="subtitle1" className={classesLocal.valueText}>
                      :&nbsp;{propertyIdLocal}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {connectionIdLocal ? (
                <div align="center">
                  <Button
                    autoFocus
                    variant="contained"
                    color="primary"
                    align="center"
                    onClick={handleClose}
                    disabled={!connectionIdLocal}
                  >
                    {labels?.SELECT_BUTTON}
                  </Button>
                </div>
              ) : null}
            </DialogContent>
          </Dialog>
          <br />
        </div>
      ) : null}
    </div>
  );
}

const DialogTitle = withStyles(styles)((DialogTitleProps) => {
  const { children, classes, onClose, ...other } = DialogTitleProps;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default ConnectionFinder;
