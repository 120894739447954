import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: "flex",
    },
    primaryBackground: {
      background: theme.palette.primary.main + " !important"
    },
    appBar: {
      background: "#ffffff",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    hide: {
      display: "none"
    },
    content: {
      flexGrow: 1,
      margin: "20px",
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    toolbar: {
      display: "flex",
      height: "70px"
    },
    rightMenu: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    smallScreenToolbar:{
      display: "flex",
      height: "70px",
      borderTop:"1px solid "+ theme.palette.quaternary.main,
    },
    quickButton:{
      backgroundColor:theme.palette.tertiary.main, 
      color:"white",      
    }
  })
);

export default useStyles;
