import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem, Select, Chip } from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { DropzoneDialog } from "material-ui-dropzone";
import { useSelector } from "react-redux";
import { ticketService } from "../service/ticket-service";

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonColor: {
      color: theme.palette.whiteColor.main,
    },
    widthSelect: {
      width: "100% !important",
    },
    widthChip: {
      maxWidth: "-webkit-fill-available",
      marginBottom: "1%",
    },
    textTransfer: {
      textTransform: "capitalize",
    },
  })
);
const AdditionalSelect = withStyles((theme) => ({
  root: {
    padding: "9px 28px 9px 1vw!important",
  },
}))(Select);
export default function AdditionalAttach({ count, dropdownData,FileContentAll,setFileContentAll }) {
  let classes = useStyles();
  let labels = useSelector(
    (store) => store?.labels?.data?.getLabels?.ICSA_ADDITIONAL_ATTACHMENT
  );
  let dropdownItems = localStorage.getItem("dropdownItems");
  if (dropdownItems === null) {
    dropdownItems = [];
  } else {
    dropdownItems = JSON.parse(dropdownItems);
  }

  const [currentData, setCurrentData] = useState(
    dropdownItems[count] ? dropdownItems[count] : dropdownData[0]
  );
  useEffect(() => {
    if (dropdownItems[count]) {
      localStorage.setItem("dropdownItems", JSON.stringify(dropdownItems));
    } else {
      dropdownItems[count] = currentData;
      localStorage.setItem("dropdownItems", JSON.stringify(dropdownItems));
    }
  }, [dropdownItems[count]]);
  const handleChange = (e) => {
    let dropdownvalue = "";
    dropdownData.forEach((element) => {
      if (element.dropDownCode === e.target.value) {
        dropdownvalue = element;
      }
    });
    setCurrentData(dropdownvalue);
    dropdownItems[count] = dropdownvalue;
    localStorage.setItem("dropdownItems", JSON.stringify(dropdownItems));
  };

  // ---------- attachment code
  const [open, setOpen] = useState(false);
  const [fileTypes, setFileTypes] = useState([]);
  const [fileTypesData, setFileTypesData] = useState([]);
  let maxFileAllow = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.MAX_FILES_ALLOWED
  );
  if (maxFileAllow) {
    maxFileAllow = Number(maxFileAllow);
  }
  let maxFileSize = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.MAX_SIZE
  );
  if (maxFileSize) {
    maxFileSize = Number(maxFileSize) * 1000000;
  }
  let lang = useSelector(
    (store) =>
      store?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
  );

  useEffect(() => {
    if (lang) {
      ticketService.getFileTypes(lang).then((res) => {
        let temp = [];
        res.data.fileTypes.forEach((k) => {
          temp.push("." + k.FileAttachmentTypeCode.toLowerCase());
        });
        setFileTypes(temp);
        setFileTypesData(res.data.fileTypes);
      });
    }
  }, [lang]);

  let allFilesName = localStorage.getItem("AllFilesName");
  let allFilesType = localStorage.getItem("AllFilesType");
  let allFilesContent = FileContentAll;
  const [files, setFiles] = useState([]);
  const getPhoto = (fileName, fileType, fileContent) => {
    return new File([fileContent], fileName, { type: fileType });
  };

  if (allFilesName === null || allFilesName.length == 0) {
    allFilesName = [];
    allFilesType = [];
    allFilesContent = [];
  } else {
    allFilesName = JSON.parse(allFilesName);
    allFilesType = JSON.parse(allFilesType);
    allFilesContent = allFilesContent != "" ? JSON.parse(allFilesContent) : [];
  }
  useEffect(() => {
    if (allFilesName !== null && allFilesName.length > 0) {
      let countFile = [];
      if (allFilesName[count] && allFilesName[count].length > 0) {
        allFilesName[count].forEach((item,i) => {
          countFile.push(
            getPhoto(
              allFilesName[count][i],
              allFilesType[count][i],
              allFilesContent[count][i]
            )
          );
        });
      }
      setFiles(countFile);
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = (file) => {
    let newFiles = "";
    if (files.length > 0) {
      newFiles = files;
      file.forEach((item) => {
        newFiles.push(item);
      });
      setFiles(newFiles);
    } else {
      setFiles(file);
    }
    if (!newFiles) {
      newFiles = file;
    }
    addFileInLocal(newFiles, "AllFilesContent", "AllFilesName", "AllFilesType");
    setOpen(false);
  };

  const addFileInLocal = (newFiles, contentFile, name, type) => {
    let fileName = [];
    let fileContent = [];
    let fileType = [];
    if(newFiles.length == 0){
      setFileContentAll(JSON.stringify([]))
    }
    newFiles.map((item, k) => {
      fileName.push(item.name);
      let fileDescription = item.name.split(".");
      let reader = new FileReader();
      fileType.push(fileDescription[1]);
      if (fileDescription[1] === "txt") {
        reader.readAsText(item);
      } else {
        reader.readAsDataURL(item);
      }
      reader.onload = (e) => {
        if (fileDescription[1] !== "txt") {
          let content = e.target.result.split(",");
          fileContent.push(content[1]);
        } else {
          fileContent.push(e.target.result);
        }
        allFilesContent[count] = fileContent;
        setFileContentAll(JSON.stringify(allFilesContent))
      };
    });
    allFilesName[count] = fileName;
    localStorage.setItem(name, JSON.stringify(allFilesName));
    allFilesType[count] = fileType;
    localStorage.setItem(type, JSON.stringify(allFilesType));
  };
  const handleDelete = (chipToDelete) => {
    let newFiles = [];
    files.forEach((data) => {
      if (data.name !== chipToDelete.name) {
        newFiles.push(data);
      }
    });
    setFiles(newFiles);
    addFileInLocal(newFiles, "AllFilesContent", "AllFilesName", "AllFilesType");
  };
  return (
    <Grid item xs={12}>
      {labels?.CHOOSE_DOCUMENT_TYPE}
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AdditionalSelect
            fullWidth
            variant="outlined"
            labelId="label"
            id="select"
            value={currentData.dropDownCode}
            name={currentData.dropDownValue}
            onChange={handleChange}
            className={classes.widthSelect}
          >
            {dropdownData.map((item, k) => {
              return (
                <MenuItem value={item.dropDownCode} name={item.dropDownValue}>
                  {item.dropDownValue}
                </MenuItem>
              );
            })}
          </AdditionalSelect>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Button
            type="file"
            variant={files.length >= maxFileAllow ? "outlined" : "contained"}
            id="ids"
            onClick={files.length >= maxFileAllow ? "" : handleOpen}
            color="secondary"
            name="ID"
            fullWidth
            className={
              files.length >= maxFileAllow
                ? classes.textTransfer
                : `${classes.buttonColor} ${classes.textTransfer}`
            }
            endIcon={<AttachFileIcon></AttachFileIcon>}
          >
            {labels?.ADDITIONAL_ATTACH_FILE}
          </Button>
          <DropzoneDialog
            open={open}
            onSave={handleSave}
            acceptedFiles={fileTypes}
            showPreviews={true}
            maxFileSize={maxFileSize}
            filesLimit={maxFileAllow - files.length}
            onClose={handleClose}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={6}>
          {files.length > 0 &&
            files.map((item, key) => {
              return (
                <>
                  <Chip
                    label={item.name}
                    onDelete={(e) => handleDelete(item)}
                    className={classes.widthChip}
                  />
                  <br />
                </>
              );
            })}
        </Grid>
      </Grid>
      <hr />
    </Grid>
  );
}