import React, { useState, useEffect } from "react";
import { Typography, Grid, IconButton } from "@material-ui/core";
import ImageConnectionId from "../../assests/connection-id.png";
import { useMediaQuery } from "react-responsive";
import Skeleton from "@material-ui/lab/Skeleton";
import { store } from "../../helpers/store";
import { commonService } from "../../services/common.service";
import { ImageDialog } from "../auth/auth-style";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import ImageBillID from "../../assests/bill-id.png";
import { connect, useSelector } from "react-redux";
import { appActions } from "../../actions/index";

let labels = "";
store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function AboutAccount(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 350px)" });
  const [findAccountIdText, setfindAccountIdText] = useState(null);
  const [bankPaymentOption_text, setbankPaymentOption_text] = useState(null);
  const [visitServiceOffice_text, setvisitServiceOffice_text] = useState(null);
  const [connectionId, setconnectionId] = useState(null);
  let altText = "";
  if (props.billpay) {
    altText = labels.BILL_ID_IMAGE_ALT_TEXT;
  } else {
    altText = labels.CONNECTION_IMAGE_ALT_TEXT;
  }
  const [openDialog, setOpenDialog] = useState(false);
  const aboutBillImage = useSelector(
    (state) => state?.parametersWidgets?.ParameterLookup?.BILL_ID_IMAGE
  );
  const aboutConnectionImage = useSelector(
    (state) => state?.parametersWidgets?.ParameterLookup?.CONNECTION_ID_IMAGE
  );
  // useEffect(() => {
  //   if (aboutConnectionImage && aboutBillImage) {
  //     if (props.billpay) {
  //       props.getAboutImage(aboutBillImage);
  //     } else {
  //       props.getAboutImage(aboutConnectionImage);
  //     }
  //   }
  // }, [aboutConnectionImage, aboutBillImage]);

  const connectionIDImage = useSelector((state) => state?.aboutImage);

  // useEffect(() => {
  //   setconnectionId(connectionIDImage);
  // }, [connectionIDImage]);

  function handleClickOpen() {
    setOpenDialog(true);
  }

  function handleClose() {
    setOpenDialog(false);
  }

  useEffect(() => {
    props.data.forEach((content) => {
      if (
        content.contentCode === "QUICK_PAY_FIND_CONNECTION_ID" &&
        !props.billpay
      ) {
        setfindAccountIdText(content);
      } else if (content.contentCode === "QUICK_PAY_BANK_PAYMENT_OPTION") {
        setbankPaymentOption_text(content);
      } else if (content.contentCode === "QUICK_PAY_VISIT_OUR_SERVICE") {
        setvisitServiceOffice_text(content);
      } else if (
        content.contentCode === "BILL_PAY_FIND_BILL" &&
        props.billpay
      ) {
        setfindAccountIdText(content);
      }
    });
  }, [props]);

  return (
    <React.Fragment>
      {findAccountIdText &&
      bankPaymentOption_text &&
      visitServiceOffice_text ? (
        <React.Fragment>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Typography color="primary" variant="h6" component="h2">
                <b>{findAccountIdText.question}</b>
              </Typography>
              <br />

              <Grid container direction="row" spacing={1}>
                <Grid item xs={isTabletOrMobile ? 6 : 12}>
                  <Typography
                    variant="body1"
                    className={props.baseStyle.quaternaryColor}
                    dangerouslySetInnerHTML={{
                      __html: findAccountIdText.answer,
                    }}
                  />
                </Grid>
                <Grid item xs={isTabletOrMobile ? 6 : 12}>
                  <IconButton aria-label="close" onClick={handleClickOpen}>
                    <img
                      src={
                        connectionId && connectionId.assetPath
                          ? connectionId.assetPath
                          : props.billpay
                          ? ImageBillID
                          : ImageConnectionId
                      }
                      alt={altText}
                      className="rightBack"
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography color="primary" variant="h6" component="h2">
                <b>{bankPaymentOption_text.question}</b>
              </Typography>
              <br />
              <Typography
                variant="body1"
                className={props.baseStyle.quaternaryColor}
                dangerouslySetInnerHTML={{
                  __html: bankPaymentOption_text.answer,
                }}
              />
            </Grid>
            <Grid item>
              <Typography color="primary" variant="h6" component="h2">
                <b>{visitServiceOffice_text.question}</b>
              </Typography>
              <br />
              <Typography
                variant="body1"
                className={props.baseStyle.quaternaryColor}
                dangerouslySetInnerHTML={{
                  __html: visitServiceOffice_text.answer,
                }}
              />
            </Grid>
          </Grid>
          <ImageDialog
            open={openDialog}
            onClose={handleClose}
            className="width100"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
              className="dialogImagePadding"
            ></DialogTitle>
            <MuiDialogContent className="dialogImagePadding">
              <img
                src={
                  connectionId
                    ? connectionId.assetPath
                    : props.billpay
                    ? ImageBillID
                    : ImageConnectionId
                }
                alt={altText}
                className="rightBack"
              />
            </MuiDialogContent>
          </ImageDialog>
        </React.Fragment>
      ) : (
        <Skeleton variant="text" />
      )}
    </React.Fragment>
  );
}

const actionCreators = {
  getAboutImage: appActions.getAboutImage,
};

const connectedAboutAccount = connect(null, actionCreators)(AboutAccount);
export { connectedAboutAccount as AboutAccount };
