import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  useScrollTrigger,
  Toolbar,
  AppBar,
  Slide,
  Grid,
  Button,
  ButtonGroup,
  Divider,
  MenuItem,
  Menu,
} from "@material-ui/core";
import Routes from "../config/routes";
import useStyles from "./layout-style";
import LSButton from "../auth/login-signup-button";
import { routePaths } from "../config/route-paths";
import { commonService, appService } from "../../services/index";
import { store } from "../../helpers/store";
import "../app/base-style.css";
import "./layout-style.css";
import "../auth/auth.css";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import { history } from "../../helpers/index";
import MenuIcon from "@material-ui/icons/Menu";
import LinearProgress from "./linear-loader";
import headerLogo from "../../assests/qc-default-logo.png";
import { connect } from "react-redux";
import { appActions } from "../../actions/index";
import Tooltip from "@material-ui/core/Tooltip";
import { OutageAlert } from "./outage-alert/outage-alert";
import ErrorBoundary from "./error-boundary";

let labels = {};

store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
});

function Layout(props) {
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
  const [progressLoader, setprogressLoader] = useState(null);
  store.subscribe(() => {
    if (store.getState().parametersWidgets.ParameterLookup) {
      setprogressLoader(store.getState().snackbar);
    }
  });

  return (
    <ErrorBoundary>
    <React.Fragment>
      <div>
        <LinearProgress progressLoad={progressLoader} />
      </div>
      <div className={classes.root}>
        <CssBaseline />
        {isTabletOrMobile ? (
          <AppBar position="fixed" className={classes.appBar}>
            <AppBarDisplay label={labels} propsLayout={props} />
          </AppBar>
        ) : (
          <HideOnScroll {...props}>
            <AppBar position="fixed" className={classes.appBar}>
              <AppBarDisplay label={labels} propsLayout={props} />
            </AppBar>
          </HideOnScroll>
        )}
        <Routes />
      </div>
      <OutageAlert />
    </React.Fragment>
    </ErrorBoundary>
  );
}

function AppBarDisplay(props) {
  const classes = useStyles();
  const [appLogo, setAppLogo] = useState(null);
  const [notshowToggle, setshowToggle] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(min-width:891px)" });
  const [quickpayPage, setquick] = useState(false);
  const [customerPage, setcustomer] = useState(false);
  const [newPage, setnsa] = useState(false);
  const [progressLoader, setprogressLoader] = useState(null);
  const [label, setLabel] = useState(null);
  const [fallBackAlt, setfallBackAlt] = useState(null);
  const [tenantLogo, setTenantLogo] = useState(null);
  const [customerRequestLink, setCustomerRequestLinkLink] = useState(null);
  store.subscribe(() => {
    if (store.getState().parametersWidgets.ParameterLookup) {
      setprogressLoader(store.getState().snackbar);
      setTenantLogo(
        store.getState().parametersWidgets.ParameterLookup.TENANT_APP_LOGO
      );
      setCustomerRequestLinkLink(
        store.getState().parametersWidgets.ParameterLookup.CUSTOMER_REQUEST_LINK
      )
    }
    if (store.getState().labels.data) {
      setLabel(store.getState().labels.data.getLabels.HEADER);
    }
  });
  useEffect(() => {
    if (tenantLogo) {
      props.propsLayout.getAppLogo(tenantLogo);
    }
  }, [tenantLogo]);
  store.subscribe(() => {
    if (store.getState().appLogo) {
      setAppLogo(store.getState().appLogo);
      setfallBackAlt(store.getState().appLogo.fallBackLabelKey);
    }
  });

  useEffect(() => {
    if (
      history.location.pathname === routePaths.QUICK_PAY ||
      history.location.pathname.startsWith(routePaths.PAYMENT) ||
      history.location.pathname === routePaths.VIEW_BALANCE ||
      history.location.pathname === routePaths.RETRY ||
      history.location.pathname === routePaths.NSA ||
      history.location.pathname.startsWith(routePaths.BILL_PAY)
    ) {
      setshowToggle(false);
      setquick(true);
      setcustomer(false);
      setnsa(false);
    }
    if (history.location.pathname.startsWith(routePaths.LOGIN)) {
      setshowToggle(true);
      setquick(false);
      setcustomer(true);
      setnsa(false);
    }
    if (history.location.pathname.startsWith(routePaths.NSA)) {
      setshowToggle(false);
      setquick(false);
      setcustomer(false);
      setnsa(true);
    }
  }, [history.location.pathname]);

  const quickPayClick = () => {
    cleaeNSAData();
    handleClose();
    setshowToggle(false);
    setquick(true);
    setcustomer(false);
    setnsa(false);
    history.push(routePaths.QUICK_PAY);
  };

  const customerPortalClick = () => {
    cleaeNSAData();
    handleClose();
    setshowToggle(true);
    setquick(false);
    setcustomer(true);
    setnsa(false);
    history.push(routePaths.LOGIN);
  };

  const nsaClick = () => {
    handleClose();
    setshowToggle(false);
    setnsa(true);
    setquick(false);
    setcustomer(false);
    history.push(routePaths.NSA);
  };

  const cleaeNSAData = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("landline");
    localStorage.removeItem("units");
    localStorage.removeItem("buildingname");
    localStorage.removeItem("bnumber");
    localStorage.removeItem("leadid");
    localStorage.removeItem("idtype");
    localStorage.removeItem("cidtype");
    localStorage.removeItem("selectedpremises");
    localStorage.removeItem("servicedate");
    localStorage.removeItem("checkbox");
    localStorage.removeItem("rmobile");
    localStorage.removeItem("signature");
    localStorage.removeItem("signaturetime");
    localStorage.removeItem("cpqid");
    localStorage.removeItem("adlnotes");
    localStorage.removeItem("cooling");
    localStorage.removeItem("cidtype");
    localStorage.removeItem("propertyType");
    localStorage.removeItem("fileName");
    localStorage.removeItem("fileContent");
    localStorage.removeItem("fileNamep");
    localStorage.removeItem("fileContentp");
    localStorage.removeItem("fileNameq");
    localStorage.removeItem("fileContentq");
    localStorage.removeItem("fileType");
    localStorage.removeItem("fileTypep");
    localStorage.removeItem("fileTypeq");
    localStorage.removeItem("Extention_landline");
    localStorage.removeItem("Title");
    localStorage.removeItem("AllFilesName")
    localStorage.removeItem("AllFilesContent")
    localStorage.removeItem("AllFilesType")
    localStorage.removeItem("dropdownItems")
    localStorage.removeItem("fileDropdownq")
    localStorage.removeItem("fileDropdownp")
    localStorage.removeItem("fileDropdown");
    localStorage.removeItem("countItems")
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hovered, setHovered] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={isTabletOrMobile ? 10 : 0}
        >
          <Grid item>
            <a href={routePaths.LOGIN + "/"}>
              <img
                className="app-logo"
                src={
                  appLogo && appLogo.assetPath ? appLogo.assetPath : headerLogo
                }
                alt={
                  fallBackAlt && label
                    ? label[fallBackAlt]
                    : "Qatar Cool Customer Portal Logo"
                }
              />
            </a>
          </Grid>
          <Grid item>
            {isTabletOrMobile && (
              <>
                <Tooltip
                  title={labels?.QUICK_PAY_TOOL_TIP}
                  placement="bottom-end"
                  arrow
                >
                  <Button
                    style={hovered ? { backgroundColor: "#008996" } : null}
                    className={classes.quickButton}
                    onClick={() => quickPayClick()}
                    disabled={quickpayPage}
                    variant="contained"
                    size="small"
                    onMouseOver={() => {
                      setHovered(true);
                    }}
                    onMouseOut={() => {
                      setHovered(false);
                    }}
                  >
                    <b>{labels.QUICK_PAY_TITLE}</b>
                  </Button>
                </Tooltip>
                <ButtonGroup variant="text" color="primary">
                  <Button
                    className="borderRight"
                    onClick={() => nsaClick()}
                    disabled={newPage}
                  >
                    <b>{labels.NEW_SERVICE_AGREEMENT}</b>
                  </Button>
                  <Button
                    className="borderRight"
                    onClick={() => customerPortalClick()}
                    disabled={customerPage}
                  >
                    <b>{labels.CUSTOMER_PORTAL}</b>
                  </Button>
                  <Button
                    onClick={() => window.open(customerRequestLink, "_blank")}
                    className="borderRight"
                  >
                    <b>{labels.CUSTOMER_REQUEST}</b>
                  </Button>
                </ButtonGroup>
              </>
            )}
          </Grid>
        </Grid>
        {!isTabletOrMobile ? (
          <Grid container justify="flex-end">
            {labels && (
              <Grid item>
                <Tooltip
                  title={labels?.QUICK_PAY_TOOL_TIP}
                  placement="bottom-end"
                  arrow
                >
                  <Button
                    style={
                      hovered
                        ? { marginRight: "1rem", backgroundColor: "#008996" }
                        : { marginRight: "1rem" }
                    }
                    className={classes.quickButton}
                    onClick={() => quickPayClick()}
                    color="teritiary"
                    disabled={quickpayPage}
                    variant="contained"
                    size="small"
                    onMouseOver={() => {
                      setHovered(true);
                    }}
                    onMouseOut={() => {
                      setHovered(false);
                    }}
                  >
                    <b>{labels.QUICK_PAY_TITLE}</b>
                  </Button>
                </Tooltip>
                <MenuIcon onClick={handleClick} color="primary" />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => window.open(customerRequestLink, "_blank")}>
                    <b>{labels.CUSTOMER_REQUEST}</b>
                  </MenuItem>
                  <MenuItem
                    onClick={customerPortalClick}
                    disabled={customerPage}
                  >
                    <b>{labels.CUSTOMER_PORTAL}</b>
                  </MenuItem>
                  <MenuItem onClick={nsaClick} disabled={newPage}>
                    <b>{labels.NEW_SERVICE_AGREEMENT}</b>
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
        ) : null}
        {notshowToggle && isTabletOrMobile ? <LSButton /> : null}
      </Toolbar>
      {notshowToggle && !isTabletOrMobile ? (
        <Toolbar className={classes.smallScreenToolbar}>
          <Divider />
          <LSButton />
        </Toolbar>
      ) : null}
      <div>
        <LinearProgress progressLoad={progressLoader} />
      </div>
    </React.Fragment>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const actionCreators = {
  getAppLogo: appActions.getAppLogo,
};

const connectedLayout = connect(null, actionCreators)(Layout);
export { connectedLayout as Layout };
