import React, { useState } from "react";
import {
  Typography,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { usageService, commonService } from "../../../services/index";
import { store } from "../../../helpers/store";
import { CircularDialog } from "../dialog-style";

export default function CyberSourceTransactionRequest(props) {
  const [res, setRes] = useState(null);
  const [openDialog, setOpen] = useState(false);
  const debounce = (fn, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  const btnCall = () => {
    handleClickOpen();
    let array = [];
    localStorage.setItem("paymentType", props.paymentType);
    let billpay = localStorage.getItem("billpay");
    store.getState().snackbar = true;
    if (billpay === "true") {
      array.push({
        billId: props.connectionId,
        Amount: props.amount,
        accountId: props.landingResponse.accountDetails.accountId,
        userName: props.landingResponse.accountDetails.name,
        connectionId: props.landingResponse.accountDetails.connectionId,
      });
      localStorage.removeItem("Details");
      localStorage.removeItem("customerName");
      localStorage.removeItem("BillpayDetails");
      localStorage.removeItem("connectionId");
      localStorage.setItem("BillpayDetails", JSON.stringify(array));
      localStorage.setItem("email", props.landingResponse.accountDetails.email);
      localStorage.removeItem("billId");
      localStorage.setItem("billId", props.connectionId);
      localStorage.setItem(
        "mobileNo",
        props.landingResponse.accountDetails.phone
      );
      localStorage.setItem("paymentProcess", "Y");
      usageService
        .prePaymentProcessingBill(
          props.connectionId,
          props.amount,
          props.paymentType,
          props.landingResponse.accountDetails.connectionId
        )
        .then((res) => {
          setRes(res);
          localStorage.removeItem("orderId");
          localStorage.setItem("orderId", res.transaction_uuid);
          localStorage.removeItem("paymentProcess");
          res && document.getElementById("cyberSourceTransaction").submit();
        })
        .catch((err) => {
          localStorage.removeItem("paymentProcess");
        });
    } else {
      array.push({
        ConnectionId: props.connectionId,
        Amount: props.amount,
      });
      let check = commonService.encodeString(
        JSON.stringify(props.landingResponse)
      );
      localStorage.removeItem("BillpayDetails");
      localStorage.removeItem("billId");
      localStorage.removeItem("Details");
      localStorage.setItem("Details", JSON.stringify(array));
      localStorage.setItem("email", props.emailId);
      localStorage.removeItem("customerName");
      localStorage.setItem("customerName", props.landingResponse.customerName);
      localStorage.removeItem("connectionId");
      localStorage.setItem("connectionId", check);
      localStorage.setItem("mobileNo", props.mobileNo);

      localStorage.setItem("paymentProcess", "Y");
      usageService
        .prePaymentProcessing(
          props.connectionId,
          props.amount,
          props.paymentType
        )
        .then((res) => {
          setRes(res.data.prePaymentProcessing);
          localStorage.removeItem("paymentProcess");
          localStorage.removeItem("orderId");
          localStorage.setItem(
            "orderId",
            res.data.prePaymentProcessing?.transaction_uuid
          );
          res.data.prePaymentProcessing &&
            document.getElementById("cyberSourceTransaction").submit();
        })
        .catch((err) => {
          localStorage.removeItem("paymentProcess");
        });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <div>
      {res && (
        <form
          method="POST"
          action={res.CyberSourcePaymentRedirectUrl} // redirect url
          name="cyberSourceTransaction"
          id="cyberSourceTransaction"
        >
          <input
            type="hidden"
            name="unsigned_field_names"
            value={res.unsigned_field_names}
          />
          <input type="hidden" name="amount" value={res.amount} />
          {/* <input type="hidden" name="submit" value={res.submit} /> */}
          <input
            type="hidden"
            name="transaction_uuid"
            value={res.transaction_uuid}
          />
          <input
            type="hidden"
            name="signed_field_names"
            value={res.signed_field_names}
          />
          <input type="hidden" name="locale" value={res.locale} />
          <input
            type="hidden"
            name="transaction_type"
            value={res.transaction_type}
          />
          <input
            type="hidden"
            name="reference_number"
            value={res.reference_number}
          />
          <input type="hidden" name="profile_id" value={res.profile_id} />
          <input type="hidden" name="access_key" value={res.access_key} />
          <input
            type="hidden"
            name="override_custom_receipt_page"
            value={res.override_custom_receipt_page}
          />
          <input type="hidden" name="currency" value={res.currency} />
          <input
            type="hidden"
            name="signed_date_time"
            value={res.signed_date_time}
          />
          <input type="hidden" name="signature" value={res.signature} />
        </form>
      )}
      <Button
        variant="contained"
        size="medium"
        color="secondary"
        className={props.checkedA ? props.accentButton : null}
        onClick={debounce(btnCall, 2000)}
        disabled={!props.checkedA}
      >
        {props.label.BALANCE_PAGE_CREDIT_CARD}
      </Button>
      <CircularDialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openDialog}
      >
        <DialogTitle id="simple-dialog-title">
          <Typography color="primary" variant="h5">
            <b>{props.label.LOADING_DIALOG_TITLE}</b>
          </Typography>
        </DialogTitle>
        <DialogContent align="center">
          <div className="margin-top-20">
            <CircularProgress color="primary" size={60} />
          </div>
        </DialogContent>
        <DialogActions>
          <Typography className="padding-10">
            {props.label.LOADING_DIALOG_SUBTITLE}
          </Typography>{" "}
        </DialogActions>
      </CircularDialog>
    </div>
  );
}
