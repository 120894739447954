import { appConstants } from "../constants";

const initialState = {};
export function parametersWidgets(state = initialState, action) {
  switch (action.type) {
    case appConstants.PARAMETER_WIDGET_SUCCESS: {
      return {
        ...state,
        ...action.params
      };
    }
    case appConstants.PARAMETER_WIDGET_FAILURE:
      return state;
    default:
      return state;
  }
}

export function connectionId(state = "", action) {
  switch (action.type) {
    case appConstants.BALANCE_VIEW_SUCCESS: {
      return {
        ...state,
        connectionId: action.connection,
        mobileNo: action.mobileNo,
        emailId:action.emailId
      };
    }
    case appConstants.BALANCE_VIEW_FAILURE:
      return state;
    default:
      return state;
  }
}

const snackbarparam = "";
export function snackbar(state = snackbarparam, action) {
  switch (action.type) {
    case appConstants.SNACKBAR_SUCCESS:
      return {
        ...state,
        ...action
      };
    default:
      return state;
  }
}

const initialStateAppLogo = {};
export function appLogo(state = initialStateAppLogo, action) {
  switch (action.type) {
    case appConstants.APPLOGO_SUCCESS: {
      return {
        ...state,
        ...action.logoResponse
      };
    }
    case appConstants.APPLOGO_FAILURE:
      return state;
    default:
      return state;
  }
}


const initialStateImage = {};
export function aboutImage(state = initialStateImage, action) {
  switch (action.type) {
    case appConstants.ABOUT_IMAGE_SUCCESS: {
      return {
        ...state,
        ...action.imageResponse
      };
    }
    case appConstants.ABOUT_IMAGE_FAILURE:
      return state;
    default:
      return state;
  }
}