import {saveNSAData} from './constants';

const saveNewServiceAgreementDataInitialState = {};
export const newSeriveAgreementData = (
  state = saveNewServiceAgreementDataInitialState,
  action
) => {
  switch (action.type) {
    case saveNSAData.NEW_SERVICE_AGREEMENT_DATA_SAVE_SUCCESS:
        return { ...state, data: action.data };
    default:
        return state;
  }
}