import React, { Component, useRef, useState } from "react";
import { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Typography,
  Button,
  Grid,
  Box,
  Card,
  CardContent,
  Divider,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { CircularProgress } from "@material-ui/core";
import { config } from "../../enviroment";
import axios from "axios";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

function MigrateUser(props) {
  const [selectedFile, setSelectedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const _reCaptchaRef = useRef();
  const siteKey = config.recaptchaKey.siteKey;
  const delay = 500;
  const enableCaptcha = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.ENABLE_CAPTCHA
  );

  const [captcha, setCaptcha] = useState({
    callback: "not fired",
    value: "[empty]",
    load: false,
    expired: false,
  });

  useEffect(() => {
    setTimeout(() => {
      setCaptcha((prevState) => ({
        ...prevState,
        load: true,
      }));
    }, delay);
  }, []);

  useEffect(() => {
    if (selectedFile && captcha.value !== "[empty]" && !captcha.expired) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [selectedFile, captcha.value, captcha.expired]);

  const captchaAsyncScriptOnLoad = () => {
    setCaptcha((prevState) => ({
      ...prevState,
      callback: "called!",
    }));
  };

  const captchaHandleChange = (value) => {
    setCaptcha((prevState) => ({
      ...prevState,
      value: value,
    }));

    if (value === null) {
      setCaptcha((prevState) => ({
        ...prevState,
        expired: true,
      }));
    }
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const captchaHandleExpired = (value) => {
    _reCaptchaRef.current.reset();
    setCaptcha((prevState) => ({
      ...prevState,
      expired: true,
    }));
  };

  const handleSubmission = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("migrateUsers", selectedFile);
    return new Promise((resolve, reject) => {
      axios
        .post(config.MigrateUser.URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            
            processData: false,
          },
          timeout:10000000000000000
        })
        .then((response) => {
          resolve(response.data);
          if (response.data.status === 201) {
            setUploadSuccess(true);
          }
          setIsLoading(false);
          setUploadSuccess(true);
        })
        .catch((error) => {
          setUploadError(true);
          reject(error);
        });
    });
  };

  const handleSnackClose = () =>{
    setSnackOpen(false);
    setUploadError(false);
    setUploadError(false);
  }

  useEffect(() => {
    if(uploadError || uploadSuccess){
      setSnackOpen(true)
    }
  }, [uploadError, uploadSuccess])

  return (
    <Grid container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100%"
        textAlign="center"
      >
        <Box p={4}>
          <Card>
            <CardContent>
              <Box display="flex" flexDirection="column">
                <Typography variant="h5" color="primary" center>
                  Migrate Users
                </Typography>
                <Typography variant="subtitle2">
                  Please select a file and fill the captcha and submit.
                </Typography>
                <Divider color="primary" />
                <Box p={2}>
                  <input
                    type="file"
                    variant="contained"
                    color="tertiary"
                    onChange={changeHandler}
                    endIcon={<AttachFileIcon></AttachFileIcon>}
                    accept=".xls, .csv, .xlsx"
                  />

                  <Box justify="center" alignItems="center" my={1.5}>
                    {enableCaptcha && (
                      <Grid item>
                        <Grid container justify="center" alignItems="center">
                          {captcha.load && (
                            <ReCAPTCHA
                              style={{ display: "inline-block" }}
                              theme="light"
                              ref={_reCaptchaRef}
                              sitekey={siteKey}
                              onChange={captchaHandleChange}
                              asyncScriptOnLoad={captchaAsyncScriptOnLoad}
                              size="normal"
                              onExpired={captchaHandleExpired}
                            />
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmission}
                    disabled={!submitEnabled}
                  >
                    Submit
                    {isLoading && (
                      <CircularProgress size={17} color="inherit" />
                    )}
                  </Button>
                </Box>

                <Snackbar
                  open={snackOpen}
                  autoHideDuration={6000}
                  onClose={handleSnackClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal:'left' }}
                >
                  <MuiAlert onClose={handleSnackClose} severity={uploadSuccess ? "success" : "error"} variant="filled">
                    {uploadSuccess && "File Uploaded Successfully!"}
                    {uploadError && "File Upload Failed"}
                  </MuiAlert>
                </Snackbar>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Grid>
  );
}

// class MigrateUserMain extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isReqLoading: false,
//       file:""
//     };
//   }

//   handleChange(event) {
//     debugger

//   }

//   async submit(event){
//     debugger

//     let formData = new FormData();
//     formData.append("migrateUsers",  event.target[0].files[0]);
//     return new Promise((resolve, reject) => {

//       axios
//         .post(config.MigrateUser.URL,formData,{
//           headers:{
//             "Content-Type": "multipart/form-data",
//             timeout:100000,
//             processData:false,
//           }
//         }

//         )
//         .then((response) => {
//           resolve(response.data);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   }
//   render() {
//     let { isReqLoading } = this.state;
//     return (
//       <React.Fragment>
//         <form>

//                  </form>
//       </React.Fragment>
//     );
//   }
// }

export default MigrateUser;
