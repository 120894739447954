import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { paymentService } from "../service/payment-service";

export default function CyberSourceTransactionRequestNSA(props) {
  const [res, setRes] = useState(null);
  const debounce = (fn, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  const btnCall = () => {
    props.log({
      key: "NSA_PAYMENT",
      message: "Credit Card Clicked",
      stackOrLog: "STACK",
    });
    props.setError(false);
    localStorage.setItem(
      "depositeAmount",
      (Math.round(props.formData.depositeAmount * 100) / 100).toFixed(2)
    );
    localStorage.setItem("uniqueId", props.formData.uniqueID);
    localStorage.setItem(
      "userName",
      props.formData.fusername + " " + props.formData.lusername
    );
    localStorage.setItem("email", props.formData.email);
    localStorage.setItem("mobile", props.formData.mobile);
    localStorage.removeItem("ticketno");
    let tempInput = JSON.parse(JSON.stringify(props?.dataObject));
    if (tempInput?.csaAttachments?.length) {
      tempInput.csaAttachments.forEach((item) => {
        item.fileContent = "HIDDEN";
      });
    }
    props.log({
      key: "NSA_PAYMENT",
      message: `Data (${props.paymentTypeCyberSource}) :`,
      addOnLog: tempInput,
      stackOrLog: "LOG",
    });
    localStorage.setItem("paymentProcess", "Y");
    paymentService
      .prePaymentProcessing(props.paymentType, props.dataObject)
      .then((res) => {
        setRes(res.data.prePaymentProcessing);
        localStorage.removeItem("paymentProcess");
        let dataValue = Object.assign({}, props.formData);
        dataValue.jointOwnerDetails.map((item, index) => {
          item.fileContent = "";
        });
        dataValue.csaAttachment.map((item, index) => {
          item.fileContent = "";
        });
        dataValue.FileContent = "";
        dataValue.FileContentp = "";
        dataValue.FileContentq = "";
        dataValue.FileContentAll = "";
        const Value = JSON.stringify(dataValue);
        localStorage.setItem("Value", Value);
        localStorage.removeItem("orderId");
        localStorage.setItem(
          "orderId",
          res.data.prePaymentProcessing?.transaction_uuid
        );
        res.data.prePaymentProcessing &&
          document.getElementById("cyberSourceTransaction").submit();
      })
      .catch((error) => {
        localStorage.removeItem("paymentProcess");
        props.log({
          key: "NSA_PAYMENT",
          message: "Prepayment API error",
          stackOrLog: "STACK",
        });
        props.setError(true);
        if (error?.response?.data?.errors[0]?.message) {
          props.log({
            key: "NSA_PAYMENT",
            message: error?.response?.data?.errors[0]?.message,
            stackOrLog: "STACK",
          });
          props.setErrorMessage(error?.response?.data?.errors[0]?.message);
        }
        props.log({ key: "NSA_PAYMENT", message: "Abort", stackOrLog: "LOG" });
        props.setOpenLoading(false);
      });
  };
  return (
    <div>
      {res && (
        <form
          method="POST"
          action={res.CyberSourcePaymentRedirectUrl} // redirect url
          name="cyberSourceTransaction"
          id="cyberSourceTransaction"
        >
          <input
            type="hidden"
            name="unsigned_field_names"
            value={res.unsigned_field_names}
          />
          <input type="hidden" name="amount" value={res.amount} />
          {/* <input type="hidden" name="submit" value={res.submit} /> */}
          <input
            type="hidden"
            name="transaction_uuid"
            value={res.transaction_uuid}
          />
          <input
            type="hidden"
            name="signed_field_names"
            value={res.signed_field_names}
          />
          <input type="hidden" name="locale" value={res.locale} />
          <input
            type="hidden"
            name="transaction_type"
            value={res.transaction_type}
          />
          <input
            type="hidden"
            name="reference_number"
            value={res.reference_number}
          />
          <input type="hidden" name="profile_id" value={res.profile_id} />
          <input type="hidden" name="access_key" value={res.access_key} />
          <input
            type="hidden"
            name="override_custom_receipt_page"
            value={res.override_custom_receipt_page}
          />
          <input type="hidden" name="currency" value={res.currency} />
          <input
            type="hidden"
            name="signed_date_time"
            value={res.signed_date_time}
          />
          <input type="hidden" name="signature" value={res.signature} />
        </form>
      )}
      <Button
        variant="contained"
        size="medium"
        color="secondary"
        className={[
          props.baseClasses.accentButton,
          props.classes.buttonsMargin,
        ]}
        onClick={debounce(btnCall, 2000)}
      >
        {props.label.BALANCE_PAGE_CREDIT_CARD}
      </Button>
    </div>
  );
}
