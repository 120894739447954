import { config } from "../../../enviroment";
import axios from "axios";

export async function getOutageStatus() {
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.OUTAGE_STATUS_URL,
      method: "get",
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
