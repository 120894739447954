import React, { useEffect, useState } from "react";
import { customStyles } from "./_new-style";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { adminService } from "../../../../services/admin.service";

export default function Instructions({ dropdownData }) {
  const customStyle = customStyles();
  let lang = useSelector(
    (store) =>
      store?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
  );
  const [title, setTitle] = useState("");
  const [instruction, setInstruction] = useState("");

  useEffect(() => {
    adminService.getContentData(lang, "ICSA_REQUIRED_FILES").then((res) => {
      res.data.getContents.ICSA_REQUIRED_FILES.forEach((item) => {
        if (item.contentCode === "ICSA_REQUIRED_FILES") {
            setTitle(item.question);
            setInstruction(item.answer);
        } 
      });
    });
  }, [lang]);
  return (
    <div className={customStyle.lightblueColorBackground}>
      <Typography color="primary">
        <b>{title}</b>
      </Typography>
      <Typography
      className="margin-top-10"
            dangerouslySetInnerHTML={{
            __html:instruction ,
          }}/>
      {dropdownData.map((item) => {
        return (
          <Typography className={customStyle.accentGreyColor}>
            - {item.dropDownValue}
          </Typography>
        );
      })}
    </div>
  );
}