import React, { useState } from "react";
import { Typography, Grid, Hidden } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { pathActions } from "../../../../actions";
import { routePaths } from "../../../config/route-paths";
import { useDispatch } from "react-redux";

const useConstructor = (callBack = () => {}) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
  }

function NSACommonHeader(props) {
    const dispatch = useDispatch();
    const[open,setOpen] = useState(false);
    const labels = useSelector((state)=>state?.labels?.data?.getLabels?.USER_MANAGEMENT);
    useConstructor(() => {
        dispatch(pathActions.getLocationPath(routePaths.NSA));
      });

      const handleClicked = () => {
        if (open === true) {
          setOpen(false);
        } else {
            setOpen(true);
        }
      }

  return (
    <React.Fragment>
        <Hidden xlUp xsDown>
          <Typography
            color="primary"
            variant="h4"
            component="h6"
            align="center"
            className="margin-top-10"
          >
            <b>
              {props.activeStep < 3
                ? labels?.NEW_SERVICE_AGREEMENT_TITLE
                : props.activeStep === 3
                ? labels?.CONFIRM_SERVICE_AGREEMENT_TITLE
                : props.activeStep === 4
                ? labels?.SUCCESS_SERVICE_AGREEMENT_TITLE
                : null}
            </b>
          </Typography>
        </Hidden>
        <Hidden smUp>
          <Grid
            container
            direction="row"
            justify="flex-start"
            spacing={2}
            className="margin-top-10"
          >
            <Grid item>
              <Typography
                color="primary"
                variant="h4"
                component="h6"
              >
                <b>
                  {" "}
                  {props.activeStep < 3
                    ? labels?.NEW_SERVICE_AGREEMENT_TITLE
                    : props.activeStep === 3
                    ? labels?.CONFIRM_SERVICE_AGREEMENT_TITLE
                    : props.activeStep === 4
                    ? labels?.SUCCESS_SERVICE_AGREEMENT_TITLE
                    : null}
                </b>
                &nbsp;
                <Link onClick={handleClicked}>
                  {open ? (
                    <span
                      className={`dividerCenterCss ${props.baseStyle.linkColor}`}
                    >
                      {labels?.QUICK_PAY_HIDE} <ExpandLess />
                    </span>
                  ) : (
                    <span
                      className={`dividerCenterCss ${props.baseStyle.linkColor}`}
                    >
                      {labels?.QUICK_PAY_DETAILS} <ExpandMore />
                    </span>
                  )}
                </Link>
              </Typography>{" "}
            </Grid>
          </Grid>{" "}
        </Hidden>
        {open && (
          <React.Fragment>
            <hr style={{ borderRadius: 1, borderWidth: 2 }} />
            <div
              className={props.baseStyle.quaternaryColor}
              dangerouslySetInnerHTML={{
                __html: props.labelSubTitle,
              }}
            ></div>
          </React.Fragment>
        )}
        <Hidden smUp>
          <hr style={{ borderRadius: 1, borderWidth: 2 }} />
        </Hidden>
        <Hidden xlUp xsDown>
          <br />
          {(props.activeStep !== 4) && props.labelSubTitle && (
            <div
              className={props.baseStyle.quaternaryColor}
              dangerouslySetInnerHTML={{
                __html: props.labelSubTitle,
              }}
            ></div>
          )}
          <hr color="#DEEDF2" style={{ borderRadius: 1, borderWidth: 3 }} />
        </Hidden>
      </React.Fragment>
  )
}

export default NSACommonHeader;