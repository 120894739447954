import { languageConstants } from "../constants/language.constants";
import { appService } from "../services/index";

export const languageActions = {
  downloadLabels
};

function downloadLabels() {
  return dispatch => {
    dispatch(request());
    appService
      .downloadLabels()
      .then(labels => {
        let errorPhoneNumber= labels.data.getLabels.CONTACTS.CONTACTS_CUSTOMER_SERVICE_TELEPHONE_NUMBER_VALUE
        let errorEmail=labels.data.getLabels.CONTACTS.CONTACTS_CUSTOMER_SERVICE_EMAIL_VALUE
        labels.data.getLabels.SERVER_EXCEPTIONS.SERVER_EXCEPTIONS_NOT_RIGHT=labels.data.getLabels.SERVER_EXCEPTIONS.SERVER_EXCEPTIONS_NOT_RIGHT.replace('&&ERROR_PHONE_NUMBER',errorPhoneNumber)
        labels.data.getLabels.SERVER_EXCEPTIONS.SERVER_EXCEPTIONS_NOT_RIGHT=labels.data.getLabels.SERVER_EXCEPTIONS.SERVER_EXCEPTIONS_NOT_RIGHT.replace('&&ERROR_EMAIL',errorEmail)
        dispatch(success(labels));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request(lan) {
    return { type: languageConstants.LABELS_REQUEST, lan };
  }
  function success(labels) {
    return { type: languageConstants.LABELS_SUCCESS, labels };
  }
  function failure(error) {
    return { type: languageConstants.LABELS_FAILURE, error };
  }
}