import React, { Component, useForm, useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ApplicantDetails from "./view/_applicant-details";
import { Grid, Tooltip } from "@material-ui/core";
import { store } from "../../../helpers/store";
import PropertyDetails from "./view/_property-details";
import { customStyles } from "./view/_new-style";
import UploadDocuments from "./view/_upload-file";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useStyles } from "../../app/base-style";
import NSACommonHeader from "./view/_new-service-header";
import { Skeleton } from "@material-ui/lab";
import {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { useSelector } from "react-redux";
import { adminService } from "../../../services/admin.service";
import { history } from "../../../helpers/history";
import { useDispatch } from "react-redux";
import {saveNewServiceAgreementData} from "./model/actions"
import { useLocation } from "react-router-dom";
import { routePaths } from "../../config/route-paths";
import { config } from "../../../enviroment";

const useStylesCustom = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: 1, 
    borderWidth: 3
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepLabel:{
    border: 10, 
    fontSize: "2rem"
  }
}));

export function HorizontalLabelPositionBelowStepper(props) {
  const dispatch = useDispatch();
  const labels = useSelector((state)=>state?.labels?.data?.getLabels?.APPLICANT_DETAILS);
  const labelsUser = useSelector((state)=>state?.labels?.data?.getLabels?.USER_MANAGEMENT);
  const labels_SA = useSelector((state)=>state?.labels?.data?.getLabels?.SERVICE_AGREEMENT);
  const country_Calling_Code = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.COUNTRY_DIALING_CODE);
  const country_Code = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.COUNTRY_CODE);

  const classes = useStylesCustom();
  const classesBase = useStyles();
  const [csaAttachment, setcsaAttachments] = useState([]);
  const [attachmentName, setAttachmentName] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [title, setTitle] = useState("MR");
  const [fusername, setfUser] = useState("");
  const [fusernameRequire, setfUsernameRequire] = useState(false);
  const [fusernameValid, setfUsernameValid] = useState(false);
  const [username, setUser] = useState("");
  const [cusername, setcUser] = useState("");
  const [cusernameRequire, setcUsernameRequire] = useState(false);
  const [cusernameValid, setcUsernameValid] = useState(false);

  const [lusername, setlUser] = useState("");
  const [lusernameRequire, setlUsernameRequire] = useState(false);
  const [lusernameValid, setlUsernameValid] = useState(false);

  const [rusername, setrUser] = useState("");
  const [rusernameRequire, setrUsernameRequire] = useState(false);
  const [rusernameValid, setrUsernameValid] = useState(false);

  const [emailRequire, setemailRequire] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [email, setEmail] = useState("");

  const [remailRequire, setremailRequire] = useState(false);
  const [remailError, setremailError] = useState(false);
  const [remail, setrEmail] = useState("");

  const [mobile, setMobile] = useState("");
  const [withoutExtmobile, setwithoutExtmobile] = useState("");

  const [mobileRequire, setmobileRequire] = useState(false);
  const [mobileError, setmobileError] = useState(false);

  const [countryCode, setCountryCode] = useState(country_Calling_Code);
  const [countryCodeName, setCountryCodeName] = useState(country_Code);
  const [lcountryCode, setlCountryCode] = useState(country_Calling_Code);

  const [rmobile, setrMobile] = useState("");
  const [rwithoutExtmobile, setrwithoutExtmobile] = useState("");
  const [rcountryCodeName, setrCountryCodeName] = useState(country_Code);

  const [rmobileRequire, setrmobileRequire] = useState(false);
  const [rmobileError, setrmobileError] = useState(false);
  const [rcountryCode, setrCountryCode] = useState(country_Calling_Code);

  const [leadid, setLeadid] = useState("");
  const [depositeAmount, setDepositeAmount] = useState("");
  const [selectedPremise, setSelectedPremise] = useState("")
  const [propertyType, setPropertyType] = useState("")
  const [Cooling, setCooling] = useState()
  const [adlnotes, setadlnotes] = useState("")

  const [unit, setUnit] = useState("");
  const [unitRequire, setunitRequire] = useState(false);
  const [unitError, setunitError] = useState(false);

  const [bname, setBname] = useState("");
  const [buildingNameRequire, setbuildingNameRequire] = useState(false);
  const [buildError, setbuildError] = useState(false);

  const [bnum, setBnum] = useState("");
  const [buildingNumberRequire, setbuildingNumberRequire] = useState(
    false
  );
  const [buildNumberError, setbuildNumberError] = useState(false);

  const [address, setAddress] = useState("");
  const [addressRequire, setaddressRequire] = useState(false);

  const [district, setDistrict] = useState("");
  const [districtRequire, setdistrictRequire] = useState(false);

  const [pobox, setPoBox] = useState("");

  const [landline, setLandline] = useState("");
  const [extension_landline, setExtension_landline] = useState("");
  const [uniqueID, setuniqueID] = useState("");
  const [uniqueIdRequire, setuniqueIdRequire] = useState(false);
  const [puniqueIdRequire, setpuniqueIdRequire] = useState(false);
  const [uniqueIdError, setuniqueIdError] = useState(false);
  const [puniqueIdError, setpuniqueIdError] = useState(false);
  const [passportLength, setpassportLength] = useState("");
  const [companyRequire, setcompanyRequire] = useState(false);
  const [comapnyError, setcomapnyError] = useState(false);
  const [premiseRequire, setPremiseRequire] = useState(false);
  const [file1Require, setfile1Require] = useState(false);
  const [file2Require, setfile2Require] = useState(false);
  const [file3Require, setfile3Require] = useState(false);

  const [jointOwnerDetails, setJointOwnerDetails] = useState([]);
  const [verifyVisibility, setVerifyVisibility] = useState(true);
  const [idType, setidType] = useState("QATAR_ID");
  const [cidType, setcidType] = useState("individual");

  const [FileContent, setFileContent] = useState("");
  const [FileContentp, setFileContentp] = useState("");
  const [FileContentq, setFileContentq] = useState("");
  const [FileContentAll, setFileContentAll] = useState("");

  const [checkbox, setcheckbox] = useState(false)

  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [mobileVeficationError, setMobileVeficationError] = useState(false);
  
  const [titleDropdown, settitleDropdown] = useState([])
  const log = (logObj) => {
    adminService.log(logObj).then((res) => {
    });
  }
  useEffect(() => {
    adminService.getDropdownpData("ICSA_TITLE").then((res) => {
            settitleDropdown(res.data.data.getDropdownData);
      });
  localStorage.removeItem("depositeAmount");
}, [])

  const [jointOwnDetailsErrorArray, setJointOwnDetailsErrorArray] = useState([])
  function saveJointOwnerData(index, jointOwnerObject){
    let jointOwnerDetailsLocal = jointOwnerDetails;
    Object.assign(jointOwnerDetailsLocal[index], jointOwnerObject)
    setJointOwnerDetails([...jointOwnerDetailsLocal]);
    let jointOwnerErrorsLocal=[];
    if(jointOwnerDetails.length > 0){  
      jointOwnerDetails.forEach((item,index) => {
        if(!item.fusername || item.fusername == "" 
            || !item.lusername || item.lusername == ""
            || !item.email || item.email == "" 
            || !item.mobile || item.mobile == "" 
            || !item.uniqueID || item.uniqueID == "")
            {  
          jointOwnerErrorsLocal.push(index);
        }
      })
      setJointOwnDetailsErrorArray(jointOwnerErrorsLocal);
    }
  }

  function deleteJointOwnerData(index){
    let jointOwnerDetailsLocal = jointOwnerDetails;
    jointOwnerDetailsLocal.splice(index, 1);
    setJointOwnerDetails([...jointOwnerDetailsLocal]);

    let jointOwnerErrorsLocal=[];
    if(jointOwnerDetails.length > 0){  
      jointOwnerDetails.forEach((item,index) => {
        if(!item.fusername || item.fusername == "" 
            || !item.lusername || item.lusername == ""
            || !item.email || item.email == "" 
            || !item.mobile || item.mobile == "" 
            || !item.uniqueID || item.uniqueID == "")
            {  
          jointOwnerErrorsLocal.push(index);
        }
      })
      setJointOwnDetailsErrorArray(jointOwnerErrorsLocal);
    }
  }

  function addNewJoinOwner() {
    let jointOwnerDetailsLocal = jointOwnerDetails;
    jointOwnerDetailsLocal.push({});
    setJointOwnerDetails([...jointOwnerDetailsLocal]);
  }
  
  function getSteps() {
    return [
      labels?.APPLICANT_DETAILS,
      labels_SA?.SA_PROPERTY,
      labels_SA?.SA_UPLOAD,
      labels_SA?.SA_CONFIRM,
      labels_SA?.SA_SUCCESS,
    ];
  }
  
  const steps = getSteps();
  let clearField = () => {
    setuniqueID("");
  };

  let handleOnChangePhone = (e) => {
    if (e) {
      setMobile(e);
      setmobileRequire(false);
      let phoneNumber = parsePhoneNumber(e);
      if (phoneNumber) {
        setmobileRequire(false)
        if (isValidPhoneNumber(e) === true) {
          setmobileError(false);
          setCountryCode(phoneNumber.countryCallingCode);
          setCountryCodeName(phoneNumber.country);
          setMobile(phoneNumber.number);
          setwithoutExtmobile(phoneNumber.nationalNumber);
        } else {
          setmobileError(true);
        }
      }else{
        setmobileRequire(true)
      }
    } else {
      setmobileRequire(true);
    }
  };

  let handleOnChangeRepPhone = (value) => {
    if (value) {
      setrmobileRequire(false);
      if (isValidPhoneNumber(value) === true) {
        setrmobileError(false);
        let phoneNumber = parsePhoneNumber(value);
        if (phoneNumber) {
          setrCountryCode(phoneNumber.countryCallingCode);
          setrMobile(phoneNumber.number);
          setrwithoutExtmobile(phoneNumber.nationalNumber);
          setrCountryCodeName(phoneNumber.country);
        } else {
          setrmobileError(true);
        }
      }
    } else {
      setrmobileRequire(true);
    }
  };
  let handleOnChangeLandPhone = (value) => {
    setlCountryCode(value);
  };
  let handleTitleChange = (event) => {
    let { name, value } = event.target;
    setTitle(value);
  };
  let companyBlur = () => {
    if (store.getState().parametersWidgets.ParameterLookup) {
      if (uniqueID) {
        setcompanyRequire(false);

        if (uniqueID.length > 6) {
          setcomapnyError(false);
        } else {
          setcomapnyError(true);
        }
      } else {
        setcompanyRequire(true);
      }
    }
  };
  let passportMinimumLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.PASSPORT_MINIMUM_LENGTH);
  let uniqueIdBlur = () => {
    let uniqueValue = idType;
    if (uniqueID) {
      setuniqueIdRequire(false);
      if (store.getState().parametersWidgets.ParameterLookup) {
        if (uniqueValue === "PASSPORT_NO") {
          if (uniqueID.length > 0) {
            if (uniqueID.length < Number(passportMinimumLength)) {
              setpuniqueIdError(true);
              localStorage.removeItem("NSA_LOG_KEY");
            } else {
              localStorage.setItem("NSA_LOG_KEY", uniqueID)
              setpuniqueIdError(false);
            }
          } else {
            localStorage.removeItem("NSA_LOG_KEY");
            setuniqueIdRequire(true);
          }
        } else if (uniqueValue === "QATAR_ID") {
          var QATAR_ID_LENGTH = store.getState().parametersWidgets
            .ParameterLookup.QATAR_ID_LENGTH;
          if (uniqueID.length !== Number(QATAR_ID_LENGTH) || isNaN(uniqueID)) {
            setuniqueIdError(true);
            localStorage.removeItem("NSA_LOG_KEY");
          } else {
            localStorage.setItem("NSA_LOG_KEY", uniqueID)
            setuniqueIdError(false);
          }
        }
      }
    } else {
      setuniqueIdRequire(true);
    }
  };

  let MOBILE_REGEX = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.MOBILE_REGEX);
      MOBILE_REGEX=MOBILE_REGEX.replace(
        "\\\\",
        "\\"
      );
  const handleChange = (event) => {
    let uniqueValue = idType;
    let cuniqueValue = cidType;
    const { name, value } = event.target;
    if (
      name === "registeredCell" ||
      name === "landline" ||
      name === "rregisteredCell"
    ) {
      const re=RegExp(MOBILE_REGEX)
      if (event.target.value === "" || re.test(event.target.value)) {
        if (name === "registeredCell") {
          setMobile(value);
        } else if (name === "rregisteredCell") {
          setrMobile(value);
        }
      }
    } else if (name === "fusername") {
      setfUser(value);
    } else if (name === "lusername") {
      setlUser(value);
    } else if (name === "musername") {
      setUser(value);
    } else if (name === "cusername") {
      setcUser(value);
    } else if (name === "registeredEmail") {
      setEmail(value);
    } else if (name === "rusername") {
      setrUser(value);
    } else if (name === "rregisteredEmail") {
      setrEmail(value);
    } else if (uniqueValue === "QATAR_ID" && name === "uniqueId") {
      setuniqueID(value);
    } else if (uniqueValue === "PASSPORT_NO" && name === "uniqueId") {
      setuniqueID(value);
    } else if (cuniqueValue === "company" && name === "company_name") {
      setuniqueID(value);
    }
  };
  const fusernameBlur = () => {
    if (fusername) {
      setfUsernameRequire(false);
      let nameMinLength = store.getState().parametersWidgets.ParameterLookup
        .NAME_MIN_LENGTH;
      if (fusername.length >= Number(nameMinLength)) {
        setfUsernameValid(false);
      } else {
        setfUsernameValid(true);
      }
    } else {
      setfUsernameRequire(true);
    }
  };
  const cusernameBlur = () => {
    if (cusername) {
      setcUsernameRequire(false);
      let nameMinLength = store.getState().parametersWidgets.ParameterLookup
        .NAME_MIN_LENGTH;
      if (cusername.length >= Number(nameMinLength)) {
        setcUsernameValid(false);
      } else {
        setcUsernameValid(true);
      }
    } else {
      setcUsernameRequire(true);
    }
  };
  const lusernameBlur = () => {
    if (lusername) {
      setlUsernameRequire(false);
      let nameMinLength = store.getState().parametersWidgets.ParameterLookup
        .NAME_MIN_LENGTH;
      if (lusername.length >= Number(nameMinLength)) {
        setlUsernameValid(false);
      } else {
        setlUsernameValid(true);
      }
    } else {
      setlUsernameRequire(true);
    }
  };

  let rusernameBlur = () => {
    if (rusername) {
      setrUsernameRequire(false);
      let nameMinLength = store.getState().parametersWidgets.ParameterLookup
        .NAME_MIN_LENGTH;
      if (rusername.length >= Number(nameMinLength)) {
        setrUsernameValid(false);
      } else {
        setrUsernameValid(true);
      }
    } else {
      setrUsernameRequire(true);
    }
  };
  const emailBlur = () => {
    if (store.getState().parametersWidgets.ParameterLookup) {
      if (email) {
        let emails = email.toLowerCase();

        setemailRequire(false);
        var emailData = store.getState().parametersWidgets.ParameterLookup
          .EMAIL_REGEX;
        var emailRegEx = new RegExp(emailData);
        var test = emailRegEx.test(emails);
        if (test) {
          setemailError(false);
        } else {
          setemailError(true);
        }
      } else {
        setemailRequire(true);
        setemailError(false);
      }
    }
  };
  let mobileBlur = (e) => {};

  const unitHandleChange = (event) => {
    const { name, value } = event.target;
    if (name == "unit") {
      setUnit(value);
    }
  };
  let poBoxHandleChange = (e) => {
    const { name, value } = e.target;
    setPoBox(value);
  };

  let buildNumberHandleChange = (e) => {
    const { name, value } = e.target;
    setBnum(value);
  };
  let buildingNumberBlur = () => {
    if (bnum) {
      setbuildingNumberRequire(false);
    } else {
      setbuildingNumberRequire(true);
    }
  };

  let unitBlur = () => {
    if (unit) {
      setunitRequire(false);
      setunitError(false);
    } else {
      setunitRequire(true);
      setunitError(true);
    }
  };

  let buildingNameHandleChange = (e) => {
    const { name, value } = e.target;
    setBname(value);
  };

  let buildingNameBlur = () => {
    if (bname) {
      setbuildingNameRequire(false);
    } else {
      setbuildingNameRequire(true);
    }
  };

  let addressHandleChange = (e) => {
    const { name, value } = e.target;
    setAddress(value);
  };

  let addressBlur = (e) => {
    if (address) {
      setaddressRequire(false);
    } else {
      setaddressRequire(true);
    }
  };
  let districtHandleChange = (e) => {
    const { name, value } = e.target;
    setDistrict(value);
  };

  let districtBlur = (e) => {
    if (district) {
      setdistrictRequire(false);
    } else {
      setdistrictRequire(true);
    }
  };
  let applicationChange = (e) => {
    setActiveStep(0);
  };
  let propertyChange = (e) => {
    setActiveStep(1);
  };

  let additionalChange = (e) => {
    setActiveStep(2);
  };

  let cancelClick = (e) => {
    let alertOk = window.confirm(labels_SA?.SA_ALERT);
    if (alertOk == true) {
      localStorage.removeItem("fileName");
      localStorage.removeItem("fileContent");
      localStorage.removeItem("fileNamep");
      localStorage.removeItem("fileContentp");
      localStorage.removeItem("fileNameq");
      localStorage.removeItem("fileContentq");
      localStorage.removeItem("fileType");
      localStorage.removeItem("fileTypep");
      localStorage.removeItem("fileTypeq");
      localStorage.removeItem("signature");
      localStorage.removeItem("signaturetime");
      localStorage.removeItem("cpqid");
      localStorage.removeItem("AllFilesName")
      localStorage.removeItem("AllFilesContent")
      localStorage.removeItem("AllFilesType")
      localStorage.removeItem("dropdownItems")
      localStorage.removeItem("fileDropdownq")
      localStorage.removeItem("fileDropdownp")
      localStorage.removeItem("fileDropdown")
      localStorage.removeItem("countItems")

      setTitle("MR");
      setfUser("");
      setUser("");
      setcUser("");
      setrUser("");
      setEmail("");
      setrEmail("");
      setrMobile("");
      setMobile("");
      setUnit("");
      setBname("");
      setBnum("");
      setAddress("");
      setDistrict("");
      setPoBox("");
      setuniqueID("");
      setidType("QATAR_ID");
      setcidType("individual")
      setcheckbox(false)
      setLeadid("");
      setDepositeAmount("");
      setSelectedPremise("")
      setPropertyType("")
      setCooling()
      setadlnotes("")
      setrCountryCodeName("QA");
      setCountryCodeName("QA");
      setLandline("");
      setExtension_landline("");
      setActiveStep((prevActiveStep) => prevActiveStep - 3);

      setJointOwnerDetails([]);
      setVerifyVisibility(true);
    }
  };

  let remailBlur = (event) => {
    if (store.getState().parametersWidgets.ParameterLookup) {
      if (remail) {
        let remails = remail.toLowerCase();
        setremailRequire(false);

        var remailData = store.getState().parametersWidgets.ParameterLookup
          .EMAIL_REGEX;
        var remailRegEx = new RegExp(remailData);
        var test = remailRegEx.test(remails);
        if (test) {
          setremailError(false);
        } else {
          setremailError(true);
        }
      } else {
        setremailRequire(true);
      }
    }
  };
  const getStepContent = (
    stepIndex,
    title,
    countryCode,
    rcountryCode,
    lcountryCode,
    username,
    fusername,
    fusernameRequire,
    fusernameValid,
    cusername,
    cusernameRequire,
    cusernameValid,
    lusername,
    lusernameRequire,
    lusernameValid,
    email,
    emailRequire,
    emailError,
    mobile,
    mobileRequire,
    mobileError,
    unit,
    unitRequire,
    unitError,
    bname,
    buildError,
    buildingNameRequire,
    bnum,
    buildingNumberRequire,
    buildNumberError,
    address,
    addressRequire,
    district,
    districtRequire,
    pobox,
    rusername,
    rusernameRequire,
    rusernameValid,
    remail,
    remailError,
    remailRequire,
    rmobile,
    rmobileError,
    rmobileRequire,
    uniqueID,
    uniqueIdRequire,
    uniqueIdError,
    puniqueIdError,
    puniqueIdRequire,
    comapnyError,
    companyRequire,
    classesBase,
    premiseRequire
  ) => {
    const customStyle = customStyles();

    switch (stepIndex) {
      case 0:
        return (
          <ApplicantDetails
            handleTitleChange={handleTitleChange}
            title={title}
            handleOnChangeRepPhone={handleOnChangeRepPhone}
            rcountryCodeName={rcountryCodeName}
            handleOnChangePhone={handleOnChangePhone}
            handleOnChangeLandPhone={handleOnChangeLandPhone}
            countryCode={countryCode}
            rcountryCode={rcountryCode}
            lcountryCode={lcountryCode}
            username={username}
            handleChange={handleChange}
            fusernameBlur={fusernameBlur}
            fusername={fusername}
            fusernameRequire={fusernameRequire}
            fusernameValid={fusernameValid}
            cusernameBlur={cusernameBlur}
            cusername={cusername}
            cusernameRequire={cusernameRequire}
            cusernameValid={cusernameValid}
            lusernameBlur={lusernameBlur}
            lusername={lusername}
            lusernameRequire={lusernameRequire}
            lusernameValid={lusernameValid}
            emailBlur={emailBlur}
            email={email}
            emailRequire={emailRequire}
            emailError={emailError}
            mobileBlur={mobileBlur}
            mobile={mobile}
            mobileRequire={mobileRequire}
            mobileError={mobileError}
            rusernameBlur={rusernameBlur}
            rusername={rusername}
            rusernameRequire={rusernameRequire}
            rusernameValid={rusernameValid}
            remail={remail}
            remailRequire={remailRequire}
            remailError={remailError}
            remailBlur={remailBlur}
            rmobile={rmobile}
            rmobileError={rmobileError}
            rmobileRequire={rmobileRequire}
            uniqueID={uniqueID}
            uniqueIdBlur={uniqueIdBlur}
            uniqueIdRequire={uniqueIdRequire}
            uniqueIdError={uniqueIdError}
            puniqueIdRequire={puniqueIdRequire}
            puniqueIdError={puniqueIdError}
            comapnyError={comapnyError}
            companyRequire={companyRequire}
            companyBlur={companyBlur}
            classesBase={classesBase}
            clearField={clearField}
            withoutExtmobile={withoutExtmobile}
            rwithoutExtmobile={rwithoutExtmobile}
            handleVerify={handleVerify}
            jointOwnerDetails={jointOwnerDetails}
            setJointOwnerDetails={setJointOwnerDetails}
            saveJointOwnerData={saveJointOwnerData}
            addNewJoinOwner={addNewJoinOwner}
            deleteJointOwnerData={deleteJointOwnerData}
            jointOwnDetailsErrorArray={jointOwnDetailsErrorArray}
            verifyVisibility={verifyVisibility}
            setVerifyVisibility={setVerifyVisibility}
            titleDropdown={titleDropdown}
            countryCodeName={countryCodeName}
            cidType={cidType}
            setcidType={setcidType}
            idType={idType}
            setidType={setidType}
            landline={landline} 
            setLandline={setLandline}
            extension_landline={extension_landline} 
            setExtension_landline={setExtension_landline}
            checkbox={checkbox}
            setcheckbox={setcheckbox}
            setIsMobileVerified={setIsMobileVerified}
            mobileVeficationError={mobileVeficationError}
          />
        );
      case 1:
        return (
          <PropertyDetails
            customStyle={customStyle}
            unitHandleChange={unitHandleChange}
            unitBlur={unitBlur}
            unit={unit}
            unitError={unitError}
            unitRequire={unitRequire}
            buildingNameHandleChange={buildingNameHandleChange}
            buildingNameBlur={buildingNameBlur}
            bname={bname}
            buildingNameRequire={buildingNameRequire}
            buildError={buildError}
            bnum={bnum}
            buildNumberHandleChange={buildNumberHandleChange}
            buildingNumberBlur={buildingNumberBlur}
            buildNumberError={buildNumberError}
            buildingNumberRequire={buildingNumberRequire}
            address={address}
            addressBlur={addressBlur}
            addressHandleChange={addressHandleChange}
            addressRequire={addressRequire}
            district={district}
            districtRequire={districtRequire}
            districtBlur={districtBlur}
            districtHandleChange={districtHandleChange}
            pobox={pobox}
            poBoxHandleChange={poBoxHandleChange}
            classesBase={classesBase}
            premiseRequire={premiseRequire}
            setLeadid={setLeadid}
            setDepositeAmount={setDepositeAmount}
            selectedPremise={selectedPremise}
            setSelectedPremise={setSelectedPremise}
            propertyType={propertyType}
            setPropertyType={setPropertyType}
            Cooling={Cooling}
            setCooling={setCooling}
            adlnotes={adlnotes}
            setadlnotes={setadlnotes}
          />
        );
      case 2:
        return (
          <UploadDocuments
            customStyle={customStyle}
            file1Require={file1Require}
            file2Require={file2Require}
            file3Require={file3Require}
            fusername={fusername}
            lusername={lusername}
            username={username}
            cusername={cusername}
            jointOwnerDetails={jointOwnerDetails}
            setJointOwnerDetails={setJointOwnerDetails}
            jointOwnerDocErrorIndex={jointOwnerDocErrorIndex}
            setJointOwnerDocErrorIndex={setJointOwnerDocErrorIndex}
            cidType={cidType}
            idType={idType}
            checkbox={checkbox}
            FileContent={FileContent}
            FileContentp={FileContentp}
            FileContentq={FileContentq}
            FileContentAll={FileContentAll}
            setFileContent={setFileContent}
            setFileContentp={setFileContentp}
            setFileContentq={setFileContentq}
            setFileContentAll={setFileContentAll}
            depositeAmount={depositeAmount}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };

const location = useLocation();
const newSeriveAgreementData = useSelector(state => state?.newSeriveAgreementData?.data);
useEffect(() => {
  if(location?.state?.stepindex && (typeof(newSeriveAgreementData) !== 'undefined' && newSeriveAgreementData != null) ){
    setActiveStep(location.state.stepindex);
  }
}, [location]);

useEffect(() => {
  if(newSeriveAgreementData && Object.keys(newSeriveAgreementData).length !== 0 && newSeriveAgreementData != {} && newSeriveAgreementData !== 'undefined'){
    setTitle(newSeriveAgreementData.title)
    setCountryCode(newSeriveAgreementData.countryCode)
    setrCountryCode(newSeriveAgreementData.rcountryCode)
    setlCountryCode(newSeriveAgreementData.lcountryCode)
    setfUser(newSeriveAgreementData.fusername)
    setlUser(newSeriveAgreementData.lusername)
    setUser(newSeriveAgreementData.username)
    setcUser(newSeriveAgreementData.cusername)
    setEmail(newSeriveAgreementData.email)
    setMobile(newSeriveAgreementData.mobile)
    setrUser(newSeriveAgreementData.rusername)
    setrEmail(newSeriveAgreementData.remail)
    setrMobile(newSeriveAgreementData.rmobile)
    setuniqueID(newSeriveAgreementData.uniqueID)
    setUnit(newSeriveAgreementData.unit)
    setBname(newSeriveAgreementData.bname)
    setBnum(newSeriveAgreementData.bnum)
    setAddress(newSeriveAgreementData.address)
    setPoBox(newSeriveAgreementData.pobox)
    setDistrict(newSeriveAgreementData.district)
    setcsaAttachments(newSeriveAgreementData.csaAttachment)
    setAttachmentName(newSeriveAgreementData.attachmentName)
    setwithoutExtmobile(newSeriveAgreementData.withoutExtmobile)
    setrwithoutExtmobile(newSeriveAgreementData.rwithoutExtmobile)
    setJointOwnerDetails(newSeriveAgreementData.jointOwnerDetails)
    setcidType(newSeriveAgreementData.cidType)
    setidType(newSeriveAgreementData.idType)
    setLandline(newSeriveAgreementData.landline) 
    setExtension_landline(newSeriveAgreementData.extension_landline)
    setcheckbox(newSeriveAgreementData.checkbox)
    settitleDropdown(newSeriveAgreementData.titleDropdown)
    setDepositeAmount(newSeriveAgreementData.depositeAmount)
    setLeadid(newSeriveAgreementData.leadid)
    setSelectedPremise(newSeriveAgreementData.selectedPremise)
    setPropertyType(newSeriveAgreementData.propertyType)
    setCooling(newSeriveAgreementData.Cooling)
    setadlnotes(newSeriveAgreementData.adlnotes)
    setVerifyVisibility(newSeriveAgreementData.verifyVisibility)
    setIsMobileVerified(newSeriveAgreementData.isMobileVerified)
    setFileContent(newSeriveAgreementData.FileContent)
    setFileContentp(newSeriveAgreementData.FileContentp)
    setFileContentq(newSeriveAgreementData.FileContentq)
    setFileContentAll(newSeriveAgreementData.FileContentAll)
  }
  else{
    localStorage.removeItem("fileName");
    localStorage.removeItem("fileContent");
    localStorage.removeItem("fileNamep");
    localStorage.removeItem("fileContentp");
    localStorage.removeItem("fileNameq");
    localStorage.removeItem("fileContentq");
    localStorage.removeItem("fileType");
    localStorage.removeItem("fileTypep");
    localStorage.removeItem("fileTypeq");
    localStorage.removeItem("signature");
    localStorage.removeItem("signaturetime");
    localStorage.removeItem("cpqid");
    localStorage.removeItem("AllFilesName")
    localStorage.removeItem("AllFilesContent")
    localStorage.removeItem("AllFilesType")
    localStorage.removeItem("dropdownItems")
    localStorage.removeItem("fileDropdownq")
    localStorage.removeItem("fileDropdownp")
    localStorage.removeItem("fileDropdown")
    localStorage.removeItem("countItems")
    localStorage.removeItem("Value")
  }
}, [])

  function handleVerify() {
    let idtype = idType;
    let cidtype = cidType;
    let idError = false;
    if (cidtype === "individual") {
      if (idtype === "QATAR_ID") {
        idError = uniqueIdError;
      } else {
        idError = puniqueIdError;
      }
    }
    if (
      ((fusername == "" ||
        fusernameValid ||
        lusername == "" ||
        lusernameValid)) ||
      email == "" ||
      uniqueID == "" ||
      idError ||
      emailError ||
      mobile == "" ||
      mobileError
    ) {
      if (fusername === "") {
        log({ key: "NSA_APPLICANT", message: "First Name is required", stackOrLog: "STACK" })
        setfUsernameRequire(true);
      }
      if (lusername === "") {
        log({ key: "NSA_APPLICANT", message: "Last Name is required", stackOrLog: "STACK" })
        setlUsernameRequire(true);
      }
      if (email === "") {
        log({ key: "NSA_APPLICANT", message: "Email is required", stackOrLog: "STACK" })
        setemailRequire(true);
      }
      if (mobile === "") {
        log({ key: "NSA_APPLICANT", message: "Mobile is required", stackOrLog: "STACK" })
        setmobileRequire(true);
      }
      if (uniqueID === "") {
        log({ key: "NSA_APPLICANT", message: "Qatar Id, Passport or Company Reg No is required", stackOrLog: "LOG" })
        setuniqueIdRequire(true);
      }
      return false;
    }
    else{
      return true;
    }
  }

  const handleNext = () => {
    log({ key: "NSA", message: "Next button clicked" })
    window.scrollTo({
      top: 0,
      pointer: "cursor",
    });
    let premise = selectedPremise
    let filename = localStorage.getItem("filename");
    let fileContent = FileContent;
    let fileContentq = FileContentq;
    let fileContentp = FileContentp;
    let idtype = idType;
    let cidtype = cidType;
    let cooling = Cooling;
    let idError = false;
    let jointOwnerDetailsError = false;
    let jointOwnerErrorsLocal = [];
    if (cidtype === "individual") {
      if (idtype === "QATAR_ID") {
        idError = uniqueIdError;
      } else {
        idError = puniqueIdError;
      }

      if(jointOwnerDetails.length > 0){
        jointOwnerDetails.forEach((item,index) => {
        if(!item.fusername || item.fusername == "" 
            || !item.lusername || item.lusername == ""
            || !item.email || item.email == "" 
            || !item.mobile || item.mobile == "" 
            || !item.uniqueID || item.uniqueID == "")
            {  
          jointOwnerErrorsLocal.push(index);
          jointOwnerDetailsError=true;
        }
      })
        
      }
    } else {
      idError = comapnyError;
    }
  
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep == 0) {
        log({ key: "NSA_APPLICANT", message: "Validating user input ", stackOrLog: "STACK" })
        if (checkbox) {
          log({ key: "NSA_APPLICANT", message: "with representative details...", stackOrLog: "STACK" })
          if (
            ((fusername != "" &&
              !fusernameValid &&
              lusername != "" &&
              !lusernameValid) ||
              cusername) &&
            email != "" &&
            !emailError &&
            uniqueID != "" &&
            !idError &&
            mobile != "" &&
            !mobileError &&
            rusername != "" &&
            remail != "" &&
            !remailError &&
            rmobile != "" &&
            !rmobileError &&
            !rusernameValid &&
            !jointOwnerDetailsError && 
            (cidtype === "individual" ? isMobileVerified : true)
          ) {
            log({ key: "NSA_APPLICANT", message: "User input validation success - Moving to Next step", stackOrLog: "LOG" })
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else {
            log({ key: "NSA_APPLICANT", message: "User input validation error", stackOrLog: "STACK" })
            setActiveStep((prevActiveStep) => prevActiveStep);
            if (fusername === "") {
              log({ key: "NSA_APPLICANT", message: "First Name is required", stackOrLog: "STACK" })
              setfUsernameRequire(true);
            }
            if (lusername === "") {
              log({ key: "NSA_APPLICANT", message: "Last Name is required", stackOrLog: "STACK" })
              setlUsernameRequire(true);
            }
            if (cusername === "") {
              log({ key: "NSA_APPLICANT", message: "Middle Name or Company Name is required", stackOrLog: "STACK" })
              setcUsernameRequire(true);
            }
            if (email === "") {
              log({ key: "NSA_APPLICANT", message: "Email is required", stackOrLog: "STACK" })
              setemailRequire(true);
            }
            if (mobile === "") {
              log({ key: "NSA_APPLICANT", message: "Mobile is required", stackOrLog: "STACK" })
              setmobileRequire(true);
            }
            if (checkbox && rusername == "") {
              log({ key: "NSA_APPLICANT", message: "Representative Name is required", stackOrLog: "STACK" })
              setrUsernameRequire(true);
            }
            if (checkbox && remail == "") {
              log({ key: "NSA_APPLICANT", message: "Representative Email is required", stackOrLog: "STACK" })
              setremailRequire(true);
            }
            if (checkbox && rmobile == "") {
              log({ key: "NSA_APPLICANT", message: "Representative Mobile is required", stackOrLog: "STACK" })
              setrmobileRequire(true);
            }
            if (uniqueID === "") {
              log({ key: "NSA_APPLICANT", message: "Qatar Id, Passport or Company Reg No is required", stackOrLog: "STACK" })
              setuniqueIdRequire(true);
              setcompanyRequire(true);
            }
            if(jointOwnerDetailsError){
              log({ key: "NSA_APPLICANT", message: "Co-Owner Inputs Required", stackOrLog: "STACK" })
              setJointOwnDetailsErrorArray(jointOwnerErrorsLocal);
            }
            if(!(isMobileVerified)){
              log({ key: "NSA_APPLICANT", message: "Mobile Verification Required", stackOrLog: "STACK" })
              setMobileVeficationError(true);
            }
            log({ key: "NSA_APPLICANT", message: "These fields are missing, Hence No Next Page", stackOrLog: "LOG" })
          }
        } else {
          log({ key: "NSA_APPLICANT", message: "without representative details...", stackOrLog: "STACK" })
          if (
            ((fusername != "" &&
              !fusernameValid &&
              lusername != "" &&
              !lusernameValid) ||
              (cusername != "" && !cusernameValid)) &&
            email != "" &&
            uniqueID != "" &&
            !idError &&
            !emailError &&
            mobile != "" &&
            !mobileError &&
            !jointOwnerDetailsError && 
            (cidtype === "individual" ? isMobileVerified : true)
          ) {
            // if(true){
            log({ key: "NSA_APPLICANT", message: "User input validation success - Moving to Next step", stackOrLog: "LOG" })
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else {
            setActiveStep((prevActiveStep) => prevActiveStep);
            if (fusername === "") {
              log({ key: "NSA_APPLICANT", message: "First Name is required", stackOrLog: "STACK" })
              setfUsernameRequire(true);
            }
            if (lusername === "") {
              log({ key: "NSA_APPLICANT", message: "Last Name is required", stackOrLog: "STACK" })
              setlUsernameRequire(true);
            }
            if (cusername === "") {
              log({ key: "NSA_APPLICANT", message: "Middle Name or Company Name is required", stackOrLog: "STACK" })
              setcUsernameRequire(true);
            }
            if (email === "") {
              log({ key: "NSA_APPLICANT", message: "Email is required", stackOrLog: "STACK" })
              setemailRequire(true);
            }
            if (mobile === "") {
              log({ key: "NSA_APPLICANT", message: "Mobile is required", stackOrLog: "STACK" })
              setmobileRequire(true);
            }
            if (uniqueID === "") {
              log({ key: "NSA_APPLICANT", message: "Qatar Id, Passport or Company Reg No is required", stackOrLog: "STACK" })
              setuniqueIdRequire(true);
              setcompanyRequire(true);
            }
            if(jointOwnerDetailsError){
              log({ key: "NSA_APPLICANT", message: "Co-Owner Inputs Required", stackOrLog: "STACK" })
              setJointOwnDetailsErrorArray(jointOwnerErrorsLocal);
            }
            if(!(isMobileVerified)){
              log({ key: "NSA_APPLICANT", message: "Mobile Verification Required", stackOrLog: "STACK" })
              setMobileVeficationError(true);
            }
            log({ key: "NSA_APPLICANT", message: "These fields are missing, Hence No Next Page", stackOrLog: "LOG" })
          }
        }
      } else if (prevActiveStep == 1) {
        log({ key: "NSA_APPLICANT", message: "Validating premise input...", stackOrLog: "STACK" })
        if (
          unit != "" &&
          bname != "" &&
          bnum != "" &&
          address != "" &&
          district != "" &&
          premise != null
        ) {
          setPremiseRequire(false);
          if (propertyType === "villa" && !cooling) {
            log({ key: "NSA_APPLICANT", message: "Cooling capacity is required", stackOrLog: "LOG" })
            setActiveStep((prevActiveStep) => prevActiveStep);
            setbuildNumberError(true);
          } else {
            log({ key: "NSA_APPLICANT", message: "Premise input validation success - Moving to Next step", stackOrLog: "LOG" })
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setbuildNumberError(false);
          }
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep);
          if (unit == "") {
            log({ key: "NSA_APPLICANT", message: "Unit is required", stackOrLog: "STACK" })
            setunitRequire(true);
          }
          if (bname == "") {
            log({ key: "NSA_APPLICANT", message: "Building Name is required", stackOrLog: "STACK" })
            setbuildingNameRequire(true);
          }
          if (bnum == "") {
            log({ key: "NSA_APPLICANT", message: "Building Number is required", stackOrLog: "STACK" })
            setbuildingNumberRequire(true);
          }
          if (address == "") {
            log({ key: "NSA_APPLICANT", message: "Address is required", stackOrLog: "STACK" })
            setaddressRequire(true);
          }
          if (district == "") {
            log({ key: "NSA_APPLICANT", message: "District is required", stackOrLog: "STACK" })
            setdistrictRequire(true);
          }
          if (premise === null) {
            log({ key: "NSA_APPLICANT", message: "Premise is required", stackOrLog: "STACK" })
            setPremiseRequire(true);
          }
          log({ key: "NSA_APPLICANT", message: "These fields are missing, Hence No Next Page", stackOrLog: "LOG" })
        }
      } else if (prevActiveStep == 2) {
        log({ key: "NSA_APPLICANT", message: "Validating attachment input...", stackOrLog: "STACK" })
        if (
          fileContent != null &&
          fileContentp != null &&
          fileContentq != null 
        ) {
          log({ key: "NSA_APPLICANT", message: "Attachment input validation success - Moving to Next step", stackOrLog: "LOG" })
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          log({ key: "NSA_APPLICANT", message: "Attachments are required", stackOrLog: "LOG" })
          setActiveStep((prevActiveStep) => prevActiveStep);
        }
      }
    });
  };

  const handleBack = () => {
    window.scrollTo({
      top: 0,
      pointer: "cursor",
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const FinishNext = (event) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  function splitFilesName(fileName){
    let fileNameArray = fileName.split(".");
    let fileNameFinal = "";
    fileNameArray.map((item,index) => {
      if(index != fileNameArray.length-1){
        if(index == 0){
          fileNameFinal = item;
        }
        else {
          fileNameFinal = fileNameFinal+"."+item;
        }
      }
    })
    return fileNameFinal;
  }

  const [jointOwnerDocErrorIndex, setJointOwnerDocErrorIndex] = useState([]);
  const getNextScreen = () => {
    log({ key: "NSA", message: "Next Button clicked", stackOrLog: "STACK" })
    let filename = localStorage.getItem("fileName");
    let fileContent = FileContent
    let fileType = localStorage.getItem("fileType");
    let filenamep = localStorage.getItem("fileNamep");
    let fileContentp = FileContentp
    let fileTypep = localStorage.getItem("fileTypep");
    let filenameq = localStorage.getItem("fileNameq");
    let fileContentq = FileContentq
    let fileTypeq = localStorage.getItem("fileTypeq");
    let fileDropdown = localStorage.getItem("fileDropdown");
    let fileDropdownp = localStorage.getItem("fileDropdownp");
    let fileDropdownq = localStorage.getItem("fileDropdownq");
    let AllFilesContent = FileContentAll;
    let AllFilesName = localStorage.getItem("AllFilesName");
    let AllFilesType= localStorage.getItem("AllFilesType");  
    let dropdownItems = localStorage.getItem("dropdownItems");   
    let csaAttachments = [];
    let attachmentName = [];
    let uploadattachments = {};
    if (filename !== null && filename != "") {
      filename = JSON.parse(filename);
      fileType = JSON.parse(fileType);
      fileContent = JSON.parse(fileContent);
      if (filename.length != 0) {
        filename.forEach((item,index) => {
          csaAttachments.push({
            fileName: splitFilesName(item),
            fileType: fileType[index],
            fileContent: fileContent[index],
            fileCategory: fileDropdown
          });
          attachmentName.push(item);
        })
      } else {
        log({ key: "NSA", message: "File 1 is required", stackOrLog: "STACK" })
        setfile1Require(true);
      }
    } else {
      log({ key: "NSA", message: "File 1 is required", stackOrLog: "STACK" })
      setfile1Require(true);
    }
    if (filenamep !== null && filenamep != "") {
      filenamep = JSON.parse(filenamep);
      fileTypep = JSON.parse(fileTypep);
      fileContentp = JSON.parse(fileContentp);
      if (filenamep.length != 0) {
        filenamep.forEach((item,index) => {
          csaAttachments.push({
            fileName: splitFilesName(item),
            fileType: fileTypep[index],
            fileContent: fileContentp[index],
            fileCategory: fileDropdownp
          });
          attachmentName.push(item);
        })
      } else {
        log({ key: "NSA", message: "File 2 is required", stackOrLog: "STACK" })
        setfile2Require(true);
      }
    } else {
      log({ key: "NSA", message: "File 2 is required", stackOrLog: "STACK" })
      setfile2Require(true);
    }
    let fileRequireQ = false;
    if (checkbox) {
      if (filenameq !== null && filenameq != "") {
        filenameq = JSON.parse(filenameq);
        fileTypeq = JSON.parse(fileTypeq);
        fileContentq = JSON.parse(fileContentq);
        fileRequireQ = false;
        if (filenameq.length != 0) {
          filenameq.forEach((item,index) => {
            csaAttachments.push({
              fileName: splitFilesName(item),
              fileType: fileTypeq[index],
              fileContent: fileContentq[index],
              fileCategory: fileDropdownq
            });
            attachmentName.push(item);
          })
        } else {
          log({ key: "NSA", message: "File 3 is required", stackOrLog: "STACK" })
          setfile3Require(true);
          fileRequireQ = true;
        }
      } else {
        log({ key: "NSA", message: "File 3 is required", stackOrLog: "STACK" })
        setfile3Require(true);
        fileRequireQ = true;
      }
    } else {
      localStorage.removeItem("fileNameq");
      localStorage.removeItem("filetypeq");
      localStorage.removeItem("uploadcontentq");
    }
    if (AllFilesName !== null && AllFilesName.length > 0) {
      AllFilesName=JSON.parse(AllFilesName);
      AllFilesType=JSON.parse(AllFilesType);
      AllFilesContent=JSON.parse(AllFilesContent);
      dropdownItems=JSON.parse(dropdownItems);
      AllFilesName.map((allfile,i)=>{
        allfile.map((eachItem,j)=>{
          csaAttachments.push({
            fileName:splitFilesName(eachItem),
            fileType:AllFilesType[i][j],
            fileContent:AllFilesContent[i][j],
            fileCategory:dropdownItems[i].dropDownCode
          })
          attachmentName.push(eachItem);
        })
      })
    }
    if(jointOwnerDetails.length > 0){
      jointOwnerDetails.map((item,i)=>{
        csaAttachments.push({
            fileName:item.fileName,
            fileType:item.fileType,
            fileContent:item.fileContent,
            fileCategory:item.uniqueValue === config.payment.QATAR_ID ? config.payment.QID : config.payment.PASSPORT
        })
        attachmentName.push(item.fileName);
      })
    }
    if (csaAttachments) {
      setcsaAttachments(JSON.stringify(csaAttachments));
      setAttachmentName(attachmentName);
    }

    let jointOwnDocErros = false;
    if(jointOwnerDetails?.length > 0)
    {     
      let jointOwnerDetailsLocal = [];
      for (var i = 0; i < jointOwnerDetails.length; i++)
      {
        if(!(jointOwnerDetails[i]?.files)){  
          jointOwnerDetailsLocal.push(i);
          jointOwnDocErros=true;
        }
      }
      setJointOwnerDocErrorIndex(jointOwnerDetailsLocal);
    }

    if (
      filename !== null &&
      filename != "" &&
      filenamep !== null &&
      filenamep != "" &&
      fileRequireQ === false && 
      jointOwnDocErros === false
    ) {
      log({ key: "NSA", message: "Checking signature...", stackOrLog: "STACK" })
      let signature = localStorage.getItem("signature");
      if (signature) {
        log({ key: "NSA", message: "Signature Found", stackOrLog: "LOG" })
      let data = {
            title: title,
            countryCode: countryCode,
            rcountryCode:rcountryCode,
            lcountryCode:lcountryCode,
            fusername:fusername,
            lusername:lusername,
            username:username,
            cusername:cusername,
            email:email,
            mobile:mobile,
            rusername:rusername,
            remail:remail,
            rmobile:rmobile,
            uniqueID:uniqueID,
            unit:unit,
            bname:bname,
            bnum:bnum,
            address:address,
            pobox:pobox,
            district:district,
            csaAttachment:csaAttachments,
            attachmentName:attachmentName,
            withoutExtmobile:withoutExtmobile,
            rwithoutExtmobile:rwithoutExtmobile,
            jointOwnerDetails:jointOwnerDetails,
            cidType:cidType,
            idType:idType,
            landline:landline, 
            extension_landline:extension_landline,
            checkbox:checkbox,
            titleDropdown:titleDropdown,
            depositeAmount:depositeAmount,
            leadid:leadid,
            selectedPremise:selectedPremise,
            propertyType:propertyType,
            Cooling:Cooling,
            adlnotes:adlnotes,
            verifyVisibility:verifyVisibility,
            isMobileVerified:isMobileVerified,
            FileContent:FileContent,
            FileContentp:FileContentp,
            FileContentq:FileContentq,
            FileContentAll:FileContentAll,
        } 
        dispatch(saveNewServiceAgreementData(data));
        log({ key: "NSA", message: "Redirecting to Summary Page...", stackOrLog: "LOG" })
        history.push(routePaths.NSA_PAY);
      } else {
        log({ key: "NSA", message: "Signature Missing", stackOrLog: "LOG" })
      }
    }
    log({ key: "NSA", message: "Next Page Function Ends", stackOrLog: "LOG" })
  };

  return (
    <React.Fragment>
      {props.labelSubTitle && props.confirmSubTitle ? (
        activeStep === 3 ? (
          <NSACommonHeader
            labelSubTitle={props.confirmSubTitle}
            baseStyle={props.baseStyle}
            activeStep={activeStep}
          />
        ) : (
          <NSACommonHeader
            labelSubTitle={props.labelSubTitle}
            baseStyle={props.baseStyle}
            activeStep={activeStep}
          />
        )
      ) : (
        <Skeleton />
      )}
      <div className={classes.root}>
        {activeStep < 3 ? (
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) =>
              label !== labels_SA?.SA_SUCCESS &&
              label !== labels_SA?.SA_CONFIRM ? (
                <Step key={label}>
                  <StepLabel className={classes.stepLabel}>
                    {label != labels_SA?.SA_SUCCESS &&
                    label !== labels_SA?.SA_CONFIRM
                      ? label
                      : null}
                  </StepLabel>
                </Step>
              ) : (
                <span></span>
              )
            )}
          </Stepper>
        ) : null}
        {activeStep < 3 ? (
          <hr style={{ borderRadius: 2, borderWidth: 2 }} />
        ) : null}

        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                {labels_SA?.SA_STEP_COMPLETED}
              </Typography>
              <div>
                <Button onClick={handleReset} color="primary">
                  {labels_SA?.SA_RESET_STEP}
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <Typography>
                {getStepContent(
                  activeStep,
                  title,
                  countryCode,
                  rcountryCode,
                  lcountryCode,
                  username,
                  fusername,
                  fusernameRequire,
                  fusernameValid,
                  cusername,
                  cusernameRequire,
                  cusernameValid,
                  lusername,
                  lusernameRequire,
                  lusernameValid,
                  email,
                  emailRequire,
                  emailError,
                  mobile,
                  mobileRequire,
                  mobileError,
                  unit,
                  unitError,
                  unitRequire,
                  bname,
                  buildError,
                  buildingNameRequire,
                  bnum,
                  buildingNumberRequire,
                  buildNumberError,
                  address,
                  addressRequire,
                  district,
                  districtRequire,
                  pobox,
                  rusername,
                  rusernameRequire,
                  rusernameValid,
                  remail,
                  remailError,
                  remailRequire,
                  rmobile,
                  rmobileError,
                  rmobileRequire,
                  uniqueID,
                  uniqueIdRequire,
                  uniqueIdError,
                  puniqueIdError,
                  puniqueIdRequire,
                  comapnyError,
                  companyRequire,
                  classesBase,
                  premiseRequire
                )}
              </Typography>
              {activeStep < 3 ? (
                <hr className="margin-top-20" />
              ) : (
                <div className="margin-top-30" />
              )}
              <Grid container direction="row">
                <Grid item xs={6} sm={6}>
                  <Typography disabled={activeStep === 0}>
                    {activeStep > 0 && activeStep < 3 ? (
                      <div className="float-left">
                        <Button
                          size="small"
                          color="primary"
                          startIcon={<ArrowBackIosIcon fontSize="small" />}
                          onClick={handleBack}
                        >
                          <b>{labels_SA?.SA_BACK}</b>
                        </Button>
                      </div>
                    ) : null}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <div align="right">
                    {activeStep === steps.length - 3 ? (
                      localStorage.getItem("signature") == "" ||
                      !localStorage.getItem("signature") ? (
                        <Tooltip
                          title={labelsUser?.PROCEED_BUTTON_TOOLTIP}
                          arrow
                        >
                          <span>
                            {" "}
                            <Button
                              variant="contained"
                              color="primary"
                              value="thanks"
                              onClick={getNextScreen}
                              disabled={
                                localStorage.getItem("signature") == "" ||
                                !localStorage.getItem("signature")
                                  ? true
                                  : false
                              }
                            >
                              {labels_SA?.SA_PROCEED}
                            </Button>
                          </span>
                        </Tooltip>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          value="thanks"
                          onClick={getNextScreen}
                          disabled={
                            localStorage.getItem("signature") == "" ||
                            !localStorage.getItem("signature")
                              ? true
                              : false
                          }
                        >
                          {labels_SA?.SA_PROCEED}
                        </Button>
                      )
                    ) : activeStep < 3 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {labels_SA?.SA_PROCEED}
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}