import React, { useState,useEffect } from "react";
import "../../../auth/auth.css";
import "../../../app/base-style.css";
import Button from "@material-ui/core/Button";
import {
  Typography,
  Grid,
  MenuItem,
  InputLabel, 
  makeStyles,
} from "@material-ui/core";
import { store } from "../../../../helpers";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import "./_new-service-agreement.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import { OutlinedTextFiled ,TitleSelect} from "../../../auth/auth-style";
import { useSelector } from "react-redux";
import { Hidden } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import OtpVerification from "./_otp-verification";
import JointOwnerDetails from "./_joint-owner-details";
import {ticketService} from "../service/ticket-service";
import { Alert } from "@material-ui/lab";
import { adminService } from "../../../../services/admin.service";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CircularProgress } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  userVerified : {
    color : theme.palette.usagePositiveColor.main,
    paddingTop: 5
  },
  mobilePadding: {
    paddingBottom: 12,
    paddingTop: 12
  },
  addJointOwnerPAdding: { 
    paddingBottom:0,
    marginTop:72,
    paddingTop:0,
  },
  verifyButton:{
    paddingBottom:0,
    marginTop:72,
    paddingLeft:0,
    [theme.breakpoints.down("xs")]: {
      width: 150
    },
  },
  mobileVerifyError:{
    display:"inline-block"
  },
  circularLoading:{
    marginLeft: 5
  }
}));

function ApplicantDetails(props) {
    const labels_Upfront_Payment = useSelector((state)=>state?.labels?.data?.getLabels?.UPFRONT_PAYMENT);
    const classes = useStyle();
    const matches = useMediaQuery('(min-width:960px)');
    const labels = useSelector((state)=>state?.labels?.data?.getLabels?.APPLICANT_DETAILS);
    const label_User_management = useSelector((state)=>state?.labels?.data?.getLabels?.USER_MANAGEMENT);
    const labels_Property = useSelector((state)=>state?.labels?.data?.getLabels?.PROPERTY_DETAILS);

    let companyNumberLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.COMPANY_NO_LENGTH);
    companyNumberLength = Number(companyNumberLength);
    let mobileLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.MOBILE_LENGTH);
    mobileLength = Number(mobileLength);
    let qatariLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.QATAR_ID_LENGTH);
    qatariLength = Number(qatariLength);
    let passportLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.PASSPORT_NO_LENGTH);
    passportLength = Number(passportLength);
    const country_Calling_Code = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.COUNTRY_DIALING_CODE);
    let userNameLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.USERNAME_MAX_LENGTH);
    userNameLength = Number(userNameLength);

    const[user,setUser]=useState({})

    const {cidType,setcidType,idType,setidType,landline,setLandline,extension_landline,setExtension_landline} = props;
    
    const [uniqueValue, setuniqueValue] = useState("QATAR_ID")
    const [loading, setLoading] = useState(false);
    const log = (logObj) => {
      adminService.log(logObj).then((res) => {
      });
    }
    const uniqueRadioChange = (event) => {
        props.clearField();
        setidType(event.target.value);
        localStorage.removeItem("fileNamep");
        localStorage.removeItem("fileTypep");
        localStorage.removeItem("fileContentp");
        setuniqueValue(event.target.value)
        setUser({
            ...user,
            uniqueId: "",
        })
      }

      const [uniqueRadioValue, setUniqueRadioValue] = useState("individual")
      const handleRadioChange = (event) => {
        props.clearField();
        setcidType(event.target.value)
        localStorage.removeItem("fileNamep");
        localStorage.removeItem("fileTypep");
        localStorage.removeItem("fileContentp");
        setUniqueRadioValue(event.target.value);
        setUser(
          {
            ...user,
            unique: ""
          }
        )
      }

      
      const handleCheckBoxChange = (event) => {
        props.setcheckbox(event.target.checked);
        if (event.target.checked) {
          props.setcheckbox(event.target.checked);
        } else {
          props.setcheckbox(false);
        }
        setUser(
          {
            ...user,
            checkboxs: event.target.checked
          }
        )
      };
      
      const [landlineRequire, setLandlineRequire] = useState(false);
      const [landlineError, setLandlineError] = useState(false)
     const landlineBlur = () => {
        if (landline) {
          setLandlineRequire(false);
          if (store.getState().parametersWidgets.ParameterLookup) {
            var MOBILE_LENGTH = store.getState().parametersWidgets.ParameterLookup
              .MOBILE_LENGTH;
            if (landline.length === Number(MOBILE_LENGTH)) {
              setLandlineError(false);
            } else {
              setLandlineError(true);
            }
          }
        } else {
          setLandlineRequire(true);
          setLandlineError(false);
        }
      }

      let MOBILE_REGEX = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.MOBILE_REGEX);
      MOBILE_REGEX=MOBILE_REGEX.replace(
        "\\\\",
        "\\"
      );
      const handleChangelocal = (event) => {
        const { name, value } = event.target;
        if (name === "landline" || (uniqueRadioValue === "individual" && name === "unique")) {
          const re=RegExp(MOBILE_REGEX)
          if (event.target.value === "" || re.test(event.target.value)) {
             if (name === "landline") {
              setLandline(value)
            } 
          }
        } else {
          setExtension_landline(value)
        }
      }

      const {verifyVisibility, setVerifyVisibility} = props;
      const [openOtpVerification, setOpenOtpVerification] = useState(false);

      const [otpError, setOtpError] = useState(false);
      const [otpMAxLimitError, setOtpMAxLimitError] = useState(false);
      const [otpErrorMsg, setOtpErrorMsg] = useState("");
      const handleVerifyClick = () => {
        log({ key: "NSA_APPLICANT", message: "Verify Mobile Clicked ", stackOrLog: "STACK" })
        if(props.handleVerify()){
          log({ key: "NSA_APPLICANT", message: `Sending OTP... Mobile: ${mobile}`, stackOrLog: "STACK" })
          setLoading(true)
          ticketService.sendOtpMobile(mobile)
          .then((response) => {
            log({ key: "NSA_APPLICANT", message: `OTP Sent... Mobile: ${mobile}`, stackOrLog: "LOG" })
            setLoading(false)
            setOpenOtpVerification(true);
            // props.setIsMobileVerified(true);
            setOtpError(false);
            setOtpMAxLimitError(false)
          })
          .catch((error) => {
            log({ key: "NSA_APPLICANT", message: `Send OTP Error... Mobile: ${mobile}`, stackOrLog: "STACK" })
            setLoading(false)
            if (error?.response?.status === 403) {
              log({ key: "NSA_APPLICANT", message: `OTP Error: ${error.response.data.message}`, stackOrLog: "LOG" })
              setOtpMAxLimitError(true);
              setOtpErrorMsg(error.response.data.message)
            }
            else{
              log({ key: "NSA_APPLICANT", message: 'OTP Error: ', addOnLog: error, stackOrLog: "LOG" })
              setOtpError(true);
            }
          });
        }
      }
      
      const handleAddJointOwnerClick = () => {
        props.addNewJoinOwner();
      }

      const {
        title,
        titleDropdown,
        handleOnChangePhone,
        handleOnChangeRepPhone,
        handleTitleChange,
        username,
        cusername,
        cusernameRequire,
        cusernameValid,
        cusernameBlur,
        fusername,
        lusername,
        handleChange,
        fusernameBlur,
        lusernameBlur,
        fusernameRequire,
        fusernameValid,
        lusernameRequire,
        lusernameValid,
        email,
        emailBlur,
        emailRequire,
        emailError,
        mobile,
        mobileBlur,
        mobileRequire,
        mobileError,
        rusername,
        rusernameBlur,
        rusernameRequire,
        rusernameValid,
        remail,
        remailError,
        remailRequire,
        remailBlur,
        rmobile,
        rmobileError,
        rmobileRequire,
        uniqueID,
        uniqueIdRequire,
        uniqueIdBlur,
        uniqueIdError,
        companyBlur,
        comapnyError,
        companyRequire,
        puniqueIdError,
        jointOwnDetailsErrorArray,
        countryCodeName,
        rcountryCodeName
      } = props;
  return (
    <React.Fragment>
        <Typography color="primary">
          <h5>
            <b>{labels?.CHOOSE_TYPE}</b>
          </h5>
        </Typography>

        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            row
            name="uniqueRadioValue"
            value={
              cidType == null
                ? uniqueRadioValue
                : cidType
            }
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="individual"
              control={<Radio className={props.classesBase.linkColor} />}
              label={labels_Property?.INDIVIDUAL}
            />
            <FormControlLabel
              value="company"
              control={<Radio className={props.classesBase.linkColor} />}
              label={labels?.COMPANY}
            />
          </RadioGroup>
        </FormControl>
        <div className="horizontalLine">
          <Typography color="primary" className="margin-top-10 ">
            <h5>
              <b>
                {cidType == "individual" ||
                cidType == null
                  ? labels?.APPLICANT_DETAILS
                  : labels?.COMPANY_DETAILS}
              </b>
            </h5>
          </Typography>
        </div>
        {cidType == "individual" ||
        cidType == null ? (
          <Grid container direction="row" spacing={2} className="margin-top-10">
            <Grid item xs={3} sm={2} md={1} lg={1}>
              <Typography align="left">
                {cidType == "individual" ||
                cidType == null
                  ? labels?.TITLE
                  : labels?.NAME_OF_COMPANY}
              </Typography>
              {titleDropdown && (
                <TitleSelect
                  fullWidth
                  variant="outlined"
                  labelId="label"
                  id="select"
                  value={title
                  }
                  onChange={handleTitleChange}
                >
                  {titleDropdown.map((item, k) => {
                    return (
                      <MenuItem value={item.dropDownCode}>
                        {item.dropDownValue}
                      </MenuItem>
                    );
                  })}
                </TitleSelect>
              )}
            </Grid>

            <Grid item xs={9} sm={10} md={3} lg={3}>
              <InputLabel align="left" required>
                {cidType == "individual" ||
                cidType == null
                  ? labels?.FIRST_NAME
                  : labels?.NAME_OF_COMPANY}
              </InputLabel>
              <OutlinedTextFiled
                variant="outlined"
                id="username_Id"
                name="fusername"
                fullWidth
                inputProps={{
                  maxLength: userNameLength,
                }}
                value={fusername}
                onChange={handleChange}
                onBlur={fusernameBlur}
                error={fusernameRequire || fusernameValid}
                autoComplete="off"
                helperText={
                  fusernameRequire === true ? (
                    labels?.FIRST_NAME_REQUIRED
                  ) : fusernameValid === true ? (
                    labels?.NAME_NOT_VALID
                  ) : (
                    <div>&nbsp;</div>
                  )
                }
                placeholder={
                  cidType == "individual"
                    ? labels?.FIRST_NAME_PLACEHOLDER
                    : labels?.COMPANY_PLACEHOLDER
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <InputLabel
                align="left"
                required={
                  cidType == "individual" ||
                  cidType == null
                    ? false
                    : true
                }
              >
                {cidType == "individual" ||
                cidType == null
                  ? labels?.MIDDLE_NAME
                  : labels?.NAME_OF_COMPANY}
              </InputLabel>
              <OutlinedTextFiled
                variant="outlined"
                id="username_Id"
                name="musername"
                fullWidth
                inputProps={{
                  maxLength: userNameLength,
                }}
                value={username}
                onChange={handleChange}
                autoComplete="off"
                placeholder={
                  cidType == "individual"
                    ? labels?.MIDDLE_NAME_PLACEHOLDER
                    : labels?.COMPANY_PLACEHOLDER
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <InputLabel align="left" required>
                {cidType == "individual" ||
                cidType == null
                  ? labels?.LAST_NAME
                  : labels?.NAME_OF_COMPANY}
              </InputLabel>
              <OutlinedTextFiled
                variant="outlined"
                id="username_Id"
                name="lusername"
                fullWidth
                inputProps={{
                  maxLength: userNameLength,
                }}
                value={lusername}
                onChange={handleChange}
                onBlur={lusernameBlur}
                error={lusernameRequire || lusernameValid}
                autoComplete="off"
                helperText={
                  lusernameRequire === true ? (
                    labels?.LAST_NAME_REQUIRED
                  ) : lusernameValid === true ? (
                    labels?.NAME_NOT_VALID
                  ) : (
                    <div>&nbsp;</div>
                  )
                }
                placeholder={
                  cidType == "individual"
                    ? labels?.LAST_NAME_PLACEHOLDER
                    : labels?.COMPANY_PLACEHOLDER
                }
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" spacing={3} className="margin-top-10">
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <InputLabel align="left" required>
                {cidType == "individual" ||
                cidType == null
                  ? labels?.MIDDLE_NAME
                  : labels?.NAME_OF_COMPANY}
              </InputLabel>
              <OutlinedTextFiled
                variant="outlined"
                id="username_Id"
                name="cusername"
                fullWidth
                inputProps={{
                  maxLength: userNameLength,
                }}
                value={cusername}
                onChange={handleChange}
                onBlur={cusernameBlur}
                error={cusernameRequire || cusernameValid}
                autoComplete="off"
                helperText={
                  cusernameRequire === true ? (
                    "Company Name is required"
                  ) : cusernameValid === true ? (
                    labels?.NAME_NOT_VALID
                  ) : (
                    <div>&nbsp;</div>
                  )
                }
                placeholder={
                  cidType == "individual"
                    ? labels?.MIDDLE_NAME_PLACEHOLDER
                    : labels?.COMPANY_PLACEHOLDER
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <InputLabel align="left" required>
                {label_User_management?.SIGNUP_REGISTERED_EMAIL}
              </InputLabel>
              <OutlinedTextFiled
                fullWidth
                variant="outlined"
                maxLength="100"
                id="registeredEmail_Id"
                name="registeredEmail"
                value={email}
                disabled={false}
                onChange={handleChange}
                onBlur={emailBlur}
                autoComplete="off"
                placeholder={label_User_management?.SIGNUP_EMAIL_PACEHOLDER}
                error={emailRequire || emailError}
                helperText={
                  emailRequire === true ? (
                    label_User_management?.ERROR_EMAIL_REQUIRED
                  ) : emailError === true ? (
                    label_User_management?.ERROR_EMAIL_VALIDATION
                  ) : (
                    <div>&nbsp;</div>
                  )
                }
              />
            </Grid>
          </Grid>
        )}

          <Grid container direction="row" spacing={2} className="margin-top-10">
          {(cidType == "individual" ||
          cidType == null) && (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <InputLabel align="left" required>
                {label_User_management?.SIGNUP_REGISTERED_EMAIL}
              </InputLabel>
              <OutlinedTextFiled
                fullWidth
                variant="outlined"
                maxLength="100"
                id="registeredEmail_Id"
                name="registeredEmail"
                value={email}
                disabled={false}
                onChange={handleChange}
                onBlur={emailBlur}
                autoComplete="off"
                placeholder={label_User_management?.SIGNUP_EMAIL_PACEHOLDER}
                error={emailRequire || emailError}
                helperText={
                  emailRequire === true ? (
                    label_User_management?.ERROR_EMAIL_REQUIRED
                  ) : emailError === true ? (
                    label_User_management?.ERROR_EMAIL_VALIDATION
                  ) : (
                    <div>&nbsp;</div>
                  )
                }
              />
            </Grid>
            )}
            <Grid item xs={8} sm={3} md={4} lg={4}>
            <InputLabel align="left">{labels?.LANDLINE_NUMBER}</InputLabel>
            <OutlinedTextFiled
              variant="outlined"
              id="landline_Id"
              inputProps={{
                maxLength: mobileLength,
              }}
              fullWidth
              name="landline"
              disabled={false}
              value={landline}
              onChange={handleChangelocal}
              onBlur={landlineBlur}
              autoComplete="off"
              placeholder={labels?.LANDLINE_PLACEHOLDER}
              error={landlineError}
              helperText={
                landlineError ? (
                  labels?.LANDLINE_NOT_VALID
                ) : (
                  <div>&nbsp;</div>
                )
              }
            />
            </Grid>
            <Grid item xs={4} sm={3} md={4} lg={4}>
            <InputLabel align="left">{labels_Upfront_Payment?.EXTENSION}</InputLabel>
            <OutlinedTextFiled
              variant="outlined"
              id="landline_Id"
              inputProps={{
                maxLength: mobileLength,
              }}
              fullWidth
              name="extension_landline"
              disabled={false}
              value={extension_landline}
              onChange={handleChangelocal}
              autoComplete="off"
              placeholder="ext."
            />
            </Grid>
          </Grid>

        <Grid container direction="row" spacing={3} className="margin-top-10">
          {cidType === "company" ? (<>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <InputLabel align="left" required>
                {label_User_management?.SIGNUP_UNIQUE_ID_COMPANY_REG_NO_LABEL}
              </InputLabel>
              <OutlinedTextFiled
                fullWidth
                variant="outlined"
                inputProps={{
                  maxLength: companyNumberLength,
                }}
                id="company_id"
                name="company_name"
                value={
                  localStorage.getItem("cpqid") == null
                    ? uniqueID
                    : localStorage.getItem("cpqid")
                }
                disabled={false}
                onChange={handleChange}
                onBlur={companyBlur}
                autoComplete="off"
                placeholder={
                  label_User_management?.SIGNUP_UNIQUE_ID_COMPANY_REG_NO_PLACEHOLDER
                }
                error={companyRequire || comapnyError}
                helperText={
                  companyRequire === true ? (
                    label_User_management?.SIGNUP_COMPANY_NO_REQUIRED
                  ) : comapnyError === true ? (
                    label_User_management?.SIGNUP_COMPANY_NO_NOT_VALID
                  ) : (
                    <div>&nbsp;</div>
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4}>
            <InputLabel align="left" required>
              {label_User_management?.SIGNUP_REGISTERED_CELLNUMBER}
            </InputLabel>
            <PhoneInput
              variant="outlined"
              fullWidth
              flags={flags}
              id="registeredCell_Id"
              name="registeredCell"
              value={mobile}
              autoComplete="off"
              placeholder={label_User_management?.SIGNUP_CELLNUMBER_PLACEHOLDER}
              international
              onBlur={mobileBlur}
              countryCallingCodeEditable={false}
              defaultCountry={countryCodeName ? countryCodeName : "QA"}
              onChange={handleOnChangePhone}
              limitMaxLength={true}
            />

            <Typography
              variant="caption"
              color="error"
              className="marginleftError"
            >
              {mobileRequire === true
                ? label_User_management?.SIGNUP_CELLNUMBER_REQUIRED
                : mobileError === true
                ? label_User_management?.SIGNUP_CELLNUMBER_NOT_VALID
                : null}
            </Typography>
          </Grid>
          </>
          ) : (
            <>
            <Grid item xs={12} sm={6} md={5} lg={4} style={{paddingBottom:0}}>
              <Grid>
                <InputLabel color="primary" align="left" required>
                  <b>{labels?.CHOOSE_IDENTIFICATION}</b>
                </InputLabel>
              </Grid>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="uniqueValue"
                  value={
                    idType == null
                      ? uniqueValue
                      : idType
                  }
                  onChange={uniqueRadioChange}
                  row
                >
                  <FormControlLabel
                    value={"QATAR_ID"}
                    control={
                      <Radio className={props.classesBase.linkColor} />
                    }
                    label={labels_Upfront_Payment?.JOINT_OWNER_QATAR_ID}
                  />
                  <FormControlLabel
                    value={"PASSPORT_NO"}
                    control={
                      <Radio className={props.classesBase.linkColor} />
                    }
                    label={
                      label_User_management?.SIGNUP_UNIQUE_ID_PASSPORTNO_LABEL
                    }
                  />
                </RadioGroup>
              </FormControl>
              <Grid item>
                <OutlinedTextFiled
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    maxLength:
                      idType === "QATAR_ID"
                        ? qatariLength
                        : idType === "PASSPORT_NO"
                        ? passportLength
                        : null,
                  }}
                  placeholder={
                    idType === "QATAR_ID" ||
                    idType == null
                      ? labels?.QATAR_PLACEHOLDER
                      : idType === "PASSPORT_NO"
                      ? labels?.PASSPORT_PLACEHOLDER
                      : null
                  }
                  id="uniqueId_Id"
                  name="uniqueId"
                  value={uniqueID}
                  onChange={handleChange}
                  onBlur={uniqueIdBlur}
                  autoComplete="off"
                  error={
                    idType === "QATAR_ID" ||
                    idType === null
                      ? uniqueIdRequire || uniqueIdError
                      : idType === "PASSPORT_NO"
                      ? uniqueIdRequire || puniqueIdError
                      : null
                  }
                  helperText={
                    idType === "QATAR_ID" ||
                    idType === null ? (
                      uniqueIdRequire === true ? (
                        label_User_management?.SIGNUP_QATARI_ID_REQUIRED
                      ) : uniqueIdError === true ? (
                        label_User_management?.SIGNUP_QATARI_ID_NOT_VALID
                      ) : (
                        <div>&nbsp;</div>
                      )
                    ) : idType === "PASSPORT_NO" ? (
                      uniqueIdRequire === true ? (
                        label_User_management?.SIGNUP_PASSPORT_NO_REQUIRED
                      ) : puniqueIdError === true ? (
                        label_User_management?.SIGNUP_PASSPORT_NO_NOT_VALID
                      ) : (
                        <div>&nbsp;</div>
                      )
                    ) : (
                      <div>&nbsp;</div>
                    )
                  }
                />
              </Grid>
            </Grid>
            
            <Hidden xsDown lgUp>
              <Grid item sm={6} style={{paddingBottom:0}}>
                &nbsp;
              </Grid>
            </Hidden>
              
            <Grid item xs={7} sm={6} md={5} lg={4} style={{paddingBottom:0}}>
            <InputLabel color="primary" align="left">
              <b>{labels_Upfront_Payment?.JOINT_OWNER_VERIFICATION}</b>
            </InputLabel>
            <InputLabel align="left" className={classes.mobilePadding} required>
              {label_User_management?.SIGNUP_REGISTERED_CELLNUMBER}
            </InputLabel>
            <PhoneInput
              variant="outlined"
              fullWidth
              flags={flags}
              id="registeredCell_Id"
              name="registeredCell"
              value={mobile}
              autoComplete="off"
              placeholder={label_User_management?.SIGNUP_CELLNUMBER_PLACEHOLDER}
              international
              onBlur={mobileBlur}
              countryCallingCodeEditable={false}
              defaultCountry={countryCodeName ? countryCodeName : "QA"}
              onChange={handleOnChangePhone}
              limitMaxLength={true}
            />

            <Typography
              variant="caption"
              color="error"
              className="marginleftError"
            >
              {mobileRequire === true
                ? label_User_management?.SIGNUP_CELLNUMBER_REQUIRED
                : mobileError === true
                ? label_User_management?.SIGNUP_CELLNUMBER_NOT_VALID
                : null}
            </Typography>
            </Grid>

            

            <Grid item className={classes.verifyButton}>
            {verifyVisibility ? (
              <Grid>
              <Button
              variant="contained"
              color="primary"
              value="thanks"
              onClick={handleVerifyClick}
              >
                {labels_Upfront_Payment?.VERIFY}
                {loading && <CircularProgress size={17} className={classes.circularLoading} color="inherit"/>}
            </Button>
            <Typography
              variant="caption"
              color="error"
              className={["marginleftError",classes.mobileVerifyError]}
            >
              {
                props.mobileVeficationError ? 
                labels_Upfront_Payment?.PLEASE_VERIFY_MOBILE : null
              }
            </Typography>
            </Grid>):(
              <Grid container direction="row" alignItems="center" className={classes.userVerified}>
              <CheckCircleIcon /> <span style={{whiteSpace: "nowrap"}}>{`${labels_Upfront_Payment?.USER_VERIFIED}`} </span>
              </Grid>
            )}
            </Grid>

            {!verifyVisibility && 
            <Grid item xs={6} sm={6} md={3} lg={2} className={
              matches
                ? classes.addJointOwnerPAdding
                : ""
            }>
              <Grid>
                {(props.jointOwnerDetails.length < 1) && <Button
                  variant="contained"
                  color="primary"
                  value="thanks"
                  onClick={handleAddJointOwnerClick}
                  >
                    {labels_Upfront_Payment?.ADD_JOINT_OWNER}
                </Button>
                }
              </Grid>
            </Grid>}
            </>
          )}
        </Grid>

        {otpMAxLimitError && <Alert severity="error">{(labels_Upfront_Payment?.MAX_OTP_ATTEMPTS_ERROS).replace("****", otpErrorMsg)}</Alert>}
        {otpError && <Alert severity="error">{label_User_management.OTP_TRY_AGAIN_ERROR}</Alert>}
        
       <OtpVerification openOtpVerification={openOtpVerification} setVerifyVisibility={setVerifyVisibility} setOpenOtpVerification={setOpenOtpVerification} mobile={mobile} setIsMobileVerified={props.setIsMobileVerified}/>
      
      
       {(cidType == "individual" ||
        cidType == null) && 
        (props.jointOwnerDetails.length > 0) &&
        <JointOwnerDetails
          jointOwnerDetails={props.jointOwnerDetails}
          setjointOwnerDetails={props.setjointOwnerDetails}
          classesBase={props.classesBase}
          titleDropdown={titleDropdown}
          saveJointOwnerData={props.saveJointOwnerData}
          handleAddJointOwnerClick={handleAddJointOwnerClick}
          deleteJointOwnerData={props.deleteJointOwnerData}
          jointOwnDetailsErrorArray={jointOwnDetailsErrorArray}
        />
        }
      
        <div className="horizontalLine margin-top-10">
          <Typography color="primary" className="margin-top-10">
            <h5>
              <b>{labels?.REPRESENTATIVE_DETAILS}</b>
            </h5>
          </Typography>
        </div>
        <Grid container>
          <Grid item>
            <Checkbox
              value="checkedA"
              className={props.classesBase.linkColor}
              checked={
                props.checkbox 
              }
              inputProps={{ "aria-label": "Checkbox A" }}
              color="red"
              onChange={handleCheckBoxChange}
            />
            <b>{labels?.CHECKBOX_TITLE}</b>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="row" className="margin-top-10">
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <InputLabel
              align="left"
              required={
                props.checkbox
              }
            >
              {labels?.NAME_OF_REPRESENTATIVE}
            </InputLabel>
            <OutlinedTextFiled
              fullWidth
              inputProps={{
                maxLength: userNameLength,
              }}
              variant="outlined"
              id="rusername"
              name="rusername"
              disabled={
                props.checkbox
                  ? false
                  : !user.checkboxs 
              }
              value={
                props.checkbox ? rusername : ""
              }
              onChange={handleChange}
              onBlur={rusernameBlur}
              error={rusernameRequire || rusernameValid}
              autoComplete="off"
              helperText={
                props.checkbox  ? (
                  rusernameRequire === true ? (
                    labels?.NAME_REQUIRED
                  ) : rusernameValid === true ? (
                    labels?.NAME_NOT_VALID
                  ) : (
                    <div>&nbsp;</div>
                  )
                ) : (
                  <div>&nbsp;</div>
                )
              }
              placeholder={labels?.REPRESENTATIVE_NAME_PLACEHOLDER}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <InputLabel
              align="left"
              required={
                props.checkbox ? true : false
              }
            >
              {label_User_management?.SIGNUP_REGISTERED_EMAIL}
            </InputLabel>
            <OutlinedTextFiled
              fullWidth
              variant="outlined"
              id="rregisteredEmail"
              name="rregisteredEmail"
              disabled={
                props.checkbox
                  ? false
                  : !user.checkboxs
              }
              value={props.checkbox ? remail : ""}
              onChange={handleChange}
              onBlur={remailBlur}
              autoComplete="off"
              placeholder={labels?.REPRESENTATIVE_EMAIL_PLACEHOLDER}
              error={remailRequire || remailError}
              helperText={
                props.checkbox ? (
                  remailRequire === true ? (
                    label_User_management?.ERROR_EMAIL_REQUIRED
                  ) : remailError === true ? (
                    label_User_management?.ERROR_EMAIL_VALIDATION
                  ) : (
                    <div>&nbsp;</div>
                  )
                ) : (
                  <div>&nbsp;</div>
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <InputLabel
              align="left"
              required={
                props.checkbox ? true : false
              }
            >
              {labels?.MOBILE_NUMBER}
            </InputLabel>

            <PhoneInput
              variant="outlined"
              flags={flags}
              fullWidth
              id="rregisteredCell"
              name="rregisteredCell"
              value={
                props.checkbox
                  ? localStorage.getItem("rmobile") == null
                    ? rmobile
                    : localStorage.getItem("rmobile")
                  : ""
              }
              autoComplete="off"
              placeholder={labels?.REPRESENTATIVE_MOBILE_PLACEHOLDER}
              international
              disabled={
                props.checkbox
                  ? false
                  : !user.checkboxs
              }
              countryCallingCodeEditable={false}
              defaultCountry={rcountryCodeName ? rcountryCodeName : "QA"}
              onChange={handleOnChangeRepPhone}
              limitMaxLength={true}
            />
            <Typography
              variant="caption"
              color="error"
              className="marginleftError"
            >
              {props.checkbox
                ? rmobileRequire === true
                  ? label_User_management?.SIGNUP_CELLNUMBER_REQUIRED
                  : rmobileError === true
                  ? label_User_management?.SIGNUP_CELLNUMBER_NOT_VALID
                  : null
                : null}
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
  )
}

export default ApplicantDetails;