import React, { useState } from "react";
import {
  Typography,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { usageService ,commonService} from "../../../services/index";
import { store } from "../../../helpers/store";
import { CircularDialog } from "../dialog-style";

export default function TransactionRequest(props) {
  const [res, setRes] = useState(null);
  const [openDialog,setOpen]=useState(false);
  const debounce = (fn, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  const btnCall = () => {
    handleClickOpen();
    let array = [];
    localStorage.setItem("paymentType", props.paymentTypeQPAY);
    let billpay = localStorage.getItem("billpay");
    store.getState().snackbar = true;
    if (billpay === "true") {
      array.push({
        billId: props.connectionId,
        Amount: props.amount,
        accountId: props.landingResponse.accountDetails.accountId,
        userName: props.landingResponse.accountDetails.name,
        connectionId: props.landingResponse.accountDetails.connectionId
      });
      localStorage.removeItem("Details");
      localStorage.removeItem("customerName");
      localStorage.removeItem("BillpayDetails");
      localStorage.removeItem("connectionId");
      localStorage.setItem("BillpayDetails", JSON.stringify(array));
      localStorage.setItem("email", props.landingResponse.accountDetails.email);
      localStorage.removeItem("billId");
      localStorage.setItem("billId", props.connectionId);
      localStorage.setItem(
        "mobileNo",
        props.landingResponse.accountDetails.phone
      );
      localStorage.setItem("paymentProcess", "Y");
      usageService
        .prePaymentProcessingBill(
          props.connectionId,
          props.amount,
          props.paymentTypeQPAY,
          props.landingResponse.accountDetails.connectionId
        )
        .then((res) => {
          setRes(res);
          localStorage.removeItem("orderId");
          localStorage.setItem("orderId", res.PUN);
          localStorage.removeItem("paymentProcess");
          res && document.getElementById("frmTransaction").submit();
        })
        .catch((err) => {
          localStorage.removeItem("paymentProcess");
        });
    } else {
      array.push({
        ConnectionId: props.connectionId,
        Amount: props.amount,
      });
      let check = commonService.encodeString(
        JSON.stringify(props.landingResponse)
      );
      localStorage.removeItem("BillpayDetails");
      localStorage.removeItem("billId");
      localStorage.removeItem("Details");
      localStorage.setItem("Details", JSON.stringify(array));
      localStorage.setItem("email", props.emailId);
      localStorage.removeItem("customerName");
      localStorage.setItem("customerName", props.landingResponse.customerName);
      localStorage.removeItem("connectionId");
      localStorage.setItem("connectionId", check);
      localStorage.setItem("mobileNo", props.mobileNo);

      localStorage.setItem("paymentProcess", "Y");
      usageService
        .prePaymentProcessing(
          props.connectionId,
          props.amount,
          props.paymentTypeQPAY
        )
        .then((res) => {
          setRes(res.data.prePaymentProcessing);
          localStorage.removeItem("paymentProcess");
          localStorage.removeItem("orderId");
          localStorage.setItem("orderId", res.data.prePaymentProcessing?.PUN);
          res.data.prePaymentProcessing &&
            document.getElementById("frmTransaction").submit();
        })
        .catch((err) => {
          localStorage.removeItem("paymentProcess");
        });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <div>
      {res && (
        <form
          method="POST"
          action={res.QPayPaymentRedirectUrl} // redirect url
          name="frmTransaction"
          id="frmTransaction"
        >
          <input type="hidden" name="Action" value={res.Action} />
          <input
            type="hidden"
            name="Amount"
            value={res.Amount}
          />
          <input type="hidden" name="BankID" value={res.BankID} />
          <input type="hidden" name="CurrencyCode" value={res.CurrencyCode} />
          <input
            type="hidden"
            name="ExtraFields_f14"
            value={res.ExtraFields_f14}
          />
          <input type="hidden" name="Lang" value={res.Lang} />
          <input type="hidden" name="MerchantID" value={res.MerchantID} />
          <input
            type="hidden"
            name="MerchantModuleSessionID"
            value={res.MerchantModuleSessionID}
          />
          <input type="hidden" name="NationalID" value={res.NationalID} />
          <input type="hidden" name="PUN" value={res.PUN} />
          <input
            type="hidden"
            name="PaymentDescription"
            value={res.PaymentDescription}
          />
          <input type="hidden" name="Quantity" value={res.Quantity} />
          <input
            type="hidden"
            name="TransactionRequestDate"
            value={res.TransactionRequestDate}
          />
          <input type="hidden" name="SecureHash" value={res.SecureHash} />
        </form>
      )}
      <Button
        variant="contained"
        size="medium"
        color="secondary"
        className={props.checkedA ? props.accentButton : null}
        onClick={debounce(btnCall, 2000)}
        disabled={!props.checkedA}
      >
        {props.label.BALANCE_PAGE_DEBIT_CARD}
      </Button>
      <CircularDialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openDialog}
      >
        <DialogTitle id="simple-dialog-title">
          <Typography color="primary" variant="h5">
            <b>{props.label.LOADING_DIALOG_TITLE}</b>
          </Typography>
        </DialogTitle>
        <DialogContent align="center">
          <div className="margin-top-20">
            <CircularProgress color="primary" size={60} />
          </div>
        </DialogContent>
        <DialogActions>
          <Typography className="padding-10">
            {props.label.LOADING_DIALOG_SUBTITLE}
          </Typography>{" "}
        </DialogActions>
      </CircularDialog>
    </div>
  );
}
