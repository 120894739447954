import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { red, green } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) =>
  createStyles({
    primaryColor: theme.palette.primary.main,
    secondaryColor: theme.palette.secondary.main,
    white: theme.palette.whiteColor.main,
    tertiary: theme.palette.tertiary.main,
    themeDirection: theme.direction,
    cardElevation: 2,
    h1Primary: {
      fontSize: "40px !important",
      color: theme.palette.primary.main + " !important",
    },
    h2Primary: {
      fontSize: "20px !important",
      color: theme.palette.primary.main + " !important",
    },
    h3Primary: {
      fontSize: "20px !important",
      color: theme.palette.primary.main + " !important",
    },
    h1Secondary: {
      fontSize: "40px !important",
      color: theme.palette.secondary.main + " !important",
    },
    h2Secondary: {
      fontSize: "20px !important",
      color: theme.palette.secondary.main + " !important",
    },
    h3Secondary: {
      fontSize: "20px !important",
      color: theme.palette.secondary.main + " !important",
    },
    paragraphPrimary: {
      fontSize: "15px !important",
      color: theme.palette.primary.main + " !important",
    },
    paragraphSecondary: {
      fontSize: "15px !important",
      color: theme.palette.secondary.main + " !important",
    },
    labelText: {
      fontSize: "20px !important",
      color: theme.palette.primary.main + " !important",
    },
    primaryButton: {
      background: theme.palette.primary.main + " !important",
      height: "39px",
      width: "155px",
      textAlign: "center !important",
      color: theme.palette.whiteColor.main + " !important",
      borderRadius: "20px !important",
      border: "none",
    },
    secondaryButton: {
      background: theme.palette.secondary.main + " !important",
      height: "39px",
      width: "155px",
      textAlign: "center !important",
      color: theme.palette.whiteColor.main + "  !important",
      borderRadius: "20px !important",
      border: "none",
    },
    centerTitle: {
      display: "flex",
      justifyContent: "center",
    },
    searchBox: {
      background: "#D7DBDF",
      padding: "5px",
      borderRadius: "5px",
      height: "35px",
    },
    primaryBackground: {
      background: theme.palette.primary.main + " !important",
    },
    secondaryBackground: {
      backgroundColor: theme.palette.secondary.main + " !important",
    },
    errorColor: {
      color: theme.palette.error.light,
    },
    whiteColor: {
      color: theme.palette.whiteColor.main + "!important",
    },
    linkColor: {
      color: theme.palette.tertiary.main+ " !important",
    },
    whiteBorder: {
      border: "1px solid " + theme.palette.whiteColor.main,
    },
    secondaryBorder: {
      border: "1px solid " + theme.palette.secondary.main,
    },
    quinaryBackground: {
      background: theme.palette.quinary.main + "!important",
    },
    quaternaryColor: {
      color: theme.palette.quaternary.main,
    },
    lightblueColorBackground: {
      background: theme.palette.quinary.main + "!important",
      padding: "5%",
    },
    accentButton: {
      background: theme.palette.tertiary.main + "!important",
      textAlign: "center !important",
      color: theme.palette.whiteColor.main+" !important",
      border: "none",
    },
    accentButtonDebit: {
      background: theme.palette.tertiary.secondary + "!important",
      textAlign: "center !important",
      color: theme.palette.whiteColor.main+" !important",
      border: "none",
    },
    redcolor:{
      color: red[400] + "!important",
    },
    greenColor:{
      color: green[400] + "!important",
    },
    pddingTabelHeader:{
      padding:"1.5em"
    },
    paddingTopHomepage:{
      paddingTop:"20vh"
    }
  })
);

export const ColorTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.quinary.main,
    color: theme.palette.primary.main,
    maxWidth: 150,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid " + theme.palette.primary.main,
  },
  arrow: {
    color: theme.palette.quinary.main,
  },
}))(Tooltip);
