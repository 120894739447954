export const appConstants = {
  PARAMETER_WIDGET_SUCCESS: "PARAMETER_WIDGET_SUCCESS",
  PARAMETER_WIDGET_FAILURE: "PARAMETER_WIDGET_FAILURE",
  BALANCE_VIEW_SUCCESS: "BALANCE_VIEW_SUCCESS",
  BALANCE_VIEW_FAILURE: "BALANCE_VIEW_FAILURE",
  SNACKBAR_SUCCESS: "SNACKBAR_SUCCESS",
  SNACKBAR_FAILURE: "SNACKBAR_FAILURE",
  APPLOGO_SUCCESS : "APPLOGO_SUCCESS",
  APPLOGO_FAILURE:"APPLOGO_FAILURE",
  ABOUT_IMAGE_SUCCESS:"ABOUT_IMAGE_SUCCESS",
  ABOUT_IMAGE_FAILURE:"ABOUT_IMAGE_FAILURE"
};
