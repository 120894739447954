import { makeStyles, createStyles } from "@material-ui/core/styles";
import { blue, grey,  red, green } from "@material-ui/core/colors";

export const customStyles = makeStyles((theme) =>
  createStyles({
    printSize: {
      width: "100%",
      height: "100%",
      padding: "3%",
    },
    printDisplay:{
      display:"none" 
    },
    greyColor:{
      color:theme.palette.quaternary.main+" !important",
      fontSize: "1em"
    },
    lightblueColor:{
      background:blue[50],
      padding: "10%"
    },
    lightblueColorPayment:{
      background:blue[50],
      padding: "10%",
      marginTop: "10px"
    },
    lightblueColorButton:{
      background:blue[50],
      padding: "10%",
      borderTop: "1px solid",
      borderTopColor:grey[300],
    },
    linkColor:{
      color:theme.palette.tertiary.main+" !important",
    },
    forthButton:{
      backgroundColor:theme.palette.secondary.main + "!important",
      color: theme.palette.whiteColor.main+" !important",
      textAlign: "center !important"
    },
    amountTertiary:{
      fontSize: "1.8rem !important",
      color: theme.palette.tertiary.main + " !important"
    },
    maindivPayment:{
      marginLeft: "10px",
      marginRight: "10px"
    }, 
    h2Primary: {
      fontSize: "large",
    },
    h2PrimaryUpdateTitle: {
      fontSize: "large",
      color: theme.palette.primary.main + " !important"
    },
    h2PrimaryActiveTab: {
      fontSize: "small",
      color: theme.palette.primary.main + " !important"
    },
    h2PrimaryActiveTabWeb:{
      fontSize: "medium",
      color: theme.palette.primary.main + "!important"
    },
    h2PrimaryTabNotSelected: {
      fontSize: "small",
      color:theme.palette.quaternary.main+" !important",
    },
    h2PrimaryTabNotSelectedWeb: {
      fontSize: "medium",
      color:theme.palette.quaternary.main+" !important",
    },
    lightblueColorBackground: {
      background:  theme.palette.quinary.main + " !important",
      padding: "20px 20px",
      borderRadius: "10px",
    },
    h3Primary: {
      fontSize: "large",
      color:theme.palette.quaternary.main+" !important",
    },
    h3PrimaryUpdate: {
      fontSize: "large"
    },
    h3Secondary: {
      fontSize: "large",
      color:theme.palette.secondary.main + " !important",
    },
    h3SecondaryButton: {
      fontSize: "large",
      color:theme.palette.secondary.main + " !important",
      textTransform: "none",
      marginTop: "-6px"
    },
    parentContainer:{
      display: "flex", 
      padding: "7px 5px" 
   },
   parentContainer50:{
    width: "50%"
  },
  parentContainer50Upload:{
    marginLeft: "20px"
  },
  ButtonColor:{
    color: theme.palette.whiteColor.main + " !important",
    textTransform:"capitalize !important"
  },
  fileNames:{
    margin: "10px"
  },
  accentGreyColor: {
    color: theme.palette.quaternary.main + " !important",
  },
  flex100: {     
    display: "flex",
    width: "100%"
  },
    resendButton: {
      background: grey[400] + " !important",
      textAlign: "center !important",
      color: grey[100] + " !important",
      borderRadius: "10px !important",
      border: "none"
    },
    resendButtonActive: {
      background: theme.palette.primary.main + " !important",
      textAlign: "center !important",
      color: grey[100] + " !important",
      borderRadius: "10px !important",
      border: "none"
    },
    secondaryButtonDisable: {
      background: grey[400] + "!important",
      textAlign: "center !important",
      color: "#FFFFFF !important",
      borderRadius: "10px !important",
      border: "none"
    },
    secondaryButton: {
      background: theme.palette.secondary.main + " !important",
      textAlign: "center !important",
      color: "#FFFFFF !important",
      borderRadius: "10px !important",
      border: "none"
    },
    otpInputStyle: {
      margin: "0 50% 25% 1vh",
      fontSize: "2rem",
      border: "2px solid rgba(0,0,0,0.3)",
      textAlign: "center",
      backgroundColor: blue[50],
      marginRight: "10px"
    },
    errorText: {
      display:"inline-block",
      paddingTop:"20px",
      color:red[400] + "!important",
    },
    errorTextOTP: {
      display:"inline-block",
      color:red[400] + "!important",
    },
    countsecs: {
      fontSize:"x-small",
      marginTop:"10px",
      marginLeft:"5px"
    },
    countdowncls:{
      marginTop: "30px", 
      marginLeft:"5px",
      float:"left",
      color:theme.palette.primary.main + " !important",
    },
    btncls:{
      marginTop: "30px"
    },
    sendotpbtn:{
      marginTop: "10px"
    },
    emailUpdateText:{
      color: theme.palette.whiteColor.main + " !important"
    },
    selectHeight:{
      padding:'0.7rem 2rem 0.7rem 0.7rem !important',
    },
    marginLeft:{
      marginRight: "20px",
      width: "300px"
    },
    textFieldsLeft:{
      marginRight: "20px",
      width: "500px",
      marginTop: "10px"
    },
    formControl: {
      width:'100%'
    },
    borderHr:{
      marginTop:"1.3rem !important",
      borderWidth: "3px !important" ,    
    },
    quaternaryBackground:{
      background:theme.palette.quaternary.main +"!important"
    }
    
  })
);

export const useLoadingStyles = makeStyles((theme) =>
createStyles({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
})
);
