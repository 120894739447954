import React, { Component } from "react";
import {
  Typography,
  IconButton,
  DialogActions,
  Button,
  Grid,
  Box,
  AppBar,
  Tab,
  Hidden,
  Tooltip,
  Dialog,
} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { OutlinedTextFiled, AntTabs, authStyles } from "../auth/auth-style";
import "./terms-condition.css";
import PropTypes from "prop-types";
import { adminService } from "../../services/index";
import { store } from "../../helpers/store";
import PrintDownload from "./print-download";
import moment from "moment-timezone";
import { useStyles } from "../app/base-style";
import { useMediaQuery } from "react-responsive";
import { pathActions } from "../../actions/path.actions";
import { connect } from "react-redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.quaternary.main,
  },
  printButton: {
    position: "absolute",
    right: theme.spacing(7.5),
    top: theme.spacing(1),
    color: theme.palette.tertiary.main,
  },
  // downloadButton: {
  //   position: "absolute",
  //   right: theme.spacing(7.5),
  //   top: theme.spacing(1),
  //   color: theme.palette.tertiary.main,
  // },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, data, index, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <Hidden xlUp xsDown>
        <PrintDownload classes={classes} data={data} index={index} />
      </Hidden>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tabpanel--${index}`,
    "aria-controls": `simple-tabpanel--${index}`,
  };
}

export const AntTab = withStyles((theme) => ({
  root: {
    "&$selected": {
      color: theme.palette.tertiary.main,
    },
    "&:focus": {
      color: theme.palette.tertiary.main,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

let labels = "";
let nameMaxLength = "";
let nameMinLength = 3;
let nameRegex = "";

store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
  if (store.getState().parametersWidgets.ParameterLookup) {
    nameMinLength = store.getState().parametersWidgets.ParameterLookup
      .NAME_MIN_LENGTH;
    nameMinLength = Number(nameMinLength);
    nameMaxLength = store.getState().parametersWidgets.ParameterLookup
      .NAME_MAX_LENGTH;
    nameMaxLength = Number(nameMaxLength);
    nameRegex = store.getState().parametersWidgets.ParameterLookup.NAME_REGEX;
  }
});

function TermsCondition(props) {
  const classes = useStyles();
  const customClasses = authStyles();
  const display = useMediaQuery({ query: "(min-width : 500px)" });
  return (
    <TermsConditionMain
      baseStyle={classes}
      customClass={customClasses}
      tablet={display}
      propsMain={props}
    />
  );
}

class TermsConditionMain extends Component {
  constructor(props) {
    super();
    this.state = {
      openDialog: false,
      scroll: "paper",
      name: props.propsMain.username,
      value: 0,
      place: [],
      arabicContent: null,
      englishContent: null,
      nameRequire: false,
      submit: localStorage.getItem("signature") ? true : false,
      table: null,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.keypress = this.keypress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  

  handleClickOpen() {
    this.setState({ openDialog: true });
  }

  handleClose() {
    this.setState({ openDialog: false });
  }

  handleChange(e, newValue) {
    this.setState({
      value: newValue,
    });
  }

  componentWillMount() {
    const rocketContainer = document.querySelector(".rocketchat-container");
    const rocketWidget = document.querySelector(".rocketchat-widget");
    if (rocketContainer && rocketWidget) {
      rocketContainer.style.display = "none";
      rocketWidget.style.display = "none";
    }
    
    adminService.getContentData("ar", "TERMS_AND_CONDITIONS").then((res) => {
      this.setState({
        arabicContent: res.data.getContents.TERMS_AND_CONDITIONS[0].answer,
      });
    });
    adminService.getContentData("en", "ICSA_UPLOAD_DOCUMENTS").then((res) => {
      this.setState({
        uploadDocTermText: res.data.getContents.ICSA_UPLOAD_DOCUMENTS[0].answer,
      });
    });
    adminService.getContentData("en", "TERMS_AND_CONDITIONS").then((res) => {
      this.setState({
        englishContent: res.data.getContents.TERMS_AND_CONDITIONS[0].answer,
      });
    });
    if(this.props.propsMain.depositeAmount>0){
      adminService.getContentData("en", "NEW_SERVICE_AGREEMENT").then((res) => {
        this.setState({
          table: res.data.getContents.NEW_SERVICE_AGREEMENT.find(o => o.contentCode === 'INDIVIDUAL_TERMS_TABLE').answer,
        });
      });
    } else {
      adminService.getContentData("en", "NEW_SERVICE_AGREEMENT").then((res) => {
        this.setState({
          table: res.data.getContents.NEW_SERVICE_AGREEMENT.find(o => o.contentCode === 'VILLAS_TERMS_TABLE').answer,
        });
      });
    }
    let timezone = "";
    let place = [];
    if (store.getState().parametersWidgets.ParameterLookup) {
      timezone = store.getState().parametersWidgets.ParameterLookup.TIMEZONE;
      place = timezone.split("/");
      this.setState({
        place,
      });
    } else {
      store.subscribe(() => {
        if (store.getState().parametersWidgets.ParameterLookup) {
          timezone = store.getState().parametersWidgets.ParameterLookup
            .TIMEZONE;
          place = timezone.split("/");
          this.setState({
            place,
          });
        }
      });
    }
  }
  keypress(event) {
    if (event.key === "Enter") {
      this.handleSubmit(event);
    }
  }

  handleSubmit() {
    if (this.state.name) {
      localStorage.setItem("signature", this.state.name);
      localStorage.setItem("signaturetime", moment().toString())      
      if (this.state.submit === true) {
        this.handleClose();
      }
      if (this.state.nameRequire === false) {
        this.handleClose();
        this.setState({ submit: true });
        this.props.propsMain.getSubmit(true);
      }
    } else {
      this.setState({
        nameRequire: true,
      });
    }
  }
  render() {
    let { name, submit } = this.state;
    var timeZone = moment.tz.guess();
    let timeZoneSplit = timeZone.split("/");

    return (
      <React.Fragment>
        <div className="margin-top-60">
          <Grid
            container
            align="center"
            justify="center"
            className="margin-top-60"
          >
            <Grid item>
              {/* <Typography variant="subtitle1" color="default">
                <h6>
                  <b>
                    Please read and agree to our terms of service before
                    submitting the form
                  </b>{" "}
                </h6>
              </Typography> */}

              <Typography variant="subtitle1" color="default">
                <h6>
                  {this.state.uploadDocTermText && (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: this.state.uploadDocTermText,
                        }}
                      />
                  )}
                </h6>
              </Typography>
              <Button
                onClick={this.handleClickOpen}
                style={{ marginTop: "30px" }}
                className={
                  this.state.submit 
                    ? `textTransform ${this.props.baseStyle.linkColor}`
                    : "textTransform"
                }
                variant={this.state.submit ? "outlined" : "contained"}
                color={this.state.submit ? "tertiary" : "primary"}
                size="large"
                endIcon={
                  this.state.submit ? (
                    <CheckCircleIcon fontSize="small" />
                  ) : (
                    <DescriptionIcon fontSize="small" />
                  )
                }
              >
                <b>
                  {this.state.submit
                    ? labels.TERMS_AND_CONDITIONS_AGREED
                    : labels.READ_TERMS_CONDITION}{" "}
                  &nbsp;
                </b>
              </Button>
              <h6 style={{ paddingTop: "15px" }}>
                <b>
                  {submit
                    ? `by ${this.props.propsMain.username} at ${moment(localStorage.getItem("signaturetime")).format(
                        "hh:mm A"
                      )} ,${moment(localStorage.getItem("signaturetime")).format("ddd,DD MMM YYYY").toString()},${
                        timeZoneSplit[1]
                      } time`
                    : ""}
                </b>
              </h6>
            </Grid>
          </Grid>
        </div>
        {labels && (
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openDialog}
            scroll={this.state.scroll}
            fullScreen={!this.props.tablet}
            id="element"
            maxWidth="md"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
              data={
                this.state.value === 1
                  ? this.state.arabicContent
                  : this.state.englishContent
              }
              index={this.state.value}
            >
              <Typography
                color="primary"
                variant="h5"
                component="h2"
                className="marginDialogTitle"
              >
                <b> {labels.TERMS_CONDITION_TITLE}</b>
              </Typography>
            </DialogTitle>
            <MuiDialogContent dividers={true} className="paddingDialog">
              <AppBar position="static" color="transparent" className="boxTab">
                <AntTabs value={this.state.value} onChange={this.handleChange}>
                  <AntTab
                    className="fontBold"
                    label={labels.TERMS_CONDITION_ENGLISH}
                    {...a11yProps(0)}
                  />
                  <AntTab
                    className="fontBold"
                    label={labels.TERMS_CONDITION_ARABIC}
                    {...a11yProps(1)}
                  />
                </AntTabs>
              </AppBar>
              <div id="content">
                <TabPanel value={this.state.value} index={0}>
                  {this.state.englishContent && (
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: this.state.englishContent,
                      }}
                    />
                  )}
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                  {this.state.arabicContent && (
                    <div dir="rtl">
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: this.state.arabicContent,
                        }}
                        align="right"
                      />
                    </div>
                  )}
                </TabPanel>
                <Typography
                className={this.props.baseStyle.pddingTabelHeader}
                        dangerouslySetInnerHTML={{
                          __html: this.state.table ,
                        }}
                        align="center"
                      />
              </div>
            </MuiDialogContent>
            <DialogActions>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={
                      this.state.nameRequire
                        ? this.props.baseStyle.errorColor
                        : null
                    }
                  >
                    {labels.TERMS_CONDITION_NAME}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid item>
                      <Grid container alignItems="flex-end" spacing={1}>
                        <Grid item>
                          <OutlinedTextFiled
                            variant="outlined"
                            id="nameId"
                            InputProps={{
                              readOnly: true,
                            }}
                            name="name"
                            value={this.props.propsMain.username}
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            className={`timeDateItalic ${this.props.baseStyle.quaternaryColor}`}
                            align="left"
                          >
                            {moment().format("dddd,DD MMM YYYY").toString()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                        {!this.props.tablet && <br />}
                        {submit ? (
                          <Button color="default" variant="contained" disabled>
                            {labels.TERMS_CONDITION_AGREED}
                          </Button>
                        ) : (
                          <Tooltip
                            title={labels.TERMS_CONDITION_BUTTON_TOOLTIP}
                            arrow
                          >
                            <Button
                              onClick={this.handleSubmit}
                              color="primary"
                              variant="contained"
                            >
                              {labels.TERMS_CONDITION_AGREE}
                            </Button>
                          </Tooltip>
                        )}
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

const actionCreators = {
  getSubmit: pathActions.getSubmit,
};

const connectedTermsCondition = connect(null, actionCreators)(TermsCondition);
export { connectedTermsCondition as TermsCondition };
