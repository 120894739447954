import { config } from "../../../enviroment";
const fnCheckout = (callback, srcHref) => {
  const existingScript = document.getElementById("migs");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = srcHref;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export default function HostedCheckout(
  orderId,
  sessionId,
  currencycode,
  merchant,
  merchantName,
  orderInfo,
  amount,
  phoneNumber,
  email,
  country,
  appLogo
) {
  fnCheckout(() => {
    window.Checkout.configure({
      billing: {
        address: {
          country: country,
        },
      },
      customer: {
        mobilePhone: phoneNumber,
        phone: phoneNumber,
        email: email && email != "undefined" ? email : undefined,
      },
      session: {
        id: sessionId,
      },
      merchant: merchant,
      order: {
        amount: function () {
          return amount;
        },
        currency: currencycode,
        description: orderInfo,
        id: orderId,
      },
      interaction: {
        merchant: {
          name: merchantName,
          logo: config.urls.ASSEST_URL_ENDPOINT+"/qc-default-logo.png"
        },
      },
    });
    window.Checkout.showPaymentPage();
  }, config.urls.PAYMENT_URL);
}
