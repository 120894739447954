import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const CircularDialog = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      width: "auto !important",
      height: "auto !important",
    },
  },
}))(Dialog);
