import React, { Component } from "react";
import "../auth/auth.css";
import { Typography, Button, Grid, Link } from "@material-ui/core";
import { userService } from "../../services/index";
import cookie from "react-cookies";
import Alert from "@material-ui/lab/Alert";
import { useStyles, ColorTooltip } from "../app/base-style";
import { store } from "../../helpers";
import { history } from "../../helpers/index";
import { routePaths } from "../config/route-paths";
import { commonService } from "../../services/common.service";
import { useMediaQuery } from "react-responsive";
import { authStyles } from "../auth/auth-style";
import clsx from "clsx";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown-now";
import { connect } from "react-redux";
import { pathActions } from "../../actions";
import ImageBackground from "../../assests/qc-bg.jpg";

let path = "";
let email = "";
let cookieExpiry = "";
let password = "";
let otpCountDown = "";
let otpLength = "";
store.subscribe(() => {
  if (store.getState().parametersWidgets.ParameterLookup) {
    cookieExpiry = store.getState().parametersWidgets.ParameterLookup
      .COOKIE_EXPIRY;
    cookieExpiry = Number(cookieExpiry);
    otpCountDown = store.getState().parametersWidgets.ParameterLookup
      .OTP_COUNTDOWN;
    otpCountDown = Number(otpCountDown);
    otpLength = store.getState().parametersWidgets.ParameterLookup.OTP_LENGTH;
    otpLength = Number(otpLength);
  }
  let mainPath = store.getState().path;
  path = mainPath.path;

  if (path === routePaths.LOGIN) {
    email = mainPath.data.username;
    password = mainPath.data.password;
  } else if (path === routePaths.REGISTER) {
    email = mainPath.data.username;
  } else if (path === routePaths.LOGIN_VIA_OTP) {
    email = mainPath.data.username;
  }
});

let titleDescription;
let lastFourDigit,flowType;
// let isLoginUser=(flowType=='LOGIN_OTPVERIFIED'?true:false)
let labels = {};
store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
});
function AuthOTP(props) {
  const classes = useStyles();
  const baseStyle = authStyles();
  const display = useMediaQuery({ query: "(min-width : 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
  let rightBlue = `split right ${classes.primaryBackground}`;

  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <div>
          <div className="bodyimage" />
          <div className={rightBlue}>
            <AuthOTPMain
              propsMain={props}
              baseStyle={classes}
              display={display}
              authStyle={baseStyle}
            />
          </div>
        </div>
      ) : (
        <React.Fragment>
          <Grid container direction="column" spacing={0}>
            <Grid item className={[classes.primaryBackground,classes.paddingTopHomepage]}>
              <AuthOTPMain
                propsMain={props}
                baseStyle={classes}
                display={display}
                authStyle={baseStyle}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

class AuthOTPMain extends Component {
  constructor(props) {
    if (path) {
      props.propsMain.getLocationPath(path);
    } else {
      props.propsMain.getLocationPath(history.location.pathname);
    }
     lastFourDigit=localStorage.getItem('LastFourDigit');
     flowType=localStorage.getItem('flowType');

    super(props);
    this.state = {
      otp: "",
      otpRequired: false,
      otpError: false,
      error: false,
      send: false,
      otpid: "",
      msg: "",
      otpnotsend: false,
      countDownStart: true,
      btnDisable: false,
      loginMessage: false,
      countNumber: 3,
      loginClick: false,
      submitButton: true,
      isSignupLoading: false,
      countdown: Date.now() + 1000 * otpCountDown,
      isLoginUser: false,
    };
    this.otpBlur = this.otpBlur.bind(this);
    this.toResendOTP = this.toResendOTP.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  componentDidMount() {
    if(flowType==='LOGIN_OTPVERIFIED'){
      this.setState({
        isLoginUser:  true,
      });
      titleDescription=labels.OTP_TITLE_NOTE?.replace('****',lastFourDigit)
    }
  }
  otpBlur() {
    const otp = this.state.otp;
    if (otp) {
      this.setState({
        otpRequired: false,
        error: false,
        otpnotsend: false,
      });
      if (otp.length === otpLength) {
        this.setState({
          otpError: false,
          otpnotsend: false,
        });
      } else {
        this.setState({
          otpError: true,
          otpRequired: false,
          error: false,
          send: false,
          otpnotsend: false,
        });
      }
    } else {
      this.setState({
        otpRequired: true,
        otpError: false,
        error: false,
        send: false,
        otpnotsend: false,
        submitButton: true,
      });
    }
  }

  handleChange(otp) {
    this.setState({
      submitButton: false,
      otpRequired: false,
      otpError: false,
      error: false,
      send: false,
      otpnotsend: false,
      otp,
    });
    if (otp.length < otpLength) {
      this.setState({
        submitButton: true,
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.submitButton === false) {
      this.otpBlur();
      const otp = this.state.otp;
      if (
        otp &&
        this.state.otpRequired === false &&
        this.state.otpError === false &&
        otp.length === otpLength
      ) {
        this.setState({
          isSignupLoading: true,
        });
        let isLoginNewDevice = false;
        const storeState = store.getState();
        const widgets = storeState.parametersWidgets.WidgetLookUp;
        if (widgets) {
          widgets.forEach((widgetItem) => {
            if (
              widgetItem.widgetCode === "LOGIN_OTP_VALIDATION_FOR_NEW_DEVICE" &&
              widgetItem.widgetDefaultValue === "true"
            ) {
              isLoginNewDevice = true;
            }
          });
        }
        if (email) {
          userService
            .validateOTP({ email, otp, password })
            .then((response) => {
              localStorage.removeItem("flowType");
              if (path === routePaths.REGISTER) {
                history.push(routePaths.LANDING + "?flowCode=signupSuccess");
              } else {
                if (isLoginNewDevice === true) {
                  let date = new Date();
                  date.setDate(date.getDate() + cookieExpiry);
                  cookie.save("QC_DSS", "QC_DSS", { path: "/", expires: date });
                }
                localStorage.clear();

                localStorage.setItem(
                  "bearer",
                  commonService.encodeString(JSON.stringify(response.data))
                );
                commonService.gotoMainApp();
              }
              this.setState({
                isSignupLoading: false,
              });
            })
            .catch((error) => {
              if (error && error.response && error.response.status === 500) {
                this.setState({
                  otpnotsend: true,
                  isSignupLoading: false,
                });
              } else if (error.response && error.response.status === 403) {
                history.push(
                  routePaths.LANDING +
                    "?flowCode=userBlockedTemp&min=" +
                    error.response.data.message
                );
              } else {
                this.setState({
                  loginClick: false,
                  error: true,
                  send: false,
                  msg: !this.state.isLoginUser ?error.response.data.message: error.response.data.message?.replace(/OTP/g, 'Verification Code')?.replace('One Time Password','Verification Code'),
                  otpnotsend: false,
                  isSignupLoading: false,
                });
              }
            });
        } else {
          history.push(routePaths.LOGIN);
        }
      }
    }
  }

  countDown = ({ seconds, completed }) => {
    let newAfter = labels.OTP_AFTER_SEC.replace("***", seconds);
    let italicStyle = `italicTypography ${this.props.baseStyle.whiteColor}`;
    if (completed) {
      this.setState({
        countDownStart: false,
      });
      return (
        <React.Fragment>
          {this.state.countNumber > 1 ? (
            <Typography
              className={italicStyle}
              align="center"
              variant="subtitle2"
            >
              {labels.OTP_NOT_RECEIVE}
            </Typography>
          ) : (
            <span></span>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <Typography
          className={this.props.baseStyle.whiteColor}
          align="center"
          variant="subtitle2"
        >
          {newAfter}
        </Typography>
      );
    }
  };

  toResendOTP() {
    const otpid = this.state.otpid;
    this.setState({
      otp: "",
      submitButton: true,
    });
    if (email) {
      userService
        .resendCommonOTP(email, otpid)
        .then((response) => {
          if (response.data.otpid) {
            this.setState({
              otpid: response.data.otpId,
              send: true,
              error: false,
              countdown: Date.now() + 1000 * otpCountDown,
            });
          } else {
            this.setState({
              error: false,
              countDownStart: true,
              countNumber: response.data.count,
              countdown: Date.now() + 1000 * otpCountDown,
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.setState({
              otpnotsend: true,
            });
          } else if (error.response.status === 403) {
            this.setState({
              btnDisable: true,
              error: false,
              loginClick: true,
              msg: error.response.data.message,
            });
          } else {
            this.setState({
              send: false,
              error: true,
              loginClick: false,
              msg: error.response.data.message,
              otpnotsend: false,
            });
          }
        });
    } else {
      history.push(routePaths.LOGIN);
    }
  }

  render() {
    let submitButtonStyle = `btnBorder ${this.props.baseStyle.whiteColor}`;
    let CancelButtonStyle = `btnBorder ${this.props.baseStyle.whiteColor} ${this.props.authStyle.tertiaryButton}`;
    let disableStyle = `btnBorder ${this.props.authStyle.disableButton} ${this.props.baseStyle.whiteColor}`;
    let loadingStyle = `btnBorder ${this.props.authStyle.loadingCursor} ${this.props.baseStyle.whiteColor}`;
    let linkText = `btnLink ${this.props.authStyle.quinaryColor}`;
    let italicStyle = `italicTypography ${this.props.baseStyle.whiteColor}`;

    return (
      <React.Fragment>
        {store.getState().labels.data && (
          <form onSubmit={this.handleSubmit}>
            <br />
            <div className="containermargin">
              <Typography
                className={this.props.baseStyle.whiteColor}
                align="center"
                variant="h4"
              >
                <b>{ !this.state.isLoginUser?  labels.OTP_TITLE: labels.OTP_TITLE_TWO_FACTOR }</b>
              </Typography>

              <Grid
                className="margin-top-20"
                container
                spacing={4}
                direction="column"
              >
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item>
                     { this.state.isLoginUser && (
                        <Typography
                          variant="body1"
                          align="center"
                          className={
                            this.state.otpRequired || this.state.otpError
                              ? this.props.baseStyle.errorColor
                              : this.props.baseStyle.whiteColor
                          }
                        >
                          {titleDescription}
                        </Typography>
                        )
                      }
                      <br />
                      <ColorTooltip
                        title={labels.OTP_HELPTEXT}
                        placement={clsx("", this.props.display && "right-end")}
                      >
                        <Typography
                          variant="h6"
                          align="center"
                          className={
                            this.state.otpRequired || this.state.otpError
                              ? this.props.baseStyle.errorColor
                              : this.props.baseStyle.whiteColor
                          }
                        >
                          { !this.state.isLoginUser? labels.OTP : labels.OTP_SUB_TITLE_TWO_FACTOR}
                        </Typography>
                      </ColorTooltip>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" justify="center">
                        <Grid item>
                          <OtpInput
                            inputStyle="otpInputStyle"
                            onChange={this.handleChange}
                            numInputs={otpLength}
                            separator={<span></span>}
                            shouldAutoFocus
                            value={this.state.otp}
                            isInputNum={true}
                          />
                        </Grid>
                        <Grid item>
                          {this.state.otpRequired && (
                            <Typography color="error" align="center">
                              {labels.OTP_REQUIRED}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item>
                          {this.state.otpError && (
                            <Typography color="error" align="center">
                              {labels.OTP_INVALID}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                direction="row-reverse"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  {this.state.submitButton ? (
                    <Button
                      className={disableStyle}
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      {labels.BUTTON_SUBMIT}
                    </Button>
                  ) : (
                    <Button
                      className={
                        this.state.isSignupLoading
                          ? loadingStyle
                          : submitButtonStyle
                      }
                      type="submit"
                      variant="contained"
                      color="secondary"
                      onClick={
                        this.state.isSignupLoading === false
                          ? this.handleSubmit
                          : null
                      }
                    >
                      {labels.BUTTON_SUBMIT}
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    disabled={
                      this.state.countDownStart || this.state.btnDisable
                    }
                    type="reset"
                    className={CancelButtonStyle}
                    onClick={this.toResendOTP}
                    variant="contained"
                  >
                    {!this.state.isLoginUser? labels.OTP_RESEND_OTP: labels?.OTP_BUTTON_TWO_FACTOR}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row-reverse"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <div className="btnBorder">&nbsp;</div>
                </Grid>

                <Grid item>
                  {this.state.countDownStart && (
                    <Countdown
                      date={this.state.countdown}
                      renderer={this.countDown}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item>
                  {this.state.countDownStart === false &&
                  this.state.error === false &&
                  this.state.send === false &&
                  this.state.btnDisable === false ? (
                    <Typography
                      className={italicStyle}
                      align="center"
                      variant="subtitle2"
                    >
                    {!this.state.isLoginUser ?labels.OTP_NOT_RECEIVE:""} 

                    </Typography>
                  ) : null}
                </Grid>
                <Grid item>
                  {this.state.error === true && (
                    <Alert severity="error">{this.state.msg}</Alert>
                  )}
                  {this.state.otpnotsend === true && (
                    <Alert severity="error">{labels.OTP_TRY_AGAIN_ERROR}</Alert>
                  )}
                  {this.state.send === true && (
                    <Alert severity="success">{labels.OTP_SEND}</Alert>
                  )}
                </Grid>
                <Grid item>
                  {this.state.loginClick &&
                  (path === routePaths.LOGIN || routePaths.LOGIN_VIA_OTP) ? (
                    <Typography
                      className={this.props.baseStyle.whiteColor}
                      align="center"
                      variant="subtitle1"
                    >
                      {labels.OTP_LOGIN_ERROR}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item>
                  {this.state.loginClick && (
                    <Link
                      href={routePaths.LOGIN}
                      className={linkText}
                      underline="hover"
                      variant="h6"
                    >
                      &#10094; {labels.BACK_TO_LOGIN}
                    </Link>
                  )}
                </Grid>
              </Grid>
            </div>
          </form>
        )}
      </React.Fragment>
    );
  }
}

const actionCreators = {
  getLocationPath: pathActions.getLocationPath,
};

const connectedAuthOTP = connect(null, actionCreators)(AuthOTP);
export { connectedAuthOTP as AuthOTP };
