import { userConstants } from "../constants/index";
import { routePaths } from "../components/config/route-paths";

export const pathActions = {
  getPath,
  getLocationPath,
  getSubmit
};

function getPath(path, data, mobile) {
  if (path === routePaths.LOGIN) {
    return (dispatch) => {
      dispatch(login(path, data));
    };
  } else if (path === routePaths.REGISTER) {
    return (dispatch) => {
      dispatch(signUp(path, data));
    };
  } else if (path === routePaths.LOGIN_VIA_OTP) {
    return (dispatch) => {
      dispatch(loginViaOTP(path, data));
    };
  } else {
    return (dispatch) => {
      dispatch(updatePassword(path));
    };
  }

  function login(path) {
    return { type: userConstants.PATH_LOGIN, path: path, data: data };
  }
  function signUp(path, data) {
    return { type: userConstants.PATH_SIGNUP, path: path, data: data };
  }
  function updatePassword(path) {
    return { type: userConstants.PATH_UPDATE_PASSWORD, path: path };
  }
  function loginViaOTP(path) {
    return { type: userConstants.PATH_LOGIN_VIA_OTP, path: path, data: data };
  }
}

function getLocationPath(path) {
  if (path === "/" || path === routePaths.LOGIN || path === (routePaths.LOGIN+"/?logout=true")) {
    return (dispatch) => {
      dispatch(login(path));
    };
  } else if (path === routePaths.REGISTER) {
    return (dispatch) => {
      dispatch(signUp(path));
    };
  } else {
    return (dispatch) => {
      dispatch(other(path));
    };
  }

  function login(path) {
    return { type: userConstants.LOCATION_LOGIN, location: path };
  }
  function signUp(path) {
    return { type: userConstants.LOCATION_SIGNUP, location: path };
  }
  function other(path) {
    return { type: userConstants.LOCATION_OTHER, location: path };
  }
}

 function getSubmit(value){
  return dispatch => {
    dispatch(submitted(value));
  };

  function submitted(value) {
    return { type: userConstants.SUBMIT_SUCCESS, submit:value };
  }
}
