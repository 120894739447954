import { languageConstants } from "../constants/language.constants";

const initialState = {};
export function labels(state = initialState, action) {
  switch (action.type) {
    case languageConstants.LABELS_SUCCESS:
      return {
        ...action.labels,
      };
    default:
      return state;
  }
}
