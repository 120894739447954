import React, { Component } from "react";
import "../auth/auth.css";
import "../app/base-style.css";
import { Typography, Grid, Button } from "@material-ui/core";
import { userService } from "../../services/index";
import Alert from "@material-ui/lab/Alert";
import { store } from "../../helpers";
import { routePaths } from "../config/route-paths";
import { useStyles } from "../app/base-style";
import { history } from "../../helpers/index";
import { CssTextField, authStyles } from "../auth/auth-style";
import { connect } from "react-redux";
import { pathActions } from "../../actions";
import { useMediaQuery } from "react-responsive";
import ImageBackground from "../../assests/qc-bg.jpg";
import { useEffect } from "react";

let labels = {};
store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
});
function Reset(props) {
  const classes = useStyles();
  const authClasses = authStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
  let rightBlue = `split right ${classes.primaryBackground}`;

  let title = "";
  if (props.location.data) {
    title = props.location.data.title;
  } else {
    title = labels.FORGOT_PASSWORD;
  }
  useEffect(() =>{
    const rocketContainer = document.querySelector('.rocketchat-container');
    const rocketWidget = document.querySelector('.rocketchat-widget')
    if(rocketContainer && rocketWidget) {
      rocketContainer.style.display = "none"
      rocketWidget.style.display = "none" 
    }
  })
  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <div>
          <div className="bodyimage" />
          <div className={rightBlue}>
            <ResetMain
              baseStyle={classes}
              authStyle={authClasses}
              propsMain={props}
              title={title}
            />
          </div>
        </div>
      ) : (
        <React.Fragment>
          <Grid container direction="column" spacing={0}>
            <Grid item className={[classes.primaryBackground,classes.paddingTopHomepage]}>
              <ResetMain
                baseStyle={classes}
                authStyle={authClasses}
                propsMain={props}
                title={title}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
class ResetMain extends Component {
  constructor(props) {
    props.propsMain.getLocationPath(history.location.pathname);

    super(props);
    this.state = {
      email: "",
      emailRequired: false,
      emailError: false,
      error: false,
      success: false,
      msg: "",
      isSignupLoading: false,
    };
    this.emailBlur = this.emailBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetSubmit = this.resetSubmit.bind(this);
    this.cancelSubmit = this.cancelSubmit.bind(this);
    this.keypress = this.keypress.bind(this);
  }

  emailBlur() {
    const email = this.state.email;
    if (email) {
      this.setState({
        emailRequired: false,
        error: false,
      });
      if (store.getState().parametersWidgets.ParameterLookup) {
        var emailData = store.getState().parametersWidgets.ParameterLookup
          .EMAIL_REGEX;
        var emailRegEx = new RegExp(emailData);
        var test = emailRegEx.test(email);
        if (test) {
          this.setState({
            emailError: false,
          });
        } else {
          this.setState({
            success: false,
            emailError: true,
            emailRequired: false,
            error: false,
          });
        }
      }
    } else {
      this.setState({
        emailError: false,
        error: false,
        emailRequired: true,
        success: false,
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  resetSubmit(e) {
    e.preventDefault();
    const email = this.state.email;

    this.emailBlur();
    if (
      this.state.emailError === false &&
      this.state.emailRequired === false &&
      email
    ) {
      this.setState({
        isSignupLoading: true,
      });
      userService
        .reset(email.toLocaleLowerCase())
        .then((response) => {
          this.setState({
            success: true,
            error: false,
            isSignupLoading: false,
          });
          history.push(routePaths.LANDING + "?flowCode=signupSuccess");
        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              error: true,
              msg: error.response.data.message,
              success: false,
              isSignupLoading: false,
            });
          }
        });
    }
  }
  cancelSubmit = () => {
    this.setState({
      emailRequired: false,
      emailError: false,
      error: false,
      success: false,
    });
    history.push(routePaths.LOGIN);
  };

  keypress(event) {
    if (event.key === "Enter") {
      this.resetSubmit(event);
    }
  }

  render() {
    const { email } = this.state;
    let CancelButtonStyle = `btnBorder ${this.props.baseStyle.whiteColor} ${this.props.baseStyle.whiteBorder}`;
    let submitButtonStyle = `btnBorder ${this.props.baseStyle.whiteColor}`;
    let loadingStyle = `btnBorder ${this.props.authStyle.loadingCursor} ${this.props.baseStyle.whiteColor}`;

    return (
      <React.Fragment>
        {store.getState().labels.data && (
          <div className="containermargin">
            <br />
            <br />

            <Typography
              className={this.props.baseStyle.whiteColor}
              variant="h4"
              align="center"
            >
              <b>{this.props.title}</b>
            </Typography>
            <br />
            <Typography
              className={this.props.baseStyle.whiteColor}
              variant="body1"
              align="center"
            >
              {labels.FORGOT_PASSWORD_SUBTITLE}
            </Typography>
            <div className="margin-top-50">
              <br />
            </div>

            <Grid container spacing={3} direction="column">
              <Grid item>
                <Typography
                  variant="h6"
                  align="left"
                  className={
                    this.state.emailRequired || this.state.emailError
                      ? this.props.baseStyle.errorColor
                      : this.props.baseStyle.whiteColor
                  }
                >
                  {labels.LOGIN_EMAIL}
                </Typography>
                <CssTextField
                  fullWidth
                  margin="normal"
                  id="email_Id"
                  name="email"
                  value={email.toLocaleLowerCase()}
                  onChange={this.handleChange}
                  onBlur={this.emailBlur}
                  error={this.state.emailRequired || this.state.emailError}
                  onKeyPress={this.keypress}
                  autoComplete="off"
                  helperText={
                    this.state.emailRequired === true
                      ? labels.ERROR_EMAIL_REQUIRED
                      : this.state.emailError === true
                      ? labels.ERROR_EMAIL_VALIDATION
                      : null
                  }
                />
                <br />
              </Grid>
            </Grid>
            <div className="margin-top-50">
              <br />
            </div>
            <Grid
              className="margin-top-50"
              container
              justify="center"
              alignItems="center"
              spacing={2}
              direction="row-reverse"
            >
              <Grid item>
                <Button
                  type="submit"
                  className={
                    this.state.isSignupLoading
                      ? loadingStyle
                      : submitButtonStyle
                  }
                  variant="contained"
                  color="secondary"
                  onClick={
                    this.state.isSignupLoading === false
                      ? this.resetSubmit
                      : null
                  }
                >
                  {labels.BUTTON_SEND_MAIL}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="reset"
                  className={CancelButtonStyle}
                  onClick={this.cancelSubmit}
                  variant="outlined"
                >
                  {labels.BUTTON_CANCEL}
                </Button>
              </Grid>
            </Grid>
            <div className="margin-top-60">
              <br />
              {this.state.success === true && (
                <Alert severity="success">{labels.MAIL_SUCCESS}</Alert>
              )}
              {this.state.error === true && (
                <Alert severity="error">{this.state.msg}</Alert>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const actionCreators = {
  getLocationPath: pathActions.getLocationPath,
};

const connectedReset = connect(null, actionCreators)(Reset);
export { connectedReset as Reset };
