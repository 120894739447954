import axios from "axios";
import { config } from "../enviroment";
import moment from "moment-timezone";
import { store } from "../helpers/store";

export const commonService = {
  encodeString: encodeString,
  decodeString: decodeString,
  getAssestUrl: getAssestWithCode,
  gotoMainApp: gotoMainApp,
  getTimezone: getTimezone,
};

function encodeString(inputString) {
  return inputString;
}

function decodeString(encodedString) {
  return encodedString;
}

async function getAssestWithCode(assestCode) {
  return new Promise((resolve, reject) => {
    axios
      .get(config.urls.ASSEST_CODE_API + assestCode)
      .then(function (response) {
        let returnData = {
          assetCode: response.data.assetCode,
          assetPath: config.urls.ASSEST_URL_ENDPOINT + response.data.assetPath,
          fallBackLabelKey: response.data.fallBackLabelKey,
        };
        resolve(returnData);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function gotoMainApp() {
  localStorage.setItem("switchDirection", "AUTH_TO_MAIN")
  window.location.href = config.urls.MAIN_APP + "/?auth=main";
  // + localStorage.getItem("bearer");
}

function getTimezone(dateTime) {
  var storeState = store.getState();
  if (storeState.parametersWidgets.ParameterLookup) {
    var dateformat =
      storeState.parametersWidgets.ParameterLookup.DATE_TIME_FORMAT;
    var date = moment(dateTime).format(dateformat);
    return date;
  }
}
