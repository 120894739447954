import { languageConstants } from "../constants/language.constants";
import { bearerTokenService } from "../services/bearer.service";
import Cookies from "universal-cookie";

export const bearerActions = {
  getBearer,
};

function getBearer() {
  return (dispatch) => {
    dispatch(request());
    const cookies = new Cookies();
    let authbearer = cookies.get("authbearer");
    if (!authbearer) {
      bearerTokenService
        .getBearerToken()
        .then((successBearer) => {
          dispatch(success(successBearer));
        })
        .catch((error) => {
          dispatch(failure(error));
        });
    }
  };

  function request() {
    return { type: languageConstants.BEARER_REQUEST };
  }
  function success(labels) {
    return { type: languageConstants.BEARER_SUCCESS };
  }
  function failure(error) {
    return { type: languageConstants.BEARER_FAILURE };
  }
}
