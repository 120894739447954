import React,{useState,useEffect} from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";

export default function PaymentComponent(props) {
  const label = useSelector((state)=>state?.labels?.data?.getLabels);
  const [labels, setlabels] = useState();
  const [billingAddressLabel, setbillingAddressLabel] = useState()
    useEffect(() => {
      setlabels(label?.USER_MANAGEMENT)
      setbillingAddressLabel(label?.PAYMENT_CONFIRMATION.PAYMENT_BILLING_ADDRESS)
  }, [label])
  const getErrorMsg = (errorCode) => {
    if(errorCode == "ORDER_NOT_FOUND") {
      return "Your order does not found";
    } else if(errorCode == "PROCESSING" && props?.paymentType == "MiGS_Gateway") {
      return "Your payment has being processed, We will try to process your transaction in a while";
    } else if(errorCode == "INVALID_GATEWAY_CODE") {
      return `Invalid Gateway Code${props?.paymentType == "MiGS_Gateway" ? ", Please check your balance after sometime" : ""}`;
    } else if(errorCode == "ORDER_ID_MISSING") {
      return `Order id is missing${props?.paymentType == "MiGS_Gateway" ? ", Please check your balance after sometime" : ""}`;
    } else {  
      return `Error occurred: ${props?.paymentType == "MiGS_Gateway" ? "We will try to process your transaction in a while" : "Transaction not successful"}`;
    }
  }
  return (
    <React.Fragment>
      <Typography component="h1" variant="h5" color="primary">
        {props?.error || props?.mainResponse &&
        props?.mainResponse?.data &&
        props?.mainResponse?.data?.postPaymentProcessing &&
        props?.mainResponse?.data?.postPaymentProcessing?.genericErrorInformation?.isInError === "true"|| props?.paymentInfo[0]?.errorInformation?.isInError === "true" ? (
          <b>{labels?.QUICK_PAY_PAYMENT_FAILED}</b>
        ) : (
          <b>{labels?.QUICK_PAY_PAYMENT_SUCCESS}</b>
        )}
      </Typography>
      <br />
      <Grid container direction="row">
        <Grid item lg={2} sm={4} xs={6}>
          <Typography variant="body1">
            <b>
              {props.isBillpay
                ? labels?.BILL_PAY_BILL_ID
                : labels?.QUICK_PAY_CONNECTION_DETAILS}
            </b>
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="body1">{props?.connectionId}</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" className="margin-top-10">
        <Grid item lg={2} sm={4} xs={6}>
          <Typography variant="body1">
            {props?.error ? (
              <b>{labels?.QUICK_PAY_ERROR_DETAILS}</b>
            ) : (
              <b>
                {" "}
                {props?.mainResponse &&
                props?.mainResponse.data &&
                props?.mainResponse?.data?.postPaymentProcessing &&
                props?.mainResponse?.data?.postPaymentProcessing?.genericErrorInformation.isInError === "true"
                  || props.paymentInfo[0]?.errorInformation?.isInError === "true"
                  ? labels?.QUICK_PAY_ERROR_AMOUNT
                  : labels?.QUICK_PAY_AMOUNT_PAID}
              </b>
            )}
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="body1">
            {props?.error ? (
              getErrorMsg(props.error)
            ) : (
              <React.Fragment>
                {props?.paymentInfo[0]?.paymentInfo?.input?.currency} &nbsp;
                {Number(parseFloat(props?.paymentInfo[0]?.paymentInfo?.input?.paymentAmount).toFixed(2)).toLocaleString('en', {
                                      minimumFractionDigits: 2
                                  })} 
              </React.Fragment>
            )}
          </Typography>
        </Grid>
      </Grid>
      {console.log(props.paymentInfo[0])}
      {props?.mainResponse &&
      props?.mainResponse?.data &&
      props?.mainResponse?.data?.postPaymentProcessing &&
      props?.mainResponse?.data?.postPaymentProcessing?.genericErrorInformation?.isInError === "true" ||
         props?.paymentInfo[0]?.errorInformation?.isInError === "true" ? null : props?.paymentInfo &&
        props?.paymentInfo[0]?.paymentInfo.output &&
        props?.paymentInfo[0]?.paymentInfo?.output?.customOutput &&
        props?.paymentInfo[0]?.paymentInfo?.output?.customOutput?.field1 ? (
        <Grid container direction="row" className="margin-top-10">
          <Grid item lg={2} sm={4} xs={6}>
            <Typography variant="body1">
              <b>{labels?.QUICK_PAY_PAYMENT_NUMBER}</b>
            </Typography>
          </Grid>
          <Grid item sm={6} xs={6}>
            <Typography variant="body1">
            {props.paymentInfo[0].paymentInfo.inputOutput.paymentEventId }{" "}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      <Grid container direction="row" className="margin-top-10">
        <Grid item lg={2} sm={4} xs={6}>
          <Typography variant="body1">
            <b>{labels?.QUICK_PAY_PAYMENT_TYPE}</b>
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="body1">
            {props?.error ? props?.paymentType : props?.paymentInfo[0]?.paymentInfo?.input?.paymentType}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" className="margin-top-10">
        <Grid item lg={2} sm={4} xs={6}>
          <Typography variant="body1">
            <b>{!props.error && labels?.QUICK_PAY_TRANSACTION_DATE}</b>
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="body1">
            {!props.error &&
              props.paymentInfo[0].paymentInfo.input.paymentDateTime}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" className="margin-top-10">
        <Grid item lg={2} sm={4} xs={6}>
          <Typography variant="body1">
            <b>{!props.error && labels?.QUCIK_PAY_CREDIT_CARD_TYPE}</b>
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="body1">
            {!props.error &&
              props.paymentInfo[0].paymentInfo.input.creditCardInfo.cardType}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" className="margin-top-10">
        <Grid item lg={2} sm={4} xs={6}>
          <Typography variant="body1">
            <b>{!props.error && labels?.QUICK_PAY_CREDIT_CARD_NUMBER}</b>
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="body1">
            {!props.error &&
              props.paymentInfo[0].paymentInfo.input.creditCardInfo.cardNumber}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" className="margin-top-10">
        <Grid item lg={2} sm={4} xs={6}>
          <Typography variant="body1">
            <b>{!props.error && billingAddressLabel}</b>
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="body1">
            {!props.error &&
              props.paymentInfo[0].billingAddress}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" className="margin-top-10">
        <Grid item lg={2} sm={4} xs={6}>
          <Typography variant="body1">
            <b>{!props?.error && "Expire Month / Expire Year"}</b>
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="body1">
            {!props?.error &&
              props?.paymentInfo[0]?.paymentInfo?.input?.creditCardInfo?.expMonth +
                "/" +
                props?.paymentInfo[0]?.paymentInfo?.input?.creditCardInfo?.expYear}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" className="margin-top-10">
        <Grid item lg={2} sm={4} xs={6}>
          <Typography variant="body1">
            {props?.mainResponse &&
            props?.mainResponse?.data &&
            props?.mainResponse?.data?.postPaymentProcessing &&
            props?.mainResponse?.data?.postPaymentProcessing?.genericErrorInformation.isInError === "true" ||
               props?.paymentInfo[0]?.errorInformation?.isInError === "true" ? (
              <b>{"Error Desc"}</b>
            ) : null}
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="body1">
            {props?.mainResponse &&
            props?.mainResponse?.data &&
            props?.mainResponse?.data?.postPaymentProcessing &&
            props?.mainResponse?.data?.postPaymentProcessing?.genericErrorInformation.isInError === "true"
              ? props?.mainResponse?.data?.postPaymentProcessing?.genericErrorInformation.error
              :  props?.paymentInfo[0]?.errorInformation?.isInError === "true"? 
              props?.paymentInfo[0]?.errorInformation?.errorMessage
              :null}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
