import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { App } from "./components/app/app";
import * as serviceWorker from "./service-worker";
import { Provider } from "react-redux";
import { store } from "./helpers";
import Favicon from 'react-favicon';
import faviconIcon from "./assests/icon-QC.png";
import ErrorBoundary from "./components/layout/error-boundary";

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store} >
      <Favicon url={faviconIcon} />
      <App store={store}/>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
