import React, { Component, useEffect, useState } from "react";
import "../../auth/auth.css";
import "../../app/base-style.css";
import { useStyles } from "../../app/base-style";
import { authStyles } from "../../auth/auth-style";
import { Grid,Card, CardContent } from "@material-ui/core";
import "../quick-pay.css";
import { useMediaQuery } from "react-responsive";
import { QuickPayLanding } from "../quick-pay-landing";
import { adminService } from "../../../services/index";
import { Skeleton } from "@material-ui/lab";
import { QuickPayCommonHeader } from "../quick-pay-header";
import { bearerTokenService } from "../../../services/bearer.service";

let billId = new URLSearchParams(window.location.search).get("bill-id");
export default function BillPay(props) {
  const classes = useStyles();
  const authClasses = authStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 960px)" });
  let quinaryBackgroundBlur = `rightSecondaryBack landingHeight ${classes.quinaryBackground}`;
  let primaryBackgroundBlur = `primaryBack ${classes.primaryBackground} `;
  useEffect(() => {
    const rocketContainer = document.querySelector('.rocketchat-container');
    const rocketWidget = document.querySelector('.rocketchat-widget')
    if(rocketContainer && rocketWidget) {
      rocketContainer.style.display = "none"
      rocketWidget.style.display = "none" 
    }
  }) 

  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <React.Fragment>
          <Grid container direction="column">
            <Grid item>
              <div className={primaryBackgroundBlur} />
            </Grid>
            <Grid item>
              <div className={quinaryBackgroundBlur} />
            </Grid>
          </Grid>
          <Card className="stack-top">
            <BillPayMain
              baseStyle={classes}
              authStyle={authClasses}
              propsMain={props}              
            />
          </Card>
        </React.Fragment>
      ) : (
        <div className="margin-top-60">
          <BillPayMain
            baseStyle={classes}
            authStyle={authClasses}
            propsMain={props}            
          />
        </div>
      )}
    </React.Fragment>
  );
}
class BillPayMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      labelSubTitle: "",
      lastNote: "",
      directRedirection:false,
    };
  }

  redirection = () => {
     this.setState({directRedirection:true})
  }

  async componentDidMount() {
    // if(!localStorage.getItem("authbearer")){
      if(localStorage.getItem("authbearer")){
        await this.callAdmin();                          
      }
      else{
        await bearerTokenService
      .getBearerToken()
      .then(async (successBearer) => {          
       await this.callAdmin();                        
      })
      .catch((error) => {
        
      });
    }
  }
  
async callAdmin(){
  await adminService.getContentData("en", "USER_MANAGEMENT").then((res) => {
    this.setState({
      data: res.data.getContents.USER_MANAGEMENT,
    });
    res.data.getContents.USER_MANAGEMENT.forEach((item) => {
      if (item.contentCode === "QUICK_PAY_LAST_PARAGRAPH") {
        this.setState({
          lastNote: item.answer,
        });
      } else if (item.contentCode === "BILL_PAY_SUBTITLE") {
        this.setState({
          labelSubTitle: item.answer,
        });
      }
    });
  });  
}

  render() {
    let { labelSubTitle, lastNote, data } = this.state;
    billId = new URLSearchParams(window.location.search).get("bill-id")
    return (
      <React.Fragment>
        <CardContent>
          {labelSubTitle && (!billId || this.state.directRedirection) ? (
            <QuickPayCommonHeader
              labelSubTitle={labelSubTitle}
              baseStyle={this.props.baseStyle}
              billId={billId}
              billpay="true"              
            />
          ) : (
            <Skeleton />
          )}
          {data ? (
            <QuickPayLanding
              baseStyle={this.props.baseStyle}
              lastNote={lastNote}
              data={data}
              authStyle={this.props.authStyle}
              billId={billId}
              billpay={true}
              redirection={this.redirection}
            />
          ) : (
            <Skeleton variant="text" />
          )}
        </CardContent>
      </React.Fragment>
    );
  }
}
