import { config } from "../../enviroment";
import axios from "axios";

export const getConnectionService = {
  getState,
  getAddress4,
  getAddress3,
  getAddress2,
  getAddress1,
};
async function getState() {
  let APIquery = `query {
    getState(
        input: {
            tenantCode: "${config.parameters.TENANT_CODE}"
        }
      ) 
    }`;
  return await axiosRequest(APIquery, "getState");
}
async function getAddress4({ state }) {
  let APIquery = `query {
    getAddress4(
        input: {
            tenantCode: "${config.parameters.TENANT_CODE}"
            state: "${state}"
        }
      ) 
    }`;
  return await axiosRequest(APIquery, "getAddress4");
}
async function getAddress3({ state, address4 }) {
  let APIquery = `query {
    getAddress3(
        input: {
            tenantCode: "${config.parameters.TENANT_CODE}"
            state: "${state}"
            address4: "${address4}"
        }
      ) 
    }`;
  return await axiosRequest(APIquery, "getAddress3");
}
async function getAddress2({ state, address4, address3 }) {
  let APIquery = `query {
    getAddress2(
        input: {
            tenantCode: "${config.parameters.TENANT_CODE}"
            state: "${state}"
            address4: "${address4}"
            address3: "${address3}"
        }
      ) 
    }`;
  return await axiosRequest(APIquery, "getAddress2");
}
async function getAddress1({ state, address4, address3, address2 }) {
  let APIquery = `query {
    getAddress1(
        input: {
            tenantCode: "${config.parameters.TENANT_CODE}"
            state: "${state}"
            address4: "${address4}"
            address3: "${address3}"
            address2: "${address2}"
        }
      ) 
    }`;
  return await axiosRequest(APIquery, "getAddress1");
}
async function axiosRequest(APIquery, queryName) {
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function (response) {
        resolve(response?.data?.data?.[queryName]);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
