import React, { Component } from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { store } from "../../helpers";
import { useStyles } from "../app/base-style";
import { CssTextField, authStyles } from "../auth/auth-style";
import Alert from "@material-ui/lab/Alert";
import { routePaths } from "../config/route-paths";
import { history } from "../../helpers/index";
import { connect } from "react-redux";
import { pathActions } from "../../actions";
import { userService } from "../../services/index";
import { useMediaQuery } from "react-responsive";
import ImageBackground from "../../assests/qc-bg.jpg";

let labels = {};
let usernameLength = "";

store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
  if (store.getState().parametersWidgets.ParameterLookup) {
    usernameLength = store.getState().parametersWidgets.ParameterLookup
      .USERNAME_LENGTH;
    usernameLength = Number(usernameLength);
  }
});

function LoginViaOTP(props) {
  const classes = useStyles();
  const authClasses = authStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
  let rightBlue = `split right ${classes.primaryBackground}`;

  let username = "";
  if (props.location.data) {
    username = props.location.data.username;
  }
  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <div>
          <div className="bodyimage" />
          <div className={rightBlue}>
            <LoginViaOTPMain
              baseStyle={classes}
              propsMain={props}
              username={username}
              authStyle={authClasses}
            />
          </div>
        </div>
      ) : (
        <React.Fragment>
          <Grid container direction="column" spacing={0}>
            <Grid item className={[classes.primaryBackground,classes.paddingTopHomepage]}>
              <LoginViaOTPMain
                baseStyle={classes}
                propsMain={props}
                username={username}
                authStyle={authClasses}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

class LoginViaOTPMain extends Component {
  constructor(props) {
    props.propsMain.getLocationPath(routePaths.LOGIN);
    super(props);
    this.state = {
      username: props.username,
      userRequire: false,
      userError: false,
      error: false,
      msg: null,
      isSignupLoading: false,
      errorCode: null,
    };
    this.keypress = this.keypress.bind(this);
    this.loginOTPSubmit = this.loginOTPSubmit.bind(this);
    this.usernameBlur = this.usernameBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toResetPassword = this.toResetPassword.bind(this);
    this.submit = this.submit.bind(this);
  }

  usernameBlur() {
    const { username } = this.state;
    if (username) {
      this.setState({
        userRequire: false,
        error: false,
      });
      if (store.getState().parametersWidgets.ParameterLookup) {
        var usernameData = store.getState().parametersWidgets.ParameterLookup
          .USERNAME;
        var usernameRegEx = new RegExp(usernameData);
        var test = usernameRegEx.test(username);
        if (test) {
          this.setState({ userError: false });
        } else {
          this.setState({ userError: true });
        }
      }
    } else {
      this.setState({
        userRequire: true,
        userError: false,
        error: false,
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    if (name === "username") {
      const re = /^[a-zA-Z0-9]+$/;
      // if (e.target.value === "" || re.test(e.target.value)) {
      if (e.target.value === "" || (e.target.value)) {
        this.setState({
          [name]: value.toLowerCase().trim(),
        });
      }
    } else {
      this.setState({ [name]: value });
    }
  }

  submit() {
    this.usernameBlur();
    const username = this.state.username;
    if (username) {
      if (this.state.userError === false && this.state.userRequire === false) {
        this.setState({
          isSignupLoading: true,
        });
        userService
          .resendEmail(username)
          .then((response) => {
            this.setState({
              isSignupLoading: false,
            });
            history.push(routePaths.LANDING + "?flowCode=signupSuccess");
          })
          .catch((error) => {
            this.setState({
              error: true,
            });
          });
      }
    }
  }


  loginOTPSubmit() {
    this.usernameBlur();

    const username = this.state.username;
    if (username) {
      if (this.state.userError === false && this.state.userRequire === false) {
        this.setState({
          isSignupLoading: true,
        });
        userService
          .loginViaOTP(username)
          .then((response) => {
            localStorage.removeItem("flowType");

            localStorage.setItem("flowType", "OTP_LOGIN");
            let res = { username: username };
            this.props.propsMain.getPath(routePaths.LOGIN, res);
            history.push(routePaths.AUTH_OTP);
            this.setState({
              isSignupLoading: false,
            });
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data.statusCode === 403 &&
              error.response.data.message
            ) {
              let errMessage, errMessageCode, terminationDate;
              errMessage = error.response.data.message;
              if (errMessage.indexOf("|") > 0) {
                errMessageCode = errMessage.split("|")[0];
                terminationDate = errMessage.split("|")[1];
                if (errMessageCode === "ACCOUNT_TERMINATED_INVALID_SA") {
                  history.push(
                    routePaths.LANDING +
                      "?flowCode=userAccountDeactivated&terminationDate=" +
                      terminationDate
                  );
                } else {
                  history.push(
                    routePaths.LANDING +
                      "?flowCode=userBlockedTemp&min=" +
                      error.response.data.message
                  );
                }
              } else {
                history.push(
                  routePaths.LANDING +
                    "?flowCode=userBlockedTemp&min=" +
                    error.response.data.message
                );
              }
            } else {
              this.setState({
                error: true,
                msg: error.response.data.message,
                errorCode: error.response.data.statusCode,
                isSignupLoading: false,
              });
            }
          });
      }
    }
  }

  keypress(event) {
    if (event.key === "Enter") {
      this.loginOTPSubmit(event);
    }
  }

  toResetPassword(e) {
    e.preventDefault();
    history.push(routePaths.RESET_REQUEST);
  }

  render() {
    let linkText = `lblForget ${this.props.authStyle.quinaryColor}`;
    let loadingStyle = `btnBorder ${this.props.authStyle.loadingCursor} ${this.props.baseStyle.whiteColor}`;
    let submitButtonStyle = `btnBorder ${this.props.baseStyle.whiteColor}`;
    let username = this.state.username;
    let loginLink = `btnLink ${this.props.baseStyle.whiteColor}`;
    let emailLink = `link ${this.props.baseStyle.whiteColor}`;
    let CancelButtonStyle = `btnBorder ${this.props.baseStyle.whiteColor} ${this.props.authStyle.tertiaryButton}`;

    return (
      <React.Fragment>
        {store.getState().labels.data && (
          <div className="containermargin">
            <br />
            <Typography
              variant="h4"
              align="center"
              className={this.props.baseStyle.whiteColor}
            >
              <b>{labels.LOGIN_TITLE}</b>
            </Typography>
            <div className="margin-top-50">
              <br />
              <br />
            </div>

            <Grid container spacing={1} direction="column">
              <Grid item>
                <Typography
                  variant="h6"
                  align="left"
                  className={
                    this.state.userRequire || this.state.userError
                      ? this.props.baseStyle.errorColor
                      : this.props.baseStyle.whiteColor
                  }
                >
                  {labels.SIGNUP_USERNAME_LABEL}
                </Typography>
                <CssTextField
                  fullWidth
                  id="Username_Id"
                  name="username"
                  value={username}
                  onChange={this.handleChange}
                  onBlur={this.usernameBlur}
                  error={this.state.userRequire || this.state.userError}
                  autoComplete="off"
                  inputProps={{ maxLength: usernameLength }}
                  onKeyPress={this.keypress}
                  helperText={
                    this.state.userRequire === true
                      ? labels.SIGNUP_USERNAME_REQUIRED
                      : this.state.userError === true
                      ? labels.SIGNUP_USERNAME_NOT_VALID
                      : null
                  }
                />
              </Grid>
              <Grid item>
                <Link
                  to={{
                    pathname: routePaths.RESET_REQUEST,
                    data: {
                      title: labels.FORGOT_YOUR_USERNAME,
                    },
                  }}
                  className={linkText}
                  align="left"
                >
                  {labels.LOGIN_FORGOT_USERNAME}
                </Link>
              </Grid>
            </Grid>
            <div className="margin-top-50">
              <br />
            </div>
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              spacing={2}
              direction="row"
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={
                    this.state.isSignupLoading
                      ? loadingStyle
                      : submitButtonStyle
                  }
                  onClick={
                    this.state.isSignupLoading === false
                      ? this.loginOTPSubmit
                      : null
                  }
                >
                  {labels.LOGIN_SEND_OTP}
                </Button>
              </Grid>
            </Grid>
            <br />
            <div className="margin-top-20">
              {this.state.error === false ? (
                <Typography
                  className={this.props.baseStyle.whiteColor}
                  align="center"
                  variant="subtitle1"
                >
                  {labels.LOGIN_SEND_OTP_LAST_HELPER}
                </Typography>
              ) : (
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={3}
                  direction="column"
                >
                  <Grid item>
                    <Alert severity="error">{this.state.msg}</Alert>
                  </Grid>
                  {this.state.errorCode && this.state.errorCode == 400 && this.state.msg == labels.LOGIN_EMAIL_ERROR ? (
                    <Grid item>
                      <Button
                        onClick={this.submit}
                        className={CancelButtonStyle}
                        variant="h6"
                      >
                        {" "}
                        <Typography variant="h6">
                          {labels.LOGIN_RESEND_EMAIL}
                        </Typography>
                      </Button>
                    </Grid>
                  ) : null}
                  <Grid item>
                    <Link
                      to={routePaths.LOGIN}
                      className={loginLink}
                      variant="h6"
                    >
                      {" "}
                      <Typography variant="h6">
                        &#10094; {labels.BACK_TO_LOGIN}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const actionCreators = {
  getPath: pathActions.getPath,
  getLocationPath: pathActions.getLocationPath,
};

const connectedLoginViaOTP = connect(null, actionCreators)(LoginViaOTP);
export { connectedLoginViaOTP as LoginViaOTP };
