import React, { Component } from "react";
import { Typography, Grid, Hidden } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { store } from "../../helpers";
import { connect } from "react-redux";
import { pathActions } from "../../actions";
import { routePaths } from "../config/route-paths";

let labels = "";
if (store.getState().labels.data) {
  labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
} else {
  store.subscribe(() => {
    if (store.getState().labels.data) {
      labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
    }
  });
}

export default class QuickPayCommonHeader extends Component {
  constructor(props) {
    props.getLocationPath(routePaths.QUICK_PAY);
    super(props);
    this.state = {
      open: false,
    };
    this.handleClicked = this.handleClicked.bind(this);
  }
  handleClicked() {
    if (this.state.open === true) {
      this.setState({
        open: false,
      });
    } else {
      this.setState({
        open: true,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.printPage ? (
          <React.Fragment>
            <Typography
              color="primary"
              variant="h4"
              component="h6"
              align="center"
              className="margin-top-10 paddingContainer"
            >
              <b>
                {this.props.billpay && this.props.billpay === "true"
                  ? labels.BILL_PAY_TITLE
                  : labels.QUICK_PAY_TITLE}
              </b>
            </Typography>
            <br />
            {this.props.labelSubTitle && (
              <div
                className={this.props.baseStyle.quaternaryColor}
                dangerouslySetInnerHTML={{
                  __html: this.props.labelSubTitle,
                }}
              ></div>
            )}
            <hr />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Hidden xlUp xsDown>
              <Typography
                color="primary"
                variant="h4"
                component="h6"
                align="center"
                className="margin-top-10"
              >
                <b>
                  {this.props.billpay && this.props.billpay === "true"
                    ? labels.BILL_PAY_TITLE
                    : labels.QUICK_PAY_TITLE}
                </b>
              </Typography>
            </Hidden>
            <Hidden smUp>
              <Grid
                container
                direction="row"
                justify="flex-start"
                spacing={2}
                className="margin-top-10"
              >
                <Grid item>
                  <Typography
                    color="primary"
                    variant="h4"
                    component="h6"
                    align="center"
                  >
                    <b>
                      {this.props.billpay && this.props.billpay === "true"
                        ? labels.BILL_PAY_TITLE
                        : labels.QUICK_PAY_TITLE}
                    </b>
                    &nbsp;
                    <Link onClick={this.handleClicked}>
                      {this.state.open ? (
                        <span
                          className={`dividerCenterCss ${this.props.baseStyle.linkColor}`}
                        >
                          {labels.QUICK_PAY_HIDE} <ExpandLess />
                        </span>
                      ) : (
                        <span
                          className={`dividerCenterCss ${this.props.baseStyle.linkColor}`}
                        >
                          {labels.QUICK_PAY_DETAILS} <ExpandMore />
                        </span>
                      )}
                    </Link>
                  </Typography>{" "}
                </Grid>
              </Grid>{" "}
            </Hidden>
            {this.state.open && (
              <React.Fragment>
                <hr />
                <div
                  className={this.props.baseStyle.quaternaryColor}
                  dangerouslySetInnerHTML={{
                    __html: this.props.labelSubTitle,
                  }}
                ></div>
              </React.Fragment>
            )}
            <Hidden smUp>
              <hr />
            </Hidden>
            <Hidden xlUp xsDown>
              <br />
              {this.props.labelSubTitle && (
                <div
                  className={this.props.baseStyle.quaternaryColor}
                  dangerouslySetInnerHTML={{
                    __html: this.props.labelSubTitle,
                  }}
                ></div>
              )}
              <hr />
            </Hidden>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const actionCreators = {
  getLocationPath: pathActions.getLocationPath,
};

const connectedQuickPay = connect(null, actionCreators)(QuickPayCommonHeader);
export { connectedQuickPay as QuickPayCommonHeader };
