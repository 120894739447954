import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { white } from "material-ui/styles/colors";
import OtpInput from "react-otp-input";
import { Button, makeStyles, Typography } from '@material-ui/core';
import MuiLink from "@material-ui/core/Link";
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import Countdown from "react-countdown-now";
import Alert from "@material-ui/lab/Alert";
import { adminService } from "../../../../services/admin.service";
import {ticketService} from "../service/ticket-service";
import { CircularProgress } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    popupTitle : {
      backgroundColor: theme.palette.primary.main,
      color: white,
    },
    circularLoading:{
      marginLeft: 5
    }
  }));

  const Link = withStyles({
    root: {
      "&[disabled]": {
        color: "grey",
        cursor: "default",
        "&:hover": {
          textDecoration: "none"
        }
      }
    }
  })(MuiLink);

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: white,
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    const classestitle = useStyle();
  
    return (
      <MuiDialogTitle disableTypography className={[classes.root,classestitle.popupTitle]} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);



function OtpVerification({openOtpVerification,setVerifyVisibility,setOpenOtpVerification,mobile,setIsMobileVerified}) {
    const labels_Upfront_Payment = useSelector((state)=>state?.labels?.data?.getLabels?.UPFRONT_PAYMENT);
      const classes = useStyle();

    let otpCountDown = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.OTP_COUNTDOWN);
    otpCountDown = Number(otpCountDown);
    let otpLength = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.OTP_LENGTH);
    otpLength = Number(otpLength);
    let invalidCountNumber = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.NO_OF_INVALID_ATTEMPTS_OTP_FLOW);
    invalidCountNumber = Number(invalidCountNumber);

    const labels = useSelector((state)=>state?.labels?.data?.getLabels?.USER_MANAGEMENT);

    const [otp, setOtp] = useState("");
    const [otpRequired, setOtpRequired] = useState(false);
    const [error, setError] = useState(false);
    const [msg, setMsg] = useState("");
    const [countDownStart, setCountDownStart] = useState(false);
    const [btnDisable, setBtnDisable] = useState(true);
    const [countNumber, setCountNumber] = useState(invalidCountNumber);
    const [countdown, setCountdown] = useState();
    const [resendLinkDisable, setResendLinkDisable] = useState(true);
    const [otpnotsend, setOtpnotsend] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [otpMAxLimitError, setOtpMAxLimitError] = useState(false);
    const [otpErrorMsg, setOtpErrorMsg] = useState("");
    const [otpUserNotFound, setOtpUserNotFound] = useState(false);  
    const [loading, setLoading] = useState(false);
    const log = (logObj) => {
      adminService.log(logObj).then((res) => {
      });
    }
    const handleChange = (otp) => {
        setOtpRequired(false);
        setOtpError(false);
        setError(false);
        setOtpnotsend(false);
        setOtp(otp);

        if (otp.length < otpLength) {
            setBtnDisable(true);
        }
        else if(otp.length == otpLength){
            setBtnDisable(false);
        }
      }

    const countDown = ({ seconds, completed }) => {
        let newAfter = labels.OTP_AFTER_SEC.replace("***", seconds);
        if (completed) {
        setCountDownStart(false);
        setResendLinkDisable(false);
          return (
            <React.Fragment>
              {countNumber > 1 ? (
                <Typography
                  align="center"
                  variant="subtitle2"
                >
                  {labels.OTP_NOT_RECEIVE}
                </Typography>
              ) : (
                <span></span>
              )}
            </React.Fragment>
          );
        } else {
          return (
            <Typography
              align="center"
              variant="subtitle2"
            >
              {newAfter}
            </Typography>
          );
        }
      };

    useEffect(() => {
      if(openOtpVerification){
          setCountdown(Date.now() + 1000 * otpCountDown);
          setCountDownStart(true);
      }
    }, [openOtpVerification])
    

    const handleSubmit = (e) => {
      log({ key: "NSA_APPLICANT", message: "Validate OTP Clicked ", stackOrLog: "STACK" })
      setLoading(true)
        e.preventDefault();
        const otplocal = otp;
        setOtpnotsend(false);
        setOtpMAxLimitError(false);
        setOtpUserNotFound(false);
        setError(false);
        setOtpError(false)
        if (
            otplocal && otplocal.length === otpLength
        ) {
            log({ key: "NSA_APPLICANT", message: "Valid OTP length ", stackOrLog: "STACK" })
            if (mobile) {
              log({ key: "NSA_APPLICANT", message: `Validate OTP API... mobile: ${mobile}`, stackOrLog: "STACK" })
                ticketService.velidateOtp(otp,mobile)
                .then((response) => {
                  log({ key: "NSA_APPLICANT", message: `OTP validated successfully mobile`, stackOrLog: "LOG" })
                  setLoading(false)
                    setVerifyVisibility(false);
                    setOpenOtpVerification(false);
                    setIsMobileVerified(true);
                })
                .catch((error) => {
                  log({ key: "NSA_APPLICANT", message: `Error Verify OTP`, stackOrLog: "STACK" })
                  setLoading(false)
                  setBtnDisable(true)
                if (error.response.status === 500) {
                    setOtpnotsend(true);
                } else if (error.response.status === 403) {
                  log({ key: "NSA_APPLICANT", message: error.response.data.message, stackOrLog: "STACK" })
                    setOtpMAxLimitError(true);
                    setOtpErrorMsg(error.response.data.message)
                } else if(error.response.status === 404){
                  log({ key: "NSA_APPLICANT", message: "Status 404", stackOrLog: "STACK" })
                    setOtpUserNotFound(true);
                } else{
                  log({ key: "NSA_APPLICANT", message: error.response.data.message, stackOrLog: "STACK" })
                    setError(true);
                    setMsg(error.response.data.message);
                    setOtpnotsend(false);
                }
                log({ key: "NSA_APPLICANT", message: "Error", stackOrLog: "LOG" })
                });
            } else {
              log({ key: "NSA_APPLICANT", message: "Mobile is required", stackOrLog: "LOG" })
            }
        } else {
          log({ key: "NSA_APPLICANT", message: "OTP is required with valid length ", stackOrLog: "LOG" })
        }
    }

    const toResendOTP = () => {
      log({ key: "NSA_APPLICANT", message: "Resending OTP", stackOrLog: "STACK" })
        setOtpnotsend(false);
        setOtpMAxLimitError(false);
        setOtpUserNotFound(false);
        setError(false);
        setOtpError(false)
        if (mobile) {
            ticketService.sendOtpMobile(mobile)
            .then((response) => {
              log({ key: "NSA_APPLICANT", message: `Resent OTP Mobile:${mobile}`, stackOrLog: "LOG" })
                setCountdown(Date.now() + 1000 * otpCountDown);
                setOtp("");
                setCountDownStart(true);      
                setResendLinkDisable(true);
            })
            .catch((error) => {
              log({ key: "NSA_APPLICANT", message: `Error`, stackOrLog: "STACK" })
                if (error.response.status === 500) {
                    setOtpnotsend(true);
                } else if (error.response.status === 403) {
                  log({ key: "NSA_APPLICANT", message: error.response.data.message, stackOrLog: "STACK" })
                    setOtpMAxLimitError(true);
                    setOtpErrorMsg(error.response.data.message)
                } else if(error.response.status === 404){
                  log({ key: "NSA_APPLICANT", message: "Status: 404", stackOrLog: "STACK" })
                    setOtpUserNotFound(true);
                } else{
                    setOtpError(true);
                }
                log({ key: "NSA_APPLICANT", message: `Error`, stackOrLog: "LOG" })
                });
        } else {
          log({ key: "NSA_APPLICANT", message: "Mobile is required", stackOrLog: "LOG" })
        }
      }

    const handleCloseOtpVerification = () => {
        setOpenOtpVerification(false);
        setOtp("");

        setOtpnotsend(false);
        setOtpMAxLimitError(false);
        setOtpUserNotFound(false);
        setError(false);
        setOtpError(false)
    }

  return (
    <Dialog onClose={handleCloseOtpVerification} aria-labelledby="customized-dialog-title" open={openOtpVerification}>
    <DialogTitle id="customized-dialog-title" onClose={handleCloseOtpVerification}>
      {labels_Upfront_Payment?.VERIFY_PRIMARI_USER}
    </DialogTitle>
    <DialogContent dividers style={{textAlign:"center"}}>
      <Typography gutterBottom>
        {labels_Upfront_Payment?.VERIFY_SIX_DIGIT_CODE}
      </Typography>
      <Typography gutterBottom>
                     <OtpInput
                        onChange={handleChange}
                        numInputs={otpLength}
                        separator={<span></span>}
                        shouldAutoFocus
                        value={otp}
                        isInputNum={true}
                        inputStyle={{
                          fontSize: 24,
                          width: 36,
                          height: 36,
                          margin: 4,
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          outline: "none",
                          borderColor: "#000a46"
                        }}
                        containerStyle={{
                          margin: "20px auto",
                          padding: 10,
                          justifyContent: "center"
                        }}
                      />
      </Typography>
      <Typography gutterBottom>
        {labels_Upfront_Payment?.DONT_RECEIVE_CODE}<br/>
        <Link
            component="button"
            disabled={resendLinkDisable}
            onClick={toResendOTP}
        >
            {labels_Upfront_Payment?.RESEND}
        </Link>
        {countDownStart && (
                    <Countdown
                      date={countdown}
                      renderer={countDown}
                    />
                  )}

                {otpMAxLimitError && 
                <Alert severity="error">{(labels_Upfront_Payment?.MAX_OTP_ATTEMPTS_ERROS).replace("****", otpErrorMsg)}</Alert>
                }
                {otpError && <Alert severity="error">{labels.OTP_TRY_AGAIN_ERROR}</Alert>}

                {error === true && (
                    <Alert severity="error">{msg}</Alert>
                )}
                {otpnotsend === true && (
                  <Alert severity="error">{labels.OTP_TRY_AGAIN_ERROR}</Alert>
                )}
                {otpUserNotFound && <Alert severity="error">{(labels_Upfront_Payment?.MAX_OTP_ATTEMPTS_ERROS).split(".")[0]}</Alert>}
      </Typography>
    </DialogContent>
    <DialogActions>
    <Button
          variant="outlined"
          color="primary"
          onClick={handleCloseOtpVerification}
          size="large"
          >
            {labels_Upfront_Payment?.CANCEL}
        </Button>
        <Button
          variant="contained"
          disabled={btnDisable}
          color="primary"
          onClick={handleSubmit}
          size="large"
          >
            {labels_Upfront_Payment?.SUBMIT}
            {loading && <CircularProgress size={17} className={classes.circularLoading} color="inherit"/>}
        </Button>
    </DialogActions>
  </Dialog>
  )
}

export default OtpVerification;