import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { routePaths } from "../../config/route-paths";
import { useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import WarningIcon from "@material-ui/icons/Warning";
import moment from "moment";
import { getOutageStatus } from "./outage-alert-service";

const useStyles = makeStyles((theme) => ({
  snackbarStyle: {
    ["@media (min-width:891px)"]: {
      top: "75px",
    },
    ["@media (max-width:891px)"]: {
      top: "145px",
    },
  },
}));

export const OutageAlert = (props) => {
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const labels = useSelector(
    (state) => state?.labels?.data?.getLabels?.SERVER_EXCEPTIONS
  );
  const [alertMsg, setAlertMsg] = useState();
  const classes = useStyles();
  const scheduledSystemDownConfig = useSelector(
    (state) =>
      state?.parametersWidgets?.ParameterLookup?.SCHEDULED_DOWNTIME_ALERT_CONFIG
  );
  const unscheduledSystemDownConfig = useSelector(
    (state) =>
      state?.parametersWidgets?.ParameterLookup
        ?.UNSCHEDULED_DOWNTIME_ALERT_CONFIG
  );
  const systemDownConfig = useSelector(
    (state) =>
      state?.parametersWidgets?.ParameterLookup
        ?.SYSTEM_DOWNTIME_ALERT_CONFIG
  );
  useEffect(() => {
    if (
      labels &&
      (window.location.pathname.includes(routePaths.LOGIN) ||
        window.location.pathname.includes(routePaths.LOGIN_VIA_OTP))
    ) {
      let status;
      getOutageStatus()
        .then((res) => {
          status = res?.data;
          if (
            unscheduledSystemDownConfig &&
            status?.status === "SYSTEM_DOWN" &&
            moment(status?.tillDateTime).isAfter(moment())
          ) {
            setSnackBarOpen(true);
            let hours =
              moment(status?.tillDateTime).diff(moment(), "hours") + 1;
            let msg = labels?.SYSTEM_DOWN_UNPLANNED_ALERT_MESSAGE?.replace(
              "{{hours}}",
              hours
            );
            setAlertMsg(msg);
          } else if (scheduledSystemDownConfig) {
            checkPlannedOutage();
          }
          if(systemDownConfig=="true"){
            setSnackBarOpen(true);
            let msg = labels?.SYSTEM_DOWN_ALERT_MESSAGE
            setAlertMsg(msg);
          }
        })
        .catch((error) => {
          checkPlannedOutage();
        });
    }
  }, [
    window.location.pathname,
    scheduledSystemDownConfig,
    unscheduledSystemDownConfig,
    labels,
  ]);

  const checkPlannedOutage = () => {
    let config = scheduledSystemDownConfig
      ? JSON.parse(scheduledSystemDownConfig)
      : undefined;
    if (!config?.length) {
      return;
    }
    config.forEach((item) => {
      if (
        item?.[0] &&
        item?.[1] &&
        moment(item?.[0]).unix() <= moment().unix() &&
        moment(item?.[1]).unix() >= moment().unix()
      ) {
        setSnackBarOpen(true);
        let msg = labels?.SYSTEM_DOWN_PLANNED_ALERT_MESSAGE?.replace(
          "{{date}}",
          moment(item?.[0]).format("DD MMM, YYYY")
        )
          .replace("{{startTime}}", moment(item?.[0]).format("hh:mm A"))
          .replace("{{endTime}}", moment(item?.[1]).format("hh:mm A"));
        setAlertMsg(msg);
      }
    });
  };

  const handleSnackbarClose = () => {
    setSnackBarOpen(false);
  };

  return (
    <>
      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.snackbarStyle}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="error"
          icon={<WarningIcon fontSize="inherit" />}
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
