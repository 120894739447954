import { userConstants } from "../constants";

export function path(state = {}, action) {
  switch (action.type) {
    case userConstants.PATH_SIGNUP:
      return {
        ...state,
        path: action.path,
        data: action.data,
        mobile: action.mobile,
      };
    case userConstants.PATH_LOGIN:
      return { ...state, path: action.path, data: action.data };
    case userConstants.PATH_LOGIN_VIA_OTP:
      return { ...state, path: action.path, data: action.data };
    default:
      return state;
  }
}

export function location(state = {}, action) {
  switch (action.type) {
    case userConstants.LOCATION_SIGNUP:
      return {
        ...state,
        login: false,
        signup: true,
      };
    case userConstants.LOCATION_LOGIN:
      return { ...state, login: true, signup: false };
    case userConstants.LOCATION_OTHER:
      return { ...state, login: false, signup: false };
    default:
      return state;
  }
}


export function submit(state = "", action) {
  switch (action.type) {
    case userConstants.SUBMIT_SUCCESS: {
      return {
        ...state,
        submitted: action.submit,
      };
    }
    default:
      return state;
  }
}