//@flow

import { config } from "../enviroment";
import axios from "axios";
import { commonService } from "./common.service";
import Cookies from "universal-cookie";

export const bearerTokenService = {
  refreshBearerToken,
  getBearerToken,
};

async function refreshBearerToken() {

  let authbearer = localStorage.getItem("authbearer");
  if (authbearer) {
    authbearer = JSON.parse(authbearer);
  }
  if (authbearer) {
    await axios
      .post(
        config.urls.REFRESH_BEARER,
        {
          grantType: "refresh_token",
          refreshToken: authbearer.refresh_token,
          source: true,
        },
        {
          headers: {
            clientId: config.confidentialClient.CLIENT_ID_KEYCLOCK,
            clientSecret:
              config.confidentialClient.AUTH_UI_CLIENT_SECRET_KEYCLOCK,
          },
        }
      )
      .then(function (response) {
        authbearer.access_token = response.data.access_token;
        authbearer.refresh_token = response.data.refresh_token;
        localStorage.removeItem("authbearer")
        localStorage.setItem(
          "authbearer",
          commonService.encodeString(JSON.stringify(authbearer))
        );
   
      })
      .catch(async function (err) {
          await bearerTokenService
          .getBearerToken()
          .then((successBearer) => {          
            this.props.getParametersWidgets();
            this.props.downloadLabels();                             
          })
          .catch((error) => {
            
          });
      });
  } else {
    //here we need to set up bearer
    // window.location.reload();
  }
}

async function getBearerToken() {
  let confidentialClient = config.confidentialClient;
  // const cookies = new Cookies();

  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.GENERATE_BEARER,
        {},
        {
          headers: {
            clientId: confidentialClient.CLIENT_ID_KEYCLOCK,
            clientSecret: confidentialClient.AUTH_UI_CLIENT_SECRET_KEYCLOCK,
            tenantCode: config.parameters.TENANT_CODE,
          },
        }
      )
      .then(function (response) {
        // cookies.remove("authbearer");
        // cookies.set(
        //   "authbearer",
        //   commonService.encodeString(JSON.stringify(response.data)),
        //   { path: "/", secure: true, httpOnly: true }
        // );
        resolve(response.data)
        localStorage.removeItem("authbearer")
        localStorage.setItem(
          "authbearer",
          commonService.encodeString(JSON.stringify(response.data))
        );
      })
      .catch(function (error) {
              reject(error);
      });
  });
}
