import axios from "axios";
import { config } from "../enviroment";
import { store } from "../helpers/store";
import { bearerTokenService } from "./bearer.service";
export const appService = {
  getParametersWidgets: getParametersWidgets,
  getLabels: getLabels,
  downloadLabels: downloadLabels,
  getPremises: getPremises,
};

async function getParametersWidgets() {
  return new Promise((resolve, reject) => {
    axios
      .get(config.urls.PARAMETER_WIDGET_CONTROL)
      .then((response) => {
        if(response){
        resolve(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

async function downloadLabels() {
  let APIquery = `{
    getLabels(input:{languageCode: "en"})
  }`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.ADMIN_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

async function getPremises() {
  let ApiQuery = `{
    getPremiseFinder{
      leadId
      state
      stateDescr
      address1
      address2
      address3
      address4,
      depositAmount,
      connectionId
    },
  }`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.PREMISES,
      method: "post",
      data: {
        query: ApiQuery + ``,
      },
      header: { tenantCode: config.parameters.TENANT_CODE },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function getLabels() {
  var labels = store.getState().labels;
  return labels;
}
