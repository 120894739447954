import axios from "axios";
import { config } from "../../src/enviroment";


export const userService = {
  login,
  loginViaOTP,
  register,
  reset,
  resetForm,
  validateOTP,
  logOut,
  resendCommonOTP,
  userCheck,
  resendEmail,
};

async function login(username, password) {
  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.LOGIN, {
        email: username,
        password: password,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function loginViaOTP(username) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.LOGIN_VIA_OTP,
        {},
        {
          headers: {
            userName: username,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function register(user, uniqueValue) {
  return await axios.post(config.urls.SIGNUP, {
    userName: user.username,
    password: user.password,
    email: user.registeredEmail.toLowerCase(),
    phoneNumber: user.mobileWithoutExt,
    countryCode:"(+"+user.countryCode+") ",
    identities: [
      {
        identityValue: user.uniqueId,
        identityCode: uniqueValue,
      },
    ],
    createdBy: 0,
  });
}

async function reset(username) {
  return await axios.post(config.urls.RESET, {
    email: username,
  });
}

async function resetForm(password, token) {
  return await axios.post(config.urls.RESETFORM, {
    password: password,
    token: token,
  });
}

async function validateOTP({ email, otp, password }) {
  const validateOTPObject = {};
  const flowType = localStorage.getItem("flowType");
  if (flowType === "LOGIN_OTPVERIFIED" || flowType === "LOGIN") {
    validateOTPObject.password = password;
    validateOTPObject.otp = Number(otp);
    validateOTPObject.flowType = flowType;
  } else {
    validateOTPObject.otp = Number(otp);
    validateOTPObject.flowType = flowType;
  }
  return await axios.post(config.urls.VALIDATEOTP, validateOTPObject, {
    headers: {
      userName: email,
    },
  });
}

async function resendCommonOTP(email) {
  const flowType = localStorage.getItem("flowType");
  return await axios.post(
    config.urls.SIGNUP_RESENDOTP,
    {
      flowType,
    },
    {
      headers: {
        userName: email,
      },
    }
  );
}

async function logOut(sessionId, email) {
  return await axios.post(config.urls.LOGOUT, {
    sessionId: sessionId,
    email: email,
  });
}

async function userCheck(username) {
  return new Promise((resolve, reject) => {
    axios
      .get(config.urls.USERCHECK,{
        headers:{
          "username":username
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

async function resendEmail(userName) {
  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.RESEND_EMAIL, 
        {
        query: 
        `query{resendEmail (username:"`+userName+`"){ isOk } }`,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
