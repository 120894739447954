import React, { useState, useEffect } from "react";
import "../auth/auth.css";
import "../app/base-style.css";
import { routePaths } from "../config/route-paths";
import { Typography } from "@material-ui/core";
import { store } from "../../helpers";
import { useStyles } from "../app/base-style";
import Grid from "@material-ui/core/Grid";
import { landingPageConstants } from "./landing-page-constants";
import { adminService } from "../../services/admin.service";
import { history } from "../../helpers/index";
import Countdown from "react-countdown-now";
import { connect } from "react-redux";
import { pathActions } from "../../actions";
import moment from "moment";
import { authStyles } from "../auth/auth-style";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ImageBackground from "../../assests/qc-bg.jpg";

let labels = {};
let profileBlockedTenure = 6;
let lockDurationDefault = "";
store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
  if (store.getState().parametersWidgets.ParameterLookup) {
    profileBlockedTenure = store.getState().parametersWidgets.ParameterLookup
      .INVALID_SA_BLOCK_TENURE;
    profileBlockedTenure = Number(profileBlockedTenure);
    lockDurationDefault = store.getState().parametersWidgets.ParameterLookup
      .LOCK_DURATION;
    lockDurationDefault = Number(lockDurationDefault);
  }
});

function LandingComponent(props){
  const classes = useStyles();

  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
  let rightBlue = `split right ${classes.primaryBackground}`;
return(
  <React.Fragment>
      {isTabletOrMobile ? (
        <div>
          <div className="bodyimage" />
          <div className={rightBlue}>
            <LandingComponentMain
              propsMain={props}
            />{" "}
          </div>
        </div>
      ) : (
        <React.Fragment>
          <Grid container direction="column" spacing={0}>
            <Grid item className={[classes.primaryBackground,classes.paddingTopHomepage]}>
              <LandingComponentMain
                propsMain={props}
              />{" "}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
);
}

function LandingComponentMain(props) {
  const classes = useStyles();
  let flowCode = new URLSearchParams(window.location.search).get("flowCode");
  let terminationDate = new URLSearchParams(window.location.search).get(
    "terminationDate"
  );
  const [title, setTitle] = useState(null);
  const [text, setText] = useState(null);
  const [linkAddress, setLinkAddress] = useState(null);
  const [content, setContent] = useState(null);
  const [contentType, setContentType] = useState(null);
  const [lockDuration, setLockDuration] = useState(null);
  const [textSecond, setTextSecond] = useState(null);
  let accountDeactivationDate;

  if (terminationDate !== null) {
    if (moment(terminationDate, "YYYY-MM-DD").isValid()) {
      accountDeactivationDate = moment(terminationDate, "YYYY-MM-DD");
    }
  }
  const authStyle = authStyles();
  let linkText = `btnLink ${authStyle.quinaryColor}`;

  useEffect((props) => {
    if (props) {
      props.propsMain.getLocationPath(history.location.pathname);
    }

    adminService.getContentData("en", "USER_MANAGEMENT").then((res) => {
      setContent(res.data.getContents.USER_MANAGEMENT);
    });
  }, []);

  if (store.getState().labels.data && content) {
    switch (flowCode) {
      case landingPageConstants.EMAIL_ACTIVATE_SUCCESS:
        if (!title) {
          setTitle(labels.LANDING_ACCOUNT_SUCCESS_TITLE);
          setText(labels.LANDING_ACCOUNT_SUCCESS_TEXT);
          setLinkAddress(routePaths.LOGIN);
        }
        break;
      case landingPageConstants.EMAIL_ACTIVATE_FAIL:
        if (!title) {
          setTitle(labels.LANDING_ACCOUNT_FAIL_TITLE);
          setText(labels.LANDING_ACCOUNT_FAIL_TEXT);
          setLinkAddress(routePaths.LOGIN);
        }
        break;
      case landingPageConstants.USER_BLOCKED_TEMP:
        if (!title) {
          setTitle(labels.ACCOUNT_BLOCK_TITLE);
          let min = new URLSearchParams(window.location.search).get("min");
          if (isNaN(min)) {
            setLockDuration(lockDurationDefault);
          } else {
            setLockDuration(min);
          }
          content.forEach((element) => {
            if (element.contentCode === "ACCOUNT_BLOCKED_TEXT") {
              setContentType(element.contentGroup);
              setText(element.answer);
              setTextSecond(element.question);
            }
          });
          setLinkAddress(routePaths.LOGIN);
        }
        break;
      case landingPageConstants.SIGNUP_SUCCESS:
        if (!title) {
          let loginPath = routePaths.LOGIN;
          let username = "";
          if (localStorage.getItem("username")) {
            username = localStorage.getItem("username");
          }
          loginPath = routePaths.LOGIN + `?username=${username}`;
          localStorage.removeItem("username");

          content.forEach((element) => {
            if (element.contentCode === "SIGNUP_SUCCESS_TEXT") {
              let text = element.answer.replace("***", authStyle.successChange);
              setContentType(element.contentGroup);
              setText(text);
            }
          });
          setTitle(labels.SIGNUP_SUCCESS__QCTITLE);
          setLinkAddress(loginPath);
        }
        break;
      case landingPageConstants.USER_VERIFICATION_FAIL:
        if (!title && !linkAddress) {
          content.forEach((element) => {
            if (element.contentCode === "VERIFICATION_FAILED_TEXT") {
              let text = element.answer.replace("&&&", authStyle.quinaryColor);
              setTitle(element.question);
              setContentType(element.contentGroup);
              setText(text.replace("&&&", authStyle.quinaryColor));
            }
          });
          setLinkAddress(routePaths.LOGIN);
        }
        break;
      case landingPageConstants.USER_ACCOUNT_DEACTIVATED:
        if (!title) {
          setTitle(labels.ACCOUNT_DEACTIVATED_TITLE);
          content.forEach((element) => {
            if (element.contentCode === "ACCOUNT_DEACTIVATED_TEXT") {
              setContentType(element.contentGroup);
              let answer = element.answer;
              answer = answer
                .replace(
                  `**TCK_TDT**`,
                  accountDeactivationDate.format("DD MMM, YYYY")
                )
                .replace(
                  `**TCK_ADT**`,
                  accountDeactivationDate
                    .add(profileBlockedTenure, "months")
                    .format("DD MMM, YYYY")
                );
              setText(answer);
            }
          });
          setLinkAddress(routePaths.LOGIN);
        }
        break;

      default:
        window.location.href = window.location.origin;
    }
  }

  function countDown({ minutes, completed }) {
    let newAfter = text.replace("***", minutes+1);
    if (completed) {
      history.push(routePaths.LOGIN);
      return (
        <div>
          <span></span>
        </div>
      );
    } else {
      let str = textSecond.split("*");
      return (
        <div>
          <Typography
            className={classes.whiteColor}
            variant="h6"
            align="center"
            dangerouslySetInnerHTML={{
              __html: newAfter,
            }}
          />
          <br />
          <br />
          <Typography
            className={classes.whiteColor}
            variant="h6"
            align="center"
          >
            {str[0]}&nbsp;
            <Link
              to={routePaths.RESET_REQUEST}
              className={authStyle.quinaryColor}
            >
              {str[1]}
            </Link>
            {str[2]}
          </Typography>
        </div>
      );
    }
  }
  return (
    <React.Fragment>
      <div className="containermargin">
        <div className="form">
          <br />
        </div>
        <Grid
          container
          justify="center"
          direction="column"
          spacing={6}
          alignItems="center"
        >
          <Grid item>
            <Typography
              className={classes.whiteColor}
              variant="h4"
              align="center"
            >
              <b>{title}</b>
            </Typography>
          </Grid>
          <Grid item>
            {contentType === "HTML" &&
            flowCode !== landingPageConstants.USER_BLOCKED_TEMP ? (
              <Typography
                className={classes.whiteColor}
                variant="h6"
                align="center"
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            ) : flowCode === landingPageConstants.USER_BLOCKED_TEMP &&
              contentType === "HTML" ? (
              <Countdown
                date={Date.now() + 60000 * lockDuration}
                renderer={countDown}
              />
            ) : (
              <Typography
                className={classes.whiteColor}
                variant="h6"
                align="center"
              >
                {text}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
      {store.getState().labels.data && content && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="flex-end"
        >
          <Link to={linkAddress} className={linkText}>
            <Typography variant="h6">
              &#10094; {labels.BACK_TO_LOGIN}
            </Typography>
          </Link>
        </Grid>
      )}
    </React.Fragment>
  );
}

const actionCreators = {
  getLocationPath: pathActions.getLocationPath,
};

const connectedLandingComponent = connect(
  null,
  actionCreators
)(LandingComponent);
export { connectedLandingComponent as LandingComponent };
