import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { TextField, Dialog, Tabs,Select } from "@material-ui/core";

export const CssTextField = withStyles((theme) => ({
  root: {
    "& label,& label.Mui-focused": {
      color: theme.palette.whiteColor.main,
      fontSize: 35,
    },
    "& .MuiInput-underline:before,& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.whiteColor.main,
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: theme.palette.whiteColor.main,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.whiteColor.main,
    },
    "& input:-internal-autofill-selected": {
      background: "none !important",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontSize: "0.90rem !important",
      color: theme.palette.error.light,
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: theme.palette.error.light,
    },
    "& .MuiInputBase-root": {
      color: theme.palette.whiteColor.main,
    },
    "& input:-webkit-autofill": {
      transition:
        "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
    },
  },
}))(TextField);

export const authStyles = makeStyles((theme) =>
  createStyles({
    tertiaryButton: {
      background: theme.palette.tertiary.main + "!important",
    },
    disableButton: {
      cursor: "no-drop !important",
    },
    loadingCursor: {
      cursor: "progress !important",
    },
    quinaryColor: {
      color: theme.palette.quinary.main + "!important",
    },
    successChange: {
      display: "block !important",
    },
    downloadButton: {
      position: "absolute",
      right: theme.spacing(7.5),
      top: theme.spacing(1),
      color: theme.palette.tertiary.main,
    },
  })
);

export const ConnectionOutlinedTextFiled = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "4.5% 1vw !important",
    },
  },
}))(TextField);

export const OutlinedTextFiled = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "11px 1vw !important",
    },
  },
}))(TextField);

export const MobileOutlinedTextFiled = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "11px 1vw 11px 0% !important",
    },
  },
}))(TextField);

export const ImageDialog = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      width: "70% !important",
    },
  },
}))(Dialog);

export const AntTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.tertiary.main,
    height: "8% !important",
  },
}))(Tabs);

export const TitleSelect = withStyles((theme) => ({
  root: {
      padding: "11px 1vw !important",
  },
}))(Select);