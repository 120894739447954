import { userConstants } from "../constants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return { ...state };    
    default:
      return state;
  }
}
