import React, { Component } from "react";
import "../auth/auth.css";
import "../app/base-style.css";
import { useStyles } from "../app/base-style";
import { authStyles } from "../auth/auth-style";
import { Card, CardContent, Grid } from "@material-ui/core";
import "../quick-pay/quick-pay.css";
import { useMediaQuery } from "react-responsive";
import { adminService } from "../../services/index";
import { Skeleton } from "@material-ui/lab";
import {HorizontalLabelPositionBelowStepper} from "./new-service-agreement/new-service-agreement";
import { store } from "../../helpers/store"
import { useEffect } from "react";
import { bearerTokenService } from "../../services/bearer.service";
import { useState } from "react";
import { useSelector } from "react-redux";

function NSAMain(props) {
    const[data,setData] = useState();
    const[labelSubTitle,setLabelSubTitle] = useState();
    const[lastNote,setLastNote] = useState();
    const[confirmSubTitle,setConfirmSubTitle] = useState();
    let lang = useSelector(
      (store) =>
        store?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
    );
    
    async function callAdmin(){
        await adminService.getContentData(lang, "NEW_SERVICE_AGREEMENT").then((res) => {
          store.getState().snackbar=false;
          setData(res.data.getContents.NEW_SERVICE_AGREEMENT)
          res.data.getContents.NEW_SERVICE_AGREEMENT.forEach((item) => {
            if (item.contentCode === "NEW_SERVICE_AGREEMENT_SUBTITLE") {
                setLabelSubTitle(item.answer);
            } 
            if (item.contentCode === "CONFIRM_SERVICE_AGREEMENT_SUBTITLE") {
                setConfirmSubTitle(item.answer)
            } 
          });
        }).catch((err) => {
          store.getState().snackbar=false;
        }) 
      }

      useEffect(() => {
        store.getState().snackbar=true;
        if(localStorage.getItem("authbearer")){
        callAdmin();
        }else{
        bearerTokenService
        .getBearerToken()
        .then(async (successBearer) => {          
        callAdmin();                  
        })
        .catch((error) => {
        });
    }
    },[lang])
  return (
    <React.Fragment>
        <CardContent className="cardPadding">
          {data && labelSubTitle ? (
            <HorizontalLabelPositionBelowStepper
              baseStyle={props.baseStyle}
              data={data}
              authStyle={props.authStyle}
              labelSubTitle ={labelSubTitle}
              confirmSubTitle={confirmSubTitle}
            />
          ) : (
            <Skeleton variant="text" />
          )}
        </CardContent>
      </React.Fragment>
  )
}

export default function NSA() {
  let pids = localStorage.getItem("ticketno");
  const classes = useStyles();
  const authClasses = authStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 460px)" });
  let quinaryBackgroundBlur = `rightSecondaryBack serviceHeight ${classes.quinaryBackground}`;
  let primaryBackgroundBlur = `primaryBack ${classes.primaryBackground} `;
  useEffect(() => {
    const rocketContainer = document.querySelector('.rocketchat-container');
    const rocketWidget = document.querySelector('.rocketchat-widget')
    if(rocketContainer && rocketWidget) {
      rocketContainer.style.display = "none"
      rocketWidget.style.display = "none" 
    }
  })
  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <React.Fragment>
          <Grid container direction="column" >
            <Grid item>
              <div className={primaryBackgroundBlur} />
            </Grid>
            <Grid item>
              <div className={quinaryBackgroundBlur} />
            </Grid>
          </Grid>
          <Card className="stack-top">
            <NSAMain baseStyle={classes} authStyle={authClasses} />
          </Card>
        </React.Fragment>
      ) : (
        <div className="margin-top-60">
          <NSAMain baseStyle={classes} authStyle={authClasses} />
        </div>
      )}
    </React.Fragment>
  );
}