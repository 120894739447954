import { Button, Chip, Grid, Typography, InputLabel, makeStyles, CardContent } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { DropzoneDialog } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import "../../../app/base-style";
import { TermsCondition } from "../../../terms-condition/terms-condition";
import { ticketService } from "../service/ticket-service";
import Additional from "./_additional-document";
import { adminService } from "../../../../services/admin.service";
import Instructions from "./_instructions.js"
import { useSelector } from "react-redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { grey300, grey600, grey700, white } from "material-ui/styles/colors";

const useConstructor = (callBack = () => {}) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabStyles: {
    minWidth: "20%",
    minHeight: "5vh",
    fontWeight: "light",
    transition: "0.5s ease-in-out",
    borderRadius: " 4px 4px 0 0",
    marginRight: 15
},
selectedTab: {
    background: theme.palette.primary.main + "!important",
    opacity : 1,
    color: white
},
unSelctedTab: {
    background: grey300,
    color: grey600,
    opacity : 1
},
baseTabStyles: {
    marginTop: "1rem",
    minHeight: "30px"
},
subtext: {
  color: "#37393a",
  fontWeight: "600",
},
asterisk: {
  color: "#c9252d",
},
tabs: {
  "& .MuiTabs-indicator": {
    display: "none"
  }
},
cardBorder:{
  border: '2px solid '+theme.palette.primary.main
},
addJointOwnerLink : {
  color: theme.palette.primary.main + "!important",
  opacity : 1,
  display: "block"
}
}));


export default function UploadDocuments(props) {
    let pwdMaxLen = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.PASSWORD_MAXLENGTH);
    return (
      <UploadFilesMain
        propsMain={props}
        customStyle={props.customStyle}
        pwdMaxLen={pwdMaxLen}
        jointOwnerDetails={props.jointOwnerDetails}
        setJointOwnerDetails={props.setJointOwnerDetails}
        jointOwnerDocErrorIndex={props.jointOwnerDocErrorIndex}
        setJointOwnerDocErrorIndex={props.setJointOwnerDocErrorIndex}
        cidType={props.cidType}
        idType={props.idType}
        checkbox={props.checkbox}
        FileContent={props.FileContent}
        FileContentp={props.FileContentp}
        FileContentq={props.FileContentq}
        FileContentAll={props.FileContentAll}
        setFileContent={props.setFileContent}
        setFileContentp={props.setFileContentp}
        setFileContentq={props.setFileContentq}
        setFileContentAll={props.setFileContentAll}
        depositeAmount={props.depositeAmount}
      />
    );
  }

  function UploadFilesMain(props) {
    const labels_Upfront_Payment = useSelector((state)=>state?.labels?.data?.getLabels?.UPFRONT_PAYMENT);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openp, setOpenp] = useState(false);
    const [openq, setOpenq] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesp, setFilesp] = useState([]);
    const [filesq, setFilesq] = useState([]);
    const [maxSize, setMaxSize] = useState(null);
    const [maxFileAllow, setMaxFileAllow] = useState(null);
    const [companyMaxFileAllow, setCompanyMaxFileAllow] = useState(null);
    const [representativeMaxFileAllow, setRepresentativeMaxFileAllow] = useState(null);
    const [maxFileSize, setMaxFileSize] = useState(null);
    const [dropdownData, setDropdownData] = useState([]);

    const {cidType,idType} = props;

    const labels = useSelector((state)=>state?.labels?.data?.getLabels?.UPLOAD_DOCUMENTS);
    const label_User_management = useSelector((state)=>state?.labels?.data?.getLabels?.USER_MANAGEMENT);

    let MAX_FILES_ALLOWED_FOR_JOINT_OWNER = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.MAX_DOCUMENT);
    MAX_FILES_ALLOWED_FOR_JOINT_OWNER=Number(MAX_FILES_ALLOWED_FOR_JOINT_OWNER)
    const {jointOwnerDetails, setJointOwnerDetails} = props
    const getPhoto = (fileName, fileType, fileContent) => {
        var file = new File([fileContent], fileName, { type: fileType });
        return file;
    }

    useEffect(() => {
        adminService.getDropdownpData("ICSA_UPLOAD_DOCUMENTS").then((res) => {
            let data = res?.data?.data?.getDropdownData
            setDropdownData(data);
          });

          let files = [];
          let fileName = localStorage.getItem("fileName");
          let fileType = localStorage.getItem("fileType");
          let fileContent = props.FileContent;
          if (fileName) {
            fileName = JSON.parse(fileName);
            fileType = JSON.parse(fileType);
            fileContent = JSON.parse(fileContent);
            for (var i = 0; i < fileName.length; i++) {
              files.push(getPhoto(fileName[i], fileType[i], fileContent[i]));
            }
            setFiles(files);
          }
          let filesp = [];
          let fileNamep = localStorage.getItem("fileNamep");
          let fileTypep = localStorage.getItem("fileTypep");
          let fileContentp = props.FileContentp
          if (fileNamep) {
            fileNamep = JSON.parse(fileNamep);
            fileTypep = JSON.parse(fileTypep);
            fileContentp = JSON.parse(fileContentp);
            for (var i = 0; i < fileNamep.length; i++) {
              filesp.push(getPhoto(fileNamep[i], fileTypep[i], fileContentp[i]));
            }
            setFilesp(filesp);
          }
          let filesq = [];
          let fileNameq = localStorage.getItem("fileNameq");
          let fileTypeq = localStorage.getItem("fileTypeq");
          let fileContentq = props.FileContentq
          if (fileNameq) {
            fileNameq = JSON.parse(fileNameq);
            fileTypeq = JSON.parse(fileTypeq);
            fileContentq = JSON.parse(fileContentq);
            for (var i = 0; i < fileNameq.length; i++) {
              filesq.push(getPhoto(fileNameq[i], fileTypeq[i], fileContentq[i]));
            }
            setFilesq(filesq);
          }
    }, [])

    const [fileTypes, setfileTypes] = useState();
    let lang = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE);
    let parameterLookup = useSelector((state)=>state?.parametersWidgets?.ParameterLookup);

    useConstructor(() => {
    ticketService.getFileTypes(lang).then(
      (res) => {
        let fileTypes = [];
        res.data.fileTypes.forEach((k) => {
          fileTypes.push("." + k.FileAttachmentTypeCode.toLowerCase());
        });
        setfileTypes(fileTypes);
      },
      (error) => { }
    );
    
      let maxSize = parameterLookup?.MAX_SIZE;
      let maxFileAllow =parameterLookup?.ICSA_PROOF_MAX_FILES_ALLOWED;
      let companyMaxFileAllow =parameterLookup?.ICSA_COMPANY_MAX_FILES_ALLOWED;
      let representativeMaxFileAllow =parameterLookup?.ICSA_REPRESENTATIVE_MAX_FILES_ALLOWED;

      maxSize = Number(maxSize);
      maxFileAllow = Number(maxFileAllow);
      companyMaxFileAllow = Number(companyMaxFileAllow);
      representativeMaxFileAllow = Number(representativeMaxFileAllow);
      const maxFileSize = Number(maxSize)  * 1024 * 1024;

        setMaxSize(maxSize);
        setMaxFileAllow(maxFileAllow);
        setMaxFileSize(maxFileSize);
        setCompanyMaxFileAllow(companyMaxFileAllow);
        setRepresentativeMaxFileAllow(representativeMaxFileAllow);
    });
    
    const handleOpen = (e) => {
        setOpen(true);
      };
     const handleOpenP = (e) => {
        setOpenp(true);
      };
    const handleOpenQ = (e) => {
        setOpenq(true);
      };
    
    const handleSave = (filesparameter) => {
        let newFiles = "";
        if (filesparameter.length !== 0) {
          newFiles = files;
          filesparameter.forEach((item) => {
            newFiles.push(item);
          });
        setFiles(newFiles);
        } else {
        setFiles(filesparameter);
        }
        if (!newFiles) {
          newFiles = filesparameter;
        }
        addFileInLocal(newFiles, "fileContent", "fileName", "fileType");
        localStorage.setItem("fileDropdown",dropdownData[2].dropDownCode)
        setOpen(false);
      }

    function addFileInLocal(newFiles, contentFile, name, type) {
        let fileName = [];
        let fileContent = [];
        let fileType = [];
        newFiles.map((item, k) => {
          fileName.push(item.name);
          let fileDescription = item.name.split(".");
          let reader = new FileReader();
          fileType.push(fileDescription[fileDescription.length-1]);
          if (fileDescription[fileDescription.length-1] === "txt") {
            reader.readAsText(item);
          } else {
            reader.readAsDataURL(item);
          }
          reader.onload = (e) => {
            fileContent.push(e.target.result.split(';base64,')[1]);
            if(contentFile == "fileContent"){
              props.setFileContent(JSON.stringify(fileContent))
            } else if (contentFile == "fileContentp"){
              props.setFileContentp(JSON.stringify(fileContent))
            } else if (contentFile == "fileContentq"){
              props.setFileContentq(JSON.stringify(fileContent))
            } 
          };
        });
        localStorage.setItem(name, JSON.stringify(fileName));
        localStorage.setItem(type, JSON.stringify(fileType));
      }

    const handleSaveP = (filespParameter) => {
        let newFiles = "";
        if (filespParameter.length !== 0) {
          newFiles = filesp;
          filespParameter.forEach((item) => {
            newFiles.push(item);
          });
        setFilesp(newFiles);
        } else {
        setFilesp(filesp);
        }
        if (!newFiles) {
          newFiles = filespParameter;
        }
        addFileInLocal(newFiles, "fileContentp", "fileNamep", "fileTypep");
        let code =
          cidType == "individual"
            ? idType == null ||
              idType == "QATAR_ID"
              ? dropdownData[0].dropDownCode
              : dropdownData[1].dropDownCode
            : dropdownData[6].dropDownCode
        localStorage.setItem("fileDropdownp", code);
        setOpenp(false);   
      }

    const handleSaveQ = (filesqparameter) => {
        let newFiles = "";
        if (filesqparameter.length !== 0) {
          newFiles = filesq;
          filesqparameter.forEach((item) => {
            newFiles.push(item);
          });
        setFilesq(newFiles);
        } else {
        setFilesq(filesq);
        }
        if (!newFiles) {
          newFiles = filesqparameter;
        }
        addFileInLocal(newFiles, "fileContentq", "fileNameq", "fileTypeq");
        let code = dropdownData[4].dropDownCode
        localStorage.setItem("fileDropdownq", code);
        setOpenq(false);
      }

    const handleClose = () => {
        setOpen(false);
      };

    const handleCloseP = () => {
        setOpenp(false);
      };

    const handleCloseQ = () => {
        setOpenq(false);
      };

    const handleDelete = (ChipData) => () => {
        let newFiles = [];
        files.forEach((data) => {
          if (data.name !== ChipData.name) {
            newFiles.push(data);
          }
        });
        setFiles(newFiles);
        addFileInLocal(newFiles, "fileContent", "fileName", "fileType");
      };
    
    const handleDeleteP = (ChipData) => () => {
        let newFiles = [];
        filesp.forEach((data) => {
          if (data.name !== ChipData.name) {
            newFiles.push(data);
          }
        });
        setFilesp(newFiles);
        addFileInLocal(newFiles, "fileContentp", "fileNamep", "fileTypep");
      };

    const handleDeleteQ = (ChipData) => () => {
        let newFiles = [];
        filesq.forEach((data) => {
          if (data.name !== ChipData.name) {
            newFiles.push(data);
          }
        });
        setFilesq(newFiles);
        addFileInLocal(newFiles, "fileContentq", "fileNameq", "fileTypeq");
      };

    const handleChangeImage = (event) => {
        var x = event.target.value;
      };

    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event, newValue) => {
          setSelectedTab(newValue);
      }
    
    const [openJointOwner, setOpenJointOwner] = useState(false)
    const handleClickJointOwner = () => {
      setOpenJointOwner(true);
    }
    const handleCloseJointOwner = () => {
      setOpenJointOwner(false);
    } 
    const handleSaveJointOwner = (filespParameter) => {
        let newFiles;
        if (filespParameter.length !== 0) {
          newFiles = jointOwnerDetails[selectedTab].files;
          filespParameter.forEach((item) => {
            newFiles=item;
          });
          let jointOwnerDetailsLocal = jointOwnerDetails;
          jointOwnerDetailsLocal[selectedTab].files=newFiles;
          setJointOwnerDetails([...jointOwnerDetailsLocal]);
        } 
        if (!newFiles) {
          newFiles = filespParameter;
        }
        let fileName;
        let fileType;
          fileName=newFiles.name;
          let fileDescription = newFiles.name.split(".");
          let fileNameFinal = "";
          fileDescription.map((item,index) => {
            if(index != fileDescription.length-1){
              if(index == 0){
                fileNameFinal = item;
              }
              else {
                fileNameFinal = fileNameFinal+"."+item;
              }
            }
          })
          let reader = new FileReader();
          fileType=fileDescription[fileDescription.length-1];
          if (fileDescription[fileDescription.length-1] === "txt") {
            reader.readAsText(newFiles);
          } else {
            reader.readAsDataURL(newFiles);
          }
        let jointOwnerDetailsLocalTemp = jointOwnerDetails;
        jointOwnerDetailsLocalTemp[selectedTab].fileName=fileNameFinal;
        
          reader.onload = (e) => {
            jointOwnerDetailsLocalTemp[selectedTab].fileContent=JSON.stringify(e.target.result.split(';base64,')[1]);
          };
        jointOwnerDetailsLocalTemp[selectedTab].fileType=fileType;
        setJointOwnerDetails([...jointOwnerDetailsLocalTemp]);

        if(jointOwnerDetails?.length > 0)
        {     
          let jointOwnerDetailsErrorLocal = [];
          for (var i = 0; i < jointOwnerDetails.length; i++)
          {
            if(!(jointOwnerDetails[i]?.files)){  
              jointOwnerDetailsErrorLocal.push(i);
            }
          }
          props.setJointOwnerDocErrorIndex(jointOwnerDetailsErrorLocal);
        }
        setOpenJointOwner(false);   
      }

    const handleDeleteJointOwner = () => {
      let jointOwnerDetailsLocalTemp = jointOwnerDetails;
      delete jointOwnerDetailsLocalTemp[selectedTab].files;
      delete jointOwnerDetailsLocalTemp[selectedTab].fileName;
      delete jointOwnerDetailsLocalTemp[selectedTab].fileContent;
      delete jointOwnerDetailsLocalTemp[selectedTab].fileType;
      setJointOwnerDetails([...jointOwnerDetailsLocalTemp]);

      if(jointOwnerDetails?.length > 0)
        {     
          let jointOwnerDetailsErrorLocal = [];
          for (var i = 0; i < jointOwnerDetails.length; i++)
          {
            if(!(jointOwnerDetails[i]?.files)){  
              jointOwnerDetailsErrorLocal.push(i);
            }
          }
          props.setJointOwnerDocErrorIndex(jointOwnerDetailsErrorLocal);
        }
    }

    const { file1Require, file2Require, file3Require, fusername } = props.propsMain;
    return(
        <Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Typography color="primary" variant="h6">
              <h5>
                <b>{labels?.UPLOAD_DOCUMENTS}</b>
              </h5>
            </Typography>
            <Typography className={classes.subtext} variant="body2">
            <span className={classes.asterisk}>*</span> Supporting file formats
            - .docx, .png, .pdf, .jpg, .jpeg, .png, .txt, .rtf
          </Typography>
          <Typography className={classes.subtext} variant="body2">
            <span className={classes.asterisk}>*</span> Maximum File size
            allowed is 5MB
          </Typography>
            <br />
            <div className={props.customStyle.parentContainer50Upload}>
              <Grid>
                <InputLabel required align="left">
                  <b>{labels?.PROOF_OF_PREMISE}</b>
                </InputLabel>
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    type="file"
                    name="premise"
                    variant={
                      files.length >= maxFileAllow
                        ? "outlined"
                        : "contained"
                    }
                    id="premise"
                    value="premisess"
                    onClick={
                      files.length < maxFileAllow
                        ? handleOpen
                        : ""
                    }
                    onChange={handleChangeImage}
                    color="secondary"
                    className={
                      files.length >= maxFileAllow
                        ? "textTransform"
                        : `textTransform ${props.customStyle.ButtonColor}`
                    }
                    endIcon={<AttachFileIcon></AttachFileIcon>}
                  >
                    {labels?.UPLOAD_DOCUMENTS_FILE_ATTACH}
                  </Button>
                </Grid>
                {files.length !== 0 &&
                  files.map((item, key) => {
                    return (
                      <Chip
                        label={item.name}
                        onDelete={handleDelete(item)}
                        className={props.customStyle.fileNames}
                      />
                    );
                  })}

                {file1Require && files.length === 0 && (
                  <Typography color="error" variant="subtitle2">
                    {labels?.DOCUMENT_REQUIRED}
                  </Typography>
                )}
                <DropzoneDialog
                  open={open}
                  onSave={handleSave}
                  acceptedFiles={fileTypes}
                  showPreviews={true}
                  maxFileSize={maxFileSize}
                  filesLimit={maxFileAllow - files.length}
                  onClose={handleClose}
                  dropzoneText={labels?.DROPZONE_TEXT}
                />
              </Grid>
              <hr style={{ borderRadius: 1, borderWidth: 2 }}></hr>
              {cidType == "individual" ? (
                <Grid>
                  <InputLabel required align="left">
                    <b>
                      {idType == null ||
                      idType == "QATAR_ID"
                        ? labels_Upfront_Payment?.JOINT_OWNER_QATAR_ID
                        : label_User_management?.SIGNUP_UNIQUE_ID_PASSPORTNO_LABEL}{" "}
                      {labels_Upfront_Payment?.JOINT_OWNER_INDIVIDUAL}
                    </b>
                  </InputLabel>

                  <Grid style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      type="file"
                      variant={
                        filesp.length >=
                        companyMaxFileAllow
                          ? "outlined"
                          : "contained"
                      }
                      value="ids"
                      id="ids"
                      onClick={
                        filesp.length <
                          companyMaxFileAllow &&
                            handleOpenP
                      }
                      color="secondary"
                      name="ID"
                      className={
                        filesp.length >=
                        companyMaxFileAllow
                          ? "textTransform"
                          : `textTransform ${props.customStyle.ButtonColor}`
                      }
                      endIcon={<AttachFileIcon></AttachFileIcon>}
                    >
                      {labels?.UPLOAD_DOCUMENTS_FILE_ATTACH}
                    </Button>
                  </Grid>
                  {filesp.length !== 0 &&
                    filesp.map((item, key) => {
                      return (
                        <Chip
                          label={item.name}
                          onDelete={handleDeleteP(item)}
                          className={props.customStyle.fileNames}
                        />
                      );
                    })}

                  {file2Require && filesp.length === 0 && (
                    <Typography color="error" variant="subtitle2">
                      {labels?.DOCUMENT_REQUIRED}
                    </Typography>
                  )}

                  <DropzoneDialog
                    open={openp}
                    onSave={handleSaveP}
                    acceptedFiles={fileTypes}
                    showPreviews={true}
                    maxFileSize={maxFileSize}
                    filesLimit={
                      companyMaxFileAllow - filesp.length
                    }
                    onClose={handleCloseP}
                    dropzoneText={labels?.DROPZONE_TEXT}
                  />
                </Grid>
              ) : (
                <Grid>
                  <InputLabel required align="left">
                    <b>{labels?.PROOF_OF_REGISTRATION}</b>
                  </InputLabel>
                  <Grid style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      type="file"
                      variant={
                        filesp.length >=
                        companyMaxFileAllow
                          ? "outlined"
                          : "contained"
                      }
                      value="ids"
                      id="ids"
                      onClick={
                        filesp.length <
                          companyMaxFileAllow &&
                            handleOpenP
                      }
                      color="secondary"
                      name="ID"
                      className={
                        filesp.length >=
                        companyMaxFileAllow
                          ? "textTransform"
                          : `textTransform ${props.customStyle.ButtonColor}`
                      }
                      endIcon={<AttachFileIcon></AttachFileIcon>}
                    >
                      {labels?.UPLOAD_DOCUMENTS_FILE_ATTACH}
                    </Button>
                  </Grid>
                  {filesp.length !== 0 &&
                    filesp.map((item, key) => {
                      return (
                        <Chip
                          label={item.name}
                          onDelete={handleDeleteP(item)}
                          className={props.customStyle.fileNames}
                        />
                      );
                    })}

                  {file2Require && filesp.length === 0 && (
                    <Typography color="error" variant="subtitle2">
                      {labels?.DOCUMENT_REQUIRED}
                    </Typography>
                  )}

                  <DropzoneDialog
                    open={openp}
                    onSave={handleSaveP}
                    acceptedFiles={fileTypes}
                    showPreviews={true}
                    maxFileSize={maxFileSize}
                    filesLimit={
                      companyMaxFileAllow - filesp.length
                    }
                    onClose={handleCloseP}
                    dropzoneText={labels?.DROPZONE_TEXT}
                  />
                </Grid>
              )}

              {props.checkbox ? (
                <Grid>
                  <hr style={{ borderRadius: 1, borderWidth: 2 }}></hr>
                  <InputLabel required align="left">
                    <b>{labels?.PROOF_OF_REPRESENTATIVE}</b>
                  </InputLabel>
                  <Grid style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      type="file"
                      variant={
                        filesq.length >=
                        representativeMaxFileAllow
                          ? "outlined"
                          : "contained"
                      }
                      onClick={
                        filesq.length <
                          representativeMaxFileAllow &&
                            handleOpenQ
                      }
                      color="secondary"
                      id="representative"
                      name="representative"
                      className={
                        filesq.length >=
                        representativeMaxFileAllow
                          ? "textTransform"
                          : `textTransform ${props.customStyle.ButtonColor}`
                      }
                      endIcon={<AttachFileIcon></AttachFileIcon>}
                    >
                      {labels?.UPLOAD_DOCUMENTS_FILE_ATTACH}
                    </Button>
                  </Grid>

                  {filesq.length !== 0 &&
                    filesq.map((item, key) => {
                      return (
                        <Chip
                          label={item.name}
                          onDelete={handleDeleteQ(item)}
                          className={props.customStyle.fileNames}
                        />
                      );
                    })}

                  {file3Require && filesq.length === 0 && (
                    <Typography color="error" variant="subtitle2">
                      {labels?.DOCUMENT_REQUIRED}
                    </Typography>
                  )}

                  <DropzoneDialog
                    open={openq}
                    onSave={handleSaveQ}
                    acceptedFiles={fileTypes}
                    showPreviews={true}
                    maxFileSize={maxFileSize}
                    filesLimit={
                      representativeMaxFileAllow -
                      filesq.length
                    }
                    onClose={handleCloseQ}
                    dropzoneText={labels?.DROPZONE_TEXT}
                  />
                </Grid>
              ) : null}
            
            
              {(jointOwnerDetails.length > 0) && 
              (<>
                <hr style={{ borderRadius: 1, borderWidth: 2 }}></hr>
                <Grid>
                <SimpleTabs selectedTab={selectedTab} handleTabChange={handleTabChange} jointOwnerDetails={jointOwnerDetails}/>
                <CardContent className={classes.cardBorder}>
                <InputLabel required align="left">
                  <b>
                    {(labels_Upfront_Payment?.UPLOAD_JOINT_OWNER_DOCUMENT).replace("****", (selectedTab+1))}{jointOwnerDetails[selectedTab]?.uniqueValue == "QATAR_ID"
                        ? labels_Upfront_Payment?.JOINT_OWNER_QATAR_ID
                        : label_User_management?.SIGNUP_UNIQUE_ID_PASSPORTNO_LABEL}
                  </b>
                </InputLabel>

                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    type="file"
                    variant={
                      jointOwnerDetails[selectedTab]?.files
                        ? "outlined"
                        : "contained"
                    }
                    value="ids"
                    id="ids"
                    onClick={
                      !(jointOwnerDetails[selectedTab]?.files) &&
                        handleClickJointOwner
                    }
                    color="secondary"
                    name="ID"
                    className={
                      (jointOwnerDetails[selectedTab]?.files)
                        ? "textTransform"
                        : `textTransform ${props.customStyle.ButtonColor}`
                    }
                    endIcon={<AttachFileIcon></AttachFileIcon>}
                  >
                    {labels?.UPLOAD_DOCUMENTS_FILE_ATTACH}
                  </Button>
                </Grid>
                {jointOwnerDetails[selectedTab]?.fileName &&
                      <Chip
                        label={jointOwnerDetails[selectedTab]?.fileName}
                        onDelete={handleDeleteJointOwner}
                        className={props.customStyle.fileNames}
                      />
                  }

                <DropzoneDialog
                  open={openJointOwner}
                  onSave={handleSaveJointOwner}
                  acceptedFiles={fileTypes}
                  showPreviews={true}
                  maxFileSize={maxFileSize}
                  filesLimit={MAX_FILES_ALLOWED_FOR_JOINT_OWNER}
                  onClose={handleCloseJointOwner}
                  dropzoneText={labels?.DROPZONE_TEXT}
                />
                </CardContent>
                {(props.jointOwnerDocErrorIndex.length > 0) && (
                  <Typography color="error" variant="subtitle2">
                    {labels_Upfront_Payment?.JOINT_OWNER_DOCUMENT_REQUIRED}{" "}
                    {props.jointOwnerDocErrorIndex.map((item,index) => {
                      let tempVar = (index+1)==props.jointOwnerDocErrorIndex.length?".":", ";
                      return (`${labels_Upfront_Payment?.JOINT_OWNER_DETAILS} ${item + 1}${tempVar}` )
                    })
                    }
                  </Typography>
                )}
              </Grid>
              </>
              )}
            </div>

            <br />
            <br />
            {dropdownData && (
              <Additional dropdownData={dropdownData} FileContentAll={props.FileContentAll} setFileContentAll={props.setFileContentAll}/>
            )}
           
          </Grid>
          <Grid item xs={12} sm={5}>
            {dropdownData && (
              <Instructions dropdownData={dropdownData} />
            )}
          </Grid>
        </Grid>
        <br /> <br />
        <Grid>
          <div align="center">
            <div
              dangerouslySetInnerHTML={{
                __html: labels?.TERMS_TEXT,
              }}
            ></div>
            <br />
            <TermsCondition
              username={
                props.propsMain.fusername
                  ? props.propsMain.fusername +
                    " " +
                    props.propsMain.username +
                    " " +
                    props.propsMain.lusername
                  : props.propsMain.cusername
              }
              depositeAmount={props.depositeAmount}
            />
          </div>
        </Grid>
      </Grid>
    )
  }

  
export function SimpleTabs(props) {
  const classes = useStyles();
  const labels_Upfront_Payment = useSelector((state)=>state?.labels?.data?.getLabels?.UPFRONT_PAYMENT);

  return (
    <div >
        <Tabs 
        variant="scrollable"
        scrollButtons="auto"
        value={props.selectedTab} 
        onChange={props.handleTabChange} 
        aria-label="simple tabs example"
        className={[classes.baseTabStyles,classes.tabs]}
        >
        {props.jointOwnerDetails.map((tab,index) => {
                return (
                    <Tab 
                      key={index}
                      className={ [(props.selectedTab == index) ? classes.selectedTab : classes.unSelctedTab , classes.tabStyles]}
                      label={<b>{labels_Upfront_Payment?.JOINT_OWNER_DETAILS}{" "}{index+1}</b>}
                    />
                )
          })}
        </Tabs>
    </div>
  );
}