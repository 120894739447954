export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  RESET_REQUEST: "USERS_RESET_REQUEST",
  RESET_SUCCESS: "USERS_RESET_SUCCESS",
  RESET_FAILURE: "USERS_RESET_FAILURE",

  PATH_SIGNUP: "USERS_PATH_SIGNUP",
  PATH_LOGIN : "USERS_PATH_LOGIN",
  PATH_UPDATE_PASSWORD : "USERS_PATH_UPDATE_PASSWORD",
  PATH_LOGIN_VIA_OTP:"USERS_PATH_LOGIN_VIA_OTP",

  LOCATION_SIGNUP:"LOCATION_SIGNUP",
  LOCATION_LOGIN:"LOCATION_LOGIN",
  LOCATION_OTHER:"LOCATION_OTHER",

  SUBMIT_SUCCESS:"SUBMITTED_SUCCESS",
};
