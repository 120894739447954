import React ,{useEffect, useState} from "react";
import { Typography, Grid, Button, CircularProgress, makeStyles, CardContent } from "@material-ui/core";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "../../../app/base-style";
import moment from "moment-timezone";
import { customStyles } from "./_new-style";
import { cssService } from "../../../../services/icsav.service";
import Alert from "@material-ui/lab/Alert";
import "./_new-service-agreement.css";
import { useSelector } from "react-redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { grey300, grey600, grey700, white } from "material-ui/styles/colors";
import Payment from "./_payment-screen";
import { history } from "../../../../helpers/history";
import "../../../auth/auth.css";
import "../../../app/base-style.css";
import { authStyles } from "../../../auth/auth-style";
import { Card } from "@material-ui/core";
import "../../../quick-pay/quick-pay.css";
import { useMediaQuery } from "react-responsive";
import { adminService } from "../../../../services/index";
import { Skeleton } from "@material-ui/lab";
import { store } from "../../../../helpers/store"
import { bearerTokenService } from "../../../../services/bearer.service";
import NSACommonHeader from "./_new-service-header";
import { useDispatch } from "react-redux";
import {saveNewServiceAgreementData} from "../model/actions"
import { routePaths } from "../../../config/route-paths";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useLocation } from "react-router-dom";
import { ticketService } from '../service/ticket-service';

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabStyles: {
    minWidth: "20%",
    minHeight: "5vh",
    fontWeight: "light",
    transition: "0.5s ease-in-out",
    borderRadius: " 4px 4px 0 0",
    marginRight: 15
},
selectedTab: {
    background: theme.palette.primary.main + "!important",
    opacity : 1,
    color: white
},
unSelctedTab: {
    background: grey300,
    color: grey600,
    opacity : 1
},
baseTabStyles: {
    marginTop: "1rem",
    minHeight: "30px"
},
tabs: {
  "& .MuiTabs-indicator": {
    display: "none"
  }
},
cardBorder:{
  border: '2px solid '+theme.palette.primary.main
},
addJointOwnerLink : {
  color: theme.palette.primary.main + "!important",
  opacity : 1,
  display: "block"
},
rightAlign:{
  justifyContent:"flex-end"
}
}));

export function ConfirmScreenMain(props) {
const labels_Upfront_Payment = useSelector((state)=>state?.labels?.data?.getLabels?.UPFRONT_PAYMENT);
const labels_SA = useSelector((state)=>state?.labels?.data?.getLabels?.SERVICE_AGREEMENT);
const [payAndSubmitText, setPayAndSubmitText] = useState("");
    useEffect(() => {
        if (props.data) {
          props.data.forEach((item) => {
            if (item.contentCode === "CLICK_PAY_AND_SUBMIT_TO_PROCEED") {
              setPayAndSubmitText(item.answer);
            }
          });
        }
      }, [props.data]);
      const log = (logObj) => {
        adminService.log(logObj).then((res) => {
        });
      }
const dispatch = useDispatch();
const newSeriveAgreementData = useSelector(state => state?.newSeriveAgreementData?.data);
const [dataObject, setDataObject] = useState();
function cleanLocalStorage(){
  dispatch(saveNewServiceAgreementData({}))
    localStorage.removeItem("fileName");
    localStorage.removeItem("fileContent");
    localStorage.removeItem("fileNamep");
    localStorage.removeItem("fileContentp");
    localStorage.removeItem("fileNameq");
    localStorage.removeItem("fileContentq");
    localStorage.removeItem("fileType");
    localStorage.removeItem("fileTypep");
    localStorage.removeItem("fileTypeq");
    localStorage.removeItem("signature");
    localStorage.removeItem("signaturetime");
    localStorage.removeItem("cpqid");
    localStorage.removeItem("AllFilesName")
    localStorage.removeItem("AllFilesContent")
    localStorage.removeItem("AllFilesType")
    localStorage.removeItem("dropdownItems")
    localStorage.removeItem("fileDropdownq")
    localStorage.removeItem("fileDropdownp")
    localStorage.removeItem("fileDropdown")
    history.push(routePaths.NSA);
}

const handleCancelClick = () => {
  let alertOk = window.confirm(labels_SA?.SA_ALERT);
  if (alertOk == true) {
    cleanLocalStorage()
  }
}

useEffect(() => {
  
  if(typeof newSeriveAgreementData !== 'undefined'){
    setDataObject(newSeriveAgreementData)
  }else{
    cleanLocalStorage()
  }
  
}, [newSeriveAgreementData])


  let landline = dataObject?.landline
  let Extention_landline = dataObject?.extension_landline
  let lcontactWithoutExtension = dataObject?.landline
  let idType = dataObject?.idType;
  let cidtype = dataObject?.cidType;
  let propertyType = dataObject?.propertyType;
  let cooling = dataObject?.Cooling;
  let selectedpremises = dataObject?.selectedPremise;
  let adlnotes = dataObject?.adlnotes;
  let signature = localStorage.getItem("signature");
  let signaturetime = localStorage.getItem("signaturetime");
  let checkBox = dataObject?.checkbox
  let titleDropdown =dataObject?.titleDropdown
  let classes = useStyles();
  let classesLocal = useStyle();
  let customClass = customStyles();
  let attachment = "";
  if (dataObject?.csaAttachment) {
    attachment = dataObject?.csaAttachment;
  }
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);
  const [internalError, SetInternalError] = React.useState(null);
  const [errormsg, setErrormsg] = React.useState(null);
  const [title,setTitle]=React.useState(null)
  var timeZone = moment.tz.guess();
  let timeZoneSplit = timeZone.split("/");
  let finalResponse = "";

  const labels = useSelector((state)=>state?.labels?.data?.getLabels?.APPLICANT_DETAILS);
  const label_User_management = useSelector((state)=>state?.labels?.data?.getLabels?.USER_MANAGEMENT);
  const labels_property_details = useSelector((state)=>state?.labels?.data?.getLabels?.PROPERTY_DETAILS);
  const labelsError = useSelector((state)=>state?.labels?.data?.getLabels?.SERVER_EXCEPTIONS);
    
  let country_Calling_Code = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.COUNTRY_DIALING_CODE);
  const dataObjectRegex = useSelector((state)=>state?.parametersWidgets?.ParameterLookup?.DATA_OBJECT_REGEX);

  let lang = useSelector(
    (store) =>
      store?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
  );
  const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event, newValue) => {
          setSelectedTab(newValue);
      }

  useEffect(() => {
    if (titleDropdown) {
      titleDropdown.map((item) => {
        if (item.dropDownCode === dataObject?.title) {
          setTitle(item.dropDownValue);
        }
      });
    }
  }, [titleDropdown, dataObject?.title]);

  const [openPayment, setOpenPayment] = useState(false);
  const closePaySubmit = () => {
      setOpenPayment(false);
  }

const location = useLocation();
useEffect(() => {
  if(location?.state?.paymentOpen){
    setOpenPayment(location.state.paymentOpen);
  }
}, [location]);

  const [data, setData] = useState({})
  const nextScreen = () => {
    log({ key: "NSA_SUMMARY", message: "Next Clicked", stackOrLog: "STACK" })
    let nametitle = dataObject?.title.replace(".", "").toUpperCase();
    let countrycode = "(+" + dataObject?.countryCode + ") "; //(+974)
    let rcountrycode = "(+" + dataObject?.rcountryCode + ") ";
    let lcontactExtension = dataObject?.rcountryCode;
    let fapplicantName = dataObject?.fusername;
    let lapplicantName = dataObject?.lusername;

    let units = dataObject?.unit;
    let buildingName = dataObject?.bname;
    let buildingNumber = dataObject?.bnum;
    let pids = dataObject?.idType;
    let cid = dataObject?.cidType;
    let rapplicantName = dataObject?.rusername;
    let signature = localStorage.getItem("signature");
    let signaturetime = localStorage.getItem("signaturetime");
    let lead = dataObject?.leadid
    let adlnotes = dataObject?.adlnotes;
    //mobile number concatination and regex....
    let countryCode = countrycode.match(/(\d+)/);
    let contactWithoutExtension = dataObject?.withoutExtmobile;
    let contactValue = countrycode + contactWithoutExtension;

    let rcountryCode = rcountrycode.match(/(\d+)/);
    let rcontactWithoutExtension = dataObject?.rwithoutExtmobile;
    let rcontactValue = rcountrycode + rcontactWithoutExtension;
    let rcontactsList1 = {
      sequence: "1",
      contactRouteType: "PRIMARYEMAIL",
      contactValue: dataObject?.remail,
      contactDetailPrimSw: "true",
      contactWithoutExtension: dataObject?.remail,
    };
    let rcontactsList2 = {
      sequence: "2",
      contactRouteType: "MOBILE",
      contactValue: rcontactValue,
      contactWithoutExtension: rcontactWithoutExtension,
      contactDetailPrimSw: "true",
      countryCode: rcountryCode[0],
    };

    let rcontactsList = [];
    if (dataObject?.remail != null && dataObject?.remail != "") {
      rcontactsList.push(rcontactsList1);
    }
    if (dataObject?.rmobile != null && dataObject?.rmobile != "") {
      rcontactsList.push(rcontactsList2);
    }

    let contPerContacts = {
      contactsList: rcontactsList,
    };
    let contactFields = {};

    if (checkBox == true && (rapplicantName != "" || rapplicantName != null)) {
      contactFields = {
        applicantName: rapplicantName,
        contPerContacts: contPerContacts,
      };
    }
    if (checkBox == false) {
      contactFields = {
        contPerContacts: contPerContacts,
      };
    }

    if (pids === null) {
      pids = "QATAR_ID";
    }
    let idlist = {};
    if (cid == "company") {
      idlist = {
        idType: "CRNUM",
        idNbr: dataObject?.uniqueID,
        idPrimary: "true",
      };
    } else if (pids === "QATAR_ID") {
      idlist = {
        idType: pids.replace("_", ""),
        idNbr: dataObject?.uniqueID,
        idPrimary: "true",
      };
    } else {
      idlist = {
        idType: "PASSPORT",
        idNbr: dataObject?.uniqueID,
        idPrimary: "true",
      };
    }

    let perIdsLists = [idlist];
    let signIds = {
      perIdsList: perIdsLists,
    };
    let contactsList1 = {
      sequence: "1",
      contactRouteType: "PRIMARYEMAIL",
      contactValue: dataObject?.email,
      contactDetailPrimSw: "true",
      contactWithoutExtension: dataObject?.email,
    };
    let contactsList2 = {
      sequence: "2",
      contactRouteType: "MOBILE",
      contactValue: contactValue,
      contactWithoutExtension: contactWithoutExtension,
      contactDetailPrimSw: "true",
      countryCode: countryCode[0],
    };
    let lContactValue = "";
    if (landline) {
      if (Extention_landline) {
        lContactValue = "(+" + Extention_landline + ") " + landline;
      } else {
        lContactValue = landline;
      }
    }
    let contactsList3 = {
      sequence: "3",
      contactRouteType: "LANDLINE",
      contactValue: lcontactWithoutExtension,
      contactExtension: Extention_landline ? Extention_landline.replace("+", ""):"",
      contactWithoutExtension: lcontactWithoutExtension,
      contactDetailPrimSw: "false",
    };

    let contactsList = [];
    if (contactsList.length === 0) {
      if (landline == null || landline == "") {
        contactsList.push(contactsList1, contactsList2);
      } else {
        contactsList.push(contactsList1, contactsList2, contactsList3);
      }
    }
    let signContacts = {
      contactsList: contactsList,
    };

    let mailingAddress = {
      unit: units,
      buildingName: buildingName,
      building: buildingNumber,
      poBox: dataObject?.pobox ? dataObject?.pobox : "",
      district: dataObject?.district,
    };
    let signedFields = {};
    if (cid == "individual") {
      signedFields = {
        title: nametitle,
        firstName: fapplicantName,
        middleName: dataObject?.username ? dataObject?.username : "",
        lastName: lapplicantName,
        mailingAddress: mailingAddress,
        signContacts: signContacts,
        signIds: signIds,
        personOrBusiness: "P",
      };
    } else {
      signedFields = {
        businessName: dataObject?.cusername,
        mailingAddress: mailingAddress,
        signContacts: signContacts,
        signIds: signIds,
        personOrBusiness: "B",
      };
    }

    let coOwnerArray=[];
    if (cid == "individual" && dataObject?.jointOwnerDetails?.length > 0){
    const jointOwnerDetailsLocal = dataObject?.jointOwnerDetails;
    for(let i = 0; i < jointOwnerDetailsLocal?.length; i++){
      let idlist = {};
      if (jointOwnerDetailsLocal[i].uniqueValue === "QATAR_ID") {
        idlist = {
          idType: jointOwnerDetailsLocal[i]?.uniqueValue.replace("_", ""),
          idNbr: jointOwnerDetailsLocal[i]?.uniqueID,
          idPrimary: "true",
        };
      } else {
        idlist = {
          idType: "PASSPORT",
          idNbr: jointOwnerDetailsLocal[i]?.uniqueID,
          idPrimary: "true",
        };
      }

      let perIdsLists = idlist;
      let signIds = {
        perIdsList: perIdsLists,
      };

        let contactsList1 = {
          sequence: "1",
          contactRouteType: "PRIMARYEMAIL",
          contactValue: jointOwnerDetailsLocal[i]?.email,
          contactDetailPrimSw: "true",
          contactWithoutExtension: jointOwnerDetailsLocal[i]?.email,
        };
        let contactsList2 = {
          sequence: "2",
          contactRouteType: "MOBILE",
          contactValue: jointOwnerDetailsLocal[i].mobile,
          contactWithoutExtension: jointOwnerDetailsLocal[i].withoutExtmobile,
          contactDetailPrimSw: "true",
          countryCode: jointOwnerDetailsLocal[i].countryCode,
        };
        let contactsList3 = {
          sequence: "3",
          contactRouteType: "LANDLINE",
          contactValue: jointOwnerDetailsLocal[i]?.landline,
          contactExtension: jointOwnerDetailsLocal[i]?.extension_landline ? jointOwnerDetailsLocal[i]?.extension_landline.replace("+", ""):"",
          contactWithoutExtension: jointOwnerDetailsLocal[i]?.landline,
          contactDetailPrimSw: "false",
        };
    
        let contactsList = [];
        if (contactsList.length === 0) {
          if (jointOwnerDetailsLocal[i].landline == null || jointOwnerDetailsLocal[i].landline == "") {
            contactsList.push(contactsList1, contactsList2);
          } else {
            contactsList.push(contactsList1, contactsList2, contactsList3);
          }
        }
        let signContacts = {
          contactsList: contactsList,
        };

        let mailingAddress = {};
        let coOwner= {};
        coOwner = {
          sequence:(coOwnerArray.length+1).toString(),
          applicantName: jointOwnerDetailsLocal[i].fusername+","+jointOwnerDetailsLocal[i].lusername,
          title: jointOwnerDetailsLocal[i].title,
          firstName:jointOwnerDetailsLocal[i].fusername,
          middleName:jointOwnerDetailsLocal[i]?.username,
          lastName:jointOwnerDetailsLocal[i].lusername,
          poBox:"",
          mailingAddress: mailingAddress,
          signContacts : signContacts,
          signIds : signIds,
        }
        coOwnerArray.push(coOwner)
    }
    }
  
    let data = "";
    var dt = new Date();
    if (adlnotes) {
      data = {
        lead: lead,
        requestedCapacity: cooling ? Number(cooling) : 0,
        signFields: signedFields,
        contactFields: contactFields,
        csaAttachments: attachment,
        serviceCommencementDate: moment(dt).format("YYYY-MM-DD"),
        signName: signature,
        addtlNotes: adlnotes,
        signDateTime: moment(signaturetime).toISOString(),
        languageCode: lang,
        allCoOwners:{coOwnerDetails:coOwnerArray}
      };
    } else {
      data = {
        lead: lead,
        requestedCapacity: cooling ? Number(cooling) : 0,
        signFields: signedFields,
        contactFields: contactFields,
        csaAttachments: attachment,
        serviceCommencementDate: moment(dt).format("YYYY-MM-DD"),
        signName: signature,
        signDateTime: moment(signaturetime).toISOString(),
        languageCode: lang,
        allCoOwners:{coOwnerDetails:coOwnerArray}
      };
    }
    if(dataObject?.depositeAmount > 0){
      data.depositeAmount = dataObject?.depositeAmount
    }
    if (data && signature) {
      log({ key: "NSA_SUMMARY", message: "Signature and Data validated", stackOrLog: "STACK" })
      if(dataObject?.depositeAmount > 0){
        log({ key: "NSA_SUMMARY", message: "Payment Mode Card Open", stackOrLog: "LOG" })
        setData(data)
        setOpenPayment(true)
      } else {
        log({ key: "NSA_SUMMARY", message: "Calling VCSA API...", stackOrLog: "STACK" })
        setIsSubmitLoading(true);
        ticketService
        .vcsaRegistrationData(JSON.stringify(data),dataObjectRegex)
        .then((res) => {
          log({ key: "NSA_SUMMARY", message: "VCSA Response received", stackOrLog: "STACK" })
          finalResponse = res?.data?.nsaRegistration?.genCase;
          log({ key: "NSA_SUMMARY", message: `Ticket No. ${finalResponse} - Amount ${dataObject.depositeAmount}`, stackOrLog: "STACK" })
          setIsSubmitLoading(false);
          SetInternalError(false);
          if (finalResponse != undefined) {
            localStorage.clear();
            localStorage.setItem("ticketno", finalResponse);
            window.scrollTo({
              top: 0,
              pointer: "cursor",
            });
            log({ key: "NSA_SUMMARY", message: "Navigating to Confirmation Screen", stackOrLog: "LOG" })
            localStorage.setItem("depositeAmount",dataObject.depositeAmount);
            history.push(routePaths.NSA_PAY_CONFIRM);
          } else {
            log({ key: "NSA_SUMMARY", message: "Error", stackOrLog: "LOG" })
          SetInternalError(true);
          setErrormsg(labels_Upfront_Payment?.SOMETHING_WRONG)
        }
        })
        .catch((error) => {
          log({ key: "NSA_SUMMARY", message: "VCSA Error", stackOrLog: "STACK" })
          if (error.response) {
            SetInternalError(true);
            if (
              error.response.status !== 500 &&
              JSON.parse(error?.response?.request?.response)?.errors[0].message
            ) {
              log({ key: "NSA_SUMMARY", message: JSON.parse(error?.response?.request?.response)?.errors[0].message, stackOrLog: "STACK" })
              setErrormsg(
                JSON.parse(error?.response?.request?.response)?.errors[0].message
              );
            } else {
              setErrormsg(labelsError?.SERVER_EXCEPTIONS_NOT_RIGHT);
            }
          }
          log({ key: "NSA_SUMMARY", message: "Error", addOnLog: error, stackOrLog: "LOG" })
          setIsSubmitLoading(false);
        });
      }
    }
  };

  const handleApplicantDetailsEdit = () => {
    history.push({ 
      pathname: routePaths.NSA,
      state: {stepindex:0}
     });
  }
  const handlePropertyDetailsEdit = () => {
    history.push({ 
      pathname: routePaths.NSA,
      state: {stepindex:1}
     });
  }
  const handleDocumentDetailsEdit = () => {
    history.push({ 
      pathname: routePaths.NSA,
      state: {stepindex:2}
     });
  }

  const getTitleValue = (code)=>{
    let tempValue;
    titleDropdown && titleDropdown.map((item) => {
      if (item.dropDownCode === code) {
        tempValue = item.dropDownValue
      }
    })
    return tempValue;
  }
  
  return (
    <>
    <NSACommonHeader
            labelSubTitle={props.confirmSubTitle}
            baseStyle={props.baseStyle}
            activeStep={3}
          />
    {typeof dataObject !== 'undefined' &&<>
    <Grid container spacing={3} direction="column">
      <Grid item xs={12}>
        <Typography color="primary" variant="h6">
          <b>
            {" "}
            {cidtype === "company"
              ? labels.COMPANY_DETAILS
              : labels.APPLICANT_DETAILS}
          </b>
          <IconButton onClick={handleApplicantDetailsEdit}>
            <BorderColorIcon color="primary" />
          </IconButton>
        </Typography>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={12} sm={6} md={4}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography className={classes.quaternaryColor}>
                  {labels_SA.SA_NAME}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                {cidtype == "individual"
                    ? title && title +
                      " " +
                      dataObject?.fusername +
                      " " +
                      dataObject?.username +
                      " " +
                      dataObject?.lusername
                    : dataObject?.cusername}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography className={classes.quaternaryColor}>
                  {labels_SA.SA_MOBILE}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {"(+" + dataObject?.countryCode + ")"}&nbsp;{dataObject?.withoutExtmobile}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography className={classes.quaternaryColor}>
                  {labels_SA.SA_EMAIL}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className="wordWrap">{dataObject?.email}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                {cidtype === "company" ? (
                  <Typography className={classes.quaternaryColor}>
                    {labels_SA.SA_REGISTRATION_NO}
                  </Typography>
                ) : idType === "QATAR_ID" ? (
                  <Typography className={classes.quaternaryColor}>
                    {label_User_management.SIGNUP_UNIQUE_ID_QATARID_LABEL}
                  </Typography>
                ) : (
                  <Typography className={classes.quaternaryColor}>
                    {label_User_management.SIGNUP_UNIQUE_ID_PASSPORTNO_LABEL}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography> {dataObject?.uniqueID}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography className={classes.quaternaryColor}>
                  {labels_SA.SA_LANDLINE}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {Extention_landline && "(+" + Extention_landline + ")"}{" "}
                  {landline ? landline : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography className={classes.quaternaryColor} variant="span">
                  {" "}
                  {labels.REPRESENTATIVE_DETAILS}
                </Typography>
                  &nbsp; &nbsp;
                <Typography className="wordWrap" variant="span">
                  {checkBox
                    ? dataObject?.rusername +
                      ", " +
                      "(+" +
                      dataObject?.rcountryCode +
                      ")" +
                      " " +
                      dataObject?.rwithoutExtmobile +
                      ", " +
                      dataObject?.remail
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

        
      {(dataObject?.jointOwnerDetails.length > 0) &&
      <Grid item xs={12}>
        <Typography color="primary" variant="h6" className="horizontalLine">
          <b> {labels_Upfront_Payment?.JOINT_OWNER_DETAILS_SUMMARY}</b>
          <IconButton onClick={handleApplicantDetailsEdit}>
            <BorderColorIcon color="primary" />
          </IconButton>{" "}
        </Typography>{" "}
        <SimpleTabs selectedTab={selectedTab} handleTabChange={handleTabChange} jointOwnerDetails={dataObject?.jointOwnerDetails}/>
        <CardContent className={classesLocal.cardBorder}>
        <Grid container direction="row" justify="space-between">
        <Grid item xs={12} sm={6} md={4}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography className={classes.quaternaryColor}>
                   {labels_SA.SA_NAME}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                {cidtype == "individual" ? 
                  getTitleValue(dataObject?.jointOwnerDetails[selectedTab].title) +
                    " " +
                    dataObject?.jointOwnerDetails[selectedTab].fusername +
                    " " +
                    dataObject?.jointOwnerDetails[selectedTab].username +
                    " " +
                    dataObject?.jointOwnerDetails[selectedTab].lusername
                  : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Typography className={classes.quaternaryColor}>
                {labels_SA.SA_MOBILE}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {"(+" + dataObject?.jointOwnerDetails[selectedTab]?.countryCode + ")"}&nbsp;{dataObject?.jointOwnerDetails[selectedTab].withoutExtmobile}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Typography className={classes.quaternaryColor}>
                {labels_SA.SA_EMAIL}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="wordWrap">{dataObject?.jointOwnerDetails[selectedTab].email}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              { 
              dataObject?.jointOwnerDetails[selectedTab].uniqueValue === "QATAR_ID" ? (
                <Typography className={classes.quaternaryColor}>
                  {label_User_management.SIGNUP_UNIQUE_ID_QATARID_LABEL}
                </Typography>
              ) : (
                <Typography className={classes.quaternaryColor}>
                  {label_User_management.SIGNUP_UNIQUE_ID_PASSPORTNO_LABEL}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Typography> {dataObject?.jointOwnerDetails[selectedTab].uniqueID}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Typography className={classes.quaternaryColor}>
                {labels_SA.SA_LANDLINE}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                { dataObject?.jointOwnerDetails[selectedTab]?.extension_landline && "(+" + dataObject?.jointOwnerDetails[selectedTab]?.extension_landline + ")"}{" "}
                { dataObject?.jointOwnerDetails[selectedTab]?.landline ? dataObject?.jointOwnerDetails[selectedTab]?.landline : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        </Grid>
        </CardContent>
      </Grid>
      }



      <Grid item xs={12}>
        {/* ------------property Details------------ */}
        <Typography color="primary" variant="h6" className="horizontalLine">
          <b> {labels_SA.SA_PROPERTY}</b>
          <IconButton onClick={handlePropertyDetailsEdit}>
            <BorderColorIcon color="primary" />
          </IconButton>{" "}
        </Typography>{" "}
        <Grid container direction="column">
          {propertyType === "villa" && (
            <Grid item>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <Typography className={classes.quaternaryColor}>
                    {labels_property_details.COOLING_CAPACITY}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>{cooling}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}{" "}
          <Grid item>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography className={classes.quaternaryColor}>
                  {labels_SA.SA_PREMISE}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>{selectedpremises.replaceAll('"', "")}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography className={classes.quaternaryColor}>
                  {labels_SA.SA_NOTES}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>{adlnotes ? adlnotes : "-"}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography className={classes.quaternaryColor}>
                  {labels_property_details.MAILING_ADDRESS}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {" "}
                  {dataObject?.unit}, {dataObject?.bname}, {dataObject?.bnum},{dataObject?.address} ,
                  {dataObject?.district}
                  {dataObject?.pobox && ", " + dataObject?.pobox}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {/* ----------- additional Details------------ */}
        <Typography color="primary" variant="h6" className="horizontalLine">
          <b> {labels_SA.ADDITIONAL_DETAILS}</b>
          <IconButton onClick={handleDocumentDetailsEdit}>
            <BorderColorIcon color="primary" />
          </IconButton>
        </Typography>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography className={classes.quaternaryColor}>
                  {labels_SA.FILE_ATTACHED}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {(attachment && attachment.length)} {labels_SA.SA_FILES}
                  <br />
                  (&nbsp;
                  {dataObject?.attachmentName.map((item, k) => {
                    return (
                      <React.Fragment>
                        {item}
                        {dataObject?.attachmentName.length - 1 > k && ", "}
                      </React.Fragment>
                    );
                  })}
                  )
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography className={classes.quaternaryColor}>
                  {labels_SA.SA_TERMS_AGREED}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  by {signature} at {moment(signaturetime).format("hh:mm A")} ,{" "}
                  {moment(signaturetime).format("ddd,DD MMM YYYY").toString()},
                  {timeZoneSplit[1]} time
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
      {dataObject.depositeAmount > 0 && 
      <div className="margin-top-30">
        <Alert severity="info">{payAndSubmitText}</Alert>
      </div>
      }
  
      <div className="margin-top-30">
        {internalError === true ? (
          <React.Fragment>
            <Alert severity="error" style={{marginBottom: 10}}>{errormsg}</Alert>
          </React.Fragment>
        ):<div className="margin-top-20"/>
        }
      </div>
    
    <Payment openPayment={openPayment} closePaySubmit={closePaySubmit} formData={dataObject} data={props.data} dataObject={data}/>
    </Grid>

    <hr className="margin-top-20" />
      <Grid container direction="row" className="margin-top-20 margin-bottom-20">
      <Grid item xs={2} sm={6}>
      <Button
        size="small"
        color="primary"
        startIcon={<ArrowBackIosIcon fontSize="small" />}
        onClick={handleDocumentDetailsEdit}
      >
        <b>{labels_SA?.SA_BACK}</b>
      </Button>
      </Grid>
      <Grid item xs={10} sm={6}>
      <Grid
        container
        spacing={2}
        className={classesLocal.rightAlign}
      >
        <Grid item>
          <Button
            onClick={handleCancelClick}
            variant="contained"
            color="default"
          >
            {labels_SA.SA_CANCEL}
          </Button>
        </Grid>
        <Grid item>
          <Button 
          color="primary" 
          onClick={nextScreen} 
          variant="contained">
            
            {isSubmitLoading ? (
              <>
              {labels_SA.SERVICE_AGREEMENT_SUBMITTING}
              <CircularProgress
                size={25}
                color="inherit"
                className={classes.whiteColor}
              />
              </>
            ) : dataObject?.depositeAmount > 0 ? labels_Upfront_Payment?.PAY_AND_SUBMIT : labels_SA.SA_SUBMIT}
          </Button>
        </Grid>
      </Grid>
      </Grid>
    </Grid>
    </>}   
    </>
  );
}


export function SimpleTabs(props) {
  const classes = useStyle();
  const labels_Upfront_Payment = useSelector((state)=>state?.labels?.data?.getLabels?.UPFRONT_PAYMENT);
  return (
    <div >
        <Tabs 
        variant="scrollable"
        scrollButtons="auto"
        value={props.selectedTab} 
        onChange={props.handleTabChange} 
        aria-label="simple tabs example"
        className={[classes.baseTabStyles,classes.tabs]}
        >
        {props.jointOwnerDetails.map((tab,index) => {
                return (
                    <Tab 
                      key={index}
                      className={ [(props.selectedTab == index) ? classes.selectedTab : classes.unSelctedTab , classes.tabStyles]}
                      label={<b>{labels_Upfront_Payment?.JOINT_OWNER_DETAILS}{" "}{index+1}</b>}
                    />
                )
          })}
        </Tabs>
    </div>
  );
}


function NSAMain(props) {
    const[data,setData] = useState();
    const[labelSubTitle,setLabelSubTitle] = useState();
    const[confirmSubTitle,setConfirmSubTitle] = useState();
    let lang = useSelector(
      (store) =>
        store?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
    );
    
    async function callAdmin(){
        await adminService.getContentData(lang, "NEW_SERVICE_AGREEMENT").then((res) => {
          store.getState().snackbar=false;
          setData(res.data.getContents.NEW_SERVICE_AGREEMENT)
          res.data.getContents.NEW_SERVICE_AGREEMENT.forEach((item) => {
            if (item.contentCode === "NEW_SERVICE_AGREEMENT_SUBTITLE") {
                setLabelSubTitle(item.answer);
            } 
            if (item.contentCode === "CONFIRM_SERVICE_AGREEMENT_SUBTITLE") {
                setConfirmSubTitle(item.answer)
            } 
          });
        }).catch((err) => {
          store.getState().snackbar=false;
        }) 
      }

      useEffect(() => {
        store.getState().snackbar=true;
        if(localStorage.getItem("authbearer")){
        callAdmin();
        }else{
        bearerTokenService
        .getBearerToken()
        .then(async (successBearer) => {          
        callAdmin();                  
        })
        .catch((error) => {
        });
    }
    },[lang])
  return (
    <React.Fragment>
        <CardContent className="cardPadding">
          {data && labelSubTitle ? (
            <ConfirmScreenMain
              baseStyle={props.baseStyle}
              data={data}
              authStyle={props.authStyle}
              labelSubTitle ={labelSubTitle}
              confirmSubTitle={confirmSubTitle}
            />
          ) : (
            <Skeleton variant="text" />
          )}
        </CardContent>
      </React.Fragment>
  )
}

export default function NConfirmScreenSA() {
  const classes = useStyles();
  const authClasses = authStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 460px)" });
  let quinaryBackgroundBlur = `rightSecondaryBack serviceHeight ${classes.quinaryBackground}`;
  let primaryBackgroundBlur = `primaryBack ${classes.primaryBackground} `;
  useEffect(() => {
    const rocketContainer = document.querySelector('.rocketchat-container');
    const rocketWidget = document.querySelector('.rocketchat-widget')
    if(rocketContainer && rocketWidget) {
      rocketContainer.style.display = "none"
      rocketWidget.style.display = "none" 
    }
  })
  useEffect(() => {
    window.scrollTo({
      top: 0,
      pointer: "cursor",
    });
  }, [])
  
  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <React.Fragment>
          <Grid container direction="column" >
            <Grid item>
              <div className={primaryBackgroundBlur} />
            </Grid>
            <Grid item>
              <div className={quinaryBackgroundBlur} />
            </Grid>
          </Grid>
          <Card className="stack-top">
            <NSAMain baseStyle={classes} authStyle={authClasses} />
          </Card>
        </React.Fragment>
      ) : (
        <div className="margin-top-60">
          <NSAMain baseStyle={classes} authStyle={authClasses} />
        </div>
      )}
    </React.Fragment>
  );
}