import React, { useState } from "react";
import {
  Typography,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { usageService, commonService } from "../../../services/index";
import { store } from "../../../helpers/store";
import { CircularDialog } from "../dialog-style";
export default function SkipCashTransactionRequest(props) {
  const [res, setRes] = useState(null);
  const [openDialog, setOpen] = useState(false);
  const debounce = (fn, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  const btnCall = () => {
    store.getState().snackbar = true;
    handleClickOpen();
    console.log("landingResponse:", props.landingResponse);
    let array = [];
    let check = commonService.encodeString(
      JSON.stringify(props.landingResponse)
    );
    localStorage.setItem("paymentType", props.paymentType);
    array.push({
      ConnectionId: props.connectionId,
      Amount: props.amount,
    });
    localStorage.removeItem("BillpayDetails");
    localStorage.removeItem("billId");
    localStorage.removeItem("Details");
    localStorage.setItem("Details", JSON.stringify(array));
    localStorage.removeItem("email");
    localStorage.setItem("email", props.emailId);
    localStorage.removeItem("customerName");
    localStorage.setItem("customerName", props.landingResponse.customerName);
    localStorage.removeItem("connectionId");
    localStorage.setItem("connectionId", check);
    localStorage.removeItem("mobileNo");
    localStorage.setItem("mobileNo", props.mobileNo);
    localStorage.removeItem("firstName");
    localStorage.setItem("firstName", props.landingResponse.firstName);
    localStorage.removeItem("lastName");
    localStorage.setItem("lastName", props.landingResponse.lastName);
    localStorage.setItem("paymentProcess", "Y");
    usageService
      .prePaymentProcessing(props.connectionId, props.amount, props.paymentType)
      .then((res) => {
        console.log("jayy prepayment:", res.data);
        setRes(res.data.prePaymentProcessing);
        localStorage.removeItem("paymentProcess");
        localStorage.removeItem("orderId");
        localStorage.setItem("orderId", res.data.prePaymentProcessing?.id);
        const paymentUrl = res.data.prePaymentProcessing?.payUrl;
        window.location.href = paymentUrl;
      })
      .catch((err) => {
        localStorage.removeItem("paymentProcess");
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <div>
      <Button
        variant="contained"
        size="medium"
        color="secondary"
        className={props.checkedA ? props.className : null}
        onClick={debounce(btnCall, 2000)}
        disabled={!props.checkedA}
      >
        {props.cardTitle}
      </Button>
      <CircularDialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openDialog}
      >
        <DialogTitle id="simple-dialog-title">
          <Typography color="primary" variant="h5">
            <b>{props.label.LOADING_DIALOG_TITLE}</b>
          </Typography>
        </DialogTitle>
        <DialogContent align="center">
          <div className="margin-top-20">
            <CircularProgress color="primary" size={60} />
          </div>
        </DialogContent>
        <DialogActions>
          <Typography className="padding-10">
            {props.label.LOADING_DIALOG_SUBTITLE}
          </Typography>{" "}
        </DialogActions>
      </CircularDialog>
    </div>
  );
}
