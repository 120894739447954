import { config } from "../../../../enviroment";
import axios from "axios";

export const ticketService = {
  getFileTypes:getFileTypes,
  sendOtpMobile:sendOtpMobile,
  velidateOtp:velidateOtp,
  vcsaRegistrationData:vcsaRegistrationData
};

async function getFileTypes(lang){

  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.COMMUNICATION_SERVICE_BASE_URL,
        {
          query:
            `query {
              fileTypes(
              input: {
                languageCode:"`+lang+`"
              }
            ) {
              EncodingType,
              FileAttachmentTypeDescription,
              FileAttachmentTypeCode
               
            }
          }`,
        }
      )
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function sendOtpMobile (mobileNumber) {
  const flowType = "NEW_USER_OTP";
  const tenantCode = config.parameters.TENANT_CODE;
  let bearer = localStorage.getItem("authbearer");
  bearer = JSON.parse(bearer);
  let accessToken = bearer.acessToken;

  return await axios({
    method: 'post',
    url: config.urls.NEW_USER_SEND_OTP,
    headers: {
      "accessToken" : accessToken
    }, 
    data: {
      "flowType": flowType,
      "mobile":mobileNumber,
      "tenantCode":tenantCode
    }
  })
}

async function velidateOtp (otp,mobileNumber) {
  const flowType = "NEW_USER_OTP";
  let bearer = localStorage.getItem("authbearer");
  bearer = JSON.parse(bearer);
  let accessToken = bearer.acessToken;

return await axios({
  method: 'post',
  url: config.urls.NEW_USER_VALIDATE_OTP,
  headers: {
    "accessToken" : accessToken
  }, 
  data: {
    "flowType": flowType,
    "otp": Number(otp),
    "mobile": mobileNumber
  }
})
}

async function vcsaRegistrationData(
  datass,dataObjectRegex
) {
    var regx = new RegExp(dataObjectRegex, "g");
     var datax=datass.replace(regx, '$1:');
     var finaldata=`{nsaRegistration(input:`+ datax +`)       
                             {
                              genCase
                              errorInformation {
                                  isInError
                              }
                              }
                   }`
  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.USAGE_SERVICE_BASE_URL, {
        query:finaldata,
      })
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}