import axios from "axios";
import { config } from "../../enviroment";
import { history } from "../../helpers/history";
import { routePaths } from "../config/route-paths";
import { bearerTokenService } from "../../services/bearer.service";
import Cookies from "universal-cookie";
import { checkAppVersion } from "./check-build-version";
import { gettingAppVersion } from "./check-build-version";

const TIMEOUT = 5 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
const envConfig = config;
let errCount=0;
const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config) => {
    if (
      !gettingAppVersion &&
      !window.location.href.includes(routePaths.PAYMENT) &&
      !window.location.href.includes(routePaths.NSA_PAY_CONFIRM) &&
      !window.location.href.includes(routePaths.PAYMENT_BILLPAY)
    ) {
      checkAppVersion();
    }
    config.headers.tenantCode = envConfig.parameters.TENANT_CODE;
    let authbearer = localStorage.getItem("authbearer");
    // const cookies = new Cookies();
    // let authbearer = cookies.get("authbearer");
    if (authbearer) {
      authbearer = JSON.parse(authbearer);
      const accessToken = authbearer.access_token;
      config.headers.accessToken = `${accessToken}`;
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  };
  const onResponseSuccess = (response) => response;
  const onResponseError = async (err) => {
    const status = err.status || (err.response ? err.response.status : 0);
    // if (err.config.url === envConfig.urls.GENERATE_BEARER) {
    //   history.push(routePaths.RETRY);
    // }
    // if (
    //   err.config.url === envConfig.urls.LOGIN ||
    //   err.config.url === envConfig.urls.SIGNUP ||
    //   err.config.url === envConfig.urls.LABELS ||
    //   err.config.url === envConfig.urls.GENERATE_BEARER
    // ) {
    //   if (status === 500) {
    //     history.push(routePaths.RETRY);
    //   }
    // }
    let errMessage='';
    if(err.response.data.message){
      errMessage=err.response.data.message
    }else{
      errMessage=Array.isArray(err.response.data.errors)?err.response.data.errors[0]['message']:''
    }
    if (status === 403 && errMessage.toLowerCase()==='access denied') {
      if(errCount===0 ){
        errCount=1;
        return await bearerTokenService.refreshBearerToken().then(async res=>{
          delete err.config.headers['accessToken']
          return new Promise(async (resolve, reject) => {
            await axios({
              url: err.config.url,
              method: err.config.method,
              data: err.config.data,
              headers: err.config.headers,
            })
              .then(response=> {
                errCount=0;
                res=resolve(response);
              })
          });
        });
      }else{
        errCount=0;
      }
    }else{
      errCount=0;
      return Promise.reject(err)
    }
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
