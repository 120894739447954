import React, { Component, useEffect } from "react";
import "../auth/auth.css";
import "../app/base-style.css";
import { useStyles } from "../app/base-style";
import { authStyles } from "../auth/auth-style";
import { Card, CardContent } from "@material-ui/core";
import "../quick-pay/quick-pay.css";
import { useMediaQuery } from "react-responsive";
import Retry from "../retry/retry";
import { adminService } from "../../services/index";
import { Skeleton } from "@material-ui/lab";
import { QuickPayCommonHeader } from "../quick-pay/quick-pay-header";

export default function RetryPage() {
  const classes = useStyles();
  const authClasses = authStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 960px)" });
  let primaryBackgroundBlur = ` ${classes.primaryBackground}`;

  useEffect(() => {
    const rocketContainer = document.querySelector('.rocketchat-container');
    const rocketWidget = document.querySelector('.rocketchat-widget')
    if(rocketContainer && rocketWidget) {
      rocketContainer.style.display = "none"
      rocketWidget.style.display = "none" 
    }
  })

  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <div className={primaryBackgroundBlur}>
            <RetryMain baseStyle={classes} authStyle={authClasses} />
          
        </div>
      ) : (
        <div className="margin-top-60">
          <RetryMain baseStyle={classes} authStyle={authClasses} />
        </div>
      )}
    </React.Fragment>
  );
}
class RetryMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:"",
      contentData:""
    };
  }
  componentDidMount() {
    adminService.getContentData("en", "SERVER_EXCEPTIONS").then((res) => {
      this.setState({
        data: res.data.getContents.SERVER_EXCEPTIONS,
      });
      res.data.getContents.SERVER_EXCEPTIONS.forEach((item) => {
        if (item.contentCode === "MAIL_PARAGRAPH") {
          this.setState({
            contentData: item.answer.replace("***","link"),
          });
        } 
      });
    });
  }

  render() {
    let { data, contentData } = this.state;
    return (
      <React.Fragment>
            <Retry
              baseStyle={this.props.baseStyle}
              authStyle={this.props.authStyle}
              data={data}
              contentData={contentData}
            />
      </React.Fragment>
    );
  }
}
