import React, { Component } from "react";
import "../auth/auth.css";
import "../app/base-style.css";
import Grid from "@material-ui/core/Grid";
import { FormHelperText, Typography, Button } from "@material-ui/core";
import { userService } from "../../services/index";
import { history } from "../../helpers/index";
import { routePaths } from "../config/route-paths";
import { useStyles } from "../app/base-style";
import { store } from "../../helpers";
import { pathActions } from "../../actions";
import { connect } from "react-redux";
import { CssTextField, authStyles } from "../auth/auth-style";
import Alert from "@material-ui/lab/Alert";
import { useMediaQuery } from "react-responsive";
import ImageBackground from "../../assests/qc-bg.jpg";
import { useEffect } from "react";

let labels = {};
store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
});
function ResetForm(props) {
  const classes = useStyles();
  const authClasses = authStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
  let rightBlue = `split right ${classes.primaryBackground}`;

  let token = props.match.params.token;
  useEffect(() =>{
    const rocketContainer = document.querySelector('.rocketchat-container');
    const rocketWidget = document.querySelector('.rocketchat-widget')
    if(rocketContainer && rocketWidget) {
      rocketContainer.style.display = "none"
      rocketWidget.style.display = "none" 
    }
  })
  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <div >
          <div className="bodyimage" />
          <div className={rightBlue}>
            <MainResetForm
              baseStyle={classes}
              labels={labels}
              token={token}
              propsMain={props}
              authStyle={authClasses}
            />
          </div>
        </div>
      ) : (
        <React.Fragment>
          <Grid container direction="column" spacing={0}>
            <Grid item className={[classes.primaryBackground,classes.paddingTopHomepage]}>
              <MainResetForm
                baseStyle={classes}
                labels={labels}
                token={token}
                propsMain={props}
                authStyle={authClasses}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

class MainResetForm extends Component {
  constructor(props) {
    props.propsMain.getLocationPath(history.location.pathname);

    super(props);
    this.state = {
      password: "",
      Repassword: "",
      pwdrequired: false,
      pwdError: false,
      repwdRequired: false,
      repwdError: false,
      success: false,
      msg: "",
      error: false,
      isSignupLoading: false,
    };
    this.keypress = this.keypress.bind(this);

    this.pwdBlur = this.pwdBlur.bind(this);
    this.repwdBlur = this.repwdBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cancelSubmit = this.cancelSubmit.bind(this);
    this.resetFormSubmit = this.resetFormSubmit.bind(this);
  }

  pwdBlur() {
    const { password } = this.state;
    if (password) {
      this.setState({
        pwdrequired: false,
      });
      if (store.getState().parametersWidgets.ParameterLookup) {
        var data = store.getState().parametersWidgets.ParameterLookup
          .PASSWORD_REGEX;
        var reg = new RegExp(data);
        var test = reg.test(password);
        if (test) {
          this.setState({
            pwdError: false,
            error: false,
          });
        } else {
          this.setState({
            pwdError: true,
            pwdrequired: false,
            success: false,
            error: false,
          });
        }
      }
    } else {
      this.setState({
        pwdrequired: true,
        pwdError: false,
        error: false,
        success: false,
      });
    }
  }

  repwdBlur() {
    const { password, Repassword } = this.state;
    if (Repassword) {
      this.setState({
        repwdRequired: false,
        error: false,
      });
      if (password && Repassword) {
        if (password === Repassword) {
          this.setState({
            repwdError: false,
          });
        } else {
          this.setState({
            repwdError: true,
            pwdrequired: false,
            pwdError: false,
            success: false,
            error: false,
          });
        }
      } else {
        this.setState({
          confirmError: true,
          pwdrequired: false,
          pwdError: false,
          success: false,
          error: false,
        });
      }
    } else {
      this.setState({
        repwdRequired: true,
        repwdError: false,
        pwdError: false,
        error: false,
        success: false,
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  cancelSubmit = () => {
    this.setState({
      pwdrequired: false,
      pwdError: false,
      repwdRequired: false,
      repwdError: false,
      success: false,
      error: false,
    });
    history.push(routePaths.LOGIN);
  };

  resetFormSubmit(e) {
    const { password } = this.state;

    this.pwdBlur();
    this.repwdBlur();
    if (
      this.state.pwdrequired === false &&
      this.state.pwdError === false &&
      this.state.repwdRequired === false &&
      this.state.repwdError === false &&
      password &&
      this.state.Repassword
    ) {
      this.setState({
        isSignupLoading: true,
      });
      const token = this.props.token;
      userService
        .resetForm(password, token)
        .then((response) => {
          this.props.propsMain.getPath(routePaths.RESET_FORM);
          history.push(routePaths.SIGNUP_SUCCESS);
          this.setState({
            isSignupLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            error: true,
            msg: error.response.data.message,
            isSignupLoading: false,
          });
        });
    }
  }

  keypress(event) {
    if (event.key === "Enter") {
      this.resetFormSubmit(event);
    }
  }

  render() {
    const { password, Repassword } = this.state;
    let helperTextColor = `helpTextMargin ${this.props.baseStyle.whiteColor}`;
    let CancelButtonStyle = `btnBorder ${this.props.baseStyle.whiteColor} ${this.props.baseStyle.whiteBorder}`;
    let submitButtonStyle = `btnBorder ${this.props.baseStyle.whiteColor}`;
    let loadingStyle = `btnBorder ${this.props.authStyle.loadingCursor} ${this.props.baseStyle.whiteColor}`;

    return (
      <React.Fragment>
        {store.getState().labels.data && (
          <React.Fragment>
            <div className="containermargin">
              <Typography
                className={this.props.baseStyle.whiteColor}
                variant="h4"
                align="center"
              >
                <b>{this.props.labels.PASSWORD_COMPARISION_TITLE}</b>
              </Typography>
              <div className="margin-top-50">
                <br />
              </div>

              <Grid container spacing={3} direction="column">
                <Grid item>
                  <Typography
                    variant="h6"
                    align="left"
                    className={
                      this.state.pwdrequired || this.state.pwdError
                        ? this.props.baseStyle.errorColor
                        : this.props.baseStyle.whiteColor
                    }
                  >
                    {this.props.labels.NEW_PASSWORD}
                  </Typography>
                  <CssTextField
                    fullWidth
                    type="password"
                    margin="normal"
                    id="Password_Id"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                    onBlur={this.pwdBlur}
                    error={this.state.pwdrequired || this.state.pwdError}
                    autoComplete="off"
                    helperText={
                      this.state.pwdrequired === true
                        ? this.props.labels.ERROR_PASSWORD_REQUIRED
                        : this.state.pwdError === true
                        ? this.props.labels.LOGIN_INVALID_PASSWORD
                        : null
                    }
                  />
                  <br />
                </Grid>
                <Grid item>
                  <Typography
                    variant="h6"
                    align="left"
                    className={
                      this.state.repwdRequired || this.state.repwdError
                        ? this.props.baseStyle.errorColor
                        : this.props.baseStyle.whiteColor
                    }
                  >
                    {this.props.labels.SIGNUP_CONFIRM_PASSWORD}
                  </Typography>
                  <CssTextField
                    fullWidth
                    type="password"
                    margin="normal"
                    id="Repassword_Id"
                    name="Repassword"
                    value={Repassword}
                    onChange={this.handleChange}
                    onBlur={this.repwdBlur}
                    error={this.state.repwdRequired || this.state.repwdError}
                    autoComplete="off"
                    onKeyPress={this.keypress}
                    helperText={
                      this.state.repwdRequired === true
                        ? this.props.labels.SIGNUP_CONFIRM_PASSWORD_REQUIRED
                        : this.state.repwdError === true
                        ? this.props.labels.ERROR_ERROR
                        : null
                    }
                  />
                  <br />
                </Grid>
              </Grid>
              <div className="margin-top-50">
                <br />
              </div>
              <Grid
                className="margin-top-50"
                container
                justify="center"
                alignItems="center"
                spacing={2}
                direction="row-reverse"
              >
                <Grid item>
                  <Button
                    type="submit"
                    className={
                      this.state.isSignupLoading
                        ? loadingStyle
                        : submitButtonStyle
                    }
                    variant="contained"
                    color="secondary"
                    onClick={
                      this.state.isSignupLoading === false
                        ? this.resetFormSubmit
                        : null
                    }
                  >
                    {this.props.labels.BUTTON_SUBMIT}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="reset"
                    className={CancelButtonStyle}
                    onClick={this.cancelSubmit}
                  >
                    {this.props.labels.BUTTON_CANCEL}
                  </Button>
                </Grid>
              </Grid>
              <div className="margin-top-60">
                {this.state.error === true && (
                  <React.Fragment>
                    <br />
                    <Alert severity="error">{this.state.msg}</Alert>
                  </React.Fragment>
                )}
              </div>
            </div>
            <FormHelperText className={helperTextColor}>
              {this.props.labels.ERROR_HELPER}
            </FormHelperText>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const actionCreators = {
  getPath: pathActions.getPath,
  getLocationPath: pathActions.getLocationPath,
};
const connectedResetForm = connect(null, actionCreators)(ResetForm);
export { connectedResetForm as ResetForm };
