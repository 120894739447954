import React, { useRef, useEffect, useState } from "react";
import "../../auth/auth.css";
import "../../app/base-style.css";
import { useStyles } from "../../app/base-style";
import { authStyles } from "../../auth/auth-style";
import { Card, CardContent, Grid, Typography, Button } from "@material-ui/core";
import "../quick-pay.css";
import { useMediaQuery } from "react-responsive";
import { config } from "../../../enviroment";
import {
  adminService,
  commonService,
  usageService,
} from "../../../services/index";
import { Skeleton } from "@material-ui/lab";
import { QuickPayCommonHeader } from "../quick-pay-header";
import { store } from "../../../helpers";
import { routePaths } from "../../config/route-paths";
import { Link } from "react-router-dom";
import { history } from "../../../helpers/index";
import ReactToPrint from "react-to-print";
import PaymentComponent from "./payment-component";
import BillpayComponent from "./billpay-payment-component";
import headerLogo from "../../../assests/icon-QC.png";
import { appActions } from "../../../actions/app.actions";
import { connect } from "react-redux";

let labels = "";
let paymentType_MIGS = "";
let paymentType_QPay = "";
let paymentType_CyberSource = "";
let paymentType_SkipCash = "";
let language_Code = "";
let label = "";

store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
    label = store.getState().labels.data.getLabels.PAYMENT_CONFIRMATION;
  }
  if (store.getState().parametersWidgets.ParameterLookup) {
    paymentType_MIGS = store
      .getState()
      .parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[1];
    paymentType_QPay = store
      .getState()
      .parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[0];
    paymentType_CyberSource = store
      .getState()
      .parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[2];
    paymentType_SkipCash = store
      .getState()
      .parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[3];
    language_Code =
      store.getState().parametersWidgets.ParameterLookup
        .TENANT_PRIMARY_LANGUAGE;
  }
});

let resultIndicator = new URLSearchParams(window.location.search).get(
  "resultIndicator"
);
let decision = new URLSearchParams(window.location.search).get("decision");
let statusId = new URLSearchParams(window.location.search).get("statusId");
let billpayUrl = JSON.stringify(window.location.href).includes(
  "/payment/billpay"
);
let Response_Amount = new URLSearchParams(window.location.search).get(
  "Response.Amount"
);
let Response_CurrencyCode = new URLSearchParams(window.location.search).get(
  "Response.CurrencyCode"
);
let Response_PUN = new URLSearchParams(window.location.search).get(
  "Response.PUN"
);
let Response_EZConnectResponseDate = new URLSearchParams(
  window.location.search
).get("Response.EZConnectResponseDate");
let Response_ConfirmationID = new URLSearchParams(window.location.search).get(
  "Response.ConfirmationID"
);
let Response_MerchantModuleSessionID = new URLSearchParams(
  window.location.search
).get("Response.MerchantModuleSessionID");
let Response_Status = new URLSearchParams(window.location.search).get(
  "Response.Status"
);
let Response_StatusMessage = new URLSearchParams(window.location.search).get(
  "Response.StatusMessage"
);
let Response_MerchantID = new URLSearchParams(window.location.search).get(
  "Response.MerchantID"
);
let Response_BankID = new URLSearchParams(window.location.search).get(
  "Response.BankID"
);
let Response_Lang = new URLSearchParams(window.location.search).get(
  "Response.Lang"
);
let Response_AcquirerID =
  new URLSearchParams(window.location.search).get("Response.AcquirerID") || "";
let Response_CardNumber = new URLSearchParams(window.location.search).get(
  "Response.CardNumber"
);

let Response_CardExpiryDate = new URLSearchParams(window.location.search).get(
  "Response.CardExpiryDate"
);
let Response_CardHolderName =
  new URLSearchParams(window.location.search).get("Response.CardHolderName") ||
  " ";
let Response_SecureHash = new URLSearchParams(window.location.search).get(
  "Response.SecureHash"
);
let Response_AgentID = new URLSearchParams(window.location.search).get(
  "Response.AgentID"
);

function QuickPayPayment(props) {
  const classes = useStyles();
  const authClasses = authStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 560px)" });
  let quinaryBackgroundBlur = `rightSecondaryBack ${classes.quinaryBackground}`;
  let primaryBackgroundBlur = `primaryBack ${classes.primaryBackground} `;

  useEffect(() => {
    const rocketContainer = document.querySelector(".rocketchat-container");
    if (rocketContainer) {
      rocketContainer.style.display = "none";
    }
  });

  return (
    <React.Fragment>
      {isTabletOrMobile ? (
        <React.Fragment>
          <Grid container direction="column">
            <Grid item>
              <div className={primaryBackgroundBlur} />
            </Grid>
            <Grid item>
              <div className={quinaryBackgroundBlur} />
            </Grid>
          </Grid>
          <Card className="stack-top">
            <QuickPayPaymentMain
              baseStyle={classes}
              authStyle={authClasses}
              props={props}
            />
          </Card>
        </React.Fragment>
      ) : (
        <div className="margin-top-60">
          <QuickPayPaymentMain
            baseStyle={classes}
            authStyle={authClasses}
            props={props}
          />
        </div>
      )}
    </React.Fragment>
  );
}

function QuickPayPaymentMain(props) {
  const [labelSubTitle, setlabelSubTitle] = useState(null);
  const [appLogo, setAppLogo] = useState(null);
  const componentRef = useRef();
  let [connectionId, setConnectionId] = useState(null);
  let [response, setResponse] = useState([]);
  let [mainResponse, setMainResponse] = useState({});
  const [paymentPara, setpaymentPara] = useState(null);
  const [error, setError] = useState(null);
  const [skeletonValue, setSkeleton] = useState(false);

  let [isBillpay, setIsbillPay] = useState(false);
  let payment_Type = "";
  let billId = localStorage.getItem("billId");
  let emailId = localStorage.getItem("email");
  let responseLandingData = JSON.parse(localStorage.getItem("connectionId"));
  let mobileNo = localStorage.getItem("mobileNo");
  if (resultIndicator && paymentType_MIGS) {
    payment_Type = paymentType_MIGS;
  } else if (decision && paymentType_CyberSource) {
    payment_Type = paymentType_CyberSource;
  } else if (statusId && paymentType_SkipCash) {
    payment_Type = paymentType_SkipCash;
  } else if (paymentType_QPay) {
    payment_Type = paymentType_QPay;
  }

  useEffect(() => {
    if (responseLandingData) {
      if (billpayUrl) {
        setConnectionId(responseLandingData?.billDetails?.billNumber);
        setIsbillPay(true);
      } else {
        setConnectionId(responseLandingData.connectionId);
        setIsbillPay(false);
      }
    }
    adminService.getContentData("en", "USER_MANAGEMENT").then((res) => {
      res.data.getContents.USER_MANAGEMENT.forEach((item) => {
        if (item.contentCode === "QUICK_PAY_SUBTITLE" && !billpayUrl) {
          setlabelSubTitle(item.answer);
        } else if (item.contentCode === "PAYMENT_PARAGRAPH") {
          setpaymentPara(item.answer);
        } else if (item.contentCode === "BILL_PAY_SUBTITLE" && billpayUrl) {
          setlabelSubTitle(item.answer);
        }
      });
    });

    if (payment_Type && language_Code) {
      setSkeleton(true);
      if (
        localStorage.getItem("orderId") &&
        localStorage.getItem("connectionId")
      ) {
        if (!resultIndicator && !decision && !statusId) {
          Response_StatusMessage = Response_StatusMessage?.replaceAll(" ", "+");
          Response_AgentID = Response_AgentID?.replace(null, "");

          if (billpayUrl) {
            //debit bill pay call
            if (Response_CardNumber && Response_CardExpiryDate) {
              usageService
                .postPaymentProcessingDebitBillPay(
                  payment_Type,
                  Response_Amount,
                  Response_BankID,
                  Response_CurrencyCode,
                  Response_EZConnectResponseDate,
                  Response_Lang,
                  Response_MerchantID,
                  Response_MerchantModuleSessionID,
                  Response_PUN,
                  Response_SecureHash,
                  Response_ConfirmationID,
                  Response_Status,
                  Response_StatusMessage,
                  Response_AcquirerID,
                  Response_CardNumber,
                  Response_CardExpiryDate,
                  Response_CardHolderName,
                  Response_AgentID
                )
                .then((res) => {
                  clearLocalStorage();
                  setSkeleton(false);
                  setMainResponse(res);
                })
                .catch((e) => {
                  setSkeleton(false);
                  clearLocalStorage();
                  e && e.response
                    ? e.response.data && e.response.data.errors
                      ? setError(e.response.data.errors[0].message)
                      : setError(e.response.data.message)
                    : setError("something went wrong");
                });
              clearLocalStorage();
            } else {
              let billId = localStorage.getItem("billId");
              clearLocalStorage();
              history.push(routePaths.BILL_PAY + "?bill-id=" + billId);
            }
          } else {
            //debit quick pay call
            if (Response_CardNumber && Response_CardExpiryDate) {
              usageService
                .postPaymentProcessingDebitPay(
                  payment_Type,
                  Response_Amount,
                  Response_BankID,
                  Response_CurrencyCode,
                  Response_EZConnectResponseDate,
                  Response_Lang,
                  Response_MerchantID,
                  Response_MerchantModuleSessionID,
                  Response_PUN,
                  Response_SecureHash,
                  Response_ConfirmationID,
                  Response_Status,
                  Response_StatusMessage,
                  Response_AcquirerID,
                  Response_CardNumber,
                  Response_CardExpiryDate,
                  Response_CardHolderName,
                  Response_AgentID,
                  mobileNo,
                  emailId
                )
                .then((res) => {
                  clearLocalStorage();
                  setSkeleton(false);
                  setResponse([
                    res.data.data.postPaymentProcessing.mainData[0],
                  ]);
                  setMainResponse(res.data);
                  store.getState().snackbar = false;
                })
                .catch((e) => {
                  clearLocalStorage();
                  e.response &&
                    e.response.data &&
                    setError(e.response.data.errors[0].message);
                  setSkeleton(false);
                });
              clearLocalStorage();
            } else {
              clearLocalStorage();
              props.props.getConnectionDetails(responseLandingData, "", "");
              history.push(routePaths.VIEW_BALANCE);
            }
          }
        } else if (decision && paymentType_CyberSource) {
          const queryString = window.location.search
            .substring(1)
            .replace(/\+/g, "%2B");
          const urlParams = new URLSearchParams(queryString);
          const RequestInput = {};
          urlParams.forEach((value, key) => {
            const decodedKey = decodeURIComponent(key);
            const decodedValue = decodeURIComponent(value);
            RequestInput[decodedKey] = decodedValue;
          });
          if (billpayUrl) {
            if (
              RequestInput.req_card_number &&
              RequestInput.req_card_expiry_date
            ) {
              usageService
                .postPaymentProcessingCreditBillPay(
                  paymentType_CyberSource,
                  RequestInput
                )
                .then((res) => {
                  clearLocalStorage();
                  setSkeleton(false);
                  setMainResponse(res);
                })
                .catch((e) => {
                  setSkeleton(false);
                  clearLocalStorage();
                  e && e.response
                    ? e.response.data && e.response.data.errors
                      ? setError(e.response.data.errors[0].message)
                      : setError(e.response.data.message)
                    : setError("something went wrong");
                });
              clearLocalStorage();
            } else {
              let billId = localStorage.getItem("billId");
              clearLocalStorage();
              history.push(routePaths.BILL_PAY + "?bill-id=" + billId);
            }
          } else {
            if (
              RequestInput.req_card_number &&
              RequestInput.req_card_expiry_date
            ) {
              usageService
                .postPaymentProcessing(
                  paymentType_CyberSource,
                  RequestInput,
                  emailId,
                  billId
                )
                .then((res) => {
                  clearLocalStorage();
                  setSkeleton(false);
                  setResponse([
                    res.data.data.postPaymentProcessing.mainData[0],
                  ]);
                  setMainResponse(res.data);
                  store.getState().snackbar = false;
                })
                .catch((e) => {
                  setSkeleton(false);
                  clearLocalStorage();
                  e && e.response
                    ? e.response.data && e.response.data.errors
                      ? setError(e.response.data.errors[0].message)
                      : setError(e.response.data.message)
                    : setError("something went wrong");
                });
              clearLocalStorage();
            } else {
              clearLocalStorage();
              props.props.getConnectionDetails(responseLandingData, "", "");
              history.push(routePaths.VIEW_BALANCE);
            }
          }
        } else if (statusId && paymentType_SkipCash) {
          const queryString = window.location.search
            .substring(1)
            .replace(/\+/g, "%2B");
          const urlParams = new URLSearchParams(queryString);
          const RequestInput = {};
          urlParams.forEach((value, key) => {
            const decodedKey = decodeURIComponent(key);
            const decodedValue = decodeURIComponent(value);
            RequestInput[decodedKey] = decodedValue;
          });
          if (billpayUrl) {
            if (RequestInput.status && RequestInput.id) {
              usageService
                .postPaymentProcessingCreditBillPay(
                  paymentType_SkipCash,
                  RequestInput
                )
                .then((res) => {
                  clearLocalStorage();
                  setSkeleton(false);
                  setMainResponse(res);
                })
                .catch((e) => {
                  setSkeleton(false);
                  clearLocalStorage();
                  e && e.response
                    ? e.response.data && e.response.data.errors
                      ? setError(e.response.data.errors[0].message)
                      : setError(e.response.data.message)
                    : setError("something went wrong");
                });
              clearLocalStorage();
            } else {
              let billId = localStorage.getItem("billId");
              clearLocalStorage();
              history.push(routePaths.BILL_PAY + "?bill-id=" + billId);
            }
          } else {
            if (RequestInput.id && RequestInput.statusId) {
              usageService
                .postPaymentProcessing(
                  paymentType_SkipCash,
                  RequestInput,
                  emailId,
                  billId
                )
                .then((res) => {
                  clearLocalStorage();
                  setSkeleton(false);
                  setResponse([
                    res.data.data.postPaymentProcessing.mainData[0],
                  ]);
                  setMainResponse(res.data);
                  store.getState().snackbar = false;
                })
                .catch((e) => {
                  setSkeleton(false);
                  clearLocalStorage();
                  e && e.response
                    ? e.response.data && e.response.data.errors
                      ? setError(e.response.data.errors[0].message)
                      : setError(e.response.data.message)
                    : setError("something went wrong");
                });
              clearLocalStorage();
            } else {
              clearLocalStorage();
              props.props.getConnectionDetails(responseLandingData, "", "");
              history.push(routePaths.VIEW_BALANCE);
            }
          }
        } else {
          if (billpayUrl) {
            usageService
              .postPaymentProcessingBill(resultIndicator)
              .then((res) => {
                setMainResponse(res);
                setSkeleton(false);
                clearLocalStorage();
              })
              .catch((e) => {
                clearLocalStorage();
                setSkeleton(false);
                e?.response &&
                  e?.response?.data &&
                  setError(e?.response?.data?.message);
              });
            clearLocalStorage();
          } else {
            usageService
              .paymentGatewayResponse(
                emailId,
                payment_Type,
                resultIndicator,
                responseLandingData?.propertyCode,
                mobileNo,
                language_Code
              )
              .then((respo) => {
                setResponse([
                  respo.data.data.postPaymentProcessing.mainData[0],
                ]);
                setMainResponse(respo.data);
                setSkeleton(false);
                clearLocalStorage();
              })
              .catch((e) => {
                clearLocalStorage();
                e.response &&
                  e.response.data &&
                  setError(e.response.data.errors[0].message);
                setSkeleton(false);
              });
            clearLocalStorage();
          }
        }
      } else {
        clearLocalStorage();
        retryFunction();
      }
    }
  }, [payment_Type, resultIndicator, language_Code]);
  const clearLocalStorage = () => {
    localStorage.removeItem("BillpayDetails");
    localStorage.removeItem("Details");
    localStorage.removeItem("customerName");
    localStorage.removeItem("orderId");
    localStorage.removeItem("connectionId");
  };
  if (!appLogo && store.getState().parametersWidgets.ParameterLookup) {
    commonService
      .getAssestUrl(
        store.getState().parametersWidgets.ParameterLookup.TENANT_APP_LOGO
      )
      .then((resp) => {
        setAppLogo(resp);
      });
  }

  function retryFunction() {
    if (billpayUrl) {
      history.push(routePaths.BILL_PAY + "?bill-id=" + billId);
    } else {
      props.props.getConnectionDetails(responseLandingData, "", "");
      history.push(routePaths.VIEW_BALANCE);
    }
  }

  return (
    <React.Fragment>
      {labels ? (
        <CardContent>
          {labelSubTitle ? (
            <QuickPayCommonHeader
              billpay={billpayUrl ? "true" : null}
              labelSubTitle={labelSubTitle}
              baseStyle={props.baseStyle}
            />
          ) : (
            <Skeleton />
          )}
          <div className="padding-10">
            <div className="displayPrint">
              <div ref={componentRef} className="printSize">
                <img
                  className="app-logo-print"
                  src={config.urls.ASSEST_URL_ENDPOINT + "/qc-default-logo.png"}
                  alt={
                    appLogo
                      ? labels[appLogo.fallBackLabelKey]
                      : labels.ASSET_LOGO_FALLBACK_TEXT
                  }
                />
                {labelSubTitle && (
                  <QuickPayCommonHeader
                    billpay={billpayUrl ? "true" : null}
                    labelSubTitle={labelSubTitle}
                    baseStyle={props.baseStyle}
                    printPage={true}
                  />
                )}
                {billpayUrl ? (
                  (mainResponse && mainResponse.length !== 0) || error ? (
                    <BillpayComponent
                      res={mainResponse}
                      error={error}
                      billId={billId}
                    />
                  ) : (
                    <Skeleton />
                  )
                ) : connectionId && (response.length != 0 || error) ? (
                  <PaymentComponent
                    baseStyle={props.baseStyle}
                    error={error}
                    paymentInfo={!error && response}
                    paymentType={payment_Type}
                    connectionId={connectionId}
                    isBillpay={isBillpay}
                    mainResponse={mainResponse}
                  />
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            {billpayUrl ? (
              (mainResponse && mainResponse.length !== 0) || error ? (
                <BillpayComponent
                  res={mainResponse}
                  error={error}
                  billId={billId}
                />
              ) : (
                <Skeleton />
              )
            ) : connectionId && (response.length != 0 || error) ? (
              <PaymentComponent
                baseStyle={props.baseStyle}
                error={error}
                paymentInfo={!error && response}
                paymentType={payment_Type}
                connectionId={connectionId}
                isBillpay={isBillpay}
                mainResponse={mainResponse}
              />
            ) : (
              <Skeleton />
            )}
            {skeletonValue === true ? (
              <div>
                <Skeleton />
                <div className="margin-top-10">
                  {label.PAYMENT_CONFIRMATION_WAITING_MESSAGE}
                </div>
              </div>
            ) : null}
            <br />
            <Typography component="subtitle1" color="primary">
              <b>{labels.QUICK_PAY_MORE_ACTIONS}</b>
            </Typography>
            <br />
            {error && (
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={retryFunction}
                  >
                    {label.PAYMENT_CONFIRMATION_RETRY}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={props.baseStyle.quaternaryColor}
                    onClick={() => history.push(routePaths.BILL_PAY)}
                  >
                    {labels.BUTTON_CANCEL}
                  </Button>
                </Grid>
              </Grid>
            )}
            {!billpayUrl &&
            mainResponse &&
            mainResponse.data &&
            mainResponse.data.postPaymentProcessing &&
            mainResponse.data.postPaymentProcessing.genericErrorInformation
              .isInError == "true" ? (
              <Grid container direction="row" spacing={2}>
                {mainResponse.data.postPaymentProcessing.genericErrorInformation
                  .status === "RETRY_ALLOWED" && (
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={retryFunction}
                    >
                      {label.PAYMENT_CONFIRMATION_RETRY}
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    className={props.baseStyle.quaternaryColor}
                    onClick={() => history.push(routePaths.BILL_PAY)}
                  >
                    {labels.BUTTON_CANCEL}
                  </Button>
                </Grid>
              </Grid>
            ) : !billpayUrl && !error ? (
              <ReactToPrint
                trigger={() => (
                  <Button color="primary" variant="contained">
                    {labels.QUICK_PAY_PRINT_RECEIPT}
                  </Button>
                )}
                content={() => componentRef.current}
                documentTitle={labels.QUICK_PAY_TITLE}
                copyStyles
              />
            ) : null}

            {billpayUrl &&
            mainResponse &&
            mainResponse.genericErrorInformation &&
            mainResponse.genericErrorInformation.isInError == "true" ? (
              <Grid container direction="row" spacing={2}>
                {mainResponse.genericErrorInformation.status ===
                  "RETRY_ALLOWED" && (
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={retryFunction}
                    >
                      {label.PAYMENT_CONFIRMATION_RETRY}
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    className={props.baseStyle.quaternaryColor}
                    onClick={() => history.push(routePaths.BILL_PAY)}
                  >
                    {labels.BUTTON_CANCEL}
                  </Button>
                </Grid>
              </Grid>
            ) : billpayUrl && !error ? (
              <ReactToPrint
                trigger={() => (
                  <Button color="primary" variant="contained">
                    {labels.QUICK_PAY_PRINT_RECEIPT}
                  </Button>
                )}
                content={() => componentRef.current}
                documentTitle={labels.QUICK_PAY_TITLE}
                copyStyles
              />
            ) : null}
            <Typography
              variant="body1"
              className="margin-top-30"
              dangerouslySetInnerHTML={{
                __html: paymentPara,
              }}
            ></Typography>
          </div>
          {!billpayUrl && (
            <React.Fragment>
              <hr className="margin-top-20" />
              <Link to={routePaths.QUICK_PAY}>
                <Typography
                  color="primary"
                  align="center"
                  className="margin-top-30"
                >
                  &#10094; {labels.QUICK_PAY_BACK_TO_HOME}
                </Typography>{" "}
              </Link>
            </React.Fragment>
          )}
        </CardContent>
      ) : (
        <Skeleton variant="text" />
      )}
    </React.Fragment>
  );
}

const actionCreators = {
  getConnectionDetails: appActions.getConnectionDetails,
};

const connectedApp = connect(null, actionCreators)(QuickPayPayment);
export { connectedApp as QuickPayPayment };
