import axios from "axios";
import { config } from "../enviroment";

export const usageService = {
  getBalance,
  postPaymentProcessingDebitPay,
  prePaymentProcessing,
  paymentGatewayResponse,
  getBillDetailsQPay,
  prePaymentProcessingBill,
  postPaymentProcessingBill,
  postPaymentProcessingDebitBillPay,
  postPaymentProcessingCreditBillPay,
  postPaymentProcessing,
};

function generateGraphQLQuery(requestInput) {
  let requestInputFields = "";

  for (const [key, value] of Object.entries(requestInput)) {
    const escapedValue =
      typeof value === "string" ? value.replace(/"/g, '\\"') : value;
    requestInputFields += `${key}: "${escapedValue}",\n`;
  }

  let APIquery = `
    query {
      postPaymentProcessing (
        input: {
          RequestInput: {
            ${requestInputFields}
          }
        }
      )
    }
  `;

  return APIquery;
}
async function getBalance(connectionId) {
  let acessToken = localStorage.getItem("bearer");
  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.USAGE_SERVICE_BASE_URL,
        {
          query: `{ postBalanceDetails
            { outstandingBalance 
              customerId 
              accountId 
              customerName 
              propertyCode 
              errorDetails 
              premiseId 
              deviceId 
              connectionId 
              emailId
              firstName         
              lastName
              middleName
              personOrBusiness 
              title  
              status 
            }
           }`,
        },
        {
          headers: {
            connectionId: connectionId,
            Authorization: "Bearer" + acessToken,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

async function postPaymentProcessingDebitPay(
  paymentType,
  amount,
  bankId,
  CurrencyCode,
  responseDate,
  lang,
  MerchantID,
  MerchantModuleSessionID,
  PUN,
  SecureHash,
  Response_ConfirmationID,
  Response_Status,
  Response_StatusMessage,
  Response_AcquirerID,
  Response_CardNumber,
  Response_CardExpiryDate,
  Response_CardHolderName,
  Response_AgentID,
  mobileNo,
  emailId
) {
  let tenantCode = config.parameters.TENANT_CODE;
  let details = localStorage.getItem("Details").replace(/"([^"]+)":/g, "$1:");
  let customerName = localStorage.getItem("customerName");
  let APIquery =
    `query {
      postPaymentProcessing (
        input:
        {
          RequestInput:{
            PaymentType: "` +
    paymentType +
    `"
            Amount:"` +
    amount +
    `"
            CurrencyCode:"` +
    CurrencyCode +
    `"
            PUN:"` +
    PUN +
    `"
            EZConnectResponseDate:"` +
    responseDate +
    `"
            ConfirmationID:"` +
    Response_ConfirmationID +
    `"
            MerchantModuleSessionID:"` +
    MerchantModuleSessionID +
    `"
            Status:"` +
    Response_Status +
    `"
            StatusMessage:"` +
    Response_StatusMessage +
    `"
            MerchantID:"` +
    MerchantID +
    `"
            BankID:"` +
    bankId +
    `"
            Lang:"` +
    lang +
    `"
            AcquirerID:"` +
    Response_AcquirerID +
    `"
            ItemID:""
            CardNumber:"` +
    Response_CardNumber +
    `"
            CardExpiryDate:"` +
    Response_CardExpiryDate +
    `"
            CardHolderName:"` +
    Response_CardHolderName +
    `"
            AgentID:"` +
    Response_AgentID +
    `"
            SecureHash:"` +
    SecureHash +
    `"
            
            userSessionId:"",
            tenantCode:"` +
    tenantCode +
    `",
            userName:"` +
    customerName +
    `" ,
            primaryEmail:"` +
    emailId +
    `",
            propertyCode:"",
            MobileNumber:"` +
    mobileNo +
    `",
            languageCode:"` +
    lang +
    `",
            Connections:` +
    details +
    `       source:"QUICK_PAY"

            }
        })
    }`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function (response) {
        localStorage.removeItem("Details");
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

async function paymentGatewayResponse(
  email,
  paymentType,
  resultIndicator,
  propertyCode,
  MobileNumber,
  languageCode
) {
  let tenantCode = config.parameters.TENANT_CODE;
  let details = localStorage.getItem("Details")?.replace(/"([^"]+)":/g, "$1:");
  let customerName = JSON.stringify(localStorage.getItem("customerName"));
  let vpc_OrderId = localStorage.getItem("orderId");

  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.USAGE_SERVICE_BASE_URL, {
        query:
          `{postPaymentProcessing(input:{RequestInput:{
            tenantCode:"` +
          tenantCode +
          `",Connections:` +
          details +
          `,userName:` +
          customerName +
          `,
            primaryEmail:"` +
          email +
          `", userSessionId:"xxxx",
            PaymentType:"` +
          paymentType +
          `",vpc_OrderId:"` +
          vpc_OrderId +
          `",resultIndicator:"` +
          resultIndicator +
          `",propertyCode:"` +
          propertyCode +
          `",MobileNumber:"` +
          MobileNumber +
          `",languageCode:"` +
          languageCode +
          `" source: "QUICK_PAY",
        }})
        }`,
      })
      .then(function (response) {
        localStorage.removeItem("Details");
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

async function prePaymentProcessingBill(
  billId,
  totalSelAmount,
  paymentType,
  connectionId
) {
  let emailId = localStorage.getItem("email");
  if (!emailId || emailId == "undefined") {
    emailId = "";
  }
  let data = {
    RequestInput: {
      Amount: totalSelAmount,
      PaymentType: paymentType,
      tenantCode: config.parameters.TENANT_CODE,
      source: "BILL_PAY",
      billId: billId,
      ConnectionId: connectionId,
      primaryEmail: localStorage.getItem("email"),
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.BILL_PAY_PRE_PAYMENT, data)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

async function postPaymentProcessingBill(resultIndicator) {
  let email = localStorage.getItem("email");
  let mobileNumber = localStorage.getItem("mobileNo");
  let vpc_OrderId = localStorage.getItem("orderId");
  let paymentType = localStorage.getItem("paymentType");
  let billId = JSON.parse(localStorage.getItem("BillpayDetails"))[0].billId;
  let Amount = JSON.parse(localStorage.getItem("BillpayDetails"))[0].Amount;
  let userName = JSON.parse(localStorage.getItem("BillpayDetails"))[0].userName;
  let accountId = JSON.parse(localStorage.getItem("BillpayDetails"))[0]
    .accountId;
  let data = {
    RequestInput: {
      tenantCode: config.parameters.TENANT_CODE,
      userName: userName,
      primaryEmail: email,
      MobileNumber: mobileNumber,
      languageCode: "en",
      userSessionId: "",
      PaymentType: paymentType,
      resultIndicator: resultIndicator,
      billId: billId,
      accountId: accountId,
      vpc_OrderId: vpc_OrderId,
      Amount: Amount,
      source: "BILL_PAY",
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.BILL_PAY_POST_PAYMENT, data)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

async function prePaymentProcessing(connectionId, totalSelAmount, paymentType) {
  let emailId = localStorage.getItem("email");
  if (!emailId || emailId == "undefined") {
    emailId = "";
  }
  let firstName = localStorage.getItem("firstName");
  if (!firstName || firstName == "undefined") {
    firstName = "";
  }
  let lastName = localStorage.getItem("lastName");
  if (!lastName || lastName == "undefined") {
    lastName = "";
  }
  let mobileNo = localStorage.getItem("mobileNo");
  if (!mobileNo || mobileNo == "undefined") {
    mobileNo = "";
  }
  let details = localStorage.getItem("Details").replace(/"([^"]+)":/g, "$1:");
  let APIquery =
    `query {
    prePaymentProcessing(
      input: {
        RequestInput:{
        Amount: ` +
    totalSelAmount +
    `
       source:"QUICK_PAY"
        PaymentType:"` +
    paymentType +
    `"
        tenantCode: "` +
    config.parameters.TENANT_CODE +
    `"
        primaryEmail:"` +
    emailId +
    `"
      firstName: "` +
    firstName +
    `"
      lastName: "` +
    lastName +
    `"
        Connections: ` +
    details +
    `
      }
    }
    ) 
  }`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

async function getBillDetailsQPay(billId) {
  let acessToken = localStorage.getItem("bearer");
  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.USAGE_SERVICE_BASE_URL,
        {
          query: `{getBillDetailsQPay{
            accountDetails {
              name,
              email,
              phone,
              address,
              accountId,
              currency,
              connectionId
            },
            billDetails {
              billNumber,
              total,
              billDueDate,
              billDate,
              totalAmountDue,
              billRoute,      }       
              summaryOfCharges{ 
                saId, 
                saInfo,
                name,
                value{name,value }
                  }
                }}  
              `,
        },
        {
          headers: {
            billId: billId,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

async function postPaymentProcessingDebitBillPay(
  paymentType,
  amount,
  bankId,
  CurrencyCode,
  responseDate,
  lang,
  MerchantID,
  MerchantModuleSessionID,
  PUN,
  SecureHash,
  ConfirmationID,
  status,
  statusMessage,
  acquirerId,
  cardNumber,
  cardExpiryDate,
  cardHolderName,
  agentID
) {
  let email = localStorage.getItem("email");
  let billId = JSON.parse(localStorage.getItem("BillpayDetails"))[0].billId;
  let username = JSON.parse(localStorage.getItem("BillpayDetails"))[0].userName;
  let accountId = JSON.parse(localStorage.getItem("BillpayDetails"))[0]
    .accountId;
  let mobileNumber = localStorage.getItem("mobileNo");
  let connectionDetails = localStorage.getItem("connectionId");
  let propertyCode = "";
  if (connectionDetails) {
    propertyCode = connectionDetails.propertyCode;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.BILL_PAY_POST_PAYMENT, {
        RequestInput: {
          PaymentType: paymentType,
          Amount: amount,
          CurrencyCode: CurrencyCode,
          PUN: PUN,
          EZConnectResponseDate: responseDate,
          ConfirmationID: ConfirmationID,
          MerchantModuleSessionID: MerchantModuleSessionID,
          Status: status,
          StatusMessage: statusMessage,
          MerchantID: MerchantID,
          BankID: bankId,
          Lang: lang,
          AcquirerID: acquirerId,
          ItemID: "",
          CardNumber: cardNumber,
          CardExpiryDate: cardExpiryDate,
          CardHolderName: cardHolderName,
          AgentID: agentID,
          SecureHash: SecureHash,
          tenantCode: config.parameters.TENANT_CODE,
          userName: username,
          primaryEmail: email,
          propertyCode: propertyCode,
          languageCode: lang,
          userSessionId: "",
          billId: billId,
          accountId: accountId,
          MobileNumber: mobileNumber,
          source: "BILL_PAY",
        },
      })

      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
async function postPaymentProcessingCreditBillPay(paymentType, RequestInput) {
  let tenantCode = config.constants.BASE_TENANT_CODE;
  let email = localStorage.getItem("email");
  let billId = JSON.parse(localStorage.getItem("BillpayDetails"))[0].billId;
  let username = JSON.parse(localStorage.getItem("BillpayDetails"))[0].userName;
  let accountId = JSON.parse(localStorage.getItem("BillpayDetails"))[0]
    .accountId;
  let mobileNumber = localStorage.getItem("mobileNo");
  let connectionDetails = localStorage.getItem("connectionId");
  let propertyCode = "";
  if (connectionDetails) {
    propertyCode = connectionDetails.propertyCode;
  }
  const additionalFields = {
    userName: username,
    primaryEmail: email,
    propertyCode: propertyCode,
    userSessionId: "",
    billId: billId,
    accountId: accountId,
    MobileNumber: mobileNumber,
    source: "BILL_PAY",
    ItemID: "",
    accountId: accountId,
    PaymentType: paymentType,
    tenantCode: tenantCode,
  };
  Object.assign(RequestInput, additionalFields);
  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.BILL_PAY_POST_PAYMENT, {
        RequestInput: RequestInput,
      })

      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
async function postPaymentProcessing(
  paymentType,
  RequestInput,
  emailId,
  billId
) {
  let tenantCode = config.parameters.TENANT_CODE;
  let email = localStorage.getItem("email");
  let mobileNumber = localStorage.getItem("mobileNo");
  let username = localStorage.getItem("customerName").replace(/(^"|"$)/g, "");
  let connectionDetails = localStorage.getItem("connectionId");
  let propertyCode = "";
  let accountId = "";
  if (connectionDetails) {
    propertyCode = connectionDetails.propertyCode;
    accountId = connectionDetails.accountId;
  }
  let details = localStorage.getItem("Details").replace(/"([^"]+)":/g, "$1:");
  const additionalFields = {
    userName: username,
    primaryEmail: email,
    propertyCode: propertyCode,
    userSessionId: "",
    billId: billId,
    accountId: accountId,
    MobileNumber: mobileNumber,
    source: "QUICK_PAY",
    ItemID: "",
    accountId: accountId,
    PaymentType: paymentType,
    tenantCode: tenantCode,
    Connections: details,
  };
  Object.assign(RequestInput, additionalFields);
  let APIquery = generateGraphQLQuery(RequestInput);

  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
